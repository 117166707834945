"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearSelection = void 0;
/**
 * Clears selection.
 *
 * @see {@link https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript}
 */
const clearSelection = () => {
    var _a, _b;
    const selection = (_a = window.getSelection) === null || _a === void 0 ? void 0 : _a.call(window);
    return (selection === null || selection === void 0 ? void 0 : selection.empty)
        ? selection.empty() // Chrome
        : (_b = selection === null || selection === void 0 ? void 0 : selection.removeAllRanges) === null || _b === void 0 ? void 0 : _b.call(selection); // Firefox
};
exports.clearSelection = clearSelection;
