var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { omit } from 'ramda';
import { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { isSpeakingVar } from 'src/cache/reactiveVars';
import { HEADER_HEIGHT, TOOLBAR_HEIGHT } from 'src/constants';
import { Annotation } from 'src/content/annotation/Annotation';
import { FootnotesContext, HeadingContext } from 'src/context';
import { classNames } from 'src/functions';
import { isDelete, isDiscussion, isInputInsert, isRetain, } from 'src/functions/typeGuards';
import { Link } from 'src/ui';
import { Input } from '../Input';
import { useMergeRichTextAnnotationsWithDelta } from './functions';
/**
 * Rich text is inline content that can appear anywhere (e.g. in a paragraph, as
 * a book title, as the name of an organization, etc. Rich text can have markup
 * (bold, italic, hyperlinks, monospaced font, etc.) and can be annotated with
 * highlights, notes, discussions, etc.
 *
 * Inline content is content (typically text) that wraps to a new line when its
 * length exceeds the available width inside the parent block element.
 *
 * This component conditionally wraps its output with 10+ types of semantic
 * formatting (rendered to HTML), and takes additional configuration into
 * account, via `flags`.
 *
 * Note that, apart from text, this component can render other types of inline
 * content, such as inline images and input fields.
 */
export var Text = function (_a) {
    var _b = _a.richTextAnnotations, initialRichTextAnnotations = _b === void 0 ? [] : _b, delta = _a.delta, id = _a.id, sentenceBeingSpoken = _a.sentenceBeingSpoken;
    var hash = useLocation().hash;
    var footnotes = useContext(FootnotesContext).footnotes;
    var isSpeaking = useReactiveVar(isSpeakingVar);
    var richText = useMergeRichTextAnnotationsWithDelta({
        delta: delta,
        richTextAnnotations: initialRichTextAnnotations,
        sentenceBeingSpoken: sentenceBeingSpoken,
    });
    var headingContext = useContext(HeadingContext);
    if (richText == null) {
        return null;
    }
    return (_jsx(_Fragment, { children: richText.ops.map(function (op, index) {
            if (op == null) {
                return null;
            }
            if (isDelete(op) || isRetain(op)) {
                return null;
            }
            /** Used below as key property on returned element */
            var key = index.toString();
            if (isInputInsert(op)) {
                var attributes_1 = op.attributes, insertInput = op.insertInput;
                var _a = attributes_1 !== null && attributes_1 !== void 0 ? attributes_1 : {}, inputId = _a.id, width = _a.width;
                if (insertInput.input === 'text' && id != null && inputId != null) {
                    return (_jsx(Input, { inputId: inputId, richTextId: id, width: width }, key));
                }
                return null; // `insert` is an unidentified object
            }
            var _b = op.annotations, annotations = _b === void 0 ? [] : _b, attributes = op.attributes, _c = op.flags, beingSpoken = _c.beingSpoken, followedByCode = _c.followedByCode, hideExternalLinkIcon = _c.hideExternalLinkIcon, precededByCode = _c.precededByCode, insert = op.insert;
            var _d = attributes !== null && attributes !== void 0 ? attributes : {}, code = _d.code, bold = _d.bold, footnote = _d.footnote, footnoteNumber = _d.footnoteNumber, href = _d.href, italic = _d.italic, smallCaps = _d.smallCaps, subscript = _d.subscript, superscript = _d.superscript, underline = _d.underline;
            if (footnote != null && !footnotes) {
                return null;
            }
            var output = footnote == null ? (insert) : (
            /**
             * Nesting a hyperlink inside another hyperlink is invalid. That’s
             * why
             */
            _jsx(FormattedMessage, __assign({ defaultMessage: "footnote", id: "5mn8gn" }, { children: function (_a) {
                    var _b = __read(_a, 1), footnoteText = _b[0];
                    return (_jsx(FormattedMessage, __assign({ defaultMessage: "reference", id: "zdR3rQ" }, { children: function (referenceText) {
                            var fn = (_jsxs(_Fragment, { children: [_jsx("span", { className: "absolute", 
                                        // `id` should match the `href` attribute given in `Content/layout/Footnotes/index.jsx`
                                        id: "".concat(referenceText[0], "-").concat(footnoteNumber), style: {
                                            marginTop: -HEADER_HEIGHT - TOOLBAR_HEIGHT - 6,
                                        } }), _jsx("sup", { children: footnoteNumber })] }));
                            var footnoteStyle = "inline-block px-[.2em] border-none rounded-[.2em] active:top-0 ".concat(headingContext.level == null
                                ? 'h-[1.05em] leading-[1.6em]'
                                : 'h-[1.2em] text-[.8em] leading-[1.8em]', " ").concat(hash === "#".concat(referenceText, "-").concat(footnoteNumber)
                                ? 'bg-yellow-subtle dark:bg-yellow-darker dark:text-yellow-strong'
                                : 'bg-black/7 dark:bg-white/10');
                            if (annotations.some(isDiscussion)) {
                                /**
                                 * `<a>` cannot appear as a descendant of `<a>`, so we
                                 * avoid a footnote hyperlink when the text might link to
                                 * a chat as well. We might `import { useOverlay } from
                                 * 'src/hooks'` and make the behavior depend on the window
                                 * width, but this seems overkill.
                                 */
                                return _jsx("span", __assign({ className: footnoteStyle }, { children: fn }));
                            }
                            return (_jsx(Link, __assign({ className: "indent-0 ".concat(footnoteStyle), 
                                // `href` should match the `id` given in `Content/layout/Footnotes/index.jsx`
                                href: "#".concat(footnoteText, "-").concat(footnoteNumber) }, { children: fn })));
                        } })));
                } }), key));
            if (typeof output === 'string' && output.includes('\n')) {
                output = output.split('\n').map(function (item, i, a) {
                    return i === a.length - 1 ? (item) : (
                    // eslint-disable-next-line react/no-array-index-key
                    _jsxs(Fragment, { children: [item, _jsx("br", {})] }, i));
                });
            }
            /**
             * We underline the text if the current sentence is being spoken and the
             * voice is active
             */
            var speechUnderline = beingSpoken && isSpeaking;
            /** Anything taken care of with a `<span />`: */
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (smallCaps || underline || speechUnderline) {
                output = (_jsx("span", __assign({ className: classNames(
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    smallCaps && 'small-caps', 
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    underline && 'underline decoration-inherit', // inherit to avoid thick dark underline when annotated by note/comment
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    underline && headingContext.level != null && 'border-b-0', speechUnderline &&
                        'underline decoration-red-subtle decoration-[5px] underline-offset-4 dark:decoration-red-strong dark:decoration-2'), "data-text-to-speech-auto-scroll": speechUnderline ? true : undefined }, { children: output })));
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (superscript) {
                output = _jsx("sup", { children: output });
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (subscript) {
                output = _jsx("sub", { children: output });
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (bold) {
                output = _jsx("strong", { children: output });
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (italic) {
                output = _jsx("em", { children: output });
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (code) {
                // has background color, so nested inside `<Highlight>`
                output = (_jsxs("code", __assign({ className: classNames(precededByCode && 'mr-0 rounded-l-none pl-0', followedByCode && 'mr-0 rounded-r-none pr-0') }, { children: [output, typeof insert === 'string' && insert.endsWith('\n') && _jsx("br", {})] })));
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (href) {
                output = (_jsx(Link, __assign({ hideExternalLinkIcon: hideExternalLinkIcon, href: href }, { children: output })));
            }
            if (annotations.length) {
                return (_jsx(Annotation, __assign({ annotations: annotations.map(function (annotation) {
                        var previousOp = richText.ops[index - 1];
                        var isFirstOccurrence = previousOp == null ||
                            !('annotations' in previousOp) ||
                            !previousOp.annotations.some(function (a) { return a.id === annotation.id; });
                        return __assign(__assign({}, annotation), { displayInSidebar: isFirstOccurrence });
                    }) }, { children: output }), JSON.stringify([
                    // TODO: Improve
                    annotations.map(function (annotation) { return (__assign(__assign({}, annotation), (isDiscussion(annotation)
                        ? {
                            comments: annotation.comments.map(omit(['liked', 'text'])),
                        }
                        : {}))); }),
                    index,
                    insert,
                ])));
            }
            return _jsx(Fragment, { children: output }, key);
        }) }));
};
