var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useApolloClient } from '@apollo/client';
import { map } from 'ramda';
import { NoteFragmentDoc } from 'src/__generated__';
import { cache } from 'src/cache';
import { isNote, isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
// FIXME: issue where second show animation rerenders Book many times
export var useToggleAtBottomOfPage = function () {
    var _a;
    var client = useApolloClient();
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    return function (_a) {
        var id = _a.id;
        /**
         * Flags to stop early. `Array.prototype.find` stops iterating over the
         * array items once its callback function returns `true`.
         */
        var hidden = false;
        var madeVisible = false;
        var toggled = false;
        // `find` instead of `forEach` to be able to stop early
        blocks === null || blocks === void 0 ? void 0 : blocks.find(function (block) {
            // Ramda’s `map` is applied to a `block` object here:
            map(function (value) {
                if (isRichText(value)) {
                    value.richTextAnnotations.forEach(function (_a) {
                        var annotation = _a.annotation;
                        if (isNote(annotation)) {
                            if (annotation.id === id) {
                                if (annotation.shownAtBottomOfPage) {
                                    toggled = true;
                                }
                                else {
                                    madeVisible = true;
                                }
                                client.writeFragment({
                                    data: __assign(__assign({}, annotation), { shownAtBottomOfPage: !annotation.shownAtBottomOfPage }),
                                    fragment: NoteFragmentDoc,
                                    id: cache.identify(annotation),
                                });
                            }
                            else if (annotation.shownAtBottomOfPage) {
                                // annotation.id !== id
                                hidden = true;
                                client.writeFragment({
                                    data: __assign(__assign({}, annotation), { shownAtBottomOfPage: false }),
                                    fragment: NoteFragmentDoc,
                                    id: cache.identify(annotation),
                                });
                            }
                        }
                    });
                }
            }, block);
            return toggled || (hidden && madeVisible);
        });
    };
};
