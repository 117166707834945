var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { animated, useSpring } from '@react-spring/web';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import { classNames } from 'src/functions';
import { Link } from 'src/ui';
// Visually measured as 40 in Safari + 1px safety margin
// Chrome and Firefox leave min. 1 character in place
var ELLIPSIS_WIDTH = 41;
export var Breadcrumb = forwardRef(function (_a, ref) {
    var children = _a.children, classNameProp = _a.className, onKeyDown = _a.onKeyDown, onMouseDown = _a.onMouseDown, path = _a.path, title = _a.title;
    var _b = __read(useMeasure(), 2), hiddenRef = _b[0], fullWidth = _b[1].width;
    var _c = __read(useState(false), 2), isHovered = _c[0], setIsHovered = _c[1];
    var _d = __read(useState(false), 2), isFocused = _d[0], setIsFocused = _d[1];
    var _e = __read(useState(ELLIPSIS_WIDTH), 2), 
    /** The width of a breadcrumb when it’s abbreviated (ending with “...”) */
    minWidth = _e[0], setMinWidth = _e[1];
    useEffect(function () {
        if (Math.ceil(fullWidth) < minWidth) {
            setMinWidth(fullWidth);
        }
    }, [fullWidth, minWidth]);
    var handleBlur = useCallback(function () { return setIsFocused(false); }, []);
    var handleFocus = useCallback(function () { return setIsFocused(true); }, []);
    var handleMouseOver = useCallback(function () { return setIsHovered(true); }, []);
    var handleMouseOut = useCallback(function () { return setIsHovered(false); }, []);
    var style = useSpring({
        config: { friction: 28, mass: 0.8, tension: 300 },
        immediate: isFocused,
        to: {
            minWidth: isHovered ? fullWidth : Math.max(ELLIPSIS_WIDTH, minWidth),
        },
    });
    var className = classNames("z-1 before:left-0 before:absolute basis-full -mr-0.5 box-border text-ellipsis overflow-hidden inline whitespace-nowrap leading-5 text-inherit decoration-inherit transition-width focus:shadow-inner focus:shadow-blue-subtle focus:outline-none dark:focus:shadow-blue-strong ".concat(title
        ? 'font-bold before:inset-y-1 before:block before:w-px before:bg-neutral-305 dark:before:bg-neutral-450'
        : "before:top-1.5 before:inline-block before:font-normal before:text-neutral-450 before:content-['⟩']"), children != null && 'cursor-pointer', classNameProp);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ 
                // Full-width element used to calculate width to animate to
                ref: hiddenRef, className: "".concat(className, " pointer-events-none invisible absolute overflow-visible") }, { children: children })), _jsx(animated.div, __assign({ ref: ref }, (path == null
                ? {
                    'aria-haspopup': 'menu',
                    onKeyDown: onKeyDown,
                    onMouseDown: onMouseDown,
                }
                : {}), { className: "".concat(className, " relative"), onBlur: handleBlur, onFocus: handleFocus, onMouseOut: handleMouseOut, onMouseOver: handleMouseOver, role: "menuitem", style: __assign({ maxWidth: fullWidth }, style), tabIndex: 0 }, { children: path == null ? children : _jsx(Link, __assign({ href: path }, { children: children })) }))] }));
});
Breadcrumb.displayName = 'Breadcrumb';
