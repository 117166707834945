"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.password = void 0;
const MINIMUM_PASSWORD_LENGTH = 6;
const password = (
/** The password to be validated */
input) => {
    if (input) {
        if (input.length < MINIMUM_PASSWORD_LENGTH) {
            return 'PasswordInsufficientLength';
        }
    }
    else {
        return 'PasswordEmpty';
    }
    return null;
};
exports.password = password;
