var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@private/fontawesome/pro-solid-svg-icons/faLightbulb';
import { faLightbulbOn } from '@private/fontawesome/pro-solid-svg-icons/faLightbulbOn';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMedia } from 'react-use';
import { UpdatePreferenceDocument } from 'src/__generated__';
import { useCurrentUserQuery } from 'src/hooks';
import { ToolbarButton } from '../ToolbarButton';
export var ColorTheme = function () {
    var _a;
    var preference = ((_a = useCurrentUserQuery()) !== null && _a !== void 0 ? _a : {}).preference;
    var color = (preference !== null && preference !== void 0 ? preference : {}).color;
    var _b = __read(useState(false), 2), hover = _b[0], setHover = _b[1];
    // TODO: Use values from GraphQL enum converted to TypeScript
    var prefersDark = useMedia('(prefers-color-scheme: dark)');
    var darkModeIsActive = color === 'Dark' || (color === 'Default' && prefersDark);
    var newColor = darkModeIsActive
        ? prefersDark
            ? 'Light'
            : 'Default'
        : prefersDark
            ? 'Default'
            : 'Dark';
    var _c = __read(useMutation(UpdatePreferenceDocument, {
        optimisticResponse: preference && {
            __typename: 'Mutation',
            updatePreference: {
                __typename: 'UpdatePreferencePayload',
                preference: __assign(__assign({}, preference), { color: newColor }),
            },
        },
        variables: { input: { color: newColor } },
    }), 1), updateColor = _c[0];
    if (!preference) {
        return null;
    }
    return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    _jsx("div", __assign({ onMouseOut: function () { return setHover(false); }, onMouseOver: function () { return setHover(true); } }, { children: _jsx(ToolbarButton, { action: updateColor, icon: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: darkModeIsActive ? (hover ? faLightbulbOn : faLightbulb) : faMoon }), tooltip: darkModeIsActive ? (_jsx(FormattedMessage, { defaultMessage: "Light mode", id: "Q7dLbA" })) : (_jsx(FormattedMessage, { defaultMessage: "Dark mode", id: "hMUQH5" })) }) })));
};
