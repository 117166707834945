var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Text } from 'src/content/inline/text/Text';
import { useBreadcrumbs } from 'src/hooks';
import { LoginForm } from 'src/pages/login/loginForm/LoginForm';
import { Link } from 'src/ui';
/** Page shown when the permission requirements haven’t been met */
export var AuthenticationRequired = function (_a) {
    var _b = _a.bookTitle.delta, delta = _b === void 0 ? { __typename: 'Delta', ops: [] } : _b;
    useBreadcrumbs([{ text: delta }]);
    return (_jsxs("main", { children: [_jsx("h1", { children: _jsx(FormattedMessage, { defaultMessage: "Log In", id: "r2Jjms" }) }), _jsx("p", __assign({ className: "indent-0" }, { children: _jsx(FormattedMessage, { defaultMessage: "{_} is not available to users who are not logged in. Log in below or {__} to get access to this book.", id: "8x30Bz", values: {
                        _: (_jsx("em", { children: _jsx(Text, { delta: delta }) })),
                        __: (_jsx(Link, __assign({ href: "/signup" }, { children: _jsx(FormattedMessage, { defaultMessage: "create an account", id: "d3lWas" }) }))),
                    } }) })), _jsx(LoginForm, {})] }));
};
