var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from 'react-helmet-async';
import { useMedia } from 'react-use';
import { rightToLeftLanguages } from 'src/constants';
import { classNames } from './functions';
/**
 * Head component. The contents of `<Helmet />` get inserted into the document’s
 * `<head>` tags. Make sure to include any additional tag names (e.g.
 * `noscript`) in `app.js` for server-side rendering. `defer={false}` sets page
 * title even before background tab is made active
 */
export var Head = function (_a) {
    var color = _a.color, font = _a.font, language = _a.language;
    var prefersContrast = useMedia('(prefers-contrast: more)');
    var contrastMode = color === 'Contrast' || (color === 'Default' && prefersContrast);
    var prefersDark = useMedia('(prefers-color-scheme: dark)');
    var darkMode = color === 'Dark' || (color === 'Default' && prefersDark);
    return (_jsxs(Helmet, __assign({ defer: false }, { children: [_jsx("html", { className: classNames(darkMode ? 'dark' : contrastMode && 'contrast', font === 'Default' ? 'default-font' : font.toLowerCase()), dir: rightToLeftLanguages.includes(language) ? 'rtl' : 'ltr', lang: language }), _jsx("meta", { charSet: "utf-8" }), _jsx("title", { children: "Nextbook" }), _jsx("link", { href: "/style/style.css", rel: "stylesheet" }), _jsx("link", { href: "/style/fonts.css", rel: "stylesheet" }), _jsx("link", { href: "/favicon.ico", rel: "icon" }), _jsx("link", { href: "/manifest.webmanifest", rel: "manifest" }), 
            // prettier-ignore
            [
                { href: '/images/favicon/320x460.png', media: '(device-width: 320px)' },
                { href: '/images/favicon/750x1334.png', media: '(device-width: 375px) and (-webkit-device-pixel-ratio: 2)' },
                { href: '/images/favicon/1242x2208.png', media: '(device-width: 414px) and (-webkit-device-pixel-ratio: 3)' },
                { href: '/images/favicon/640x920.png', media: '(device-width: 320px) and (-webkit-device-pixel-ratio: 2)' },
                { href: '/images/favicon/768x1004.png', media: '(device-width: 768px) and (orientation: portrait)' },
                { href: '/images/favicon/748x1024.png', media: '(device-width: 768px) and (orientation: landscape)' },
                { href: '/images/favicon/1536x2008.png', media: '(device-width: 1536px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
                { href: '/images/favicon/2048x1496.png', media: '(device-width: 1536px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' }
            ].map(function (_a) {
                var href = _a.href, media = _a.media;
                return (_jsx("link", { href: href, media: media }, media));
            }), 
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            [57, 72, 76, 114, 120, 144, 152, 180].map(function (size) { return (_jsx("link", { href: "/images/favicon/".concat(size, ".png"), rel: "apple-touch-icon", sizes: "".concat(size, "x").concat(size) }, size)); }), _jsx("link", { color: "#E4003D", href: "/images/favicon/mask-icon.svg", rel: "mask-icon" }), _jsx("meta", { content: "yes", name: "apple-mobile-web-app-capable" }), _jsx("meta", { content: "black", name: "apple-mobile-web-app-status-bar-style" }), _jsx("meta", { content: "Nextbook", name: "application-name" }), _jsx("meta", { content: "Interactive publishing for higher education", name: "description" }), _jsx("meta", { content: "/images/favicon/70.png", name: "msapplication-square70x70logo" }), _jsx("meta", { content: "/images/favicon/150.png", name: "msapplication-square150x150logo" }), _jsx("meta", { content: "/images/favicon/310.png", name: "msapplication-square310x310logo" }), _jsx("meta", { content: "/images/favicon/144.png", name: "msapplication-TileImage" }), _jsx("meta", { content: "#E4003D", name: "msapplication-TileColor" }), _jsx("meta", { content: "#2c2c2c", name: "theme-color" }), _jsx("meta", { content: "Nextbook", name: "title" }), _jsx("meta", { content: "width=device-width, initial-scale=1", name: "viewport" })] })));
};
