var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { DiscussionButton } from './DiscussionButton';
import { HighlightButton } from './HighlightButton';
import { NoteButton } from './NoteButton';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t# Only used in the payload of annotation creation mutations. Here, the annotation\n\t# object (e.g. discussion, highlight, note) in the mutation payload has a\n\t# field richTextAnnotations containing richText (cf. below).\n\n\t# In the query response, the richText object sits at the base, and has a\n\t# richTextAnnotations field  containing annotation \u2014 so the inverse shape.\n\tfragment annotation on AnnotationInterface {\n\t\trichTextAnnotations {\n\t\t\tid\n\t\t\tlength\n\t\t\tposition\n\t\t\trichText {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\t# Only used in the payload of annotation creation mutations. Here, the annotation\n\t# object (e.g. discussion, highlight, note) in the mutation payload has a\n\t# field richTextAnnotations containing richText (cf. below).\n\n\t# In the query response, the richText object sits at the base, and has a\n\t# richTextAnnotations field  containing annotation \u2014 so the inverse shape.\n\tfragment annotation on AnnotationInterface {\n\t\trichTextAnnotations {\n\t\t\tid\n\t\t\tlength\n\t\t\tposition\n\t\t\trichText {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"
    /** The popup contents (icons) shown when text gets selected */
])));
/** The popup contents (icons) shown when text gets selected */
export var SelectionButtons = function () { return (_jsxs(_Fragment, { children: [_jsx(HighlightButton, {}), _jsx(NoteButton, {}), _jsx(DiscussionButton, {})] })); };
var templateObject_1;
