var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Text } from 'src/content/inline/text/Text';
import { classNames } from 'src/functions';
import { usePrefetch } from 'src/hooks';
import { Link } from 'src/ui';
/** The buttons at the bottom of a book page to navigate back/forward */
export var Button = function (_a) {
    var url = _a.url, label = _a.label, _b = _a.previous, previous = _b === void 0 ? false : _b, title = _a.title;
    var prefetch = usePrefetch(url);
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("link", { href: url, rel: previous ? 'prev' : 'next' }) }), _jsxs(Link, __assign({ className: classNames('group relative my-0 -mx-1.5 block rounded border-none px-1.5 pt-3.5 pb-4 transition-colors duration-150 hover:text-black active:top-0 dark:hover:text-white', previous ? 'text-left' : 'text-right'), href: url, onFocus: prefetch, onMouseOver: prefetch }, { children: [_jsxs("div", __assign({ className: "whitespace-nowrap text-xs font-bold uppercase leading-4 tracking-wide" }, { children: [previous && (
                            // ➳
                            _jsx(FontAwesomeIcon, { className: "absolute right-full mx-0 mb-0 -mt-px text-base", icon: faAngleLeft })), label, !previous && (
                            // ➳
                            _jsx(FontAwesomeIcon, { className: "absolute left-full mx-0 mb-0 -mt-px text-base", icon: faAngleRight }))] })), _jsx("div", __assign({ className: "-mt-0.5 text-base font-light leading-6 text-neutral-400 transition-colors duration-150 group-hover:text-neutral-700 dark:text-neutral-400 dark:group-hover:text-neutral-300" }, { children: _jsx(Text, { delta: title }) }))] }))] }));
};
