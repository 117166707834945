import { useMemo } from 'react';
import { getFormattedPath } from 'src/functions';
import { useBookQuery, useIsChatModeActive, useParams } from 'src/hooks';
import { getCurrentTitle } from './getCurrentTitle';
var getNextTitleIndex = function (_a) {
    // We can’t just look at the first title to know on which page we are: the
    // chapter (i.e. top-level) title is included in the blocks for each page, but
    // is only shown on the first page of the chapter.
    var depthOfPageTitle = _a.depthOfPageTitle, params = _a.params, titles = _a.titles;
    var currentTitle = getCurrentTitle({ params: params, titles: titles });
    if (!currentTitle) {
        return undefined;
    }
    var currentTitleIndex = titles.findIndex(function (_a) {
        var id = _a.id;
        return id === currentTitle.id;
    });
    return titles.findIndex(function (_a, index) {
        var depth = _a.depth;
        return depthOfPageTitle !== undefined &&
            index > currentTitleIndex && // the next title occurs after the current one
            depth <= depthOfPageTitle && // and is eligible for a page of its own
            // ensure that we don’t link to a deeper title on the same page:
            (depth <= currentTitle.depth || // either the next title is not a child
                titles // or the next title is not the first one within its parent
                    .slice(currentTitleIndex + 1, index)
                    .find(function (titleInBetween) { return titleInBetween.depth >= depth; }));
    });
};
export var useNextPage = function () {
    var _a;
    var isChatModeActive = useIsChatModeActive();
    var params = useParams();
    var _b = (_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}, depthOfPageTitle = _b.depthOfPageTitle, titles = _b.titles;
    return useMemo(function () {
        if (!titles) {
            return undefined;
        }
        var nextTitleIndex = getNextTitleIndex({
            depthOfPageTitle: depthOfPageTitle,
            params: params,
            titles: titles,
        });
        if (nextTitleIndex == null) {
            return undefined;
        }
        var title = titles[nextTitleIndex];
        // always remain within the same mode & book:
        if (isChatModeActive || title == null) {
            return undefined;
        }
        var url = "/".concat(params.slice(0, 2).join('/')).concat(getFormattedPath({
            depthOfPageTitle: depthOfPageTitle,
            titles: titles.slice(0, nextTitleIndex + 1),
        }));
        return { title: title, url: url };
    }, [depthOfPageTitle, isChatModeActive, params, titles]);
};
