var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { last } from 'ramda';
/**
 * Content can be wide, in which case the sidebar cannot be used to display
 * annotations. We group sibling blocks that are not interrupted by wide
 * content, so that they can be grouped in a div and so that the annotations can
 * be stopped from overflowing at the bottom of this div.
 *
 * This function introduces one additional level of nesting at most.
 */
export var groupNarrowBlocks = function (blocks) {
    return blocks.reduce(function (acc, curr) {
        if ('wide' in curr && curr.wide) {
            return __spreadArray(__spreadArray([], __read(acc), false), [curr], false); // wide elements aren’t nested
        }
        var lastItem = last(acc); // assigned to a variable to help TypeScript
        if (Array.isArray(lastItem)) {
            return __spreadArray(__spreadArray([], __read(acc.slice(0, acc.length - 1)), false), [__spreadArray(__spreadArray([], __read(lastItem), false), [curr], false)], false);
        }
        return __spreadArray(__spreadArray([], __read(acc), false), [[curr]], false);
    }, []);
};
