"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.walkTheDOM = void 0;
/**
 * @author Douglas Crockford From “JavaScript: The Good Parts”, page 35
 *
 *   Define a function `walk_the_DOM` that visits every node of the tree in HTML
 *   source order, starting from some given node. It invokes a function, passing
 *   it each node in turn. walkTheDOM calls itself to process each of the child
 *   nodes.
 */
const walkTheDOM = (
/** DOM node */
node, 
/** Function taking `Node` as first and only argument */
func) => {
    func(node);
    let next = node.firstChild;
    while (next) {
        (0, exports.walkTheDOM)(next, func);
        next = next.nextSibling;
    }
};
exports.walkTheDOM = walkTheDOM;
