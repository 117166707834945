var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@private/fontawesome/pro-light-svg-icons/faTimes';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useUpdateEffect } from 'react-use';
import { lightboxVar } from 'src/cache/reactiveVars';
import { isElement } from 'src/functions/typeGuards';
export var Lightbox = function (_a) {
    var _b;
    var children = _a.children, onHide = _a.onHide;
    var ref = useRef(null);
    var pathname = useLocation().pathname;
    useUpdateEffect(function () {
        lightboxVar(null);
    }, [pathname]);
    var lightbox = (_b = useReactiveVar(lightboxVar)) !== null && _b !== void 0 ? _b : children;
    var hide = useCallback(function () {
        lightboxVar(null);
        onHide === null || onHide === void 0 ? void 0 : onHide();
    }, [onHide]);
    useEffect(function () {
        var handleMouseDown = function (_a) {
            var _b;
            var target = _a.target;
            if (!isElement(target) || ((_b = ref.current) === null || _b === void 0 ? void 0 : _b.contains(target)) !== true) {
                hide();
            }
        };
        var handleKeyDown = function (_a) {
            var key = _a.key;
            if (key === 'Escape') {
                hide();
            }
        };
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('keydown', handleKeyDown);
        return function () {
            // cleanup
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hide]);
    if (lightbox == null) {
        return null;
    }
    return (_jsx("div", __assign({ className: "pointer-events-none fixed inset-0 z-60 flex items-center justify-center bg-black/20 backdrop-blur-md" }, { children: _jsxs("div", __assign({ ref: ref, className: "pointer-events-auto relative z-60 box-border max-w-3xl animate-appear rounded border border-neutral-250 bg-white/98 py-8 px-4 text-center font-sans backdrop-blur-xl backdrop-saturate-150 supports-blur:bg-white/98 dark:border-transparent dark:bg-gray-850 dark:supports-blur:bg-gray-850 hires:border-0.5" }, { children: [lightbox, _jsx("button", __assign({ className: "absolute right-2 top-2 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full border-none p-2 font-normal shadow-none transition-colors hover:bg-black/9 dark:hover:bg-white/9", onClick: hide, type: "button" }, { children: _jsx(FontAwesomeIcon, { className: "box-border", fixedWidth: true, icon: faTimes }) }))] })) })));
};
