var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var tableTypeDef = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\textend type Table {\n\t\twide: Boolean!\n\t}\n"], ["\n\textend type Table {\n\t\twide: Boolean!\n\t}\n"
    /** Wide block threshold width */
])));
/** Wide block threshold width */
var THRESHOLD = 464; // paragraph column width
var UNDEFINED_COLUMN_WIDTH = 120;
export var Table = {
    fields: {
        wide: function (_existing, _a) {
            var _b;
            var readField = _a.readField;
            var columnWidths = (_b = readField('columnWidths')) !== null && _b !== void 0 ? _b : [];
            return (columnWidths
                .map(function (width) { return (width === 0 ? UNDEFINED_COLUMN_WIDTH : width); })
                .reduce(function (a, b) { return a + b; }, 0) >= THRESHOLD);
        },
    },
};
var templateObject_1;
