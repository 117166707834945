"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.email = void 0;
const email = (
/** The email address to be validated */
input) => {
    if (input) {
        if (!/(.+)@(.+){1,}\.(.+){1,}/.test(input)) {
            return 'EmailInvalid';
        }
    }
    else {
        return 'EmailEmpty';
    }
    return null;
};
exports.email = email;
