var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getCookieDomain } from '@nextbook/shared';
import Cookie from 'js-cookie';
import { useCallback, useState } from 'react';
/**
 * Sets a cookie for the correct domain:
 *
 * - `.lvh.me` in development
 * - `.nextbook.io` in production
 *
 * The domain must be set manually like this (preceded by a dot) to make the
 * cookie available on all subdomains. The default behavior restricts the cookie
 * to the root domain or a single subdomain.
 *
 * An eslint rule (via `eslint-plugin-deprecate`) warns us if we use
 * `Cookie.set` directly, and suggests to use this function instead.
 */
export var useCookie = function (key) {
    var _a = __read(useState(Cookie.get(key)), 2), cookieState = _a[0], setCookieState = _a[1];
    var setCookie = useCallback(function (value, options) {
        if (options === void 0) { options = {}; }
        var SECONDS_PER_DAY = 86400;
        // eslint-disable-next-line deprecate/member-expression
        Cookie.set(key, value, __assign(__assign({ domain: getCookieDomain() }, (options.expires == null
            ? {}
            : // Browsers that support `max-age` will ignore `expires`, which is slightly less robust but better supported.
                { 'max-age': (options.expires * SECONDS_PER_DAY).toString() })), options));
        setCookieState(value);
    }, [key]);
    return [cookieState, setCookie];
};
