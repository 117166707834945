var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Label } from 'src/content/block/title/Label';
import { useBookQuery } from 'src/hooks';
import { Button } from './Button';
import { DefaultTitleLabel } from './DefaultTitleLabel';
import { DEFAULT_SEPARATOR } from './Pagination';
import { useNextPage } from './useNextPage';
export var NextButton = function () {
    var _a, _b, _c, _d, _e;
    var titles = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).titles;
    var _f = (_b = useNextPage()) !== null && _b !== void 0 ? _b : {}, title = _f.title, url = _f.url;
    // Always render an element for flexbox alignment purposes
    if (!title || url == null) {
        return _jsx("div", {});
    }
    var delta = title.text.delta;
    /**
     * If none of the titles have custom labels (like "Part") preceding the title
     * numbers, we use the default naming scheme ("Chapter" & "Section"), because
     * there is no risk of default labels clashing with custom labels at a
     * different depth.
     */
    var alwaysUseDefaultLabel = !(titles === null || titles === void 0 ? void 0 : titles.find(function (t) { return t.label; }));
    // TODO: Use decimals interspersed with dots and include all parent title
    // numbers if no labels, separators or list-style-types were set
    if (alwaysUseDefaultLabel) {
        return (_jsx(Button, { label: _jsx(DefaultTitleLabel, { depth: title.depth, nextLabel: title.numbers == null || title.numbers.length === 0, number: (_c = title.numbers) === null || _c === void 0 ? void 0 : _c.join((_d = title.separator) !== null && _d !== void 0 ? _d : DEFAULT_SEPARATOR) }), title: delta, url: url }));
    }
    if (title.numbers != null && title.numbers.length > 0) {
        return (_jsx(Button, { label: _jsx(Label, __assign({}, title, { label: (_e = title.label) !== null && _e !== void 0 ? _e : (typeof title.depth === 'number' && (_jsx(DefaultTitleLabel, { depth: title.depth }))), numbers: title.numbers })), title: delta, url: url }));
    }
    return (_jsx(Button, { label: _jsx(FormattedMessage, { defaultMessage: "Next", id: "9+Ddtu" }), title: delta, url: url }));
};
