// TODO: Make dependent on how high the header & toolbar is, and whether the toolbar is present
var VISUAL_CORRECTION = 54;
export var findMatchingBreadcrumb = function (_a) {
    var index = _a.index, _b = _a.title, breadcrumbVerticalStartPosition = _b.breadcrumbVerticalStartPosition, depth = _b.depth, titles = _a.titles, 
    /** Vertical scroll position */
    y = _a.y;
    var next = titles.find(function (title, i) { return title.depth <= depth && i > index; });
    if (breadcrumbVerticalStartPosition == null) {
        return false;
    } // happens on chat page
    return (breadcrumbVerticalStartPosition - VISUAL_CORRECTION <= y &&
        ((next === null || next === void 0 ? void 0 : next.breadcrumbVerticalStartPosition) == null ||
            y < next.breadcrumbVerticalStartPosition - VISUAL_CORRECTION));
};
