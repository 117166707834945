var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown as faChevronDownLight } from '@private/fontawesome/pro-light-svg-icons/faChevronDown';
import { faChevronDown } from '@private/fontawesome/pro-solid-svg-icons/faChevronDown';
import { last } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll, scroller } from 'react-scroll';
import { Label } from 'src/content/block/title/Label';
import { Text } from 'src/content/inline/text/Text';
import { classNames } from 'src/functions/classNames';
import { useParams } from 'src/hooks';
export var RecursiveTree = function (_a) {
    var depthOfPageTitle = _a.depthOfPageTitle, maxDepth = _a.maxDepth, _b = _a.numbered, numbered = _b === void 0 ? true : _b, _c = _a.parentDepth, parentDepth = _c === void 0 ? 0 : _c, _d = _a.parentIsCurrentPage, parentIsCurrentPage = _d === void 0 ? false : _d, _e = _a.sidebar, sidebar = _e === void 0 ? false : _e, staticHeaderTitles = _a.staticHeaderTitles, titles = _a.titles;
    var params = useParams();
    var pathname = useLocation().pathname;
    var openedTitle = titles.find(function (_a) {
        var path = _a.path;
        return pathname.startsWith("/book/".concat(params[1], "/").concat(path));
    });
    var initiallyOpenedTitleIndex = titles.findIndex(function (_a) {
        var id = _a.id;
        return id === (openedTitle === null || openedTitle === void 0 ? void 0 : openedTitle.id);
    });
    var _f = __read(useState(initiallyOpenedTitleIndex), 2), open = _f[0], setOpen = _f[1];
    var pathnameRef = useRef(pathname);
    useEffect(function () {
        // Only run this effect on page navigation
        if (pathnameRef.current !== pathname) {
            pathnameRef.current = pathname;
            setOpen(initiallyOpenedTitleIndex);
        }
    }, [pathname, initiallyOpenedTitleIndex]);
    return (_jsx("ol", __assign({ className: classNames(sidebar
            ? "mt-0 text-neutral-500 dark:text-neutral-350 ".concat(parentDepth === 0 ? 'pl-2' : parentDepth === 1 ? 'pl-4' : 'pl-0')
            : parentDepth === 0
                ? 'mt-0 pl-0'
                : 'pl-4', sidebar &&
            parentDepth === depthOfPageTitle &&
            "".concat(parentIsCurrentPage
                ? 'before:bg-black before:opacity-90 dark:before:bg-white'
                : 'before:bg-neutral-250 dark:before:bg-neutral-650', " relative ml-4 before:absolute before:inset-y-1 before:-left-3.5 before:w-1 before:rounded")) }, { children: titles.map(function (title, index) {
            var _a;
            var _b = title.children, children = _b === void 0 ? [] : _b, depth = title.depth, formattedPath = title.formattedPath, id = title.id, label = title.label, numbers = title.numbers, separator = title.separator, style = title.style, text = title.text;
            var breadcrumb = 'breadcrumb' in title ? title.breadcrumb : undefined;
            var offset = depth - parentDepth - 1;
            var HAS_NUMBER = numbers != null && numbers.length > 0 && offset === 0 && numbered;
            // const pageURL = `${pathname}${hash}`
            var titleURL = "/book/".concat(params[1]).concat(formattedPath);
            /** The title whose content is currently in view (just one) */
            var IS_CURRENT_TITLE = 
            // Highlight the current title if it has `breadcrumb === true`. The
            // first few breadcrumbs in the header can be static and may not
            // have `breadcrumb` set to `true`, which is the desired behaviour
            // in this case, because we don’t want to highlight multiple titles
            // at the same time.
            (breadcrumb === true || id === ((_a = last(staticHeaderTitles)) === null || _a === void 0 ? void 0 : _a.id)) &&
                // Additionally, the first title block on the page should be highlighted
                // immediately; that is, even if the user didn’t yet scroll down
                // past the first title. We can use the `header.titles` array for
                // this instead of the `blocks` array from the book query (less
                // data being sent back and forth).
                // Avoid highlighting the parent title if a (more specific) child
                // title that is shown in the table of contents has been highlighted:
                !children.some(function (child) {
                    return 'breadcrumb' in child &&
                        child.breadcrumb &&
                        child.depth <= maxDepth;
                });
            var isCurrentPage = titleURL.split('#')[0] === pathname;
            var hasToggle = children.length > 0 && sidebar && parentDepth === 0;
            return (_jsxs("li", __assign({ className: classNames('relative list-none whitespace-nowrap', sidebar &&
                    depthOfPageTitle != null &&
                    depth === depthOfPageTitle &&
                    'mt-0.5 mb-1', sidebar &&
                    depthOfPageTitle != null &&
                    depth > depthOfPageTitle &&
                    'ml-0', !HAS_NUMBER && 'ml-0') }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [formattedPath == null ? (_jsx(Text, { delta: text.delta })) : (_jsxs(Link, __assign({ className: classNames('border-none', sidebar
                                    ? 'inline-block max-w-full overflow-hidden text-ellipsis transition-colors hover:text-black dark:hover:text-white'
                                    : 'whitespace-normal', IS_CURRENT_TITLE &&
                                    'relative font-bold text-black dark:text-white', depth === 1 && 'font-bold uppercase tracking-wide', hasToggle && 'max-w-fit', 
                                // A title that’s shown on the page, not necessarily in view (can be multiple)
                                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                                (breadcrumb ||
                                    staticHeaderTitles.find(function (_) { return _.id === id; })) &&
                                    'text-black dark:text-white'), onClick: function (_a) {
                                    var metaKey = _a.metaKey, shiftKey = _a.shiftKey;
                                    if (children.length > 0 && sidebar) {
                                        setOpen(function (prev) { return (prev === index ? prev : index); });
                                    }
                                    if (!metaKey && !shiftKey && isCurrentPage) {
                                        var hash = titleURL.split('#')[1];
                                        var smooth = 'easeOutQuint';
                                        if (hash == null) {
                                            animateScroll.scrollToTop({ smooth: smooth });
                                        }
                                        else {
                                            scroller.scrollTo(hash, { smooth: smooth });
                                        }
                                    }
                                    else {
                                        window.scrollTo(0, 0);
                                    }
                                }, to: isCurrentPage && !titleURL.includes('#') ? '#' : titleURL }, { children: [HAS_NUMBER && (_jsx("span", __assign({ className: "whitespace-nowrap" }, { children: _jsx(Label
                                        // Don’t hang things like “Hoofdstuk” off the left margin
                                        // Also, if there’s no title, the label becomes the title
                                        // and shouldn’t hang either.
                                        , { 
                                            // Don’t hang things like “Hoofdstuk” off the left margin
                                            // Also, if there’s no title, the label becomes the title
                                            // and shouldn’t hang either.
                                            hanging: false, label: label, numbers: numbers, separator: separator, style: style }) }))), ' ', _jsx(Text, { delta: text.delta })] }))), hasToggle && (_jsx(FontAwesomeIcon, { className: classNames('relative ml-1 shrink-0 cursor-pointer select-none transition-transform', index === open ? 'mt-0.5 rotate-180' : 'mt-1 rotate-0'), fixedWidth: true, icon: depth <= 2 ? faChevronDown : faChevronDownLight, onClick: function () {
                                    if (children.length > 0 && sidebar) {
                                        setOpen(function (prev) { return (prev === index ? null : index); });
                                    }
                                } }))] })), children.length > 0 &&
                        (!sidebar || depth > 1 || index === open) &&
                        depth !== maxDepth && (_jsx(RecursiveTree, { depthOfPageTitle: depthOfPageTitle, maxDepth: maxDepth, numbered: numbered && !offset, parentDepth: depth, parentIsCurrentPage: isCurrentPage, sidebar: sidebar, staticHeaderTitles: staticHeaderTitles, titles: children }))] }), id));
        }) })));
};
