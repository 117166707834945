var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { classNames } from 'src/functions';
var getClassName = function (score) {
    switch (score) {
        case 0:
            return 'text-red-darker dark:text-red-strong bg-red-subtle dark:bg-red-dark';
        case 1:
            return 'text-orange-darker dark:text-orange-strong bg-orange-subtle dark:bg-orange-dark';
        case 2:
            return 'text-yellow-darker dark:text-yellow-strong bg-yellow-subtle dark:bg-yellow-dark';
        case 3:
            return 'text-green-darker dark:text-green-strong bg-green-subtle dark:bg-green-dark';
        default:
            return 'text-blue-darker dark:text-blue-strong bg-blue-subtle dark:bg-blue-dark';
    }
};
export var PasswordStrengthLabel = function (_a) {
    // We use the score to match against an index in the following two arrays (e.g. a
    // score of 1 corresponds to the second item in the arrays, i.e. orange and “Weak”).
    var score = _a.score;
    var messages = [
        _jsx(FormattedMessage, { defaultMessage: "Very weak", id: "Jhn3HT" }, 0),
        _jsx(FormattedMessage, { defaultMessage: "Weak", id: "fx99AL" }, 1),
        _jsx(FormattedMessage, { defaultMessage: "Moderate", id: "OlIql8" }, 2),
        _jsx(FormattedMessage, { defaultMessage: "Strong", id: "NOTA3a" }, 3),
        _jsx(FormattedMessage, { defaultMessage: "Very strong", id: "H4qy2K" }, 4),
    ];
    return (_jsx("div", __assign({ className: classNames('pointer-events-none -my-0.5 mr-1.5 ml-2 inline-block whitespace-nowrap rounded py-0.5 px-1.5 font-sans font-light', getClassName(score)) }, { children: messages[score] })));
};
