"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deltaToString = void 0;
const typeGuards_1 = require("./typeGuards");
/**
 * Converts a Delta object to a string representation of the same text.
 *
 * @returns The string representation of the Delta
 */
const deltaToString = (input) => input.ops
    .filter(typeGuards_1.isTextInsert)
    .map((op) => op.insert)
    .join('');
exports.deltaToString = deltaToString;
