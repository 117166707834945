import { last } from 'ramda';
export var getNextFocusTarget = function (_a) {
    var _b, _c, _d;
    var _e = _a.leftward, leftward = _e === void 0 ? false : _e, level = _a.level, positions = _a.positions, homeIconRef = _a.homeIconRef, userIconRef = _a.userIconRef;
    if (leftward) {
        if (level === 0 || level === 'HOME_ICON') {
            return homeIconRef;
        }
        if (level === 'USER_ICON' || typeof level !== 'number') {
            var lastBreadcrumb = last(positions.current);
            if (!lastBreadcrumb) {
                return { current: undefined };
            }
            return lastBreadcrumb.breadcrumb;
        }
        return (_b = positions.current[level - 1]) === null || _b === void 0 ? void 0 : _b.breadcrumb;
    }
    // move focus rightwards:
    if (level === 'HOME_ICON') {
        return (_c = positions.current[0]) === null || _c === void 0 ? void 0 : _c.breadcrumb;
    }
    if (level === positions.current.length - 1) {
        return userIconRef;
    }
    if (level === 'USER_ICON' || typeof level !== 'number') {
        return userIconRef;
    }
    return (_d = positions.current[level + 1]) === null || _d === void 0 ? void 0 : _d.breadcrumb;
};
