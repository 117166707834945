var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Block } from 'src/content/block/Block';
import { getChildren } from 'src/content/functions';
import { classNames } from 'src/functions/classNames';
import { useBlocks } from 'src/hooks';
/** Renders a table row in the table header, body, or footer. */
export var TableRow = function (_a) {
    var parent = _a.parent, tableCells = _a.tableCells, isLast = _a.isLast;
    var Cell = parent === 'thead' ? 'th' : 'td';
    var blocks = useBlocks();
    if (!blocks) {
        return null;
    }
    return (_jsx("tr", __assign({ className: classNames(parent === 'tbody' &&
            'border-b border-b-neutral-250 dark:border-b-gray-745', isLast === true && 'last:border-b-0' // don’t use group-last here because className="group" on TableRow affects the hover style for MultipleChoiceOption
        ) }, { children: tableCells.map(function (tableCell) {
            var _a, _b, _c;
            var children = getChildren({ blocks: blocks, parentId: tableCell.id });
            return (_jsx(Cell, __assign({ className: classNames('min-h-6 min-w-[5em] border-b border-b-neutral-250 align-top text-sm lining-nums leading-4 dark:border-b-gray-745', parent === 'thead' &&
                    'font-normal first:rounded-l-sm last:rounded-r-sm dark:font-light', ((_a = tableCell.colSpan) !== null && _a !== void 0 ? _a : 1) > 1 && 'text-left'), colSpan: (_b = tableCell.colSpan) !== null && _b !== void 0 ? _b : undefined, rowSpan: (_c = tableCell.rowSpan) !== null && _c !== void 0 ? _c : undefined }, { children: (children === null || children === void 0 ? void 0 : children.length) ? (children.map(function (block) { return _jsx(Block, { block: block }, block.id); })) : (
                // Prevent empty rows from collapsing
                _jsx(_Fragment, { children: "\u00A0" })) }), tableCell.id));
        }) })));
};
