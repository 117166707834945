import { isTextInsert } from 'src/functions/typeGuards';
/**
 * Calculates character position after element, based on Delta
 *
 * @returns Character position after element
 */
export var getEndPoint = function (
/** Array element index */
index, array) {
    return array.slice(0, index + 1).reduce(function (accumulator, current) {
        return isTextInsert(current)
            ? accumulator + current.insert.length
            : accumulator + 1;
    }, // 1 for non-text (e.g. input field) or footnote numbers
    0);
};
