var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { hasNavVar, toolbarVar } from 'src/cache/reactiveVars';
import { HEADER_HEIGHT, TOOLBAR_HEIGHT } from 'src/constants';
import { NavContext } from 'src/context';
import { classNames } from 'src/functions';
import { useIsShowingNav } from 'src/hooks/useIsShowingNav';
var COMPACT_GRID_MIN_WIDTH = 752;
export var Nav = function (_a) {
    var _b;
    var className = _a.className, children = _a.children, dir = _a.dir, lang = _a.lang;
    var hasNav = useReactiveVar(hasNavVar);
    var isShowingNav = useIsShowingNav().isShowingNav;
    var width = useWindowSize().width;
    var toolbar = useReactiveVar(toolbarVar);
    var showing = (_b = (isShowingNav && width >= COMPACT_GRID_MIN_WIDTH)) !== null && _b !== void 0 ? _b : false;
    // broadcast presence
    useEffect(function () {
        if (hasNav !== showing) {
            hasNavVar(showing);
        }
        return function () {
            if (hasNav) {
                hasNavVar(false);
            }
        };
    }, [hasNav, showing]);
    var navContext = useMemo(function () { return ({ appearsWithinNav: true }); }, []);
    return showing ? (_jsx(NavContext.Provider, __assign({ value: navContext }, { children: _jsx("nav", __assign({ className: classNames('sticky z-2 box-border justify-self-stretch overflow-scroll border-r border-neutral-250 bg-neutral-150/40 px-4 pt-0 pb-20 leading-6 dark:border-neutral-650 dark:bg-gray-830 hires:border-r-0.5', className), dir: dir, lang: lang, style: {
                height: "calc(100vh - ".concat(HEADER_HEIGHT + (toolbar ? TOOLBAR_HEIGHT : 0), "px)"),
                top: HEADER_HEIGHT + (toolbar ? TOOLBAR_HEIGHT : 0),
            } }, { children: children })) }))) : null;
};
