export var getEllipsisDropdownTitles = function (_a) {
    var id = _a.id, titles = _a.titles;
    var startIndex = titles.findIndex(function (title) { return title.id === id; });
    if (startIndex === -1) {
        return [];
    }
    var startTitle = titles[startIndex];
    if (startTitle == null) {
        return [];
    }
    var endIndex = titles.findIndex(function (title, index) { return index > startIndex && title.depth <= startTitle.depth; });
    return titles.slice(startIndex + 1, endIndex === -1 ? Infinity : endIndex);
};
