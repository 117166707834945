var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { LoginForm } from 'src/pages/login/loginForm/LoginForm';
import { Link } from '../Link';
export var NotLoggedIn = function () { return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "text-center font-medium" }, { children: _jsx(FormattedMessage, { defaultMessage: "Keep Track of Your Progress", id: "W2BL11" }) })), _jsxs("div", __assign({ className: "my-6 mx-auto max-w-lg px-4" }, { children: [_jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: "Log in to your Nextbook account to keep track of your progress, annotate your textbooks, and get help from your classmates.", id: "+FLFYX" }) }), _jsx(LoginForm, {}), _jsxs("p", { children: [_jsx(FormattedMessage, { defaultMessage: "Don\u2019t have an account yet?", id: "qIp7NX" }), ' ', _jsxs(Link, __assign({ className: "border-none text-red-accent hover:border-b hover:border-b-red-accent", href: "/signup" }, { children: [_jsx(FormattedMessage, { defaultMessage: "Sign up now", id: "nghzca" }), ' ', _jsx(FontAwesomeIcon, { className: "text-sm", icon: faArrowRight })] }))] })] }))] })); };
