var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@private/fontawesome/pro-light-svg-icons/faSpinnerThird';
/** Spinning icon to show that the page is loading. */
export var Spinner = function () { return (_jsx("div", __assign({ className: "absolute inset-0 flex select-none items-center justify-center text-5xl" }, { children: _jsx("div", __assign({ className: "flex h-16 w-16 items-center justify-center rounded-full bg-neutral-70 backdrop-blur supports-blur:bg-neutral-70/80 theme-contrast:bg-white dark:bg-gray-780 dark:supports-blur:bg-gray-780/80" }, { children: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: faSpinnerThird, spin: true }) })) }))); };
