import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
var messages = defineMessages({
    arabic: { defaultMessage: 'Arabic', id: 'fQ5yno' },
    croatian: { defaultMessage: 'Croatian', id: 'f/jyk+' },
    czech: { defaultMessage: 'Czech', id: 'CACPC+' },
    dutch: { defaultMessage: 'Dutch', id: 'EiEAKD' },
    egyptianArabic: { defaultMessage: 'Egyptian Arabic', id: 'ml3HWG' },
    english: { defaultMessage: 'English', id: 'WkrNSk' },
    french: { defaultMessage: 'French', id: 'njAU72' },
    german: { defaultMessage: 'German', id: 'U/NPbq' },
    greek: { defaultMessage: 'Greek', id: 'gsuLMm' },
    hungarian: { defaultMessage: 'Hungarian', id: 'hfXlpq' },
    italian: { defaultMessage: 'Italian', id: 'NEmnAV' },
    polish: { defaultMessage: 'Polish', id: '+ozGrs' },
    portuguese: { defaultMessage: 'Portuguese', id: 'A4UTjl' },
    romanian: { defaultMessage: 'Romanian', id: '5UH1sh' },
    russian: { defaultMessage: 'Russian', id: 'kTt/ND' },
    slovenian: { defaultMessage: 'Slovenian', id: 'YqsgWz' },
    spanish: { defaultMessage: 'Spanish', id: '8WtyrD' },
    turkish: { defaultMessage: 'Turkish', id: 'PurNgG' },
    ukrainian: { defaultMessage: 'Ukrainian', id: 'RIUFf+' },
    welsh: { defaultMessage: 'Welsh', id: '3MC1Yh' },
});
export var useLanguageName = function () {
    var intl = useIntl();
    return useCallback(function (
    /** ISO 639-1 code */
    language) {
        switch (language) {
            case 'ar':
                return {
                    emoji: '🇸🇦',
                    native: 'اَلْعَرَبِيَّةُ‎',
                    translated: intl.formatMessage(messages.arabic),
                };
            case 'cs':
                return {
                    emoji: '🇨🇿',
                    native: 'Čeština',
                    translated: intl.formatMessage(messages.czech),
                };
            case 'cy':
                return {
                    emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
                    native: 'Welsh',
                    translated: intl.formatMessage(messages.welsh),
                };
            case 'de':
                return {
                    emoji: '🇩🇪',
                    native: 'Deutsch',
                    translated: intl.formatMessage(messages.german),
                };
            case 'el':
                return {
                    emoji: '🇬🇷',
                    native: 'Ελληνικά',
                    translated: intl.formatMessage(messages.greek),
                };
            case 'en':
                return {
                    emoji: '🇬🇧',
                    native: 'English',
                    translated: intl.formatMessage(messages.english),
                };
            case 'es':
                return {
                    emoji: '🇪🇸',
                    native: 'Español',
                    translated: intl.formatMessage(messages.spanish),
                };
            case 'fr':
                return {
                    emoji: '🇫🇷',
                    native: 'Français',
                    translated: intl.formatMessage(messages.french),
                };
            case 'hr':
                return {
                    emoji: '🇭🇷',
                    native: 'Croatian',
                    translated: intl.formatMessage(messages.croatian),
                };
            case 'hu':
                return {
                    emoji: '🇭🇺',
                    native: 'Magyar',
                    translated: intl.formatMessage(messages.hungarian),
                };
            case 'it':
                return {
                    emoji: '🇮🇹',
                    native: 'Italiano',
                    translated: intl.formatMessage(messages.italian),
                };
            case 'nl':
                return {
                    emoji: '🇳🇱',
                    native: 'Nederlands',
                    translated: intl.formatMessage(messages.dutch),
                };
            case 'pl':
                return {
                    emoji: '🇵🇱',
                    native: 'Polski',
                    translated: intl.formatMessage(messages.polish),
                };
            case 'pt':
                return {
                    emoji: '🇵🇹',
                    native: 'Português',
                    translated: intl.formatMessage(messages.portuguese),
                };
            case 'ro':
                return {
                    emoji: '🇷🇴',
                    native: 'Română',
                    translated: intl.formatMessage(messages.romanian),
                };
            case 'ru':
                return {
                    emoji: '🇷🇺',
                    native: 'Русский',
                    translated: intl.formatMessage(messages.russian),
                };
            case 'sl':
                return {
                    emoji: '🇸🇮',
                    native: 'Slovenščina',
                    translated: intl.formatMessage(messages.slovenian),
                };
            case 'tr':
                return {
                    emoji: '🇹🇷',
                    native: 'Türkçe',
                    translated: intl.formatMessage(messages.turkish),
                };
            case 'uk':
                return {
                    emoji: '🇺🇦',
                    native: 'Українська',
                    translated: intl.formatMessage(messages.ukrainian),
                };
        }
    }, [intl]);
};
