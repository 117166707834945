var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { QuitGuidedTourDocument } from 'src/__generated__';
import { useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
/**
 * The user icon in the upper left corner on every page. The component is
 * exported as a named export as well, so that it gets recognized by React
 * Styleguidist.
 */
export var UserButton = forwardRef(function (_a, ref) {
    var _b;
    var onKeyDown = _a.onKeyDown;
    var _c = (_b = useCurrentUserQuery()) !== null && _b !== void 0 ? _b : {}, firstName = _c.firstName, id = _c.id, preference = _c.preference;
    var _d = (preference !== null && preference !== void 0 ? preference : {}).guidedTourStep, guidedTourStep = _d === void 0 ? null : _d; // `{}` when not logged in
    var _e = __read(useMutation(QuitGuidedTourDocument), 1), quitGuidedTour = _e[0];
    var handleClick = useCallback(function () {
        if (guidedTourStep === 'ProfileButton') {
            quitGuidedTour();
        }
    }, [guidedTourStep, quitGuidedTour]);
    var handleKeyDown = useCallback(function (event) {
        if (guidedTourStep === 'ProfileButton') {
            quitGuidedTour();
        }
        onKeyDown(event);
    }, [guidedTourStep, onKeyDown, quitGuidedTour]);
    return (_jsxs(Link, __assign({ ref: ref, className: "relative -top-px z-50 flex items-center overflow-hidden whitespace-nowrap px-3 pt-1.5 pb-1 font-light leading-5 focus:outline-none focus:ring-inset focus:ring-blue-subtle dark:focus:ring-blue-dark", href: id == null ? '/login' : '/profile', onClick: handleClick, onKeyDown: handleKeyDown }, { children: [firstName !== null && firstName !== void 0 ? firstName : _jsx(FormattedMessage, { defaultMessage: "Sign In", id: "Ub+AGc" }), ' ', _jsx(FontAwesomeIcon, { className: "relative ml-1", icon: faUserCircle })] })));
});
UserButton.displayName = 'UserButton';
