import { mergeArrayByIdField } from '../fieldPolicies';
import { Discussion, discussionTypeDef } from './Discussion';
import { Note, noteTypeDef } from './Note';
import { RichText, richTextTypeDef } from './RichText';
import { Table, tableTypeDef } from './Table';
import { Title, titleTypeDef } from './Title';
export var typeDefs = [
    discussionTypeDef,
    noteTypeDef,
    richTextTypeDef,
    tableTypeDef,
    titleTypeDef,
];
// By default, `__typename` and `id` are used together to uniquely identify
// items in the store, but sometimes more information is needed to tell objects apart.
export var typePolicies = {
    AllBooksPayload: {
        fields: {
            books: mergeArrayByIdField,
        },
    },
    Book: {
        fields: {
            authors: mergeArrayByIdField,
            organizations: mergeArrayByIdField,
            readers: mergeArrayByIdField,
            units: mergeArrayByIdField,
        },
    },
    /** `Delta` doesn’t contain an id of its own */
    Discussion: Discussion,
    /**
     * The `Footnote` type is defined on the client side only, and so don’t have
     * id’s of their own. Each footnote is uniquely defined by the `id` if the
     * `RichText` element it was cut out of, and the `index` at which it
     * occurred.
     */
    Footnote: { keyFields: ['__typename', 'index', 'richTextId'] },
    Group: {
        fields: {
            admins: mergeArrayByIdField,
            organizations: mergeArrayByIdField,
            users: mergeArrayByIdField,
        },
    },
    Note: Note,
    Organization: {
        fields: {
            books: mergeArrayByIdField,
            units: mergeArrayByIdField,
            users: mergeArrayByIdField,
        },
    },
    RichText: RichText,
    Table: Table,
    Title: Title,
    User: {
        fields: {
            booksWhereAuthor: mergeArrayByIdField,
            booksWhereReader: mergeArrayByIdField,
            emails: mergeArrayByIdField,
            organizations: mergeArrayByIdField,
            units: mergeArrayByIdField,
        },
    },
};
