var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var useMaxDimensions = function () {
    var _a = __read(useState({
        maxHeight: document.documentElement.clientHeight,
        maxWidth: document.documentElement.clientWidth,
    }), 2), dimensions = _a[0], setDimensions = _a[1];
    useEffect(function () {
        var handleResize = function () {
            return setDimensions({
                maxHeight: document.documentElement.clientHeight,
                maxWidth: document.documentElement.clientWidth,
            });
        };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return dimensions;
};
