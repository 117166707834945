import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { classNames } from 'src/functions';
export var useColorOptions = function () {
    var intl = useIntl();
    var options = useMemo(function () { return [
        {
            borderColor: classNames('border-red-darker'),
            className: classNames('bg-red-subtle dark:bg-red-strong'),
            color: 'Red',
            title: intl.formatMessage({ defaultMessage: 'Red', id: 'D+ZFS/' }),
        },
        {
            borderColor: classNames('border-orange-darker'),
            className: classNames('bg-orange-subtle dark:bg-orange-strong'),
            color: 'Orange',
            title: intl.formatMessage({ defaultMessage: 'Orange', id: 'n3m1xt' }),
        },
        {
            borderColor: classNames('border-yellow-darker'),
            className: classNames('bg-yellow-subtle dark:bg-yellow-strong'),
            color: 'Yellow',
            title: intl.formatMessage({ defaultMessage: 'Yellow', id: '5Tw3Ta' }),
        },
        {
            borderColor: classNames('border-green-darker'),
            className: classNames('bg-green-subtle dark:bg-green-strong'),
            color: 'Green',
            title: intl.formatMessage({ defaultMessage: 'Green', id: '1W4okO' }),
        },
        {
            borderColor: classNames('border-blue-darker'),
            className: classNames('bg-blue-subtle dark:bg-blue-strong'),
            color: 'Blue',
            title: intl.formatMessage({ defaultMessage: 'Blue', id: 'k2tJ9k' }),
        },
    ]; }, [intl]);
    return options;
};
