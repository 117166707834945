var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useId, useRef } from 'react';
import { useHover } from 'react-use';
import { classNames } from 'src/functions';
import { Link } from 'src/ui';
import { Tooltip } from 'src/ui/Tooltip';
/** Button prototype */
export var ToolbarButton = function (_a) {
    var action = _a.action, _b = _a.active, active = _b === void 0 ? false : _b, className = _a.className, _c = _a.disabled, disabled = _c === void 0 ? false : _c, href = _a.href, icon = _a.icon, tooltip = _a.tooltip, _d = _a.showing, showing = _d === void 0 ? true : _d, _e = _a.isSquare, isSquare = _e === void 0 ? true : _e, passThroughProps = __rest(_a, ["action", "active", "className", "disabled", "href", "icon", "tooltip", "showing", "isSquare"]);
    var anchorRef = useRef(null);
    var buttonRef = useRef(null);
    var id = useId();
    var classNameProp = classNames('relative inline-flex h-10 cursor-pointer items-center justify-center overflow-hidden text-sm text-neutral-750 transition-all duration-200 theme-contrast:text-black dark:text-neutral-250', showing ? isSquare && 'w-10' : 'pointer-events-none w-0', disabled
        ? 'opacity-30'
        : 'hover:bg-black/5 hover:text-black active:bg-black/9 dark:hover:bg-white/5 dark:hover:text-white dark:active:bg-white/9', active && 'bg-black/5 dark:bg-white/5 dark:text-white', className);
    var button = href == null ? (_jsx("button", __assign({ ref: buttonRef, "aria-hidden": !showing, "aria-labelledby": id, className: classNameProp, disabled: disabled, onClick: action, type: "button" }, passThroughProps, { children: icon }))) : (_jsx(Link, __assign({ ref: anchorRef, "aria-labelledby": id, className: classNameProp, href: href }, passThroughProps, { children: icon })));
    var _f = __read(useHover(button), 2), hoverable = _f[0], hovered = _f[1];
    return (_jsxs(_Fragment, { children: [hoverable, tooltip != null && (_jsx(Tooltip, __assign({ buttonRef: href == null ? buttonRef : anchorRef, id: id, showing: hovered }, { children: tooltip })))] }));
};
ToolbarButton.displayName = 'ToolbarButton';
