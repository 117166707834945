var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { map } from 'ramda';
import { NoteFragmentDoc, UpdateNoteDocument } from 'src/__generated__';
import { isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation UpdateNote($input: UpdateNoteInput!) {\n\t\tupdateNote(input: $input) {\n\t\t\t... on UpdateNotePayload {\n\t\t\t\tnote {\n\t\t\t\t\tid\n\t\t\t\t\ttext\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation UpdateNote($input: UpdateNoteInput!) {\n\t\tupdateNote(input: $input) {\n\t\t\t... on UpdateNotePayload {\n\t\t\t\tnote {\n\t\t\t\t\tid\n\t\t\t\t\ttext\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var useUpdateNote = function () {
    var _a;
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    var _b = __read(useMutation(UpdateNoteDocument, {
        // explicitly update the note so that the overlay content gets updated as well
        update: function (cache, _a) {
            var data = _a.data;
            if ((data === null || data === void 0 ? void 0 : data.updateNote.__typename) !== 'UpdateNotePayload') {
                return;
            }
            var note = data.updateNote.note;
            blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                // `map` from Ramda (below) is applied to an object:
                map(function (value) {
                    if (isRichText(value)) {
                        value.richTextAnnotations.forEach(function (_a) {
                            var annotation = _a.annotation;
                            if (annotation.id === note.id) {
                                var newNote = __assign(__assign({}, annotation), { text: note.text });
                                cache.writeFragment({
                                    data: newNote,
                                    fragment: NoteFragmentDoc,
                                    id: cache.identify(newNote),
                                });
                            }
                        });
                    }
                }, block);
            });
        },
    }), 1), updateNote = _b[0];
    return updateNote;
};
var templateObject_1;
