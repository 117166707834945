import { useEffect } from 'react';
import { headerVar } from 'src/cache/reactiveVars';
export var useHeader = function (
/**
 * Will get " — Nextbook" appended in <title />, if it doesn’t already contain
 * "Nextbook".
 */
pageTitle) {
    useEffect(function () {
        headerVar(pageTitle);
        return function () {
            headerVar(undefined);
        };
    }, [pageTitle]);
};
