var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContinueGuidedTourDocument } from 'src/__generated__';
import { classNames } from 'src/functions/classNames';
import { useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
/** The icon in the upper left corner on every page. */
export var Logo = forwardRef(function (_a, ref) {
    var _b;
    var children = _a.children, className = _a.className, color = _a.color, onKeyDown = _a.onKeyDown, style = _a.style;
    var preference = ((_b = useCurrentUserQuery()) !== null && _b !== void 0 ? _b : {}).preference;
    var _c = (preference !== null && preference !== void 0 ? preference : {}).guidedTourStep, guidedTourStep = _c === void 0 ? null : _c; // `{}` when not logged in
    var _d = __read(useMutation(ContinueGuidedTourDocument), 1), continueGuidedTour = _d[0];
    var handleClick = useCallback(function (event) {
        if (guidedTourStep === 'Welcome') {
            event.preventDefault(); // Don’t follow hyperlink
            continueGuidedTour();
        }
    }, [continueGuidedTour, guidedTourStep]);
    // const handleKeyDown = useCallback((event: KeyboardEvent<HTMLAnchorElement>) => {
    // 	if (guidedTourStep === 'Welcome') {
    // 		event.preventDefault() // Don’t follow hyperlink
    // 		continueGuidedTour()
    // 	} else onKeyDown()
    // }, [])
    return (_jsx(FormattedMessage, __assign({ defaultMessage: "Home", id: "ejEGdx" }, { children: function (_a) {
            var _b = __read(_a, 1), msg = _b[0];
            return (_jsxs(Link, __assign({ ref: ref, "aria-label": msg, className: classNames('relative z-50 mb-0 box-content inline-block w-4 min-w-[16px] overflow-hidden bg-no-repeat px-2.5 pt-2 pb-1 align-bottom text-sm leading-5 focus:outline-none focus:ring focus:ring-blue-subtle dark:focus:ring-blue-strong', className), href: "/", id: "icon", onClick: handleClick, onKeyDown: onKeyDown, style: __assign(__assign({}, style), { 
                    // TODO: rewrite to Tailwind:
                    backgroundPosition: '11px 6px', backgroundSize: '16px 16px' }) }, { children: [_jsxs("svg", __assign({ className: "relative fill-black", clipRule: "evenodd", fillRule: "evenodd", height: "16px", strokeLinejoin: "round", strokeMiterlimit: 1.41421, style: { fill: color !== null && color !== void 0 ? color : 'currentColor' }, version: "1.1", viewBox: "0 0 16 16", width: "16px" }, { children: [_jsx("g", __assign({ transform: "matrix(0.0541215,0,0,0.051503,-20.2065,-7.15445)" }, { children: _jsx("g", { transform: "matrix(18.477,-0,-0,19.4164,373.354,138.913)" }) })), _jsx("g", __assign({ transform: "matrix(0.0541215,0,0,0.051503,-20.2065,-7.15445)" }, { children: _jsx("path", { d: "M521.17,172.377L632.031,239.637L632.031,416.112L521.17,348.852L410.308,416.112L410.308,240.532L521.17,172.377Z" }) }))] })), children] })));
        } })));
});
Logo.displayName = 'Logo';
