var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var titleTypeDef = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\textend type Title {\n\t\t\"\"\"\n\t\tIndicates whether the title is currently being shown in the header\n\t\t\"\"\"\n\t\tbreadcrumb: Boolean\n\t\t\"\"\"\n\t\tVertical position below which the breadcrumb is shown\n\t\t\"\"\"\n\t\tbreadcrumbVerticalStartPosition: Int\n\t}\n"], ["\n\textend type Title {\n\t\t\"\"\"\n\t\tIndicates whether the title is currently being shown in the header\n\t\t\"\"\"\n\t\tbreadcrumb: Boolean\n\t\t\"\"\"\n\t\tVertical position below which the breadcrumb is shown\n\t\t\"\"\"\n\t\tbreadcrumbVerticalStartPosition: Int\n\t}\n"])));
export var Title = {
    fields: {
        breadcrumb: function (_) { return _ !== null && _ !== void 0 ? _ : false; },
        breadcrumbVerticalStartPosition: function (_) { return _ !== null && _ !== void 0 ? _ : null; },
    },
};
var templateObject_1;
