import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/destructuring-assignment */
import { FormattedMessage } from 'react-intl';
/** @returns A generic title label for a given depth */
export var DefaultTitleLabel = function (props) {
    var depth = props.depth;
    switch (depth) {
        case 1: {
            if ('nextLabel' in props && props.nextLabel) {
                return _jsx(FormattedMessage, { defaultMessage: "Next chapter", id: "HnmoFs" });
            }
            if ('previousLabel' in props && props.previousLabel) {
                return (_jsx(FormattedMessage, { defaultMessage: "Previous chapter", id: "zMeZa6" }));
            }
            return (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Chapter", id: "dOgCSN" }), 'number' in props && props.number && _jsxs(_Fragment, { children: [" ", props.number] })] }));
        }
        default:
            if ('nextLabel' in props && props.nextLabel) {
                return _jsx(FormattedMessage, { defaultMessage: "Next section", id: "gVB2Pv" });
            }
            if ('previousLabel' in props && props.previousLabel) {
                return (_jsx(FormattedMessage, { defaultMessage: "Previous section", id: "pKXuL2" }));
            }
            return (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Section", id: "q1Cd7m" }), 'number' in props && props.number && _jsxs(_Fragment, { children: [" ", props.number] })] }));
    }
};
