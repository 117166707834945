var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext, useEffect, useRef } from 'react';
import { SelectableContext } from 'src/context';
export var useSelectableRef = function (id) {
    var ref = useRef(null);
    var setSelectableElements = useContext(SelectableContext).setSelectableElements;
    useEffect(function () {
        var current = ref.current;
        if (current && id != null) {
            setSelectableElements(function (selectableElements) {
                var _a;
                return id in selectableElements
                    ? selectableElements
                    : __assign(__assign({}, selectableElements), (_a = {}, _a[id] = current, _a));
            });
        }
        return function () {
            if (id != null) {
                setSelectableElements(function (selectableElements) {
                    var _a = selectableElements, _b = id, _this = _a[_b], remaining = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                    return remaining;
                });
            }
        };
    }, [id, setSelectableElements]);
    return ref;
};
