var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@private/fontawesome/pro-regular-svg-icons';
import { classNames } from 'src/functions';
var getClassNameForVariant = function (variant) {
    switch (variant) {
        case 'danger':
            return classNames('border-red-dark/70 bg-red-accent/80 text-white dark:border dark:border-red-strong dark:bg-transparent dark:text-red-strong');
        case 'neutral':
            return classNames('border-black/60 bg-black/5 text-black/80 disabled:border-black/40 disabled:bg-transparent disabled:text-black/50 dark:border-white/30 dark:bg-black/20 dark:text-white/80 dark:disabled:border-white/40 dark:disabled:text-white/50');
    }
};
export var Button = function (_a) {
    var children = _a.children, className = _a.className, disabled = _a.disabled, _b = _a.loading, loading = _b === void 0 ? false : _b, icon = _a.icon, _c = _a.variant, variant = _c === void 0 ? 'neutral' : _c, props = __rest(_a, ["children", "className", "disabled", "loading", "icon", "variant"]);
    return (_jsxs("button", __assign({ type: "button" // default value
     }, props, { className: classNames('relative mt-0 inline-flex select-none items-center whitespace-nowrap rounded-md border border-b-4 py-1.5 px-3 align-bottom font-sans text-lg font-light shadow-md transition-all active:mt-[3px] active:border-b active:shadow-none disabled:active:mt-0 disabled:active:border-b-4 disabled:active:shadow-md', loading ? 'cursor-progress' : 'disabled:cursor-default', getClassNameForVariant(variant), className), disabled: disabled === true || loading }, { children: [icon && (_jsx(FontAwesomeIcon, { className: "mr-2", fixedWidth: true, icon: loading ? faSpinnerThird : icon, spin: loading })), children] })));
};
