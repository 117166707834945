var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from 'src/functions';
import { PasswordStrengthLabel } from './PasswordStrengthLabel';
import { useWarningDictionary } from './useWarningDictionary';
/** Input element with validation */
export var ValidatedInput = forwardRef(function (_a, ref) {
    var autoFocus = _a.autoFocus, defaultValue = _a.defaultValue, displayStrength = _a.displayStrength, errorMessage = _a.errorMessage, name = _a.name, onChange = _a.onChange, placeholder = _a.placeholder, style = _a.style, type = _a.type, value = _a.value;
    var warningDictionary = useWarningDictionary();
    var _b = __read(useState({}), 2), passwordStrength = _b[0], setPasswordStrength = _b[1];
    var zxcvbn = useRef();
    /** Lazy-load information on commonly used passwords */
    var getZxcvbn = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!displayStrength) return [3 /*break*/, 2];
                    return [4 /*yield*/, import('zxcvbn')];
                case 1:
                    data = _a.sent();
                    zxcvbn.current = data.default;
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [displayStrength]);
    // cDM — lazy loading
    useEffect(function () {
        getZxcvbn();
    }, [getZxcvbn]);
    var handleChange = useCallback(function (event) {
        var _a;
        var newValue = event.target.value;
        if (onChange) {
            onChange(event);
        } // Update parent state with current input value
        if (displayStrength && zxcvbn.current) {
            var evaluation = zxcvbn.current(newValue);
            setPasswordStrength(newValue.length > 0
                ? {
                    message: ((_a = warningDictionary[evaluation.feedback.warning]) !== null && _a !== void 0 ? _a : '')
                        .replace(/(\w)'(\w)/g, '$1’$2') // Convert straight to curly quotes
                        .replace(/'(\w)/g, '‘$1')
                        .replace(/(\w)'/g, '$1’')
                        .replace(/"(\w)/g, '“$1')
                        .replace(/(\w)"/g, '$1”'),
                    score: evaluation.score,
                }
                : {});
        }
    }, [displayStrength, onChange, warningDictionary]);
    var message = passwordStrength.message, score = passwordStrength.score;
    return (_jsxs("div", __assign({ className: "relative flex w-full flex-col pr-3", style: style }, { children: [_jsx("input", { 
                // TODO: Evaluate whether `autoFocus` is forgivable for the login & signup page, from a usability perspective
                ref: ref, autoFocus: autoFocus, className: classNames('mx-0 mt-px -mb-px box-border block w-full rounded-md border border-neutral-200 bg-neutral-50 py-2.5 px-3 font-sans text-base font-light text-neutral-750 dark:border-neutral-600 dark:bg-neutral-800 dark:text-white', errorMessage == null
                    ? undefined
                    : 'border-red-accent dark:border-red-strong'), defaultValue: defaultValue, name: name, onChange: handleChange, placeholder: placeholder, type: type, value: value }), errorMessage != null && (_jsx("div", __assign({ className: "pointer-events-none absolute right-5 -bottom-2.5 box-border inline-block whitespace-nowrap bg-neutral-70 bg-gradient-to-b from-neutral-50 to-neutral-70 px-1 text-right font-sans text-xs font-light leading-5 text-red-accent theme-contrast:bg-white theme-contrast:to-white dark:bg-gray-780 dark:from-neutral-800 dark:to-gray-780 dark:text-red-strong" }, { children: errorMessage }))), type === 'password' && displayStrength && (_jsxs("div", __assign({ className: "absolute -bottom-6 box-border flex h-5 w-[200%] flex-nowrap items-start pt-2.5 pr-3 text-xs" }, { children: [score != null && _jsx(PasswordStrengthLabel, { score: score }), message != null && (_jsx("div", __assign({ style: { color: '#999' } }, { children: "".concat(message).concat(!message || message.endsWith('.') ? '' : '.') })))] })))] })));
});
ValidatedInput.displayName = 'ValidatedInput';
