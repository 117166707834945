var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'ramda';
import { useCallback } from 'react';
import { writeRichTextFragment } from 'src/content/inline/text/writeRichTextFragment';
import { isDiscussion, isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
import { writeDiscussionFragment } from './graphql/writeDiscussionFragment';
/**
 * Removes a comment from its discussion, and, if the removed comment is the
 * first comment within the discussion, removes the entire discussion.
 */
export var useRemoveCommentFromDiscussion = function () {
    var _a;
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    return useCallback(function (_a) {
        var cache = _a.cache, commentId = _a.commentId, deleted = _a.deleted;
        if (deleted) {
            blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                // `map` from Ramda (below) is applied to an object:
                map(function (value) {
                    if (isRichText(value)) {
                        value.richTextAnnotations.forEach(function (_a) {
                            var _b;
                            var annotation = _a.annotation;
                            if (isDiscussion(annotation) &&
                                annotation.comments.find(function (_a) {
                                    var id = _a.id;
                                    return id === commentId;
                                })) {
                                // delete the entire discussion if the first comment (the question) was deleted
                                if (((_b = annotation.comments[0]) === null || _b === void 0 ? void 0 : _b.id) === commentId) {
                                    writeRichTextFragment({
                                        cache: cache,
                                        data: __assign(__assign({}, value), { richTextAnnotations: value.richTextAnnotations.filter(function (_a) {
                                                var id = _a.annotation.id;
                                                return id !== annotation.id;
                                            }) }),
                                    });
                                }
                                else {
                                    writeDiscussionFragment({
                                        cache: cache,
                                        data: __assign(__assign({}, annotation), { comments: annotation.comments.filter(function (_a) {
                                                var id = _a.id;
                                                return id !== commentId;
                                            }) }),
                                    });
                                }
                            }
                        });
                    }
                }, block);
            });
        }
    }, [blocks]);
};
