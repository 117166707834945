var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { clearSelection } from '@nextbook/shared';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { highlightPopupVar, modeVar, selectionPopupVar, showingHighlightsVar, } from 'src/cache/reactiveVars';
import { classNames } from 'src/functions';
import { position as popupPosition } from 'src/ui/popup/functions';
var useColorData = function (color) {
    var intl = useIntl();
    switch (color) {
        case 'Red':
            return {
                className: classNames('bg-red-subtle text-red-darker ring-red-subtle dark:bg-red-dark dark:text-red-strong dark:ring-red-dark'),
                colorString: intl.formatMessage({
                    defaultMessage: 'Red highlight',
                    id: 'gHZ7ca',
                }),
            };
        case 'Orange':
            return {
                className: classNames('bg-orange-subtle text-orange-darker ring-orange-subtle dark:bg-orange-dark dark:text-orange-strong dark:ring-orange-dark'),
                colorString: intl.formatMessage({
                    defaultMessage: 'Orange highlight',
                    id: 'AHQg1m',
                }),
            };
        case 'Green':
            return {
                className: classNames('bg-green-subtle text-green-darker ring-green-subtle dark:bg-green-dark dark:text-green-strong dark:ring-green-dark'),
                colorString: intl.formatMessage({
                    defaultMessage: 'Green highlight',
                    id: 'tV6NTm',
                }),
            };
        case 'Blue':
            return {
                className: classNames('bg-blue-subtle text-blue-darker ring-blue-subtle dark:bg-blue-dark dark:text-blue-strong dark:ring-blue-dark'),
                colorString: intl.formatMessage({
                    defaultMessage: 'Blue highlight',
                    id: 'UUOkhZ',
                }),
            };
        default:
            return {
                className: classNames('bg-yellow-subtle text-yellow-darker ring-yellow-subtle dark:bg-yellow-dark dark:text-yellow-strong dark:ring-yellow-dark'),
                colorString: intl.formatMessage({
                    defaultMessage: 'Yellow highlight',
                    id: 'Bb+qcw',
                }),
            };
    }
};
export var Highlight = function (_a) {
    var children = _a.children, _b = _a.color, color = _b === void 0 ? 'Yellow' : _b, _c = _a.hasHyperlink, hasHyperlink = _c === void 0 ? false : _c, id = _a.id, _d = _a.keyboardFocusTarget, keyboardFocusTarget = _d === void 0 ? true : _d, style = _a.style;
    var mode = useReactiveVar(modeVar);
    var highlightPopup = useReactiveVar(highlightPopupVar);
    var ref = useRef(null);
    var selectionPopup = useReactiveVar(selectionPopupVar);
    var showingHighlights = useReactiveVar(showingHighlightsVar);
    var toggle = useCallback(function (event) {
        if (id != null && !hasHyperlink) {
            clearSelection();
            // TODO: Make sure popup is always within window
            var _a = 'clientX' in event
                ? {
                    x: event.clientX + window.scrollX,
                    y: event.clientY + window.scrollY - 3,
                }
                : popupPosition(event.currentTarget.getBoundingClientRect(), event.currentTarget.getClientRects()), x = _a.x, y = _a.y;
            highlightPopupVar(id === (highlightPopup === null || highlightPopup === void 0 ? void 0 : highlightPopup.highlightId)
                ? undefined
                : { highlightId: id, x: x, y: y });
            if (selectionPopup != null) {
                selectionPopupVar(undefined);
            }
        }
    }, [hasHyperlink, highlightPopup === null || highlightPopup === void 0 ? void 0 : highlightPopup.highlightId, id, selectionPopup]);
    useEffect(function () {
        var handleGlobalMouseDown = function (_a) {
            var _b;
            var target = _a.target;
            if (((_b = ref.current) === null || _b === void 0 ? void 0 : _b.contains(target)) !== true &&
                highlightPopup != null) {
                highlightPopupVar(undefined);
            }
        };
        document.addEventListener('mousedown', handleGlobalMouseDown);
        return function () {
            document.removeEventListener('mousedown', handleGlobalMouseDown);
        };
    }, [highlightPopup]);
    var handleKeyDown = useCallback(function (event) {
        switch (event.key) {
            case 'Enter':
                toggle(event);
                break;
            case 'Escape': {
                if (highlightPopup != null) {
                    highlightPopupVar(undefined);
                }
                break;
            }
            case ' ': {
                toggle(event);
                event.preventDefault();
                break;
            }
        }
    }, [highlightPopup, toggle]);
    var handleMouseDown = useCallback(function (event) {
        // `event.button` is 0 for left click and 2 for right click
        if (event.button === 0) {
            toggle(event);
            if (id != null && !hasHyperlink) {
                event.preventDefault(); // Prevent text selection in highlight on doubleclick
                event.stopPropagation(); // Prevent popup from being hidden immediately
            }
        }
    }, [hasHyperlink, id, toggle]);
    var _e = useColorData(color), className = _e.className, colorString = _e.colorString;
    var ariaOwnsId = id == null ? undefined : "ariaId".concat(id);
    var highlightIdMatches = highlightPopup != null &&
        'highlightId' in highlightPopup &&
        highlightPopup.highlightId === id;
    if (showingHighlights) {
        var isActive = mode == null;
        return (_jsx("span", __assign({ ref: ref }, (isActive
            ? {
                'aria-haspopup': 'menu',
                'aria-label': colorString,
                'aria-owns': ariaOwnsId,
                'aria-pressed': highlightIdMatches,
                onKeyDown: handleKeyDown,
                onMouseDown: handleMouseDown,
                role: hasHyperlink ? 'button' : 'link',
                tabIndex: 0,
            }
            : {}), { "aria-flowto": highlightIdMatches ? 'popup' : undefined, className: classNames(id != null && isActive && 'cursor-pointer', 'relative rounded box-decoration-clone ring-1 focus:ring-4', className), id: keyboardFocusTarget ? undefined : ariaOwnsId, style: style }, { children: children })));
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment -- required by TypeScript
    return _jsx(_Fragment, { children: children });
};
