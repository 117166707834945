/** Enables keyboard navigation */
export var handleKeyDown = function (action) { return function (event) {
    if (event.key === 'Enter') {
        action();
    }
    if (event.key === ' ') {
        action();
        event.preventDefault();
    }
}; };
