var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { HEADER_HEIGHT, TOOLBAR_HEIGHT } from 'src/constants';
import { Text } from 'src/content/inline/text/Text';
import { FootnotesContext } from 'src/context';
import { classNames } from 'src/functions';
import { Link } from 'src/ui';
import { getFootnotesFromBlocks } from './getFootnotesFromBlocks';
/**
 * Broadcasts presence so that `RichText` components know that they can include
 * a footnote number with hyperlink to the footnote, when applicable.
 */
var useSetFootnotes = function () {
    var setFootnotes = useContext(FootnotesContext).setFootnotes;
    useEffect(function () {
        setFootnotes(true);
        return function () { return setFootnotes(false); };
    }, [setFootnotes]);
};
/**
 * Section at the bottom of a page where the footnotes are shown, preceding the
 * pagination buttons
 */
export var Footnotes = function (_a) {
    var blocks = _a.blocks;
    useSetFootnotes();
    var hash = useLocation().hash;
    var footnoteBlocks = useMemo(function () { return getFootnotesFromBlocks(blocks); }, [blocks]);
    if (footnoteBlocks.length === 0) {
        return null;
    }
    return (_jsx("ol", __assign({ className: "relative flex flex-col gap-3 list-decimal col-content mt-24 pl-6 before:absolute before:bottom-full before:left-0 before:mb-4 before:block before:w-1/3 before:border-t before:border-t-neutral-750 theme-default-font:font-serif theme-dyslexia:font-dyslexia theme-dyslexia:text-sm theme-lowres:font-lowres theme-lowres:text-sm theme-sans:font-sans theme-sans:text-[15px] theme-serif:font-serif theme-serif:text-lg theme-default-font:dark:font-sans theme-default-font:dark:text-[15px] hires:before:border-t-0.5" }, { children: footnoteBlocks.map(function (text, index) { return (_jsx(FormattedMessage, __assign({ defaultMessage: "footnote", id: "5mn8gn" }, { children: function (_a) {
                var _b = __read(_a, 1), footnoteText = _b[0];
                return (_jsxs("li", __assign({ className: classNames('-mx-2 px-2 -my-1 py-1 rounded', hash === "#".concat(footnoteText, "-").concat(index + 1) &&
                        'bg-yellow-subtle dark:bg-yellow-darker dark:text-yellow-strong transition-colors') }, { children: [_jsx("span", { className: "absolute", id: "".concat(footnoteText, "-").concat(index + 1), 
                            // `id` should match the `href` attribute given in `<Text />`
                            style: { marginTop: -HEADER_HEIGHT - TOOLBAR_HEIGHT - 10 } }), _jsx(Text, __assign({}, text)), ' ', _jsx(FormattedMessage, __assign({ defaultMessage: "reference", id: "zdR3rQ" }, { children: function (_a) {
                                var _b = __read(_a, 1), referenceText = _b[0];
                                return (_jsx(Link, __assign({ className: "inline-block h-4 select-none rounded border-none bg-black/5 px-1 text-xs leading-5 dark:bg-white/10", 
                                    // `href` should match the `id` given in `<Text />`
                                    href: "#".concat(referenceText, "-").concat(index + 1) }, { children: "\u21A9\uFE0E" })));
                            } }))] })));
            } }), (index + 1).toString())); }) })));
};
