import { useMemo } from 'react';
import { useLanguageName } from './useLanguageName';
export var useLanguageList = function () {
    var languageName = useLanguageName();
    return useMemo(function () {
        // Keep up-to-date with `translate:extract-strings` in `package.json`
        var data = [
            'ar',
            'cs',
            'cy',
            'de',
            'el',
            'en',
            'es',
            'fr',
            'hr',
            'hu',
            'it',
            'nl',
            'pl',
            'pt',
            'ro',
            'ru',
            'sl',
            'tr',
            'uk',
        ];
        return data.map(function (language) {
            var _a = languageName(language), translated = _a.translated, emoji = _a.emoji, native = _a.native;
            return {
                emoji: emoji,
                iso: language,
                name: { native: native, translated: translated },
            };
        });
    }, [languageName]);
};
