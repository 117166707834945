var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClosestParent } from '@nextbook/shared';
import { faEllipsisH } from '@private/fontawesome/pro-light-svg-icons/faEllipsisH';
import { animated, config, useTransition } from '@react-spring/web';
import { equals, last, reverse } from 'ramda';
import { createRef, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbsDocument, ContinueGuidedTourDocument, } from 'src/__generated__';
import { breadcrumbsVar } from 'src/cache/reactiveVars';
import { Text } from 'src/content/inline/text/Text';
import { FootnotesContext, GuidedTourContext } from 'src/context';
import { classNames, deltaToString, removeFootnotes } from 'src/functions';
import { useBookQuery, useCurrentUserQuery, useParams } from 'src/hooks';
import { MIN_BOOK_PARAMS } from 'src/hooks/useParams';
import { MINIMUM_HORIZONTAL_DROPDOWN_MARGIN } from './constants';
import { DropdownMenu } from './dropdownMenu/DropdownMenu';
import { DropdownMenuItem } from './dropdownMenu/DropdownMenuItem';
import { GuidedTourDropdownMenu } from './dropdownMenu/GuidedTourDropdownMenu';
import { getEllipsisDropdownTitles, getNextFocusTarget, groupTitlesPerDropdownMenu, } from './functions';
import { useHandleResize, useHandleScroll, usePositions, } from './hooks';
import { AdminButton, Breadcrumb, EditorToggle, Logo, UserButton, } from './items';
import { SettingsButton } from './items/SettingsButton';
var DROPDOWN_MENU_MARGIN_LEFT = -30;
/**
 * Fallback for horizontal dropdown menu position, roughly aligned with the
 * first menu item, in case something went wrong
 */
var DROPDOWN_HORIZONTAL_POSITION_FALLBACK = 38;
/** Small header with breadcrumbs and dropdown menus */
export var Breadcrumbs = function () {
    var _a, _b, _c;
    var staticBreadcrumbs = useReactiveVar(breadcrumbsVar).map(function (breadcrumb) {
        var text = typeof breadcrumb.text === 'string'
            ? {
                __typename: 'Delta',
                ops: [{ __typename: 'TextInsert', insert: breadcrumb.text }],
            }
            : breadcrumb.text;
        return __assign(__assign({}, breadcrumb), { static: true, text: text });
    });
    var pageTitle = reverse(staticBreadcrumbs)
        .map(function (breadcrumb) { return deltaToString(removeFootnotes(breadcrumb.text)); })
        .join(' — ') || 'Nextbook';
    var params = useParams();
    var _d = __read(params, 1), mode = _d[0];
    /**
     * Criterion to skip queries: when we’re not in a book view
     *
     * TODO: Make more robust
     */
    var skip = params.length < MIN_BOOK_PARAMS ||
        mode == null ||
        !['book', 'edit', 'summary'].includes(mode);
    var blocks = ((_a = useBookQuery({ skip: skip })) !== null && _a !== void 0 ? _a : {}).blocks;
    /** Depth of currently opened dropdown menu, if any */
    var _e = __read(useState(), 2), dropdownMenu = _e[0], setDropdownMenu = _e[1];
    var _f = __read(useMutation(ContinueGuidedTourDocument), 1), continueGuidedTour = _f[0];
    var preference = ((_b = useCurrentUserQuery()) !== null && _b !== void 0 ? _b : {}).preference;
    var guidedTourStep = (preference !== null && preference !== void 0 ? preference : {}).guidedTourStep; // `{}` when not logged in
    var setDropdownMenuCb = useCallback(function (newMenu) {
        setDropdownMenu(newMenu);
        if (newMenu == null
            ? guidedTourStep === 'DropDownMenu'
            : guidedTourStep === 'Breadcrumbs') {
            continueGuidedTour();
        }
    }, [continueGuidedTour, guidedTourStep]);
    var linkAction = useCallback(function () {
        setDropdownMenuCb();
        window.scroll(0, 0);
    }, [setDropdownMenuCb]);
    // || (params.length === 3 && params[2] === 'table-of-contents')
    // ['admin', 'confirm', 'reset-password'].includes(params[0])
    var data = useQuery(BreadcrumbsDocument, {
        skip: skip,
        variables: { bookParams: params.slice(0, 2) },
    }).data;
    var book = (data === null || data === void 0 ? void 0 : data.book.__typename) === 'BookPayload' ? data.book.book : undefined;
    var _g = book !== null && book !== void 0 ? book : {}, _h = _g.depthOfPageTitle, depthOfPageTitle = _h === void 0 ? null : _h, _j = _g.titles, titles = _j === void 0 ? [] : _j;
    /**
     * References to the DOM element for the home icon, each breadcrumb level, and
     * ellipsis icon
     */
    var dropdownMenuRef = useRef(null);
    var homeIconRef = useRef(null);
    var userIconRef = useRef();
    var dynamicBreadcrumbs = useMemo(function () {
        return (blocks !== null && blocks !== void 0 ? blocks : []).filter(function (block) {
            return block.__typename === 'Title' &&
                Boolean(block.breadcrumb) &&
                !staticBreadcrumbs.map(function (_a) {
                    var id = _a.id;
                    return id;
                }).includes(block.id);
        });
    }, [blocks, staticBreadcrumbs]);
    var breadcrumbs = useMemo(function () {
        var _a, _b;
        return __spreadArray(__spreadArray([], __read(staticBreadcrumbs
            // Restructured for uniformity with `Block_Title_Fragment`
            .map(function (breadcrumb) { return (__assign(__assign({}, breadcrumb), { text: { delta: breadcrumb.text } })); })
            .slice(0, (_b = (_a = dynamicBreadcrumbs[0]) === null || _a === void 0 ? void 0 : _a.depth) !== null && _b !== void 0 ? _b : Infinity)), false), __read(dynamicBreadcrumbs), false);
    }, [dynamicBreadcrumbs, staticBreadcrumbs]);
    /** Titles to be shown in the ellipsis dropdown menu */
    var ellipsisSiblings = getEllipsisDropdownTitles({
        id: (_c = last(breadcrumbs)) === null || _c === void 0 ? void 0 : _c.id,
        titles: titles,
    });
    /** Wrapped in `useRef` to prevent redoing calculation on every render */
    var groupedTitles = useRef(groupTitlesPerDropdownMenu(titles));
    useEffect(function () {
        // when the data is loading, `titles` is an empty array, so we need to manually
        // set `groupedTitles` when we can.
        if (titles.length && !groupedTitles.current.length) {
            groupedTitles.current = groupTitlesPerDropdownMenu(titles);
        }
    }, [titles]);
    var positions = usePositions({
        breadcrumbs: breadcrumbs,
        ellipsisSiblings: ellipsisSiblings,
        groupedTitles: groupedTitles,
    });
    /**
     * Changes the focus, e.g. when a breadcrumb is in focus and the user presses
     * the left or right arrow key.
     */
    var changeFocus = useCallback(function (_a) {
        var _b;
        var event = _a.event, _c = _a.leftward, leftward = _c === void 0 ? false : _c, level = _a.level;
        var current = ((_b = getNextFocusTarget({
            homeIconRef: homeIconRef,
            leftward: leftward,
            level: level,
            positions: positions,
            userIconRef: userIconRef,
        })) !== null && _b !== void 0 ? _b : {}).current;
        if (current == null) {
            return;
        }
        current.focus();
        event.preventDefault();
    }, [homeIconRef, positions, userIconRef]);
    /**
     * Indicator of how the dropdown menu is being positioned (from the right edge
     * of the browser window, relative to a breadcrumb, or full-width on narrow
     * screens).
     */
    var _k = __read(useState('BREADCRUMB'), 2), horizontalPositioningMode = _k[0], setHorizontalPositioningMode = _k[1];
    /**
     * @returns Flag, used to ignore events that are handled elsewhere, in case
     *   the breadcrumb is in focus (see `handleKeyDown`)
     */
    var breadcrumbInFocus = useCallback(function (_a) {
        var target = _a.target;
        return Boolean(target &&
            positions.current
                .map(function (_a) {
                var current = _a.breadcrumb.current;
                return current;
            }
            /**
             * Type assertion probably unavoidable, @see
             * {@link https://stackoverflow.com/a/43851475}
             */
            )
                .includes(target));
    }, [positions]);
    /** @returns Whether the element in focus is a menu item */
    var menuItemInFocus = useCallback(function (event) {
        return positions.current
            .flatMap(function (position) { return position.menuItems; })
            .map(function (menuItem) { return menuItem.current; })
            .includes(event.target);
    }, [positions]);
    useEffect(function () {
        var handleGlobalKeyDown = function (event) {
            var _a, _b, _c;
            switch (event.key) {
                case 'ArrowDown':
                    if (dropdownMenu !== undefined &&
                        !breadcrumbInFocus(event) &&
                        !menuItemInFocus(event)) {
                        if (typeof dropdownMenu === 'number') {
                            (_c = (_b = (_a = positions.current[dropdownMenu]) === null || _a === void 0 ? void 0 : _a.menuItems[0]) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.focus();
                            event.preventDefault(); // prevent scroll
                        }
                    }
                    break;
                case 'ArrowLeft':
                    if (dropdownMenu !== undefined &&
                        !breadcrumbInFocus(event) &&
                        !menuItemInFocus(event)) {
                        setDropdownMenuCb(typeof dropdownMenu === 'number' && dropdownMenu > 0
                            ? dropdownMenu - 1
                            : undefined);
                        changeFocus({ event: event, leftward: true, level: dropdownMenu });
                    }
                    break;
                case 'ArrowRight':
                    if (dropdownMenu !== undefined &&
                        !breadcrumbInFocus(event) &&
                        !menuItemInFocus(event)) {
                        setDropdownMenuCb(typeof dropdownMenu === 'number' &&
                            dropdownMenu < breadcrumbs.length - 1
                            ? dropdownMenu + 1
                            : undefined);
                        changeFocus({ event: event, level: dropdownMenu });
                    }
                    break;
                case 'Escape':
                    if (dropdownMenu != null) {
                        setDropdownMenuCb(undefined);
                        event.preventDefault(); // e.g. prevent exiting full screen mode on macOS
                    }
                    break;
                case 'Tab':
                    // e.g. when the focus is on a menu item in the dropdown menu
                    if (dropdownMenu !== undefined &&
                        !breadcrumbInFocus(event) &&
                        !menuItemInFocus(event)
                    // document.activeElement.tagName === 'BODY'
                    ) {
                        if (event.shiftKey) {
                            setDropdownMenuCb(typeof dropdownMenu === 'number' && dropdownMenu > 0
                                ? dropdownMenu - 1
                                : undefined);
                            changeFocus({ event: event, leftward: true, level: dropdownMenu });
                        }
                        else {
                            setDropdownMenuCb(typeof dropdownMenu === 'number' &&
                                dropdownMenu < breadcrumbs.length - 1
                                ? dropdownMenu + 1
                                : undefined);
                            changeFocus({ event: event, level: dropdownMenu });
                        }
                    }
                    break;
                default:
            }
        };
        var handleGlobalMouseDown = function (_a) {
            var button = _a.button, target = _a.target;
            if (!getClosestParent(target, '._preventHandleMouseDown') &&
                button === 0) {
                // left mouse button
                setDropdownMenuCb(undefined);
            }
        };
        window.addEventListener('keydown', handleGlobalKeyDown);
        window.addEventListener('mousedown', handleGlobalMouseDown);
        return function () {
            // cleanup
            window.removeEventListener('keydown', handleGlobalKeyDown);
            window.removeEventListener('mousedown', handleGlobalMouseDown);
        };
    }, [
        breadcrumbInFocus,
        breadcrumbs.length,
        changeFocus,
        dropdownMenu,
        menuItemInFocus,
        positions,
        setDropdownMenuCb,
    ]);
    /** Flag indicating whether an ellipsis icon is shown */
    var ellipsis = Boolean((ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings.length) > 0);
    var refs = useContext(GuidedTourContext);
    /** Used by `react-spring` to animate dropdown menu */
    var transition = useTransition(dropdownMenu, {
        config: __assign(__assign({}, config.stiff), { tension: 300 }),
        enter: { opacity: 1, top: 40 },
        from: { opacity: 0, top: 20 },
        leave: {
            opacity: 0,
            /**
             * Ensure that a dropdown menu that moves up while being hidden, doesn’t
             * steal a click from the underlying breadcrumb.
             */
            pointerEvents: 'none',
            top: 20,
        },
    });
    var handleResize = useHandleResize({
        dropdownElement: dropdownMenuRef,
        dropdownMenu: dropdownMenu,
        horizontalPositioningMode: horizontalPositioningMode,
        positions: positions,
        setHorizontalPositioningMode: setHorizontalPositioningMode,
    });
    var toggleDropdownMenu = useCallback(function (_a, 
    /** 0-based breadcrumb index (0 = book, 1 = chapter etc) */
    level) {
        var target = _a.target;
        if (!getClosestParent(target, '._preventToggleDropdownMenu')) {
            // if the dropdown menu for this level was open: hide all
            setDropdownMenuCb(function (previous) {
                return previous === level ? undefined : level;
            });
            var TIMEOUT = 100; // small timeout to ensure the element is in the DOM
            window.setTimeout(handleResize, TIMEOUT);
        }
    }, [handleResize, setDropdownMenuCb]);
    var handleKeyDown = function (
    /** 0-based breadcrumb index (0 = book, 1 = chapter etc) */
    level) {
        return function (event) {
            var _a, _b, _c, _d, _e;
            var _f = (typeof level === 'number' ? (_a = positions.current[level]) !== null && _a !== void 0 ? _a : {} : {}).menuItems, menuItems = _f === void 0 ? [] : _f;
            /** Index of menu item in focus (see also `breadcrumbInFocus`) */
            var focusedMenuItemIndex = menuItems
                .map(function (menuItem) { return menuItem.current; })
                .findIndex(equals(event.target));
            switch (event.key) {
                case 'ArrowDown':
                    // dropdown menu must be in view to change focus
                    if (typeof dropdownMenu === 'number' && dropdownMenu === level) {
                        // dropdown menu was already showing
                        var current = ((_c = (_b = positions.current[level]) === null || _b === void 0 ? void 0 : _b.menuItems[focusedMenuItemIndex === -1
                            ? 0 // breadcrumb is in focus ⟶ focus first menu item
                            : Math.min(focusedMenuItemIndex + 1, menuItems.length - 1) // wrap around on last item
                        ]) !== null && _c !== void 0 ? _c : {}).current;
                        current === null || current === void 0 ? void 0 : current.focus();
                    }
                    else {
                        // dropdown menu was hidden
                        setDropdownMenuCb(function (prevDropdownMenu) {
                            return prevDropdownMenu === level ? prevDropdownMenu : level;
                        });
                    }
                    event.preventDefault(); // prevent scroll
                    break;
                case 'ArrowLeft':
                    if (dropdownMenu !== undefined) {
                        // only move the dropdown menu if it’s already open
                        setDropdownMenuCb(typeof level === 'number' && level > 0 ? level - 1 : 0);
                    }
                    changeFocus({ event: event, leftward: true, level: level });
                    break;
                case 'ArrowRight':
                    if (dropdownMenu !== undefined) {
                        // only move the dropdown menu if it’s already open
                        setDropdownMenuCb(typeof level === 'number' &&
                            (level < breadcrumbs.length - 1 ||
                                (level === breadcrumbs.length - 1 && ellipsis))
                            ? level + 1
                            : undefined);
                    }
                    changeFocus({ event: event, level: level });
                    break;
                case 'ArrowUp':
                    // dropdown menu should exist
                    if (dropdownMenu === level) {
                        // dropdown menu was already showing
                        if (focusedMenuItemIndex !== -1 && typeof level === 'number') {
                            var current = ((_e = (_d = positions.current[level]) === null || _d === void 0 ? void 0 : _d.menuItems[(focusedMenuItemIndex - 1) % menuItems.length]) !== null && _e !== void 0 ? _e : {}).current;
                            current === null || current === void 0 ? void 0 : current.focus();
                        }
                    }
                    event.preventDefault(); // prevent scroll
                    break;
                case 'Enter':
                    toggleDropdownMenu(event, level);
                    break;
                case ' ':
                    toggleDropdownMenu(event, level);
                    event.preventDefault(); // prevent pagedown
                    break;
                case 'Tab':
                    if (breadcrumbInFocus(event) || menuItemInFocus(event)) {
                        // When the dropdown menu is _hiding_, not _hidden_, the menu items
                        // can still receive focus if the user tabs immediately after triggering
                        // the hide animation. In order to prevent this, we manually move the
                        // focus and prevent the default behaviour.
                        changeFocus({ event: event, leftward: event.shiftKey, level: level });
                        if (dropdownMenu !== undefined) {
                            setDropdownMenuCb(event.shiftKey && typeof level === 'number' && level > 0
                                ? // move dropdown menu to the left
                                    level - 1
                                : !event.shiftKey &&
                                    typeof level === 'number' &&
                                    (level < breadcrumbs.length - 1 ||
                                        (level === breadcrumbs.length - 1 && ellipsis))
                                    ? // move dropdown menu to the right
                                        level + 1
                                    : undefined);
                        }
                        event.stopPropagation(); // prevents handleGlobalKeyDown from being called
                    }
                    else if (event.target === userIconRef.current && event.shiftKey) {
                        changeFocus({ event: event, leftward: true, level: level });
                    }
                    break;
                default:
            }
        };
    };
    var handleMouseDown = function (
    /** 0-based breadcrumb index (0 = book, 1 = chapter etc) */
    level) {
        return function (event) {
            if (event.button === 0) {
                // left mouse button
                toggleDropdownMenu(event, level);
                event.preventDefault();
            }
        };
    };
    useHandleScroll({ blocks: blocks });
    /**
     * The buttons on the top right are put here, so that they can be given extra
     * props, so that they work nicely with keyboard navigation.
     */
    var topRightButtons = [
        SettingsButton,
        AdminButton,
        EditorToggle,
        UserButton,
    ].map(function (Component, index) { return (_jsx(Component, { ref: index === 2 ? refs.profileButton : createRef(), onKeyDown: handleKeyDown(breadcrumbs.length + index) }, index)); });
    var setFootnotes = useContext(FootnotesContext).setFootnotes;
    var footnotesContext = useMemo(function () { return ({ footnotes: false, setFootnotes: setFootnotes }); }, [setFootnotes]);
    if (staticBreadcrumbs.length === 0) {
        return null;
    }
    return (
    // Don’t show footnote numbers in the header
    _jsxs(FootnotesContext.Provider, __assign({ value: footnotesContext }, { children: [_jsx(Helmet, __assign({ defer: false }, { children: _jsxs("title", { children: [pageTitle, pageTitle.includes('Nextbook') ? '' : ' — Nextbook'] }) })), _jsxs("div", __assign({ className: "relative z-5 box-border flex select-none justify-between border-b border-b-neutral-250 bg-white text-sm backdrop-blur-xl backdrop-saturate-150 supports-blur:bg-white/80 dark:border-b-neutral-650 dark:bg-gray-850 dark:text-neutral-70 dark:supports-blur:bg-gray-850 hires:border-b-0.5 dark:hires:border-b", role: "menubar" }, { children: [_jsx(Logo, { ref: homeIconRef, onKeyDown: handleKeyDown('HOME_ICON') }), _jsxs("div", __assign({ ref: refs.breadcrumbs, className: "box-border flex w-full flex-nowrap overflow-hidden" }, { children: [positions.current.map(function (_a, index) {
                                var ref = _a.breadcrumb;
                                var breadcrumb = breadcrumbs[index];
                                return (_jsx(Breadcrumb, __assign({ 
                                    /**
                                     * The ellipsis icon doesn’t have an `id` — but its position can
                                     * be taken by a title that does, so we use both `id` and
                                     * `index` for now.
                                     */
                                    ref: ref, 
                                    // eslint-disable-next-line tailwindcss/no-custom-classname
                                    className: classNames('_preventHandleMouseDown', index === positions.current.length - 1 && ellipsis
                                        ? 'z-1 flex items-center pb-0 pr-4 pl-3 pt-0.5'
                                        : 'px-3 pt-1.5 pb-1', 
                                    // show full-size if the corresponding dropdown is open, or if rightmost
                                    (index === dropdownMenu ||
                                        index === positions.current.length - 1 ||
                                        (ellipsis && index === positions.current.length - 2)) &&
                                        'shrink-0'), onKeyDown: handleKeyDown(index), onMouseDown: handleMouseDown(index), path: breadcrumb != null && 'href' in breadcrumb
                                        ? breadcrumb.href
                                        : undefined, title: index === 0 }, { children: ellipsis && index === positions.current.length - 1 ? (_jsx(FontAwesomeIcon, { className: "ml-0.5 cursor-pointer text-2xl text-neutral-600 dark:text-neutral-500", icon: faEllipsisH })) : (_jsx(Text, { delta: breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.text.delta })) }), [breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.id, index.toString()]
                                    .filter(Boolean)
                                    .join('')));
                            }), _jsx(GuidedTourDropdownMenu, { dropDownMenu: dropdownMenuRef, setDropdownMenu: setDropdownMenuCb }), transition(
                            // // check if breadcrumb exists: the user can scroll past the breadcrumb-showing area with dropdown menu open
                            // .filter(
                            // 	({ item }) =>
                            // 		item !== null &&
                            // 		(breadcrumbs[item] || // menu opened by clicking breadcrumb
                            // 			(ellipsis && item === breadcrumbs.length)) // menu opened by clicking ellipsis
                            // )
                            function (s, item) {
                                var _a, _b, _c, _d, _e;
                                if (item == null || typeof item !== 'number') {
                                    return null;
                                }
                                var ref = (_a = positions.current[item]) === null || _a === void 0 ? void 0 : _a.breadcrumb;
                                var _f = (_c = (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) !== null && _c !== void 0 ? _c : {}, _g = _f.left, left = _g === void 0 ? DROPDOWN_HORIZONTAL_POSITION_FALLBACK : _g, 
                                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                _h = _f.width, 
                                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                width = _h === void 0 ? 8 : _h;
                                return (_jsx(animated.div, __assign({ ref: dropdownMenuRef, className: "fixed z-22 -ml-8", style: __assign(__assign({}, s), (horizontalPositioningMode === 'BREADCRUMB'
                                        ? { left: left }
                                        : __assign(__assign({}, (horizontalPositioningMode === 'FULL_WIDTH'
                                            ? {
                                                left: MINIMUM_HORIZONTAL_DROPDOWN_MARGIN -
                                                    DROPDOWN_MENU_MARGIN_LEFT,
                                            }
                                            : {})), { 
                                            // ['RIGHT', 'FULL_WIDTH'].includes(horizontalPositioningMode)
                                            right: MINIMUM_HORIZONTAL_DROPDOWN_MARGIN }))) }, { children: _jsx(DropdownMenu, __assign({ minWidth: width }, { children: (_e = (_d = groupedTitles.current) === null || _d === void 0 ? void 0 : _d.find(function (_a) {
                                            var _b, _c;
                                            var id = _a.id;
                                            return id === ((_b = breadcrumbs[item]) === null || _b === void 0 ? void 0 : _b.id) ||
                                                (ellipsis && id === ((_c = ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings[0]) === null || _c === void 0 ? void 0 : _c.id));
                                        } // menu opened by clicking ellipsis
                                        )) === null || _e === void 0 ? void 0 : _e.siblings.map(function (title) {
                                            var _a;
                                            return (_jsx(DropdownMenuItem, { depthOfPageTitle: depthOfPageTitle, 
                                                // {...(array.some((t) => t.key > key)
                                                // 	? // While switching between dropdown menus, there’s a brief
                                                // 	  // moment when two menus are being shown at the same time.
                                                // 	  // The `key` value assigned by `react-spring` increments,
                                                // 	  // so we include a check to ensure the most recent dropdown
                                                // 	  // menu (i.e., the dropdown menu appearing into view)
                                                // 	  // gets the refs assigned to it.
                                                // 	  {}
                                                // 	: {
                                                // 			ref: positions.current[item].menuItems[i],
                                                // 	  })}
                                                displayHand: title.id === ((_a = breadcrumbs[item]) === null || _a === void 0 ? void 0 : _a.id), linkAction: linkAction, onKeyDown: handleKeyDown(item), title: title, titles: titles }, title.id));
                                        }) })) })));
                            })] })), _jsx("div", __assign({ className: "inline-flex justify-end whitespace-nowrap" }, { children: topRightButtons }))] })), _jsx("div", { id: "belowHeader" })] })));
};
