var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'src/functions';
/** Link to the admin page for the current book */
export var Button = forwardRef(function (_a, ref) {
    var href = _a.href, icon = _a.icon, label = _a.label, className = _a.className, onKeyDown = _a.onKeyDown;
    return (_jsxs(Link, __assign({ ref: ref, className: classNames('relative mb-0 flex cursor-pointer select-none items-center gap-2 whitespace-nowrap pt-1.5 pr-0.5 pl-2.5 pb-1 text-right align-top font-sans text-sm font-light leading-5 text-red-accent decoration-inherit hover:bg-red-accent/7 focus:outline-none focus:ring focus:ring-blue-subtle dark:text-red-strong dark:hover:bg-transparent dark:focus:ring-blue-strong', className), onKeyDown: onKeyDown, role: "button", to: href }, { children: [icon, _jsx("span", __assign({ className: "hidden pr-2 coverpage:flex" }, { children: label }))] })));
});
Button.displayName = 'Button';
