var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isTextInsert } from './typeGuards';
export var removeFootnotes = function (delta) { return (__assign(__assign({}, delta), { ops: delta.ops.filter(function (op) { var _a; return isTextInsert(op) && ((_a = op.attributes) === null || _a === void 0 ? void 0 : _a.footnote) == null; }) })); };
