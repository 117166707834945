var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { toolbarVar } from 'src/cache/reactiveVars';
import { ie11 } from 'src/constants';
import { useParams } from 'src/hooks';
import { MIN_BOOK_PARAMS } from 'src/hooks/useParams';
import { Annotate } from './annotate/Annotate';
import { ColorTheme, 
// Escape,
NextPage, PreviousPage, 
// Summary,
TableOfContentsToggle, } from './buttons';
// import { TextToSpeech } from './textToSpeech/TextToSpeech'
import { ToolbarButtonSeparator } from './ToolbarButtonSeparator';
export var Toolbar = function () {
    // const [maxWidth, setMaxWidth] = useState(0)
    // const handleScroll = useCallback(() => {
    // 	if (!isChatModeActive) {
    // 		const { pageYOffset } = window
    // 		const MOVEMENT_END_OFFSET = 200 // consider `Math.round` below if not divisible by `MOVEMENT_SLOWING_FACTOR`
    // 		const MOVEMENT_SLOWING_FACTOR = 2
    // 		if (pageYOffset <= 0 && maxWidth !== 0) setMaxWidth(0)
    // 		else if (pageYOffset > 0 && pageYOffset < MOVEMENT_END_OFFSET) {
    // 			setMaxWidth(Math.round(pageYOffset / MOVEMENT_SLOWING_FACTOR))
    // 		} else if (
    // 			pageYOffset >= MOVEMENT_END_OFFSET &&
    // 			maxWidth !== MOVEMENT_END_OFFSET / MOVEMENT_SLOWING_FACTOR
    // 		) {
    // 			setMaxWidth(MOVEMENT_END_OFFSET / MOVEMENT_SLOWING_FACTOR)
    // 		}
    // 	}
    // }, [])
    // useEffect(() => {
    // 	window.addEventListener('scroll', handleScroll)
    // 	return () => window.removeEventListener('scroll', handleScroll)
    // })
    var toolbar = useReactiveVar(toolbarVar);
    var params = useParams();
    if (ie11 || // Text to speeach doesn't work on ie11
        (params[0] !== 'book' && params[0] !== 'summary') ||
        params.length < MIN_BOOK_PARAMS ||
        !toolbar) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "relative box-border flex select-none justify-between border-b border-b-neutral-250 bg-neutral-150/97 supports-blur:bg-neutral-150/80 supports-blur:backdrop-blur theme-contrast:supports-blur:bg-neutral-150 dark:border-b-neutral-750 dark:bg-gray-810 dark:supports-blur:bg-gray-810 hires:border-b-0.5" }, { children: [_jsxs("div", __assign({ className: "flex justify-start" }, { children: [_jsx(PreviousPage, {}), _jsx(TableOfContentsToggle, {}), _jsx(ToolbarButtonSeparator, {}), _jsx(Annotate, {})] })), _jsxs("div", __assign({ className: "flex justify-end" }, { children: [_jsx(ColorTheme, {}), _jsx(NextPage, {})] }))] })));
};
