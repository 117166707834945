var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { writeDiscussionFragment } from './graphql/writeDiscussionFragment';
/** Used by subscription and mutation to add replies to a discussion */
export var addCommentToDiscussion = function (_a) {
    var cache = _a.cache, comment = _a.comment, discussion = _a.discussion, discussionId = _a.discussionId;
    if (discussion.id === discussionId &&
        /**
         * Only add a comment if it hasn’t already been added before by the mutation
         * or subscription, whichever received their response data first
         */
        !discussion.comments.find(function (_a) {
            var id = _a.id;
            return id === comment.id;
        })) {
        var newDiscussion = __assign(__assign({}, discussion), { comments: __spreadArray(__spreadArray([], __read(discussion.comments), false), [comment], false) });
        writeDiscussionFragment({ cache: cache, data: newDiscussion });
    }
};
