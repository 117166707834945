var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { Block } from 'src/content/block/Block';
import { isListItem } from 'src/functions/typeGuards';
import { useBlocks } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment list on List {\n\t\ttype\n\t\tparentId\n\t}\n\n\tfragment listItem on ListItem {\n\t\tparentId\n\t}\n"], ["\n\tfragment list on List {\n\t\ttype\n\t\tparentId\n\t}\n\n\tfragment listItem on ListItem {\n\t\tparentId\n\t}\n"])));
export var List = function (_a) {
    var id = _a.id, listStyleType = _a.type;
    var blocks = useBlocks();
    if (!blocks) {
        return null;
    }
    var listItems = blocks === null || blocks === void 0 ? void 0 : blocks.filter(function (block) { return isListItem(block) && block.parentId === id; });
    var items = listItems.map(function (listItem) {
        var children = blocks === null || blocks === void 0 ? void 0 : blocks.filter(function (block) { return 'parentId' in block && block.parentId === listItem.id; });
        return (_jsx("li", { children: children.map(function (block) { return (_jsx(Block, { block: block }, block.id)); }) }, listItem.id));
    });
    switch (listStyleType) {
        /** Symbolic */
        case 'disc':
        case 'circle':
        case 'square':
        case 'disclosure-open':
        case 'disclosure-closed':
            return (_jsx("ul", __assign({ className: "pl-6 lining-nums", style: { listStyleType: listStyleType } }, { children: items })));
        /** Numeric, alphabetic, Japanese, Korean, Chinese, Ethiopic */
        default:
            return (_jsx("ol", __assign({ className: "pl-6 lining-nums", style: { listStyleType: listStyleType !== null && listStyleType !== void 0 ? listStyleType : 'disc' } }, { children: items })));
    }
};
var templateObject_1;
