var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@private/fontawesome/pro-light-svg-icons/faDownload';
import filesize from 'filesize';
import gql from 'graphql-tag';
import { S3_CONTENT_ENDPOINT } from 'src/constants';
import { icon } from './icon';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment file on File {\n\t\turl\n\t\tsize\n\t\tdescription {\n\t\t\t...richText\n\t\t}\n\t\tparentId\n\t}\n"], ["\n\tfragment file on File {\n\t\turl\n\t\tsize\n\t\tdescription {\n\t\t\t...richText\n\t\t}\n\t\tparentId\n\t}\n"
    /**
     * @returns Link to a file that can be downloaded. Should be used for content
     *   that cannot be displayed in a web browser.
     */
])));
/**
 * @returns Link to a file that can be downloaded. Should be used for content
 *   that cannot be displayed in a web browser.
 */
export var File = function (_a) {
    var _b, _c;
    var url = _a.url, size = _a.size;
    if (url == null) {
        return null;
    }
    var fileName = (_c = (_b = url.split('/').pop()) === null || _b === void 0 ? void 0 : _b.split('#')[0]) === null || _c === void 0 ? void 0 : _c.split('?')[0];
    var extension = fileName === null || fileName === void 0 ? void 0 : fileName.split('.').pop();
    var hypertextReference = url.match(/https?:\/\//)
        ? url
        : "".concat(S3_CONTENT_ENDPOINT).concat(url.startsWith('/') ? url : "/".concat(url));
    return (_jsxs("div", __assign({ className: "mx-0 my-3 flex rounded-md border border-neutral-200 pt-1 pl-1 pb-0.5 pr-1.5 font-sans" }, { children: [extension != null && (_jsx("div", __assign({ className: "my-1 ml-1 mr-2.5 text-6xl" }, { children: _jsx(FontAwesomeIcon, { icon: icon(extension) }) }))), _jsx("div", __assign({ className: "flex w-full flex-col justify-center" }, { children: _jsx("div", __assign({ className: "flex flex-row items-end justify-between" }, { children: _jsxs("div", __assign({ className: "w-full text-center font-mono text-sm" }, { children: [fileName, ' / ', size != null && (_jsxs(_Fragment, { children: [filesize(size, { round: 0 }), ' / '] })), _jsxs("a", __assign({ className: "border-blue-hyperlink py-0 px-0.5 text-blue-hyperlink hover:border-b", download: true, href: hypertextReference }, { children: [_jsx(FontAwesomeIcon, { className: "inline-block pr-1 align-baseline text-sm", icon: faDownload }), "Download"] }))] })) })) }))] })));
};
var templateObject_1;
