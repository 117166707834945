import { useReactiveVar } from '@apollo/client';
import { useWindowSize } from 'react-use';
import { hasNavVar } from 'src/cache/reactiveVars';
var COMPACT_GRID_WITHOUT_TOC_MIN_WIDTH = 799;
var NORMAL_GRID_WITH_TOC_MIN_WIDTH = 1055;
/**
 * Provides a boolean value, determined by the window width, based on which we
 * decide:
 *
 * - Whether to make the underlined text for a note clickable,
 * - Whether to make the underlined text for a discussion clickable
 * - Whether we navigate to the dedicated chat page when a new discussion has been
 *   created
 *
 * TODO: Use condition to determine whether to render `<Overlay />`, and
 * broadcast presence of `<Overlay />` to context
 */
export var useOverlay = function () {
    var hasNav = useReactiveVar(hasNavVar);
    var hideSidebarWidth = hasNav
        ? NORMAL_GRID_WITH_TOC_MIN_WIDTH
        : COMPACT_GRID_WITHOUT_TOC_MIN_WIDTH;
    var width = useWindowSize().width;
    return width < hideSidebarWidth;
};
