import { DiscussionFragmentDoc, } from 'src/__generated__';
import { cache } from 'src/cache';
export var writeDiscussionFragment = function (_a) {
    var store = _a.cache, data = _a.data;
    store.writeFragment({
        data: data,
        fragment: DiscussionFragmentDoc,
        fragmentName: 'discussion',
        id: cache.identify(data),
    });
};
