var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { omit, prop, sum } from 'ramda';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deltaToSentences } from 'src/functions';
import { isDelete, isDiscussion, isInputInsert, isRetain, isTextInsert, } from 'src/functions/typeGuards';
import { getEndPoint } from './getEndPoint';
import { getRichTextAnnotations } from './getRichTextAnnotations';
// exported for testing purposes only
export var mergeRichTextAnnotationsWithDelta = function (_a) {
    var _b;
    var delta = _a.delta, discussionId = _a.discussionId, isChatModeActive = _a.isChatModeActive, richTextAnnotations = _a.richTextAnnotations, sentenceBeingSpoken = _a.sentenceBeingSpoken;
    /**
     * If the current piece of text is being spoken, we need to split up the text
     * into its constituting sentences. If not, it’s more efficient to disregard
     * this logic.
     */
    var SPLIT_UP_PER_SENTENCE = typeof sentenceBeingSpoken === 'number';
    if (delta == null || delta.ops.length === 0) {
        return;
    }
    var sentenceLengths;
    var cumulativeSentenceLengths;
    var cumulativeSentenceAndWhitespaceLengths = [];
    if (SPLIT_UP_PER_SENTENCE) {
        sentenceLengths = deltaToSentences(delta).map(prop('length'));
        cumulativeSentenceLengths = sentenceLengths.map(function (_, index) {
            return sum(sentenceLengths.slice(0, index));
        });
        /** Also contains lengths of intersentence whitespace */
        var sentenceAndWhitespaceLengths_1 = deltaToSentences(delta)
            .reduce(function (accumulator, currentValue) {
            var _a;
            /**
             * When showing the text being spoken, we want to ignore the whitespace
             * between two sentences (e.g. avoid that the underline extends past the
             * end-of-sentence punctuation mark), so we split off the trailing
             * whitespace.
             */
            var _b = __read((_a = currentValue.match(/\s+$/)) !== null && _a !== void 0 ? _a : [], 1), _c = _b[0], whitespace = _c === void 0 ? '' : _c;
            var sentence = currentValue.slice(0, currentValue.length - whitespace.length);
            return __spreadArray(__spreadArray(__spreadArray([], __read(accumulator), false), [sentence], false), __read((whitespace ? [whitespace] : [])), false);
        }, [])
            .map(prop('length'));
        cumulativeSentenceAndWhitespaceLengths = sentenceAndWhitespaceLengths_1.map(function (_, index) { return sum(sentenceAndWhitespaceLengths_1.slice(0, index)); });
    }
    var breakpoints = Array.from(new Set(// Retain unique values
    __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(richTextAnnotations.map(function (_) { return _.position; })), false), __read(richTextAnnotations.map(function (_) { return _.position + _.length; })), false), __read(((_b = delta === null || delta === void 0 ? void 0 : delta.ops.map(function (_, index, array) { return getEndPoint(index, array); })) !== null && _b !== void 0 ? _b : [])), false), __read(cumulativeSentenceAndWhitespaceLengths), false), [
        0, // Make sure the sorted array begins with 0
    ], false).filter(function (i) { return i >= 0; })
        .sort(function (i, j) { return i - j; }) // Remove negative endpoints & sort
    ));
    var opsWithEndpoints = delta.ops.map(function (op, index, array) {
        var endpoint = getEndPoint(index, array);
        /** 1 for non-text (e.g. input field) or footnote numbers */
        var length = isTextInsert(op) ? op.insert.length : 1;
        return __assign(__assign({}, op), { endpoint: endpoint, startpoint: endpoint - length });
    });
    var ops = breakpoints
        .map(function (breakpoint, index, array) {
        var _a, _b, _c, _d, _e, _f, _g;
        var nextBreakpoint = array[index + 1];
        var op = opsWithEndpoints.find(function (_a) {
            var endpoint = _a.endpoint, startpoint = _a.startpoint;
            return startpoint <= breakpoint &&
                (index === array.length - 1 || // startpoint <= last breakpoint
                    (nextBreakpoint != null && endpoint >= nextBreakpoint));
        });
        if (isDelete(op) || isRetain(op)) {
            return op;
        }
        // External links are appended with an icon. We don’t want this icon to
        // be displayed multiple times when the hyperlink is broken up with
        // other formatting. So we’ll have to check if the next element contains
        // the same href, and if so, set a flag which we’ll use for conditional
        // styling.
        // Same for elements that are split up by a highlight, creating adjacent
        // elements of the same type: sometimes custom formatting is needed
        // which takes into account whether the formatting is preceded or
        // followed by formatting of the same type
        var next = index <= array.length - 2 // TODO: Check if anything goes wrong (<= was <)
            ? opsWithEndpoints.find(function (_a) {
                var endpoint = _a.endpoint, startpoint = _a.startpoint;
                var nextNextItem = array[index + 2];
                return (nextBreakpoint != null &&
                    startpoint <= nextBreakpoint &&
                    (index === array.length - 2 ||
                        (nextNextItem != null && endpoint >= nextNextItem)));
            })
            : undefined;
        var previous = index >= 1
            ? opsWithEndpoints.find(function (_a) {
                var endpoint = _a.endpoint, startpoint = _a.startpoint;
                var previousItem = array[index - 1];
                var item = array[index];
                return (previousItem != null &&
                    startpoint <= previousItem &&
                    item != null &&
                    endpoint >= item);
            })
            : undefined;
        var insert;
        if (isTextInsert(op)) {
            insert = op.insert.substring(breakpoint - op.startpoint, index === array.length - 1 || nextBreakpoint == null
                ? undefined
                : nextBreakpoint - op.startpoint);
        }
        var previousCumulativeSentenceLength;
        var cumulativeSentenceLength;
        var sentenceIndex;
        var intersentenceWhitespace = false;
        if (SPLIT_UP_PER_SENTENCE) {
            previousCumulativeSentenceLength = sum(sentenceLengths.slice(0, sentenceBeingSpoken));
            cumulativeSentenceLength =
                previousCumulativeSentenceLength +
                    ((_a = sentenceLengths[sentenceBeingSpoken]) !== null && _a !== void 0 ? _a : 0);
            /** Current sentence index */
            sentenceIndex = cumulativeSentenceLengths.findIndex(function (length, i) {
                var nextLength = i < cumulativeSentenceLengths.length - 1
                    ? cumulativeSentenceLengths[i + 1]
                    : undefined;
                return (nextLength != null &&
                    breakpoint >= length &&
                    breakpoint < nextLength &&
                    nextBreakpoint != null &&
                    nextBreakpoint >= length &&
                    nextBreakpoint < nextLength);
            });
            intersentenceWhitespace =
                typeof insert === 'string' && insert.trim() === '' && isTextInsert(op)
                    ? Boolean(op.insert
                        .substring(0, breakpoint - op.startpoint)
                        .match(/(\.|!|\?)$/))
                    : false;
        }
        var flags = {
            /**
             * If `true`, provide visual indication (pink underline) that the
             * current phrase is being read out loud
             */
            beingSpoken: SPLIT_UP_PER_SENTENCE &&
                previousCumulativeSentenceLength != null &&
                cumulativeSentenceLength != null &&
                previousCumulativeSentenceLength <= breakpoint &&
                breakpoint < cumulativeSentenceLength &&
                !intersentenceWhitespace,
            /** Used to make the horizontal margin/padding look nice */
            followedByCode: isTextInsert(op) &&
                isTextInsert(next) &&
                ((_b = op.attributes) === null || _b === void 0 ? void 0 : _b.code) === true &&
                ((_c = next.attributes) === null || _c === void 0 ? void 0 : _c.code) === true,
            /**
             * `true` if hyperlink is cut in two parts. This flag is used to avoid
             * adding an icon (via CSS pseudo class) in the middle of a link.
             */
            hideExternalLinkIcon: isTextInsert(op) &&
                isTextInsert(next) &&
                ((_d = op.attributes) === null || _d === void 0 ? void 0 : _d.href) != null &&
                op.attributes.href === ((_e = next.attributes) === null || _e === void 0 ? void 0 : _e.href),
            /** Used to make the horizontal margin/padding look nice */
            precededByCode: isTextInsert(op) &&
                isTextInsert(previous) &&
                ((_f = op.attributes) === null || _f === void 0 ? void 0 : _f.code) === true &&
                ((_g = previous.attributes) === null || _g === void 0 ? void 0 : _g.code) === true,
            // (index === array.length - 1 ||
            // 	cumulativeSentenceLength < nextBreakpoint
            /** 0-based index of the sentence the operation belongs to */
            sentenceIndex: !intersentenceWhitespace &&
                sentenceIndex !== -1 &&
                SPLIT_UP_PER_SENTENCE
                ? sentenceIndex
                : undefined,
        };
        var relevantRichTextAnnotations = getRichTextAnnotations(richTextAnnotations, breakpoint, index, array);
        if (index === array.length - 1 || op == null) {
            return null;
        }
        if (isInputInsert(op)) {
            return __assign(__assign({}, omit(['endpoint', 'startpoint'], op)), { annotations: relevantRichTextAnnotations.map(function (_a) {
                    var annotation = _a.annotation;
                    return annotation;
                }), flags: flags });
        }
        if (insert == null) {
            return null;
        }
        // op is TextInsert:
        return __assign(__assign({}, omit(['endpoint', 'startpoint'], op)), { annotations: relevantRichTextAnnotations.map(function (_a) {
                var annotation = _a.annotation;
                return annotation;
            }), flags: flags, insert: insert });
    })
        .filter(function (op, _, opsArray) {
        return op &&
            (!isTextInsert(op) || op.insert.length > 0) &&
            (!isChatModeActive ||
                ('flags' in op &&
                    // In chat mode, we retain all ops that belong to sentences
                    // overlapping with the discussed (i.e. underlined) text
                    opsArray
                        .filter(function (o) {
                        return o != null &&
                            'annotations' in o &&
                            (o === null || o === void 0 ? void 0 : o.annotations.find(function (a) { return isDiscussion(a) && a.id === discussionId; }));
                    })
                        .map(function (o) {
                        return o != null && 'flags' in o ? o.flags.sentenceIndex : null;
                    })
                        .includes(op.flags.sentenceIndex)) ||
                // In chat mode, we also want to retain titles, e.g. to display
                // right above a paragraph, or for the breadcrumbs. Although
                // not completely sufficient, we can completely retain rich tet
                // that has not been annotated — because if it would have been
                // irrelevant for the chat page, the entire block would have been
                // discarded in the Book component.
                !richTextAnnotations.filter(function (_a) {
                    var annotation = _a.annotation;
                    return isDiscussion(annotation);
                }).length);
    });
    return { ops: ops };
};
/**
 * Text can contain certain types of inline elements (hyperlinks, bold/italic/
 * monospaced text, inline code, etc) as specified by the author. In addition,
 * text can be highlighted and annotated. We need to split up the `ops` so that
 * each operation has uniform formatting. The `ops` in `delta` are already split
 * up according to the formatting added by the author. Now we have to split the
 * ops before and after each user addition.
 *
 * @returns Augmented/extended Delta containing all data needed to render TODO:
 *   Write test for this function for the case where `delta = { ops: [] }`
 */
export var useMergeRichTextAnnotationsWithDelta = function (_a) {
    var delta = _a.delta, richTextAnnotations = _a.richTextAnnotations, sentenceBeingSpoken = _a.sentenceBeingSpoken;
    var _b = __read(useSearchParams(), 1), searchParams = _b[0];
    var discussionId = searchParams.get('conversation');
    var isChatModeActive = discussionId != null;
    return useMemo(function () {
        return mergeRichTextAnnotationsWithDelta({
            delta: delta,
            discussionId: discussionId,
            isChatModeActive: isChatModeActive,
            richTextAnnotations: richTextAnnotations,
            sentenceBeingSpoken: sentenceBeingSpoken,
        });
    }, [
        delta,
        discussionId,
        isChatModeActive,
        richTextAnnotations,
        sentenceBeingSpoken,
    ]);
};
