var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@private/fontawesome/pro-regular-svg-icons/faEye';
import { faPencil } from '@private/fontawesome/pro-solid-svg-icons/faPencil';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { useCurrentUserQuery, useParams } from 'src/hooks';
import { Button } from './Button';
/** Button that toggles the editor view */
export var EditorToggle = forwardRef(function (_a, ref) {
    var onKeyDown = _a.onKeyDown;
    var _b = __read(useParams(), 4), mode = _b[0], book = _b[1], chapter = _b[2], section = _b[3];
    var currentUser = useCurrentUserQuery();
    if (mode == null ||
        !['book', 'edit'].includes(mode) ||
        (currentUser === null || currentUser === void 0 ? void 0 : currentUser.role) !== 'Administrator') {
        return null;
    }
    return (_jsx(Routes, { children: _jsx(Route, { element: _jsx(Button, { ref: ref, href: "/".concat(mode === 'edit' ? 'book' : 'edit', "/").concat(book, "/").concat(chapter).concat(section == null ? '' : "/".concat(section)), icon: _jsx(FontAwesomeIcon, { icon: mode === 'edit' ? faEye : faPencil }), label: mode === 'edit' ? (_jsx(FormattedMessage, { defaultMessage: "View", id: "FgydNe" })) : (_jsx(FormattedMessage, { defaultMessage: "Edit", id: "wEQDC6" })), onKeyDown: onKeyDown }), path: "/:mode/:book/:chapter/*" }) }));
});
EditorToggle.displayName = 'EditorToggle';
