var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useApolloClient } from '@apollo/client';
import { assocPath, map } from 'ramda';
import { useCallback } from 'react';
import { BookDocument, } from 'src/__generated__';
import { LINE_HEIGHT } from 'src/constants';
import { shouldSkipOnPage } from 'src/content/block/title/shouldSkipOnPage';
import { getAnnotations } from 'src/functions';
import { isRichText, isSidebarAnnotation, isTitle, } from 'src/functions/typeGuards';
var INTER_ANNOTATION_GAP = 8; // px
export var useSetAnnotationPosition = function () {
    var client = useApolloClient();
    return useCallback(function (_a) {
        var _b;
        var height = _a.height, id = _a.id, left = _a.left, maxBottom = _a.maxBottom, minTop = _a.minTop, params = _a.params;
        var variables = {
            bookParams: params.slice(0, 2),
            pageParams: params.slice(2),
        };
        var cacheData = client.readQuery({ query: BookDocument, variables: variables });
        if ((cacheData === null || cacheData === void 0 ? void 0 : cacheData.book.__typename) !== 'BookPayload') {
            return;
        }
        var blocks = cacheData.book.book.blocks;
        if (!blocks) {
            return;
        }
        /**
         * Flat list of annotations to be sorted: first vertically, then
         * horizontally (later: right to left for Arabic). By using a flat list,
         * we can avoid recursion. Blocks are not guaranteed to be in the right
         * order (e.g. in case of tables or, later, side-by-side content).
         */
        var sidebarAnnotations = [];
        // First, we add/update the measurements for each element regardless of any overlap
        // Avoid mutating `data`
        var update = assocPath(['book', 'book', 'blocks'], blocks.map(function (block, index) {
            // `map` from `ramda` is applied to a `block` object here:
            return map(function (value) {
                return isRichText(value)
                    ? __assign(__assign({}, value), { richTextAnnotations: value.richTextAnnotations.map(function (richTextAnnotation) {
                            var annotation = richTextAnnotation.annotation;
                            var updatedAnnotation = __assign(__assign({}, annotation), (isSidebarAnnotation(annotation) &&
                                annotation.id === id
                                ? {
                                    height: height !== null && height !== void 0 ? height : annotation.height,
                                    left: left !== null && left !== void 0 ? left : annotation.left,
                                    maxBottom: maxBottom !== null && maxBottom !== void 0 ? maxBottom : annotation.maxBottom,
                                    minTop: minTop !== null && minTop !== void 0 ? minTop : annotation.minTop,
                                }
                                : {}));
                            var previousBlockAnnotations = getAnnotations(blocks[index - 1]);
                            var displayInSidebar = !previousBlockAnnotations.some(function (a) { return a.id === annotation.id; });
                            if (isSidebarAnnotation(annotation) &&
                                isSidebarAnnotation(updatedAnnotation) &&
                                displayInSidebar &&
                                !(isTitle(block) &&
                                    shouldSkipOnPage({ depth: block.depth, params: params }))) {
                                sidebarAnnotations.push(__assign(__assign({}, updatedAnnotation), { richTextId: value.id }));
                            }
                            return __assign(__assign({}, richTextAnnotation), { annotation: updatedAnnotation });
                        }) }) : value;
            }, block);
        }), cacheData);
        /**
         * We only order the entire sidebar once, after all required data
         * (`height`, `minTop`, ...) is available for all annotations. If not, we
         * just write the data to the store.
         */
        sidebarAnnotations.sort(function (a, b) {
            var _a, _b;
            if (a.minTop == null || b.minTop == null) {
                return 0;
            }
            // If the difference in vertical position is smaller than the line
            // height (e.g. subscript), compare horizontal positions instead
            return Math.abs(a.minTop - b.minTop) < LINE_HEIGHT
                ? ((_a = a.left) !== null && _a !== void 0 ? _a : 0) - ((_b = b.left) !== null && _b !== void 0 ? _b : 0)
                : a.minTop - b.minTop;
        });
        // index of the annotation that we’re setting the position for (equal to order from top)
        var index = sidebarAnnotations.findIndex(function (annotation) { return annotation.id === id; });
        if (index !== -1) {
            // Correct the position for any overlapping sidebar element
            sidebarAnnotations.forEach(function (_, i) {
                var _a, _b, _c, _d, _e;
                var sidebarAnnotation = sidebarAnnotations[i];
                if (sidebarAnnotation != null) {
                    sidebarAnnotation.top = Math.max((_a = sidebarAnnotation.minTop) !== null && _a !== void 0 ? _a : 0, i > 0
                        ? ((_c = (_b = sidebarAnnotations[i - 1]) === null || _b === void 0 ? void 0 : _b.top) !== null && _c !== void 0 ? _c : 0) +
                            ((_e = (_d = sidebarAnnotations[i - 1]) === null || _d === void 0 ? void 0 : _d.height) !== null && _e !== void 0 ? _e : 0) +
                            INTER_ANNOTATION_GAP
                        : 0);
                }
            });
        }
        if ((update === null || update === void 0 ? void 0 : update.book.__typename) !== 'BookPayload') {
            return;
        }
        client.writeQuery({
            data: assocPath(['book', 'book', 'blocks'], (_b = update.book.book.blocks) === null || _b === void 0 ? void 0 : _b.map(function (block) {
                return map(function (value) {
                    if (isRichText(value)) {
                        return __assign(__assign({}, value), { richTextAnnotations: value.richTextAnnotations.map(function (richTextAnnotation) {
                                var annotation = richTextAnnotation.annotation;
                                return __assign(__assign({}, richTextAnnotation), { annotation: __assign(__assign({}, annotation), sidebarAnnotations.find(function (a) {
                                        return a.id === annotation.id &&
                                            a.richTextId === value.id;
                                    })) });
                            }) });
                    }
                    return value;
                }, block);
            }), update),
            query: BookDocument,
            variables: variables,
        });
    }, [client]);
};
