var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { usePreviousPage } from 'src/content/layout/pagination/usePreviousPage';
import { useIsChatModeActive, usePrefetch } from 'src/hooks';
import { ToolbarButton } from '../ToolbarButton';
import { ToolbarButtonSeparator } from '../ToolbarButtonSeparator';
export var PreviousPage = function () {
    var _a;
    var isChatModeActive = useIsChatModeActive();
    var location = useLocation();
    var url = ((_a = usePreviousPage()) !== null && _a !== void 0 ? _a : {}).url;
    var prefetch = usePrefetch(url);
    if (url == null) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ToolbarButton, { className: isChatModeActive && 'pl-2', href: isChatModeActive ? location.pathname : url, icon: _jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { fixedWidth: true, icon: faAngleLeft }), isChatModeActive && (_jsx("span", __assign({ className: "ml-1 -mb-px whitespace-nowrap pr-3 text-xs font-bold uppercase tracking-wide" }, { children: _jsx(FormattedMessage, { defaultMessage: "Return to Book", id: "nGM+ef" }) })))] }), isSquare: !isChatModeActive, onFocus: prefetch, onMouseOver: prefetch, tooltip: isChatModeActive ? undefined : (_jsx(FormattedMessage, { defaultMessage: "Previous page", id: "k9hDFZ" })) }), _jsx(ToolbarButtonSeparator, {})] }));
};
