var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { makeVar, useMutation, useReactiveVar } from '@apollo/client';
import { UpdatePreferenceDocument } from 'src/__generated__';
import { useCurrentUserQuery } from './useCurrentUserQuery';
/**
 * Local setting that’s unaware of whether `<Nav />` is present. Ignored when
 * the user is logged in.
 */
var isShowingNavIfLoggedOutVar = makeVar(true);
/**
 * Uses either the user preference (if logged in), synced to the server, or
 * local state (if not logged in)
 */
export var useIsShowingNav = function () {
    var _a, _b;
    var isShowingNavIfLoggedOut = useReactiveVar(isShowingNavIfLoggedOutVar);
    var currentUser = useCurrentUserQuery();
    var preference = (currentUser !== null && currentUser !== void 0 ? currentUser : {}).preference;
    var _c = __read(useMutation(UpdatePreferenceDocument, {
        optimisticResponse: preference
            ? {
                __typename: 'Mutation',
                updatePreference: {
                    __typename: 'UpdatePreferencePayload',
                    preference: __assign(__assign({}, preference), { toc: !(preference === null || preference === void 0 ? void 0 : preference.toc) }),
                },
            }
            : undefined,
        variables: { input: { toc: (preference === null || preference === void 0 ? void 0 : preference.toc) !== true } },
    }), 1), toggleNav = _c[0];
    if (currentUser == null) {
        return {
            isShowingNav: isShowingNavIfLoggedOut,
            setIsShowingNav: function () {
                return isShowingNavIfLoggedOutVar(!isShowingNavIfLoggedOut);
            },
        };
    }
    return {
        isShowingNav: (_b = (_a = currentUser.preference) === null || _a === void 0 ? void 0 : _a.toc) !== null && _b !== void 0 ? _b : false,
        setIsShowingNav: toggleNav,
    };
};
