var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { lightboxVar } from 'src/cache/reactiveVars';
import { Block } from 'src/content/block/Block';
import { getChildren } from 'src/content/functions';
import { Text } from 'src/content/inline/text/Text';
import { classNames } from 'src/functions';
import { isMultipleChoiceOption, isParagraph } from 'src/functions/typeGuards';
import { useBlocks, useBookQuery, useCurrentUserQuery } from 'src/hooks';
import { NotLoggedIn } from 'src/ui/lightbox/NotLoggedIn';
import { useToggleMultipleChoiceOption } from './useToggleMultipleChoiceOption';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment multipleChoice on MultipleChoice {\n\t\tparentId\n\t\tradio\n\t}\n\n\tfragment multipleChoiceOption on MultipleChoiceOption {\n\t\tchecked\n\t\tcount\n\t\tparentId\n\t}\n"], ["\n\tfragment multipleChoice on MultipleChoice {\n\t\tparentId\n\t\tradio\n\t}\n\n\tfragment multipleChoiceOption on MultipleChoiceOption {\n\t\tchecked\n\t\tcount\n\t\tparentId\n\t}\n"
    /** Multiple choice */
])));
/** Multiple choice */
export var MultipleChoice = function (_a) {
    var _b, _c;
    var id = _a.id, radio = _a.radio;
    var blocks = useBlocks();
    var book = useBookQuery();
    var isLoggedIn = Boolean((_b = useCurrentUserQuery()) === null || _b === void 0 ? void 0 : _b.id);
    var _d = __read(useState(false), 2), showingAnswers = _d[0], setShowingAnswers = _d[1];
    var toggleMultipleChoiceOption = useToggleMultipleChoiceOption();
    var handleChange = useCallback(function (_a) {
        var multipleChoiceOptionId = _a.multipleChoiceOptionId, optimisticResponse = _a.optimisticResponse;
        return function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var target;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        target = event.target;
                        if (!isLoggedIn) return [3 /*break*/, 2];
                        return [4 /*yield*/, toggleMultipleChoiceOption({
                                id: id,
                                multipleChoiceOptionId: multipleChoiceOptionId,
                                optimisticResponse: optimisticResponse,
                                radio: radio,
                                target: target,
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        event.preventDefault();
                        lightboxVar(_jsx(NotLoggedIn, {}));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }, [isLoggedIn, id, radio, toggleMultipleChoiceOption]);
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
    }, []);
    if (!blocks) {
        return null;
    }
    var answerOptions = blocks
        .filter(isMultipleChoiceOption)
        .filter(function (block) { return block.parentId === id; });
    var questionBlocks = blocks.filter(function (block) {
        return !isMultipleChoiceOption(block) &&
            'parentId' in block &&
            block.parentId === id;
    });
    /**
     * Flag used to determine whether to show a button to view the students’
     * answers.
     */
    var isCoordinator = (_c = book === null || book === void 0 ? void 0 : book.groups.some(function (group) { return group.hasCurrentUserAsCoordinator; })) !== null && _c !== void 0 ? _c : false;
    var numberOfAnswers = answerOptions.reduce(function (acc, _a) {
        var count = _a.count;
        return acc + (count !== null && count !== void 0 ? count : 0);
    }, 0);
    return (_jsxs("form", __assign({ className: "my-3", onSubmit: handleSubmit }, { children: [questionBlocks.map(function (block) { return (_jsx(Block, { block: block }, block.id)); }), answerOptions.map(function (multipleChoiceOption) {
                var children = getChildren({
                    blocks: blocks,
                    parentId: multipleChoiceOption.id,
                });
                var showAsInline = children.length === 1;
                var firstChild = children[0];
                return (_jsxs("label", __assign({ className: classNames('group block', showingAnswers ? 'mt-3' : 'cursor-pointer'), htmlFor: multipleChoiceOption.id }, { children: [!showingAnswers && (_jsx("input", { checked: multipleChoiceOption.checked, 
                            // className not applied here but instead on input.css, because
                            // they’re conditional on whether the input is being displayed
                            // within a table.
                            className: "relative mr-4 top-1.5", id: multipleChoiceOption.id, name: id, onChange: handleChange({
                                multipleChoiceOptionId: multipleChoiceOption.id,
                                optimisticResponse: radio || !multipleChoiceOption.checked,
                            }), type: radio ? 'radio' : 'checkbox', value: multipleChoiceOption.id })), showAsInline && firstChild != null && isParagraph(firstChild) ? (_jsx("span", __assign({ className: classNames('relative -my-0.5 -mx-1.5 rounded py-0.5 px-1.5', !showingAnswers &&
                                (multipleChoiceOption.checked
                                    ? '-z-1 bg-neutral-170 dark:bg-gray-740'
                                    : 'group-hover:-z-2 group-hover:bg-neutral-120 dark:group-hover:bg-gray-760')) }, { children: _jsx(Text, __assign({}, firstChild.text)) }))) : (children.map(function (block) { return _jsx(Block, { block: block }, block.id); })), showingAnswers && multipleChoiceOption.count != null && (_jsxs("div", __assign({ className: "relative mb-3.5 w-full rounded-sm border border-blue-dark/50 bg-white dark:border-none dark:bg-gray-870 hires:border-0.5" }, { children: [_jsx("div", { className: classNames('h-2 bg-blue-subtle dark:bg-blue-strong', multipleChoiceOption.count === numberOfAnswers
                                        ? 'rounded-sm'
                                        : 'rounded-l-sm'), style: {
                                        width: "".concat((100 * multipleChoiceOption.count) / numberOfAnswers, "%"),
                                    } }), _jsx("div", __assign({ className: "absolute -ml-5 w-10 text-center font-sans text-xs", style: {
                                        left: "".concat((100 * multipleChoiceOption.count) / numberOfAnswers, "%"),
                                    } }, { children: multipleChoiceOption.count }))] })))] }), multipleChoiceOption.id));
            }), isCoordinator && (_jsx("div", __assign({ className: "mt-0.5 flex justify-between font-sans text-xs text-neutral-500 dark:text-neutral-350" }, { children: numberOfAnswers === 0 ? (_jsx(FormattedMessage, { defaultMessage: "None of your students have answered this question yet.", id: "v+Hl4C" })) : (_jsx("button", __assign({ className: "cursor-pointer select-none", onClick: function () { return setShowingAnswers(function (prev) { return !prev; }); }, type: "button" }, { children: showingAnswers ? (_jsxs(_Fragment, { children: ["\u27F5\u00A0", _jsx(FormattedMessage, { defaultMessage: "Hide answers", id: "kocqmC" })] })) : (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Show {_, plural, one {# answer} other {# answers} }", id: "6MOy08", values: { _: numberOfAnswers } }), "\u00A0\u27F6"] })) }))) })))] })));
};
var templateObject_1;
