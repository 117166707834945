import { faFile } from '@private/fontawesome/pro-light-svg-icons/faFile';
import { faFileAlt } from '@private/fontawesome/pro-light-svg-icons/faFileAlt';
import { faFileArchive } from '@private/fontawesome/pro-light-svg-icons/faFileArchive';
import { faFileAudio } from '@private/fontawesome/pro-light-svg-icons/faFileAudio';
import { faFileChartPie } from '@private/fontawesome/pro-light-svg-icons/faFileChartPie';
import { faFileCode } from '@private/fontawesome/pro-light-svg-icons/faFileCode';
import { faFileCsv } from '@private/fontawesome/pro-light-svg-icons/faFileCsv';
import { faFilePdf } from '@private/fontawesome/pro-light-svg-icons/faFilePdf';
import { faFileSpreadsheet } from '@private/fontawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faFileVideo } from '@private/fontawesome/pro-light-svg-icons/faFileVideo';
import { faFileWord } from '@private/fontawesome/pro-light-svg-icons/faFileWord';
/**
 * @returns The applicable Font Awesome icon for a given file type, as
 *   determined by the file’s `extension`.
 */
export var icon = function (extension) {
    switch (extension) {
        case 'avi':
        case 'mp4':
            return faFileVideo;
        case 'csv':
            return faFileCsv;
        case 'doc':
        case 'docx':
        case 'docm':
        case 'dotx':
        case 'dotm':
        case 'docb':
            return faFileWord;
        case 'css':
        case 'cpp':
        case 'graphql':
        case 'html':
        case 'js':
        case 'json':
        case 'jsx':
        case 'py':
        case 'scss':
            return faFileCode;
        case 'mp3':
            return faFileAudio;
        case 'pdf':
            return faFilePdf;
        case 'pps':
        case 'ppsx':
        case 'ppt':
        case 'pptm':
        case 'pptx':
            return faFileChartPie;
        case 'rar':
        case 'zip':
            return faFileArchive;
        case 'txt':
            return faFileAlt;
        case 'xls':
        case 'xlsm':
        case 'xlsx':
            return faFileSpreadsheet;
        default:
            return faFile;
    }
};
