var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
export var DEFAULT_NOTE_HEIGHT = 102; // Approximation (measured)
/** Fields shared by annotations shown in the sidebar (`Discussion` and `Note`) */
export var sidebarAnnotationFields = {
    left: function (_) { var _a; return (_a = _ !== null && _ !== void 0 ? _ : window.scrollX) !== null && _a !== void 0 ? _a : null; },
    maxBottom: function (_) { return _ !== null && _ !== void 0 ? _ : Infinity; },
    minTop: function (_) { var _a; return (_a = _ !== null && _ !== void 0 ? _ : window.scrollY) !== null && _a !== void 0 ? _a : null; },
    shownAtBottomOfPage: function (_) { return _ !== null && _ !== void 0 ? _ : false; },
    top: function (_) { var _a; return (_a = _ !== null && _ !== void 0 ? _ : window.scrollY) !== null && _a !== void 0 ? _a : null; },
};
export var noteTypeDef = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\textend type Note {\n\t\theight: Int\n\t\tleft: Int\n\t\tmaxBottom: Int\n\t\tminTop: Int\n\t\tshownAtBottomOfPage: Boolean!\n\t\ttop: Int\n\t}\n"], ["\n\textend type Note {\n\t\theight: Int\n\t\tleft: Int\n\t\tmaxBottom: Int\n\t\tminTop: Int\n\t\tshownAtBottomOfPage: Boolean!\n\t\ttop: Int\n\t}\n"])));
export var Note = {
    fields: __assign(__assign({}, sidebarAnnotationFields), { height: function (_) { return _ !== null && _ !== void 0 ? _ : DEFAULT_NOTE_HEIGHT; } }),
};
var templateObject_1;
