var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { omit, values } from 'ramda';
import { isHighlight, isRichText } from './typeGuards';
export var extractHighlights = function (_a) {
    var blocks = _a.blocks;
    return blocks === null || blocks === void 0 ? void 0 : blocks.flatMap(function (block) {
        return values(block)
            .filter(
        // TODO: figure out how to remove redundant type, or why it’s not possible
        function (value) {
            return isRichText(value) && Boolean(value.richTextAnnotations.length);
        })
            .map(function (value) {
            return value.richTextAnnotations
                .filter(function (_a) {
                var annotation = _a.annotation;
                return isHighlight(annotation);
            })
                .map(function (richTextAnnotation) { return (__assign(__assign({}, omit(['annotation'], richTextAnnotation)), { 
                // TODO: figure out how to remove redundant type, or why it’s not possible
                annotation: richTextAnnotation.annotation, richTextId: value.id })); });
        });
    }).flat();
};
