var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { breadcrumbsVar } from 'src/cache/reactiveVars';
export var useBreadcrumbs = function (titles) {
    useEffect(function () {
        if (titles) {
            var breadcrumbs = titles
                .filter(function (title) { return Boolean(title); })
                .map(function (breadcrumb) { return (__assign(__assign({}, breadcrumb), { text: typeof breadcrumb.text === 'string'
                    ? {
                        __typename: 'Delta',
                        ops: [{ __typename: 'TextInsert', insert: breadcrumb.text }],
                    }
                    : breadcrumb.text })); });
            breadcrumbsVar(breadcrumbs);
        }
        return function () {
            if (titles) {
                breadcrumbsVar([]);
            }
        };
    }, [titles]);
};
