var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@private/fontawesome/pro-regular-svg-icons/faAngleRight';
import gql from 'graphql-tag';
import { last } from 'ramda';
import { useCallback, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { OPTIMISTIC_DISCUSSION_ID } from 'src/constants';
// import { useCommentDeletedSubscription, useReplyAddedSubscription } from 'src/__generated__'
import { useParams } from 'src/hooks';
import { useSetAnnotationPosition } from '../hooks/useSetAnnotationPosition';
import { Comment } from './comment/Comment';
// import useCommentDeletedConfig from './graphql/useCommentDeleted'
// import replyAddedConfig from './graphql/replyAdded'
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment discussion on Discussion {\n\t\tcomments {\n\t\t\t...comment\n\t\t}\n\t\theight @client\n\t\tid\n\t\tleft @client\n\t\tmaxBottom @client\n\t\tminTop @client\n\t\ttop @client\n\t}\n"], ["\n\tfragment discussion on Discussion {\n\t\tcomments {\n\t\t\t...comment\n\t\t}\n\t\theight @client\n\t\tid\n\t\tleft @client\n\t\tmaxBottom @client\n\t\tminTop @client\n\t\ttop @client\n\t}\n"])));
export var Discussion = function (_a) {
    var _b;
    var _c = _a.bottom, bottom = _c === void 0 ? false : _c, comments = _a.comments, id = _a.id, top = _a.top;
    var params = useParams();
    var ref = useRef(null);
    var setAnnotationPosition = useSetAnnotationPosition();
    // useCommentDeletedSubscription(
    // 	useCommentDeletedConfig({ discussionId: id })
    // )
    // useReplyAddedSubscription(replyAddedConfig({ discussionId: id }))
    var handleResize = useCallback(function () {
        if (ref.current && id !== OPTIMISTIC_DISCUSSION_ID) {
            var height = ref.current.getBoundingClientRect().height;
            setAnnotationPosition({ height: height, id: id, params: params });
        }
    }, [id, params, setAnnotationPosition]);
    /**
     * `if (!discussion)` prevents a runtime error on redirect to the chat page:
     * `discussion` is momentarily `undefined` when creating a new discussion on a
     * narrow page (i.e., when the sidebar cannot be shown on the right).
     *
     * TODO: Figure out why
     */
    // if (!discussion) return null
    var MAX_NUMBER_OF_COMMENTS = 7; // including one placeholder for entering a new comment
    var _d = __read(useSearchParams(), 1), searchParams = _d[0];
    var numberOfCommentsShown = searchParams.get('conversation') === id
        ? comments.length
        : MAX_NUMBER_OF_COMMENTS;
    return (
    // groups.length && // TODO: move this to
    // groups[0].users?.find(s => s.id === userId) ?
    _jsxs("div", __assign({ ref: ref, className: "transition-top duration-300 ".concat(bottom ? 'mt-12 font-sans' : 'absolute -mt-px w-full'), style: top == null ? undefined : { top: top } }, { children: [comments.slice(0, numberOfCommentsShown).map(function (comment, index) { return (_createElement(Comment, __assign({}, comment, { key: comment.id, discussionId: id, onResize: comment.sent ? undefined : handleResize, reply: index !== 0 }))); }), comments.length < numberOfCommentsShown + 1 ? (((_b = last(comments)) === null || _b === void 0 ? void 0 : _b.sent) === true && (_jsx(Comment, { discussionId: id, onResize: handleResize, reply: true }, comments.length))) : (_jsx("div", __assign({ className: "text-right" }, { children: _jsxs(Link // No use for <A> here: no scrolling, no reload, etc.
                , __assign({ className: "mb-2 -mt-2 mr-1.5 font-sans text-xs leading-6 text-neutral-450", to: "?conversation=".concat(id) }, { children: ["View all", ' ', _jsx("span", __assign({ className: "relative top-px pl-1 text-base" }, { children: _jsx(FontAwesomeIcon, { icon: faAngleRight }) }))] })) })))] })));
    // : null
};
var templateObject_1;
