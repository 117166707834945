var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@private/fontawesome/pro-regular-svg-icons/faTimes';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ie11 } from 'src/constants';
import { useCookie } from 'src/hooks';
import { Link } from 'src/ui';
export var UpdateBrowser = function () {
    var _a = __read(useCookie('hideIE11Warning'), 2), cookie = _a[0], setCookie = _a[1];
    var intl = useIntl();
    var handleClick = useCallback(function () { return setCookie(new Date().toISOString(), { expires: 31 /* days */ }); }, [setCookie]);
    var handleKeyDown = useCallback(function (event) {
        var key = event.key;
        switch (key) {
            case ' ':
            case 'Enter':
                event.preventDefault();
                setCookie(new Date().toISOString());
                break;
            default:
        }
    }, [setCookie]);
    if (!ie11 || cookie != null) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "m-0 flex justify-start bg-yellow-subtle text-sm leading-4 text-yellow-darker dark:bg-blue-dark dark:text-blue-strong" }, { children: [_jsx("div", __assign({ className: "py-2" }, { children: _jsx("span", __assign({ "aria-label": intl.formatMessage({
                        defaultMessage: 'Warning',
                        id: '3SVI5p',
                    }), role: "img" }, { children: "\u26A0\uFE0F" })) })), _jsx("div", __assign({ className: "w-full py-2" }, { children: _jsx(FormattedMessage, { defaultMessage: "Internet Explorer is not supported by Nextbook. Please consider {upgrading}.", id: "5oWzYN", values: {
                        upgrading: (_jsx(Link, __assign({ className: "underline", href: "/update-browser" }, { children: _jsx(FormattedMessage, { defaultMessage: "upgrading your browser", id: "XYZrg3" }) }))),
                    } }) })), _jsx("div", __assign({ className: "cursor-pointer py-3.5 text-center hover:bg-blue-strong/10 hover:outline-none focus:bg-blue-strong/10 focus:outline-none dark:hover:bg-black/5 dark:focus:bg-black/5", onClick: handleClick, onKeyDown: handleKeyDown, role: "button", tabIndex: 0 }, { children: _jsx(FontAwesomeIcon, { icon: faTimes }) }))] })));
};
