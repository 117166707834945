var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { compose } from 'ramda';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { LINE_HEIGHT } from 'src/constants';
import { Block } from 'src/content/block/Block';
import { shouldSkipOnPage } from 'src/content/block/title/shouldSkipOnPage';
import { Text } from 'src/content/inline/text/Text';
import { classNames } from 'src/functions/classNames';
import { isImage, isTitle } from 'src/functions/typeGuards';
import { useParams } from 'src/hooks';
import { Button } from 'src/ui/Button';
import { Chatbox } from './Chatbox';
import { SectionContext } from './context';
import { Footnotes } from './footnotes/Footnotes';
import { discussion, groupNarrowBlocks, handleFootnotes } from './functions';
import { Overlay } from './overlay/Overlay';
import { Pagination } from './pagination/Pagination';
import { Sidebar } from './Sidebar';
export var Layout = function (_a) {
    var _b;
    var blocks = _a.blocks, formatting = _a.formatting, dir = _a.dir, lang = _a.lang;
    var _c = useLocation(), hash = _c.hash, pathname = _c.pathname;
    var params = useParams();
    var ref = useRef(null);
    /** Only update on navigation between pages */
    useEffect(function () {
        var handleLoad = function () {
            if (hash) {
                var path_1 = hash.substring(1);
                var titleIndex = blocks.findIndex(function (block) { return isTitle(block) && block.path === path_1; });
                // Only scroll to the “most specific” current title if it’s preceded by
                // other content. For example, if the section title is only preceded by a
                // chapter title, we don’t want to scroll to the section title on page load.
                if (blocks.slice(0, titleIndex).find(function (block) { return !isTitle(block); })) {
                    scroller.scrollTo(path_1, { smooth: 'easeOutQuint' });
                }
            }
        };
        window.addEventListener('load', handleLoad);
        return function () { return window.removeEventListener('load', handleLoad); };
    }, [blocks, hash, pathname]);
    var groupedBlocks = compose(groupNarrowBlocks, handleFootnotes)(blocks.filter(function (block, index) {
        var _a;
        if (isTitle(block)) {
            return true;
        }
        var mostRecentTitleId = (_a = __spreadArray([], __read(blocks.slice(0, index)), false).reverse()
            .find(isTitle)) === null || _a === void 0 ? void 0 : _a.id;
        return block.parentId === mostRecentTitleId;
    }));
    var _d = __read(useSearchParams(), 1), searchParams = _d[0];
    var discussionId = searchParams.get('conversation');
    var isChatModeActive = discussionId != null;
    var firstBlock = blocks[0];
    var title = (_b = blocks.find(function (block) { return isTitle(block) && 'depth' in block && block.depth === 2; })) !== null && _b !== void 0 ? _b : (firstBlock != null && isTitle(firstBlock) ? firstBlock : undefined);
    return (_jsxs("main", __assign({ className: "p-0", dir: dir, lang: lang, style: {
            counterReset: 'footnote',
            // Align bottom pagination buttons at bottom of pages that are shorter than the
            // browser window. We can’t set `grid-template-columns` by default on the `main`
            // tag, because non-book pages don’t contain pagination buttons that explicitly
            // take up the `bottom` grid template area, causing the `grid-template-rows`
            // to get allocated automatically, such that the fifth element would get stretched
            // out, which we want to avoid.
            gridTemplateRows: 'auto auto 1fr auto',
        } }, { children: [_jsx(Pagination, {}), formatting && (_jsx(Helmet, { children: _jsx("style", __assign({ type: "text/css" }, { children: [
                        formatting.indentFirstParagraph === true &&
                            ":not(p) + p { text-indent: ".concat(LINE_HEIGHT, "px; }"),
                        formatting.indentSubsequentParagraphs === true &&
                            "p + p { text-indent: ".concat(LINE_HEIGHT, "px; }"),
                    ]
                        .filter(Boolean)
                        .join(' ') })) })), groupedBlocks.map(function (blockOrBlocks) {
                return Array.isArray(blockOrBlocks) ? (
                // `blockOrBlocks` is an array of narrow (i.e. non-wide) blocks
                _jsx("div", __assign({ ref: ref, className: "section-with-grid-children grid gap-x-4 theme-default-font:font-serif theme-dyslexia:font-dyslexia theme-dyslexia:text-sm theme-lowres:font-lowres theme-lowres:text-sm theme-sans:font-sans theme-sans:text-[15px] theme-serif:font-serif theme-serif:text-lg theme-default-font:dark:font-sans theme-default-font:dark:text-[15px]", style: {
                        gridColumnStart: 'content',
                    } }, { children: _jsxs(SectionContext.Provider, __assign({ value: ref }, { children: [blockOrBlocks.map(function (block) { return (_jsx(Block, { block: block }, block.id)); }), params[0] === 'summary' &&
                                // the only block that’s returned is a Title
                                blocks.length === 1 && (_jsxs("div", __assign({ className: "my-11 -mx-1 rounded border border-neutral-310 bg-black/5 p-7 font-sans font-light dark:border-white dark:hires:border-0.5" }, { children: [title ? (_jsx(FormattedMessage, { defaultMessage: "Your personal summary for {title} is currently empty. If you highlight text or add notes, they will show up here. \uD83E\uDD13", id: "YiBmhG", values: {
                                            title: (_jsx("em", { children: _jsx(Text, { delta: 'text' in title ? title.text.delta : undefined }) })),
                                        } })) : (_jsx(FormattedMessage, { defaultMessage: "Your personal summary is currently empty. If you highlight text or add notes, they will show up here. \uD83E\uDD13", id: "gLkF2j" })), _jsx("div", __assign({ className: "mt-6 text-right" }, { children: _jsx(Button, __assign({ href: "/book/".concat(params
                                                .slice(1, params.length)
                                                .join('/')), red: true }, { children: _jsx(FormattedMessage, { defaultMessage: "Go Back", id: "ekfOaV" }) })) }))] }))), !isChatModeActive && _jsx(Sidebar, {})] })) }), blockOrBlocks.map(function (_a) {
                    var id = _a.id;
                    return id;
                }).join(':'))) : (
                // `blockOrBlocks` is a single (wide) block
                _jsx("div", __assign({ className: classNames('wide-block-row grid overflow-x-scroll theme-default-font:font-serif theme-dyslexia:font-dyslexia theme-dyslexia:text-sm theme-lowres:font-lowres theme-lowres:text-sm theme-sans:font-sans theme-sans:text-[15px] theme-serif:font-serif theme-serif:text-lg theme-default-font:dark:font-sans theme-default-font:dark:text-[15px]', isImage(blockOrBlocks) && 'overflow-y-hidden'), style: { gridColumn: 'wideBlockRow' } }, { children: _jsx(Block, { block: blockOrBlocks }) }), blockOrBlocks.id));
            }), isChatModeActive ? (_jsx(Chatbox, { discussion: discussion({ blocks: blocks, discussionId: discussionId }) })) : (_jsxs(_Fragment, { children: [_jsx(Footnotes, { blocks: blocks.filter(function (block) {
                            return block.__typename !== 'Title' ||
                                !shouldSkipOnPage({ depth: block.depth, params: params });
                        }) }), _jsx(Pagination, { bottom: true }), _jsx(Overlay, {})] }))] })));
};
