var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteCommentDocument } from 'src/__generated__';
import { useRemoveCommentFromDiscussion } from 'src/content/annotation/discussion/useRemoveCommentFromDiscussion';
import { useIsChatModeActive } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation DeleteComment($input: DeleteCommentInput!) {\n\t\tdeleteComment(input: $input) {\n\t\t\t... on DeleteCommentPayload {\n\t\t\t\tdeleted\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation DeleteComment($input: DeleteCommentInput!) {\n\t\tdeleteComment(input: $input) {\n\t\t\t... on DeleteCommentPayload {\n\t\t\t\tdeleted\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var useDeleteComment = function () {
    var _a = __read(useMutation(DeleteCommentDocument), 1), deleteComment = _a[0];
    var isChatModeActive = useIsChatModeActive();
    var navigate = useNavigate();
    var removeCommentFromDiscussion = useRemoveCommentFromDiscussion();
    return useCallback(function (_a) {
        var commentId = _a.commentId, params = _a.params, reply = _a.reply;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, deleteComment({
                        optimisticResponse: {
                            __typename: 'Mutation',
                            deleteComment: { __typename: 'DeleteCommentPayload', deleted: true },
                        },
                        update: function (cache, _a) {
                            var data = _a.data;
                            if ((data === null || data === void 0 ? void 0 : data.deleteComment.__typename) !== 'DeleteCommentPayload') {
                                return;
                            }
                            if (!reply) {
                                if (isChatModeActive) {
                                    navigate("/".concat(params.join('/')));
                                }
                            }
                            removeCommentFromDiscussion({
                                cache: cache,
                                commentId: commentId,
                                deleted: data.deleteComment.deleted,
                            });
                        },
                        variables: { input: { commentId: commentId } },
                    })];
            });
        });
    }, [deleteComment, isChatModeActive, navigate, removeCommentFromDiscussion]);
};
var templateObject_1;
