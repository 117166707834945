import { assocPath, map, mergeDeepLeft } from 'ramda';
import { isRichText, isTextInsert } from 'src/functions/typeGuards';
/**
 * Footnotes are formatted by including `{ footnote: UUID }` in the Delta
 * attributes. Sibling ops that share the same footnote id are grouped together
 * into a single footnote.
 *
 * Footnotes have a 1-based index that’s used to link to the footnote (via
 * `#footnote-1`) and to the reference number in the text (via `#reference-1`).
 * Because this number has to increment across the entire page (i.e., across
 * multiple Delta’s), we calculate the index here instead of in the `Text`
 * component.
 */
export var handleFootnotes = function (blocks) {
    var footnoteIndex = 0;
    return blocks.map(function (block) {
        // Ramda’s `map` is applied to a `block` object here:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix type
        return map(function (value) {
            if (isRichText(value)) {
                return assocPath(['delta', 'ops'], value.delta.ops
                    .filter(isTextInsert) // for type checking purposes only
                    .flatMap(function (currentValue, index, array) {
                    var _a, _b, _c, _d;
                    // Put the footnote in the footnotes array, but only if we’re at the first
                    // `op` for a given footnote (this way, we avoid handling the same op twice).
                    if (((_a = currentValue.attributes) === null || _a === void 0 ? void 0 : _a.footnote) != null &&
                        (index === 0 || // we’re either at the first op,
                            ((_c = (_b = array[index - 1]) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.footnote) !==
                                currentValue.attributes.footnote) // or the previous op doesn’t belong to the same footnote
                    ) {
                        footnoteIndex += 1;
                        // first op within footnote adds the footnote number
                        return mergeDeepLeft({
                            attributes: {
                                // footnoteLength: value.delta.ops
                                // 	.filter(
                                // 		// We don’t check for adjacency; this shouldn’t be necessary
                                // 		op =>
                                // 			op.attributes?.footnote ===
                                // 			currentValue.attributes.footnote
                                // 	)
                                // 	.map(op => op.insert.length)
                                // 	.reduce((a, b) => a + b),
                                footnoteNumber: footnoteIndex,
                            },
                        }, currentValue);
                    }
                    if (((_d = currentValue.attributes) === null || _d === void 0 ? void 0 : _d.footnote) != null) {
                        return [];
                    } // second and later ops within footnote are omitted
                    return currentValue;
                }), value);
            }
            return value;
        }, block);
    });
};
