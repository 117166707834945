var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { faArrowRightToBracket } from '@private/fontawesome/pro-regular-svg-icons';
import Cookie from 'js-cookie';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { cachedFormVar } from 'src/cache/reactiveVars';
import { QrInput, ValidatedInput } from 'src/ui/form';
import { Button } from 'src/ui/form/Button';
export var LoginForm = function (_a) {
    var email = _a.email;
    var errorMessage = useRef(Cookie.get('message'));
    var csrf = useRef(Cookie.get('_csrf'));
    useEffect(function () {
        Cookie.remove('message');
    }, []);
    var cachedForm = useReactiveVar(cachedFormVar);
    useEffect(function () {
        if (cachedForm) {
            cachedFormVar(undefined);
        }
    }, [cachedForm]);
    return (_jsxs("form", __assign({ action: "/login", className: "my-6 -mx-3 flex flex-col", method: "post" }, { children: [(cachedForm === null || cachedForm === void 0 ? void 0 : cachedForm.qr) != null && (_jsxs("div", __assign({ className: "ml-3 flex w-full flex-row justify-between" }, { children: [_jsx(QrInput, { children: _jsx(FormattedMessage, { defaultMessage: "The QR code {qr} will be automatically activated upon login.", id: "bvbuN7", values: {
                                qr: _jsx("code", __assign({ style: { margin: '0 0.1em' } }, { children: cachedForm.qr })),
                            } }) }), _jsx("input", { name: "_csrf", type: "hidden", value: csrf.current }), _jsx("input", { name: "qr", type: "hidden", value: cachedForm.qr })] }))), _jsxs("div", __assign({ className: "mt-6 flex w-full flex-row justify-between" }, { children: [_jsx(FormattedMessage, __assign({ defaultMessage: "Email", id: "sy+pv5" }, { children: function (_a) {
                            var _b = __read(_a, 1), msg = _b[0];
                            return (_jsx(ValidatedInput, __assign({}, (email != null || (cachedForm === null || cachedForm === void 0 ? void 0 : cachedForm.email) != null
                                ? { defaultValue: email !== null && email !== void 0 ? email : cachedForm === null || cachedForm === void 0 ? void 0 : cachedForm.email }
                                : { autoFocus: true }), { name: "email", placeholder: msg, type: "text" })));
                        } })), _jsx(FormattedMessage, __assign({ defaultMessage: "Password", id: "5sg7KC" }, { children: function (_a) {
                            var _b = __read(_a, 1), msg = _b[0];
                            return (_jsx(ValidatedInput, { autoFocus: email != null || (cachedForm === null || cachedForm === void 0 ? void 0 : cachedForm.email) != null || undefined, errorMessage: errorMessage.current, name: "password", placeholder: msg, type: "password" }));
                        } })), _jsx(Button, __assign({ icon: faArrowRightToBracket, type: "submit" }, { children: _jsx(FormattedMessage, { defaultMessage: "Log In", id: "r2Jjms" }) }))] }))] })));
};
