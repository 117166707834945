var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { ContinueGuidedTourDocument, QuitGuidedTourDocument, } from 'src/__generated__';
import { useCurrentUserQuery } from 'src/hooks';
import { Breadcrumbs, LanguageSelector, ProfileButton, ScrollDown, Welcome, } from './steps';
export * from './Buttons';
export * from './Overlay';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation ContinueGuidedTour {\n\t\tcontinueGuidedTour {\n\t\t\t... on ContinueGuidedTourPayload {\n\t\t\t\tpreference {\n\t\t\t\t\tguidedTourStep\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n\n\tmutation QuitGuidedTour {\n\t\tquitGuidedTour {\n\t\t\t... on QuitGuidedTourPayload {\n\t\t\t\tpreference {\n\t\t\t\t\tguidedTourStep\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation ContinueGuidedTour {\n\t\tcontinueGuidedTour {\n\t\t\t... on ContinueGuidedTourPayload {\n\t\t\t\tpreference {\n\t\t\t\t\tguidedTourStep\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n\n\tmutation QuitGuidedTour {\n\t\tquitGuidedTour {\n\t\t\t... on QuitGuidedTourPayload {\n\t\t\t\tpreference {\n\t\t\t\t\tguidedTourStep\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"
    /** @see {@link https://medialoot.com/item/hand-drawn-vector-arrows} TODO: Provide attribution for arrows on separate Acknowledgements page */
])));
/** @see {@link https://medialoot.com/item/hand-drawn-vector-arrows} TODO: Provide attribution for arrows on separate Acknowledgements page */
export var GuidedTour = function () {
    var _a;
    var _b = __read(useMutation(ContinueGuidedTourDocument), 1), continueGuidedTour = _b[0];
    var _c = __read(useMutation(QuitGuidedTourDocument), 1), quitGuidedTour = _c[0];
    var preference = ((_a = useCurrentUserQuery()) !== null && _a !== void 0 ? _a : {}).preference;
    var guidedTourStep = (preference !== null && preference !== void 0 ? preference : {}).guidedTourStep; // `{}` when not logged in
    useEffect(function () {
        var handleKeyDown = function (_a) {
            var key = _a.key;
            if (guidedTourStep == null) {
                return;
            }
            switch (key) {
                case 'ArrowRight':
                    // TODO: add special case for arrow down & dropdown
                    continueGuidedTour();
                    break;
                case 'Escape':
                    quitGuidedTour();
                    break;
                default:
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return function () { return window.removeEventListener('keydown', handleKeyDown); };
    }, [continueGuidedTour, guidedTourStep, quitGuidedTour]);
    switch (guidedTourStep) {
        case 'Welcome':
            return _jsx(Welcome, {});
        case 'Breadcrumbs':
            return _jsx(Breadcrumbs, {});
        case 'ScrollDown':
            return _jsx(ScrollDown, {});
        case 'LanguageSelector':
            return _jsx(LanguageSelector, {});
        case 'ProfileButton':
            return _jsx(ProfileButton, {});
        default:
    }
    return null;
};
var templateObject_1;
