var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { animated, config, useTransition } from '@react-spring/web';
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { highlightPopupVar, modeVar, selectionPopupVar, } from 'src/cache/reactiveVars';
import { classNames } from 'src/functions';
import { position, usePointerEvents } from './functions';
import { HighlightButtons } from './highlightButtons/HighlightButtons';
import { SelectionButtons } from './selectionButtons/SelectionButtons';
import { useGetRichTextParts } from './selectionButtons/useGetRichTextParts';
var TOUCHSCREEN_POPUP_VERTICAL_OFFSET = 150;
// FIXME: glitchy selection when moving up & hovering the popup element
export var Popup = function () {
    var _a;
    var mode = useReactiveVar(modeVar);
    var pointerEvents = usePointerEvents();
    var getRichTextParts = useGetRichTextParts();
    var selectionPopup = useReactiveVar(selectionPopupVar);
    var highlightPopup = useReactiveVar(highlightPopupVar);
    var isShowing = selectionPopup != null || highlightPopup != null;
    var _b = (_a = selectionPopup !== null && selectionPopup !== void 0 ? selectionPopup : highlightPopup) !== null && _a !== void 0 ? _a : {}, x = _b.x, y = _b.y;
    var transition = useTransition(__spreadArray(__spreadArray([], __read((selectionPopup ? [{ type: 'Selection' }] : [])), false), __read((highlightPopup
        ? [{ id: highlightPopup.highlightId, type: 'Highlight' }]
        : [])), false), {
        config: __assign(__assign({}, config.stiff), { clamp: true, tension: 400 }),
        enter: { opacity: 1, transform: 'scale(1)' },
        from: { border: '100px solid red', opacity: 0, transform: 'scale(0.5)' },
        keys: function (item) { return "".concat(item.type, ":").concat(item.id); },
        leave: { opacity: 0, transform: 'scale(0.5)' },
    });
    useEffect(function () {
        var handleKeyDown = function (event) {
            if (isShowing &&
                (event.key === 'Escape' ||
                    // We currently disable tabbing to the selection popup, because the selection
                    // itself disappears, which is confusing. If we want to enable this, we should
                    // make sure to tab immediately to the popup menu instead of first to other
                    // elements as is currently the case.
                    (event.key === 'Tab' && selectionPopup != null))) {
                selectionPopupVar(undefined);
            }
        };
        /**
         * The `selectionchange` event is called not only when the visible selection
         * (highlighted in light blue) changes, but also when the user clicks
         * anywhere on the page, regardless of whether the click causes
         * deselection.
         */
        var handleSelectionChange = function () {
            var selection = window.getSelection();
            if (!selection) {
                return;
            }
            if (getRichTextParts().length > 0) {
                var range = selection.getRangeAt(0);
                var newDimensions = position(range.getBoundingClientRect(), range.getClientRects());
                if (newDimensions.x !== x || newDimensions.y !== y) {
                    selectionPopupVar(newDimensions);
                    highlightPopupVar(undefined);
                }
            }
            else {
                if (selectionPopup && selection.isCollapsed) {
                    selectionPopupVar(undefined);
                }
            }
        };
        if (mode == null) {
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('selectionchange', handleSelectionChange);
        }
        return function () {
            if (mode == null) {
                document.removeEventListener('keydown', handleKeyDown);
                document.removeEventListener('selectionchange', handleSelectionChange);
            }
        };
    }, [getRichTextParts, isShowing, mode, selectionPopup, x, y]);
    var root = useMemo(function () { return document.getElementById('root'); }, []);
    if (!root) {
        return null;
    }
    var touch = false; // TODO: fix
    return createPortal(transition(function (_a, item) {
        var opacity = _a.opacity, transform = _a.transform;
        return isShowing && (_jsx("div", __assign({ className: classNames('pointer-events-none absolute z-70 -mt-16 text-center', item.type === 'Selection' && 'transition-all'), style: {
                /** `x`: used when showing, `coords.current.x`: when hiding */
                left: x,
                top: y !== null && y !== void 0 ? y : 0 + (touch ? TOUCHSCREEN_POPUP_VERTICAL_OFFSET : 0),
            } }, { children: _jsx(animated.div, __assign({ "aria-orientation": "horizontal", 
                // Why not `-ml-1/2` for horizontal centering?
                className: "pointer-events-auto ml-[-100%] inline-flex origin-[center_calc(100%_+_13px)] select-none whitespace-nowrap rounded bg-neutral-730 text-lg font-light text-neutral-150 shadow after:absolute after:left-1/2 after:-ml-3.5 after:h-0 after:w-0 after:border-transparent dark:bg-gray-850 ".concat(touch
                    ? 'after:bottom-[calc(100%_-_1px)] after:border-[14px] after:border-t-0 after:border-b-neutral-730 dark:after:border-b-gray-850'
                    : 'after:top-[calc(100%_-_1px)] after:border-[14px] after:border-b-0 after:border-t-neutral-730 dark:after:border-t-gray-850'), id: "popup" // 'popup' must correspond to value for `aria-flowto` in Highlight component
                , role: "menu" // must match value for `aria-haspopup` in Highlight component
                , style: {
                    opacity: opacity,
                    pointerEvents: pointerEvents,
                    transform: transform,
                } }, { children: item.type === 'Selection' ? (_jsx(SelectionButtons, {})) : (item.type === 'Highlight' && (_jsx(HighlightButtons, { highlightId: item.id }))) })) })));
    }), root);
};
