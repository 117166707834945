var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { Element } from 'react-scroll';
import { useMeasure } from 'react-use';
import { TitleFragmentDoc, } from 'src/__generated__';
import { cache } from 'src/cache';
import { hasNavVar } from 'src/cache/reactiveVars';
import { HEADER_HEIGHT, TOOLBAR_HEIGHT } from 'src/constants';
import { Text } from 'src/content/inline/text/Text';
import { HeadingContext } from 'src/context';
import { useParams, useSelectableRef } from 'src/hooks';
import { Label } from './Label';
import { shouldSkipOnPage } from './shouldSkipOnPage';
export var Title = function (title) {
    var breadcrumbVerticalStartPosition = title.breadcrumbVerticalStartPosition, depth = title.depth, label = title.label, _a = title.numbers, numbers = _a === void 0 ? null : _a, path = title.path, separator = title.separator, style = title.style, text = title.text;
    var client = useApolloClient();
    var params = useParams();
    var hasNav = useReactiveVar(hasNavVar);
    /**
     * The `skip` attribute determines whether a title should be shown on the page
     * or not. (Parent titles are always included in the `blocks` array for
     * breadcrumb purposes, but may be hidden in the main content.) If `true`, the
     * title should not be shown on the page and is just included for the
     * breadcrumbs (e.g. chapter title on second and later pages of chapter)
     */
    var skip = shouldSkipOnPage({ depth: depth, params: params });
    /** Delay to make sure the entire application has finished rendering */
    var _b = __read(useMeasure(), 2), ref = _b[0], top = _b[1].top;
    useEffect(function () {
        if (!skip && top !== breadcrumbVerticalStartPosition) {
            // update scroll threshold
            // include check to prevent infinite loop
            var id = cache.identify(title);
            var cacheData = client.readFragment({
                fragment: TitleFragmentDoc,
                fragmentName: 'title',
                id: id,
            });
            if (cacheData) {
                client.writeFragment({
                    data: __assign(__assign({}, cacheData), { breadcrumbVerticalStartPosition: top }),
                    fragment: TitleFragmentDoc,
                    fragmentName: 'title',
                    id: id,
                });
            }
        }
    }, [breadcrumbVerticalStartPosition, client, skip, title, top]);
    var selectableRef = useSelectableRef(text.id);
    /** Level of heading element ('h1', ..., 'h6') */
    var level;
    var PARAMS_FOR_CHAPTER_PAGE = 3;
    switch (depth) {
        // book title doesn’t occur on same page as chapter title
        // case 0: return <h1>{children}</h1>
        case 1:
            if (params.length <= PARAMS_FOR_CHAPTER_PAGE) {
                level = 1;
            } // || params[0] === SUMMARY
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            level = depth;
            break;
        default:
            level = 6;
    }
    var headingContext = useMemo(function () { return ({ level: level }); }, [level]);
    if (skip) {
        return null;
    }
    var content = (_jsxs(_Fragment, { children: [_jsx(Label, { hanging: !hasNav && label == null, label: label, numbers: numbers, separator: separator, style: style }), ' ', _jsx("span", __assign({ ref: selectableRef }, { children: _jsx(Text, __assign({}, text)) }))] }));
    if (level == null) {
        return null;
    }
    /** Name of React heading element */
    var Tag = "h".concat(level);
    return (_jsx(HeadingContext.Provider, __assign({ value: headingContext }, { children: _jsxs(Tag, __assign({ ref: ref, className: "relative mt-4 mb-2" }, { children: [_jsx(Element
                // `scrollAnchor` is inside the `h` tag so that its reference point is
                // the top of this element, not the bottom of the preceding element.
                // TODO: Make className dependent on whether the toolbar is showing
                , { 
                    // `scrollAnchor` is inside the `h` tag so that its reference point is
                    // the top of this element, not the bottom of the preceding element.
                    // TODO: Make className dependent on whether the toolbar is showing
                    className: "absolute", name: path, style: {
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        top: -(HEADER_HEIGHT + TOOLBAR_HEIGHT + (depth > 4 ? 12 : 0)),
                    } }), content] })) })));
};
