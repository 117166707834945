var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useReactiveVar } from '@apollo/client';
import { values } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { hasNavVar, hasSidebarVar, showingCommentsVar, showingNotesVar, } from 'src/cache/reactiveVars';
import { Discussion } from 'src/content/annotation/discussion/Discussion';
import { Note } from 'src/content/annotation/note/Note';
import { shouldSkipOnPage } from 'src/content/block/title/shouldSkipOnPage';
import { getAnnotations } from 'src/functions';
import { isRichText, isSidebarAnnotation, isTitle, } from 'src/functions/typeGuards';
import { useBookQuery, useParams } from 'src/hooks';
/**
 * Space available at the right side of the page, where annotations such as
 * notes and discussions are shown.
 */
export var Sidebar = function () {
    var hasNav = useReactiveVar(hasNavVar);
    var params = useParams();
    var showingComments = useReactiveVar(showingCommentsVar);
    var showingNotes = useReactiveVar(showingNotesVar);
    // There can be multiple sidebars below each other, interspersed by wide content.
    // The vertical position of (absolutely positioned) annotations has to take the
    // `top` position of its containing sidebar into account.
    var _a = __read(useMeasure(), 2), ref = _a[0], verticalPosition = _a[1].top;
    useEffect(function () {
        hasSidebarVar(true);
        return function () {
            hasSidebarVar(false);
        };
    }, []);
    var book = useBookQuery();
    var sidebarAnnotations = useMemo(function () {
        var _a;
        var blocks = (_a = book === null || book === void 0 ? void 0 : book.blocks) !== null && _a !== void 0 ? _a : [];
        return blocks
            .flatMap(function (block, index) {
            return values(block)
                .filter(isRichText)
                .map(function (_a) {
                var richTextAnnotations = _a.richTextAnnotations;
                return richTextAnnotations
                    .map(function (_a) {
                    var annotation = _a.annotation;
                    var previousBlockAnnotations = getAnnotations(blocks[index - 1]);
                    var displayInSidebar = !previousBlockAnnotations.find(function (_a) {
                        var id = _a.id;
                        return id === annotation.id;
                    });
                    /**
                     * If the title is not on the page (but was included in the
                     * `blocks` array so that it could be shown as a breadcrumb),
                     * its annotations should be hidden as well.
                     */
                    if (isTitle(block) &&
                        'depth' in block &&
                        shouldSkipOnPage({ depth: block.depth, params: params })) {
                        return null;
                    }
                    // don’t include `annotation.top` here, because we want to measure
                    // the component’s height, and hence render it, which
                    // is required to calculate its `top` position
                    if (!isSidebarAnnotation(annotation) ||
                        !displayInSidebar ||
                        annotation.top == null ||
                        verticalPosition == null) {
                        return null;
                    }
                    switch (annotation.__typename) {
                        case 'Discussion':
                            return showingComments ? (_createElement(Discussion, __assign({}, annotation, { key: annotation.id, 
                                // (*) `annotation.top` is calculated relative to the
                                // top of the page, but `<aside />` has `position: relative`,
                                // so its children are positioned relative to itself, and
                                // we need to correct the value of `top` accordingly:
                                top: annotation.top - verticalPosition }))) : null;
                        case 'Note':
                            return showingNotes ? (_createElement(Note, __assign({}, annotation, { key: annotation.id, top: annotation.top - verticalPosition }))) : null;
                        default:
                            return null;
                    }
                })
                    .filter(Boolean);
            });
        })
            .flat();
    }, [book === null || book === void 0 ? void 0 : book.blocks, params, showingComments, showingNotes, verticalPosition]);
    return (_jsx("aside", __assign({ ref: ref, className: "".concat(hasNav ? 'normalwithtoc:grid' : 'compactwithouttoc:grid', " relative z-1 hidden min-w-[287px] overflow-visible font-sans leading-[unset]"), style: { gridColumn: 'sidebar', gridTemplateAreas: 'sidebar' } }, { children: sidebarAnnotations })));
};
