var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import gql from 'graphql-tag';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment video on Video {\n\t\tparentId\n\t\tsource\n\t}\n"], ["\n\tfragment video on Video {\n\t\tparentId\n\t\tsource\n\t}\n"
    /** Initially embedded videos only (Vimeo, YouTube, etc.). */
])));
/** Initially embedded videos only (Vimeo, YouTube, etc.). */
export var Video = function (_a) {
    var 
    // caption: { annotations, delta, id },
    source = _a.source;
    var src = source.toString();
    // state = { acknowledgedDoNotTrackWarning: true } // set flag in user prefs
    var acknowledgedDoNotTrackWarning = true;
    return ['https://www.youtube.com', 'https://youtube.com'].some(function (url) {
        return src.startsWith(url);
    }) ? (acknowledgedDoNotTrackWarning ? (_jsx("div", __assign({ 
        // Maintain 16:9 aspect ratio cf. https://stackoverflow.com/a/10441480
        className: "relative mx-0 my-6 h-0 w-full bg-neutral-150 pb-[56.25%]" }, { children: _jsx("iframe", { src: src.replace('/watch?v=', '/embed/'), title: "YouTube" }) }))) : (_jsx("div", {}))) : src.startsWith('https://vimeo.com') ? (_jsx("div", __assign({ className: "relative mx-0 my-6 h-0 w-full bg-neutral-150 pb-[56.25%]" }, { children: _jsx("iframe", { src: src.replace('vimeo.com', 'player.vimeo.com/video'), title: "Vimeo" }) }))) : src.startsWith('https://cdnapisec.kaltura.com') ? (_jsx("div", __assign({ className: "relative mx-0 my-6 h-0 w-full bg-neutral-150 pb-[56.25%]" }, { children: _jsx("iframe", { allow: "autoplay *; fullscreen *; encrypted-media *", frameBorder: "0", 
            // height="360"
            sandbox: "allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation", src: src, title: "Kaltura" }) }))) : (
    // TODO: Take this into account in text editor
    // eslint-disable-next-line jsx-a11y/media-has-caption
    _jsx("video", { controls: true, preload: "metadata", src: src }));
};
var templateObject_1;
