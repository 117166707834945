var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { AuthenticationRequired } from './AuthenticationRequired';
import NotFound from './NotFound';
import { PermissionDenied } from './PermissionDenied';
import { UnknownError } from './UnknownError';
/** Message to display when there is a runtime error in the application */
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return { error: error };
    };
    // componentDidCatch(error: Error, info: ErrorInfo) {
    ErrorBoundary.prototype.componentDidCatch = function () {
        if (process.env.NODE_ENV === 'production') {
            // Raven.captureException(error, { extra: info })
        }
    };
    ErrorBoundary.prototype.render = function () {
        var _a, _b;
        var children = this.props.children;
        var error = this.state.error;
        if (!error) {
            return children;
        }
        var title = ((_b = (_a = error.data) === null || _a === void 0 ? void 0 : _a.book) !== null && _b !== void 0 ? _b : {}).title;
        if (!title) {
            return _jsx(UnknownError, {});
        }
        // TODO: remove `throw`s in server and replace by one generic error boundary
        if (error.message) {
            switch (error.message) {
                case 'NotFound':
                    return _jsx(NotFound, {});
                case 'Denied':
                    return _jsx(PermissionDenied, { bookTitle: title });
                case 'NotLoggedIn':
                    return _jsx(AuthenticationRequired, { bookTitle: title });
                default:
                    return _jsx(UnknownError, {});
            }
        }
        var HTTP_STATUS_CODE_NOT_FOUND = 404;
        // TODO: remove res.status(...) in server and replace by one generic error boundary
        if (error.statusCode != null) {
            switch (error.statusCode) {
                case HTTP_STATUS_CODE_NOT_FOUND:
                    return _jsx(NotFound, {});
                default:
                    return _jsx(UnknownError, {});
            }
        }
        return _jsx(UnknownError, {});
    };
    return ErrorBoundary;
}(Component));
export { ErrorBoundary };
