var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useRef, useState } from 'react';
/**
 * When selecting text, the popup can obstruct the mouse and cause the selection
 * to jitter. This hook provides a return value `pointerEvents` which can be
 * supplied to the popup‘s style prop. When the mouse button is pressed down,
 * `pointerEvents` is 'none'. When the mouse button is up, `pointerEvents` is
 * 'all'. A 100ms delay is introduced to allow the popup buttons to receive a
 * mousedown event.
 */
export var usePointerEvents = function () {
    var _a = __read(useState('all'), 2), pointerEvents = _a[0], setPointerEvents = _a[1];
    var pointerEventsTimeout = useRef(undefined);
    useEffect(function () {
        var handleMouseDown = function () {
            var DRAG_TIMEOUT = 100; // milliseconds
            pointerEventsTimeout.current = window.setTimeout(function () { return setPointerEvents('none'); }, DRAG_TIMEOUT);
        };
        var handleMouseUp = function () {
            window.clearTimeout(pointerEventsTimeout.current);
            setPointerEvents('all');
        };
        // When selecting a word by double clicking it, sometimes the mouseup event
        // fires just once — so we must ensure that the popup doesn’t remain disabled.
        var handleDblClick = handleMouseUp;
        document.addEventListener('dblclick', handleDblClick);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        // cleanup
        return function () {
            document.removeEventListener('dblclick', handleDblClick);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    });
    return pointerEvents;
};
