"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overlapping = void 0;
/**
 * Overlapping highlights either (a) overlap with the starting point of an
 * existing highlight
 *
 *      e.g.:       ---existing---
 *            -----new-----
 *
 *      or      ---existing---
 *            -----new-----------
 *
 * (b) overlap with the ending point of an existing highlight
 *
 *      e.g.: ----existing----
 *                     ----new------
 *
 *      or      ---existing---
 *            -----new-----------
 *
 * (c) occur completely within an existing highlight
 *
 *      e.g. -----existing------
 *       ---new---
 *
 * Scenarios (a) and (b) are not mutually exclusive.
 *
 * @returns Merged highlight parts
 */
const overlapping = (existingHighlightedRichTextParts, newRichTextPart) => {
    var _a;
    return ((_a = existingHighlightedRichTextParts === null || existingHighlightedRichTextParts === void 0 ? void 0 : existingHighlightedRichTextParts.filter((item) => 
    // first, filter all highlights to get the ones in the same richText
    item.richTextId === newRichTextPart.richTextId &&
        // next, find the overlapping highlights
        // Scenario (a):
        ((item.position >= newRichTextPart.position &&
            item.position < newRichTextPart.position + newRichTextPart.length) ||
            // Scenario (b):
            (item.position + item.length <=
                newRichTextPart.position + newRichTextPart.length &&
                item.position + item.length > newRichTextPart.position)))) !== null && _a !== void 0 ? _a : []);
};
exports.overlapping = overlapping;
