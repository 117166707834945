import { values } from 'ramda';
import { isDiscussion, isRichText } from 'src/functions/typeGuards';
export var discussion = function (_a) {
    var blocks = _a.blocks, discussionId = _a.discussionId;
    var d = null;
    blocks.find(function (block) {
        return values(block).find(function (value) {
            return !d &&
                isRichText(value) &&
                value.richTextAnnotations.find(function (richTextAnnotation) {
                    var annotation = richTextAnnotation.annotation;
                    if (isDiscussion(annotation) && annotation.id === discussionId) {
                        d = annotation;
                        return true; // ends search in `annotations`
                    }
                    return false;
                });
        });
    });
    return d;
};
