var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { init, last, omit, values } from 'ramda';
import { isRichText, isTextInsert } from 'src/functions/typeGuards';
/** @returns An array of footnotes */
export var getFootnotesFromBlocks = function (blocks) {
    return blocks
        // rich texts shouldn’t be grouped per block, just per block attribute
        .flatMap(function (block) {
        return values(block).filter(function (value) {
            if (isRichText(value)) {
                var richText = value;
                return richText.delta.ops.find(function (op) { var _a; return isTextInsert(op) && ((_a = op.attributes) === null || _a === void 0 ? void 0 : _a.footnote); });
            }
            return false;
        }).map(function (_a) {
            var ops = _a.delta.ops, id = _a.id;
            return ({
                id: id,
                ops: ops.filter(function (op) { var _a; return isTextInsert(op) && ((_a = op.attributes) === null || _a === void 0 ? void 0 : _a.footnote); }),
            });
        });
    })
        .flatMap(
    // Generate a nested array where the top level are the footnotes, and the
    // bottom level are the Delta operations (`ops`) per footnote. For this,
    // we need to split up the incoming array (grouped per block attribute) at
    // the top level first, because a single block attribute can have multiple
    // footnotes.
    function (item) {
        var splitRichText = item.ops.reduce(function (acc, op) {
            var _a;
            var lastAcc = last(acc);
            if (
            // `?.footnote` is not necessary (we know `footnote` exists on `attributes`)
            // but it’s easier than to construct the correct type to supply to
            // `accumulator` above (i.e., a `BlockFragment` with the guarantee)
            lastAcc &&
                ((_a = last(lastAcc)) === null || _a === void 0 ? void 0 : _a.attributes.footnote) === op.attributes.footnote) {
                return __spreadArray(__spreadArray([], __read(init(acc)), false), [__spreadArray(__spreadArray([], __read(lastAcc), false), [op], false)], false);
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [[op]], false);
        }, []);
        return splitRichText.map(function (ops) { return ({
            delta: {
                __typename: 'Delta',
                ops: ops.map(function (op) { return (__assign(__assign({}, op), { attributes: omit(['footnote'], op.attributes) })); }),
            },
            id: item.id,
        }); });
    }, []);
};
