var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Text } from 'src/content/inline/text/Text';
import { getFormattedPath } from 'src/functions';
import { useParams } from 'src/hooks';
/** List item in a dropdown menu */
export var DropdownMenuItem = forwardRef(function (_a, ref) {
    var depthOfPageTitle = _a.depthOfPageTitle, displayHand = _a.displayHand, linkAction = _a.linkAction, handleKeyDown = _a.onKeyDown, title = _a.title, titles = _a.titles;
    var params = useParams();
    /** Handeling the scroll to the Hierachy Component */
    var scroll = useCallback(function () {
        linkAction();
        scroller.scrollTo(title.path, { smooth: 'easeOutQuint' });
    }, [linkAction, title.path]);
    return (_jsxs(Link, __assign({ ref: ref, className: "".concat(displayHand
            ? 'focus:before:left-[.4em] focus:before:top-[.3em] focus:before:h-[1.3em] focus:before:w-[2.2em] focus:before:rounded'
            : 'focus:before:left-[.58em] focus:before:top-[.62em] focus:before:h-[.55em] focus:before:w-[.55em] focus:before:rounded-full', " relative block cursor-pointer whitespace-nowrap py-0.5 pl-11 pr-10 text-inherit before:absolute focus:outline-none focus:before:block focus:before:bg-blue-subtle dark:focus:before:bg-blue-strong"), onClick: scroll, onKeyDown: handleKeyDown, role: "menuitem", to: "/".concat(params.slice(0, 2).join('/')).concat(getFormattedPath({
            depthOfPageTitle: depthOfPageTitle,
            titles: titles.slice(0, titles.findIndex(function (_a) {
                var id = _a.id;
                return id === title.id;
            }) + 1),
        })) }, { children: [title.numbers && title.numbers.length > 0 && (_jsx("span", { className: "dropdown-menu-counter absolute right-full -mr-9 inline-block align-bottom", style: displayHand ? { visibility: 'hidden' } : {} })), displayHand && (_jsx("span", __assign({ className: "absolute -top-1 right-full -mr-9 box-border inline-block align-bottom text-3xl" }, { children: "\u261E" }))), _jsx("span", __assign({ className: "inline-block align-bottom transition-colors" }, { children: _jsx(Text, { delta: title.text.delta }) }))] })));
});
DropdownMenuItem.displayName = 'DropdownMenuItem';
