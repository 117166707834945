var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createRef, useRef } from 'react';
/** Used to position dropdown menu relative to their corresponding breadcrumbs */
export var usePositions = function (_a) {
    var _b, _c, _d, _e, _f;
    var breadcrumbs = _a.breadcrumbs, ellipsisSiblings = _a.ellipsisSiblings, groupedTitles = _a.groupedTitles;
    var numberOfDropdownMenus = breadcrumbs.length + ((ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings.length) > 0 ? 1 : 0);
    /**
     * Width and height for each breadcrumb, used to position dropdown menu; and a
     * reference to the breadcrumb, and the first and last element in its
     * corresponding dropdown menu
     *
     * We only need to be able to access the first and last element via the
     * keyboard from this component. The other menu items are handled within
     * DropdownMenu. Two dropdownmenus may overlap during opening/closing, hence
     * the separate refs for each level..
     */
    var positions = useRef(__spreadArray(__spreadArray([], __read(breadcrumbs), false), __read(((ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings.length) ? [ellipsisSiblings[0]] : [])), false).map(function (breadcrumb, i) {
        var _a, _b;
        return ({
            breadcrumb: createRef(),
            // `width` will be added here
            menuItems: i === 0
                ? // first dropdown menu has a fixed number of items (4)
                    [
                        createRef(),
                        createRef(),
                        createRef(),
                        createRef(),
                    ]
                : (_b = (_a = groupedTitles.current
                    .find(function (_a) {
                    var _b;
                    var id = _a.id;
                    return id === ((_b = (breadcrumb !== null && breadcrumb !== void 0 ? breadcrumb : ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings[0])) === null || _b === void 0 ? void 0 : _b.id);
                })) === null || _a === void 0 ? void 0 : _a.siblings.map(function () { return createRef(); })) !== null && _b !== void 0 ? _b : [],
        });
    }));
    // trim the `positions` array if the breadcrumbs array from Redux is shorter
    // for breadcrumbs that have disappeared from the DOM, e.g. due to scrolling
    if (numberOfDropdownMenus < positions.current.length) {
        positions.current.splice(numberOfDropdownMenus);
    }
    var _loop_1 = function (i) {
        // match two cases:
        if (positions.current[i] == null) {
            // a) the breadcrumb hasn’t had a reference assigned (breadcrumb has yet to appear during this render cycle)
            positions.current[i] = { breadcrumb: createRef(), menuItems: [] };
        }
        // can be cumulative:
        if (((_b = positions.current[i]) === null || _b === void 0 ? void 0 : _b.menuItems) == null ||
            ((_d = (_c = positions.current[i]) === null || _c === void 0 ? void 0 : _c.menuItems) === null || _d === void 0 ? void 0 : _d.length) === 0) {
            // b) no menu item refs assigned yet (query wasn’t returned from server yet)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            positions.current[i].menuItems =
                (_f = (_e = groupedTitles.current
                    .find(function (_a) {
                    var _b, _c;
                    var id = _a.id;
                    return id === ((_c = ((_b = breadcrumbs[i]) !== null && _b !== void 0 ? _b : ellipsisSiblings === null || ellipsisSiblings === void 0 ? void 0 : ellipsisSiblings[0])) === null || _c === void 0 ? void 0 : _c.id);
                })) === null || _e === void 0 ? void 0 : _e.siblings.map(function () { return createRef(); })) !== null && _f !== void 0 ? _f : [];
        }
    };
    // add references for breadcrumbs that have appeared due to scrolling
    for (var i = 0; i < numberOfDropdownMenus; i += 1) {
        _loop_1(i);
    }
    return positions;
};
