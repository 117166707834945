var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import { pick } from 'ramda';
import { BreadcrumbsDocument } from 'src/__generated__';
import { breadcrumbsVar } from 'src/cache/reactiveVars';
import { nestContentInsideSections } from 'src/content/functions';
import { classNames } from 'src/functions';
import { useParams } from 'src/hooks';
import { Spinner } from 'src/ui';
import { addFormattedPaths } from './addFormattedPaths';
import { RecursiveTree } from './RecursiveTree';
/** Table of contents with book titles and links to the pages */
export var TableOfContents = function (_a) {
    var className = _a.className, depth = _a.depth, sidebar = _a.sidebar;
    var params = useParams();
    var _b = useQuery(BreadcrumbsDocument, {
        variables: { bookParams: params.slice(0, 2) },
    }), data = _b.data, loading = _b.loading;
    var book = (data === null || data === void 0 ? void 0 : data.book.__typename) === 'BookPayload' ? data.book.book : undefined;
    var breadcrumbs = useReactiveVar(breadcrumbsVar);
    if (loading) {
        return sidebar == null ? _jsx(Spinner, {}) : null;
    }
    // `book` can be `null` in case of a 404. An error is displayed via the `Book` component.
    if (!book) {
        return null;
    } // <Spinner />
    var titles = nestContentInsideSections(addFormattedPaths(pick(['depthOfPageTitle', 'titles'], book)));
    return (_jsx("div", __assign({ className: classNames(sidebar != null && 'mt-6', "text-left ".concat(className)), style: { counterReset: 'menu' } }, { children: _jsx(RecursiveTree, { depthOfPageTitle: book.depthOfPageTitle, maxDepth: depth !== null && depth !== void 0 ? depth : book.depthOfPageTitle + 1, sidebar: sidebar, staticHeaderTitles: breadcrumbs !== null && breadcrumbs !== void 0 ? breadcrumbs : [], titles: titles }) })));
};
