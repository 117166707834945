var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router';
import { HEADER_HEIGHT } from 'src/constants';
import { classNames } from 'src/functions';
import { useBreadcrumbs } from 'src/hooks';
import { Link, Nav } from 'src/ui';
import DisplayPreferences from './displayPreferences/DisplayPreferences';
import { Overview } from './Overview';
import ProfileSettings from './settings';
var menuItems = [
    {
        label: _jsx(FormattedMessage, { defaultMessage: "Overview", id: "9uOFF3" }),
        url: '/profile',
    },
    {
        label: (_jsx(FormattedMessage, { defaultMessage: "Display Preferences", id: "1eslim" })),
        url: '/profile/preferences',
    },
    {
        label: _jsx(FormattedMessage, { defaultMessage: "Account Settings", id: "NQgbYA" }),
        url: '/profile/settings',
    },
];
/** Personal user page */
export default function Profile() {
    var intl = useIntl();
    useBreadcrumbs([
        {
            href: '/profile',
            text: intl.formatMessage({ defaultMessage: 'Profile', id: 'itPgxd' }),
        },
        {
            href: '/profile',
            text: intl.formatMessage({ defaultMessage: 'Overview', id: '9uOFF3' }),
        },
    ]);
    var pathname = useLocation().pathname;
    return (_jsxs(_Fragment, { children: [_jsx(Nav, __assign({ className: "w-80" }, { children: _jsx("ul", __assign({ className: "my-4 list-none whitespace-nowrap" }, { children: menuItems.map(function (_a) {
                        var url = _a.url, label = _a.label;
                        var isActive = pathname === url;
                        return (_jsx("li", { children: _jsx(Link, __assign({ className: classNames('relative flex items-center rounded px-4 border-solid border-transparent py-2 text-sm font-medium', isActive ? 'bg-neutral-250' : 'hover:bg-neutral-150'), href: url }, { children: label })) }, url));
                    }) })) })), _jsx("main", __assign({ className: "flex w-full flex-col items-center pt-12", style: { marginTop: HEADER_HEIGHT } }, { children: _jsx("div", __assign({ className: "max-w-3xl" }, { children: _jsxs(Routes, { children: [_jsx(Route, { element: _jsx(Overview, {}), path: "" }), _jsx(Route, { element: _jsx(DisplayPreferences, {}), path: "preferences/*" }), _jsx(Route, { element: _jsx(ProfileSettings, {}), path: "settings/*" })] }) })) }))] }));
}
