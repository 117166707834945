var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import { deepmerge } from 'deepmerge-ts';
import gql from 'graphql-tag';
import { AddEmailDocument, ProfileEmailsFragmentDoc } from 'src/__generated__';
import { OPTIMISTIC_EMAIL_ID } from 'src/constants';
import { useCurrentUserQuery } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation AddEmail($input: AddEmailInput!) {\n\t\taddEmail(input: $input) {\n\t\t\t... on AddEmailPayload {\n\t\t\t\temail {\n\t\t\t\t\tid\n\t\t\t\t\taddress\n\t\t\t\t\tverified\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation AddEmail($input: AddEmailInput!) {\n\t\taddEmail(input: $input) {\n\t\t\t... on AddEmailPayload {\n\t\t\t\temail {\n\t\t\t\t\tid\n\t\t\t\t\taddress\n\t\t\t\t\tverified\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var useAddEmail = function () {
    var user = useCurrentUserQuery();
    var _a = __read(useMutation(AddEmailDocument), 2), addEmail = _a[0], error = _a[1].error;
    return {
        addEmail: function (_a) {
            var email = _a.email;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, addEmail({
                            optimisticResponse: {
                                __typename: 'Mutation',
                                addEmail: {
                                    __typename: 'AddEmailPayload',
                                    email: {
                                        __typename: 'Email',
                                        address: email,
                                        id: OPTIMISTIC_EMAIL_ID,
                                        verified: false,
                                    },
                                },
                            },
                            update: function (cache, result) {
                                var _a, _b, _c;
                                if (((_a = result.data) === null || _a === void 0 ? void 0 : _a.addEmail.__typename) !== 'AddEmailPayload' ||
                                    user == null) {
                                    return;
                                }
                                var newEmail = (_c = (_b = result.data) === null || _b === void 0 ? void 0 : _b.addEmail) === null || _c === void 0 ? void 0 : _c.email;
                                cache.updateFragment({
                                    fragment: ProfileEmailsFragmentDoc,
                                    id: cache.identify(user),
                                }, function (data) { return data && deepmerge(data, { emails: [newEmail] }); });
                            },
                            variables: { input: { email: email } },
                        })];
                });
            });
        },
        error: error,
    };
};
var templateObject_1;
