var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import gql from 'graphql-tag';
import { Fragment } from 'react';
import { Blockquote } from './Blockquote';
import { Codeblock } from './Codeblock';
import { File } from './file/File';
import { Formula } from './Formula';
import { Image } from './image/Image';
import { List } from './list/List';
import { MultipleChoice } from './multipleChoice/MultipleChoice';
import { Paragraph } from './Paragraph';
import { Table } from './table/Table';
import { TextArea } from './textArea/TextArea';
import { Title } from './title/Title';
import { Video } from './Video';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment block on BlockInterface {\n\t\tid\n\t\t...blockquote\n\t\t...box\n\t\t...codeblock\n\t\t...file\n\t\t...formula\n\t\t...image\n\t\t...list\n\t\t...listItem\n\t\t...multipleChoice\n\t\t...multipleChoiceOption\n\t\t...paragraph\n\t\t...table\n\t\t...tableCell\n\t\t...textArea\n\t\t...title\n\t\t...video\n\t}\n"], ["\n\tfragment block on BlockInterface {\n\t\tid\n\t\t...blockquote\n\t\t...box\n\t\t...codeblock\n\t\t...file\n\t\t...formula\n\t\t...image\n\t\t...list\n\t\t...listItem\n\t\t...multipleChoice\n\t\t...multipleChoiceOption\n\t\t...paragraph\n\t\t...table\n\t\t...tableCell\n\t\t...textArea\n\t\t...title\n\t\t...video\n\t}\n"])));
/** The content items for a certain title (hierarchical level) */
export var Block = function (_a) {
    var block = _a.block;
    var key = block.id;
    switch (block.__typename) {
        case 'Blockquote':
            return _createElement(Blockquote, __assign({}, block, { key: key }));
        case 'Codeblock':
            return _createElement(Codeblock, __assign({}, block, { key: key }));
        case 'File':
            return _createElement(File, __assign({}, block, { key: key }));
        case 'Formula':
            return _createElement(Formula, __assign({}, block, { key: key }));
        case 'Image':
            return (_jsx(Fragment, { children: _jsx(Image, __assign({}, block)) }, key));
        case 'List':
            return _createElement(List, __assign({}, block, { key: key }));
        case 'MultipleChoice':
            return _createElement(MultipleChoice, __assign({}, block, { key: key }));
        case 'Paragraph':
            return _createElement(Paragraph, __assign({}, block, { key: key }));
        case 'Table':
            return _createElement(Table, __assign({}, block, { key: key }));
        case 'TextArea':
            return _createElement(TextArea, __assign({}, block, { key: key }));
        case 'Title':
            return _createElement(Title, __assign({}, block, { key: key }));
        case 'Video':
            return _createElement(Video, __assign({}, block, { key: key }));
        default:
            return null;
    }
};
var templateObject_1;
