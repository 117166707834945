var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { activeHighlightColorVar, highlightPopupVar, } from 'src/cache/reactiveVars';
import { useColorOptions } from 'src/hooks/useColorOptions';
import { useDeleteHighlight } from './useDeleteHighlight';
import { useRecolorHighlight } from './useRecolorHighlight';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation DeleteHighlight($input: DeleteHighlightInput!) {\n\t\tdeleteHighlight(input: $input) {\n\t\t\t... on DeleteHighlightPayload {\n\t\t\t\tdeleted\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n\n\tmutation RecolorHighlight($input: RecolorHighlightInput!) {\n\t\trecolorHighlight(input: $input) {\n\t\t\t... on RecolorHighlightPayload {\n\t\t\t\thighlight {\n\t\t\t\t\tcolor\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation DeleteHighlight($input: DeleteHighlightInput!) {\n\t\tdeleteHighlight(input: $input) {\n\t\t\t... on DeleteHighlightPayload {\n\t\t\t\tdeleted\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n\n\tmutation RecolorHighlight($input: RecolorHighlightInput!) {\n\t\trecolorHighlight(input: $input) {\n\t\t\t... on RecolorHighlightPayload {\n\t\t\t\thighlight {\n\t\t\t\t\tcolor\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var HighlightButtons = function (_a) {
    var highlightId = _a.highlightId;
    var options = useColorOptions();
    var recolorHighlight = useRecolorHighlight();
    var recolor = useCallback(function (color) {
        activeHighlightColorVar(color);
        recolorHighlight({ color: color, highlightId: highlightId });
        highlightPopupVar(undefined);
    }, [highlightId, recolorHighlight]);
    var deleteHighlight = useDeleteHighlight({ highlightId: highlightId });
    var handleKeyDown = useCallback(function (
    /** The function we want to execute on enter / spacebar keydown */
    action) {
        return function (event) {
            if (event.key === 'Enter') {
                action();
            }
            if (event.key === ' ') {
                action();
                event.preventDefault();
            }
        };
    }, []);
    /**
     * Putting `highlightPopupVar(undefined)` in the `update` function of the
     * `deleteHighlight` mutation would cause it to be called twice
     */
    var clear = useCallback(function () {
        deleteHighlight();
        highlightPopupVar(undefined);
    }, [deleteHighlight]);
    return (_jsxs(_Fragment, { children: [options.map(function (_a, index) {
                var color = _a.color, className = _a.className, title = _a.title;
                return (_jsx("div", __assign({ className: "flex h-10 cursor-pointer items-center justify-center first:w-9 first:pl-2 ".concat(index === options.length - 1 ? 'w-9 pr-1' : 'w-8'), onKeyDown: handleKeyDown(function () { return recolor(color); }), onMouseDown: function () { return recolor(color); }, role: "menuitem", tabIndex: 0 }, { children: _jsx("div", { className: "".concat(className, " inline-block h-4 w-4 rounded-full transition-transform"), title: title }) }), color));
            }), _jsx("div", __assign({ className: "box-border flex h-10 cursor-pointer items-center justify-center border-l border-l-neutral-500 px-4 py-3 text-sm font-normal hover:text-white hires:border-l-0.5", onKeyDown: handleKeyDown(clear), onMouseDown: clear, role: "menuitem", tabIndex: 0 }, { children: _jsx(FormattedMessage, { defaultMessage: "Clear", id: "/GCoTA" }) }))] }));
};
var templateObject_1;
