var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { Label } from 'src/content/block/title/Label';
import { useBookQuery, useParams } from 'src/hooks';
import { Button } from './Button';
import { DefaultTitleLabel } from './DefaultTitleLabel';
import { DEFAULT_SEPARATOR } from './Pagination';
import { usePreviousPage } from './usePreviousPage';
export var PreviousButton = function () {
    var _a, _b, _c, _d, _e;
    var _f = __read(useParams(), 2), _mode = _f[0], book = _f[1];
    var intl = useIntl();
    var titles = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).titles;
    var _g = (_b = usePreviousPage()) !== null && _b !== void 0 ? _b : {}, title = _g.title, url = _g.url;
    if (!title || url == null) {
        return (_jsx(Button, { label: _jsx(FormattedMessage, { defaultMessage: "Back", id: "cyR7Kh" }), previous: true, title: {
                __typename: 'Delta',
                ops: [
                    {
                        __typename: 'TextInsert',
                        insert: intl.formatMessage({
                            defaultMessage: 'Cover Page',
                            id: 'm3yW9G',
                        }),
                    },
                ],
            }, url: "/book/".concat(book) }));
    }
    // If there’s no previous title, link to the cover page:
    var delta = title.text.delta;
    /**
     * If none of the titles have custom labels (like "Part") preceding the title
     * numbers, we use the default naming scheme ("Chapter" & "Section"), because
     * there is no risk of default labels clashing with custom labels at a
     * different depth.
     */
    var alwaysUseDefaultLabel = !(titles === null || titles === void 0 ? void 0 : titles.find(function (t) { return t.label; }));
    // TODO: Use decimals interspersed with dots and include all parent title
    // numbers if no labels, separators or list-style-types were set
    if (alwaysUseDefaultLabel) {
        return (_jsx(Button, { label: _jsx(DefaultTitleLabel, { depth: title.depth, number: (_c = title === null || title === void 0 ? void 0 : title.numbers) === null || _c === void 0 ? void 0 : _c.join((_d = title.separator) !== null && _d !== void 0 ? _d : DEFAULT_SEPARATOR), previousLabel: (title === null || title === void 0 ? void 0 : title.numbers) == null || title.numbers.length === 0 }), previous: true, title: delta, url: url }));
    }
    if ((title === null || title === void 0 ? void 0 : title.numbers) != null && title.numbers.length > 0) {
        return (_jsx(Button, { label: _jsx(Label, __assign({}, title, { label: (_e = title.label) !== null && _e !== void 0 ? _e : (typeof title.depth === 'number' && (_jsx(DefaultTitleLabel, { depth: title.depth }))), numbers: title === null || title === void 0 ? void 0 : title.numbers })), previous: true, title: delta, url: url }));
    }
    return (_jsx(Button, { label: _jsx(FormattedMessage, { defaultMessage: "Previous", id: "JJNc3c" }), previous: true, title: delta, url: url }));
};
