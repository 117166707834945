var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getFormattedPath } from 'src/functions';
/**
 * Completes the URL for table of content items
 *
 * @returns Titles array where each element has an additional `formattedPath`
 *   attributes
 */
export var addFormattedPaths = function (_a) {
    var depthOfPageTitle = _a.depthOfPageTitle, _b = _a.titles, titles = _b === void 0 ? [] : _b;
    return titles.map(function (title, index) { return (__assign(__assign({}, title), { formattedPath: getFormattedPath({
            depthOfPageTitle: depthOfPageTitle,
            titles: titles.slice(0, index + 1),
        }) })); });
};
