var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@private/fontawesome/pro-regular-svg-icons/faTrashAlt';
import { faUndo } from '@private/fontawesome/pro-regular-svg-icons/faUndo';
import { faWindowMinimize } from '@private/fontawesome/pro-regular-svg-icons/faWindowMinimize';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OPTIMISTIC_NOTE_ID } from 'src/constants';
import { classNames } from 'src/functions';
import { useParams } from 'src/hooks';
import { useSetAnnotationPosition } from '../hooks/useSetAnnotationPosition';
import { useToggleAtBottomOfPage } from '../hooks/useToggleAtBottomOfPage';
import { useDeleteNote } from './useDeleteNote';
import { useUpdateNote } from './useUpdateNote';
export var Note = function (_a) {
    var initialHeight = _a.height, id = _a.id, shownAtBottomOfPage = _a.shownAtBottomOfPage, text = _a.text, top = _a.top, _b = _a.isStatic, isStatic = _b === void 0 ? false : _b;
    var intl = useIntl();
    /** Return value of setTimeout, used to clear the timeout */
    var delay = useRef();
    var params = useParams();
    var ref = useRef(null);
    var textAreaRef = useRef(null);
    var height = useRef(initialHeight);
    var setAnnotationPosition = useSetAnnotationPosition();
    // const overlayAvailable = useOverlay()
    var SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE = shownAtBottomOfPage; // && overlayAvailable
    var _c = __read(useState(false), 2), deleteConfirmation = _c[0], setDeleteConfirmation = _c[1];
    var _d = __read(useState(false), 2), focus = _d[0], setFocus = _d[1];
    var _e = __read(useState(text), 2), value = _e[0], setValue = _e[1];
    var deleteNote = useDeleteNote({ noteId: id, textAreaRef: textAreaRef });
    var updateNote = useUpdateNote();
    var toggleAtBottomOfPage = useToggleAtBottomOfPage();
    useEffect(function () {
        var handleGlobalKeyDown = function (_a) {
            var key = _a.key;
            if (key === 'Escape' && deleteConfirmation) {
                setDeleteConfirmation(false);
            }
        };
        window.addEventListener('keydown', handleGlobalKeyDown);
        return function () { return window.removeEventListener('keydown', handleGlobalKeyDown); };
    }, [deleteConfirmation]);
    useEffect(function () {
        var _a;
        if (!shownAtBottomOfPage) {
            var rect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (rect &&
                id !== OPTIMISTIC_NOTE_ID &&
                // TODO: Check why all of these are 0 in DOMRect on focus (left mouse click)
                !(
                // See (*)
                (rect.bottom === 0 &&
                    rect.height === 0 &&
                    rect.left === 0 &&
                    rect.right === 0 &&
                    rect.top === 0 &&
                    rect.width === 0 &&
                    rect.x === 0 &&
                    rect.y === 0))) {
                if (height.current !== rect.height) {
                    height.current = rect.height;
                    setAnnotationPosition({ height: rect.height, id: id, params: params });
                }
            }
        }
    }, [id, params, setAnnotationPosition, shownAtBottomOfPage, value]); // also include `value`
    /**
     * If the note got updated at the bottom of the page, we need to make sure
     * that other instances (such as the note in the sidebar) have the most
     * up-to-date value as well.
     *
     * FIXME: in a more elegant way (messes with cursor position)
     */
    // const updatedTextAsSet = useRef(text)
    // useEffect(() => {
    // 	if (updatedTextAsSet.current !== text) {
    // 		updatedTextAsSet.current = text // first, to avoid an infinite loop
    // 		setValue(text) // second
    // 	}
    // }, [text])
    var handleBlur = useCallback(function () {
        setFocus(false);
    }, []);
    var handleChange = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setValue(target.value);
        var rect = shownAtBottomOfPage
            ? null
            : (_b = ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        // The mutation gets delayed by 0.5 seconds, and gets reset if there’s a new
        // mutation within 0.5 seconds. This makes the textarea more responsive.
        window.clearTimeout(delay.current);
        var DELAY = 500;
        delay.current = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, updateNote({ variables: { input: { id: id, text: target.value } } })];
        }); }); }, 
        // Update immediately if height changed
        ref.current && rect && rect.height > 0 && height.current !== rect.height
            ? 0
            : DELAY // see (*)
        );
    }, [id, shownAtBottomOfPage, updateNote]);
    var handleFocus = useCallback(function () {
        setFocus(true);
        // The commented line below was written so that the “currently active” note
        // would be shown immediately at the page bottom upon resize. Figure out a
        // more robust solution.
        // if (!shownAtBottomOfPage) toggleAtBottomOfPage({ id, params })
    }, []);
    var handleKeyDown = useCallback(function (_a) {
        var key = _a.key;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(key === 'Escape')) return [3 /*break*/, 3];
                        if (!(value === '')) return [3 /*break*/, 2];
                        return [4 /*yield*/, deleteNote()];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE) {
                            toggleAtBottomOfPage({ id: id });
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }, [
        SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE,
        deleteNote,
        id,
        toggleAtBottomOfPage,
        value,
    ]);
    // TODO: Harmonise this behaviour (perform action on space/enter key down) for _all_ elements & components implementing this behaviour
    var handleCloseIconKeyDown = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = event.key;
                    switch (_a) {
                        case ' ': return [3 /*break*/, 1];
                        case 'Enter': return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 5];
                case 1:
                    if (!value) return [3 /*break*/, 2];
                    setDeleteConfirmation(true);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, deleteNote()];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    event.preventDefault();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [deleteNote, value]);
    var handleCloseIconMouseDown = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.button === 0)) return [3 /*break*/, 3];
                    if (!value) return [3 /*break*/, 1];
                    setDeleteConfirmation(true);
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, deleteNote()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [deleteNote, value]);
    var handleMinimizeIconKeyDown = useCallback(function (event) {
        switch (event.key) {
            case ' ':
            case 'Enter':
                toggleAtBottomOfPage({ id: id });
                event.preventDefault();
                break;
            default:
        }
    }, [id, toggleAtBottomOfPage]);
    var handleMinimizeIconMouseDown = useCallback(function () {
        toggleAtBottomOfPage({ id: id });
    }, [id, toggleAtBottomOfPage]);
    var placeholder = intl.formatMessage({
        defaultMessage: 'Add a note',
        id: 'dTW4m3',
    });
    return (_jsx("div", __assign({ ref: ref, className: classNames('absolute w-full transition-top duration-300', SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE
            ? 'inset-0 rounded-t border border-b-0 border-yellow-noteborder dark:border-yellow-strong'
            : 'col-sidebar'), style: __assign(__assign({}, (SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE
            ? {}
            : { marginTop: -13, paddingBottom: 14 })), (top == null
            ? { visibility: 'hidden' }
            : SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE
                ? undefined
                : { top: top })) }, { children: _jsxs("div", __assign({ className: "relative h-full w-full" }, { children: [deleteConfirmation && (_jsxs("div", __assign({ className: "absolute inset-0 z-1 box-border flex select-none flex-col items-center justify-center bg-yellow-subtle/50 p-1.5 backdrop-blur dark:bg-yellow-dark/50 ".concat(SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE ? 'rounded-t' : 'rounded') }, { children: [_jsx("div", __assign({ className: "mb-1 whitespace-normal text-center text-xs text-black/50 dark:text-yellow-strong" }, { children: _jsx(FormattedMessage, { defaultMessage: "Are you sure you want to permanently delete this note?", id: "vyY/zY" }) })), _jsxs("div", __assign({ className: "flex items-center gap-2" }, { children: [_jsxs("button", __assign({ className: "cursor-pointer rounded border border-black/50 py-1 px-2 align-baseline text-xs text-black/50 hover:border-black hover:text-black dark:border-none dark:bg-yellow-dark/50 dark:text-yellow-strong dark:shadow-none dark:hover:bg-yellow-dark", onClick: function () { return setDeleteConfirmation(false); }, type: "button" }, { children: [_jsx(FontAwesomeIcon, { className: "mr-2 text-xs", icon: faUndo }), _jsx(FormattedMessage, { defaultMessage: "Keep", id: "pNTyiX" })] })), _jsxs("button", __assign({ className: "cursor-pointer rounded border border-black/50 py-1 px-2 align-baseline text-xs text-black/50 hover:border-black hover:text-black dark:border-none dark:bg-yellow-dark/50 dark:text-yellow-strong dark:shadow-none dark:hover:bg-yellow-dark", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                        return [2 /*return*/, deleteNote()];
                                    }); }); }, type: "button" }, { children: [_jsx(FontAwesomeIcon, { className: "mr-2 text-xs", icon: faTrashAlt }), _jsx(FormattedMessage, { defaultMessage: "Delete", id: "K3r6DQ" })] }))] }))] }))), _jsx("textarea", { ref: textAreaRef, className: classNames(
                    // inset-0 doesn’t stretch the textarea in Firefox
                    'absolute top-0 left-0 m-0 box-border inline-block h-full min-h-6 w-full resize-none border border-transparent border-b-yellow-noteborder bg-yellow-subtle bg-gradient-to-br from-yellow-subtle to-yellow-subtlegradient py-4 px-5 font-sans text-sm leading-6 text-yellow-dark shadow outline-none focus:border-yellow-dark/20 focus:ring-0 dark:border dark:border-yellow-strong/30 dark:bg-yellow-darker dark:bg-none dark:text-yellow-strong dark:shadow-none dark:placeholder:text-yellow-strong/50 dark:placeholder:opacity-100 dark:focus:border-yellow-strong hires:dark:border-0.5', SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE
                        ? 'overflow-visible rounded-t'
                        : 'overflow-hidden rounded'), id: "note-".concat(id || 0), onBlur: handleBlur, onChange: handleChange, onFocus: handleFocus, onKeyDown: handleKeyDown, placeholder: placeholder, value: value }), _jsx("div", __assign({ "aria-hidden": true, className: "pointer-events-none invisible m-0 box-border inline-block h-full min-h-6 w-full resize-none overflow-hidden whitespace-pre-wrap rounded py-4 px-5 font-sans text-sm leading-6 outline-none", style: { overflowWrap: 'anywhere' } }, { children: (value === null || value === void 0 ? void 0 : value.endsWith('\n'))
                        ? "".concat(value, " ") // Make sure the last line gets shown, if empty
                        : value || placeholder })), _jsxs("div", __assign({ className: "absolute top-px -right-0.5 flex text-neutral-750 theme-contrast:text-black dark:text-yellow-strong" }, { children: [SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE && (_jsx("span", __assign({ className: "relative flex cursor-pointer items-center justify-center py-2 pl-3 pr-2 text-yellow-darker/50 transition-colors hover:text-yellow-darker dark:text-yellow-strong/70 dark:hover:text-yellow-strong", onKeyDown: handleMinimizeIconKeyDown, onMouseDown: handleMinimizeIconMouseDown, role: "button", tabIndex: 0 }, { children: _jsx(FontAwesomeIcon, { className: "rounded-tr rounded-bl text-base", icon: faWindowMinimize }) }))), !isStatic && (_jsxs("span", __assign({ className: "relative flex cursor-pointer items-center justify-center py-2 pr-3 pl-2 text-yellow-darker/50 transition-colors hover:text-yellow-darker dark:text-yellow-strong/70 dark:hover:text-yellow-strong", onKeyDown: handleCloseIconKeyDown, onMouseDown: handleCloseIconMouseDown, role: "button", tabIndex: 0 }, { children: [value === '' && focus && !SHOWN_IN_OVERLAY_AT_BOTTOM_OF_PAGE && (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: "-my-1 mr-1 rounded-sm border border-transparent bg-white/50 py-0.5 px-1 text-xs dark:bg-black/20 hires:border-x-0.5 hires:border-t-0.5" }, { children: "Esc" })), _jsx("span", __assign({ className: "mr-1 text-xs" }, { children: " / " }))] })), _jsx(FontAwesomeIcon, { className: "rounded-tr rounded-bl text-base", icon: faXmark })] })))] }))] })) })));
};
