import { makeVar } from '@apollo/client';
export var activeHighlightColorVar = makeVar('Yellow');
export var autoScrollVar = makeVar(false);
export var breadcrumbsVar = makeVar([]);
// Cached form data, saved temporarily when the user navigates between some pages
// (e.g. after scanning a QR code (if not logged in) -> login page -> sign up page)
// in order to prevent having to ask to scan the QR code again.
export var cachedFormVar = makeVar(undefined);
/**
 * Indicates whether the navigation panel is being displayed.
 *
 * - `false` if the page doesn’t contain a `<Nav />` component
 * - `useReactiveVar(isShowingNavIfLoggedOutVar)` if the page contains a `<Nav />`
 *   component
 */
export var hasNavVar = makeVar(false);
/**
 * `true` if `<Sidebar />` is present on the page
 *
 * TODO: Refactor to `{ available: boolean, showing: boolean }` and use to
 * determine `<Overlay />`
 */
export var hasSidebarVar = makeVar(false);
export var headerVar = makeVar(undefined);
/**
 * Implemented separately from `selectionPopupVar` because the “selection” popup
 * is hidden onSelectionChange, when the selection is collapsed; whilst the
 * “highlight” popup is be shown when clicking a highlight. This click can cause
 * an existing selection to collapse, leading to a race condition. So it’s
 * easier to implement both types of popups as separate variables, and use
 * `useClosePopup` as a convenience hook to close “the” popup (i.e. both types
 * of popups, but max. one is shown at the same time).
 */
export var highlightPopupVar = makeVar(undefined);
export var isSpeakingVar = makeVar(false);
export var lightboxVar = makeVar(null);
export var modeVar = makeVar(undefined);
/** See `highlightPopupVar` */
export var selectionPopupVar = makeVar(undefined);
/** State corresponding to a toggle that allows users to hide all comments */
export var showingCommentsVar = makeVar(true);
/** State corresponding to a toggle that allows users to hide all notes */
export var showingNotesVar = makeVar(true);
/** State corresponding to a toggle that allows users to hide all highlights */
export var showingHighlightsVar = makeVar(true);
export var toolbarVar = makeVar(false);
