var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import hexRgb from 'hex-rgb';
import { init, last } from 'ramda';
import { Fragment, useMemo } from 'react';
import { S3_CONTENT_ENDPOINT, rightToLeftLanguages } from 'src/constants';
import { Text } from 'src/content/inline/text/Text';
import { classNames, deltaToString } from 'src/functions';
import { Link } from 'src/ui';
import { useFontSize } from './useFontSize';
var STATIC_FALLBACK_FONT_SIZE = 10; // Visually
var getContrastingColor = function (hex) {
    var _a = hexRgb(hex, { format: 'object' }), red = _a.red, green = _a.green, blue = _a.blue;
    return Math.round(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- see https://stackoverflow.com/a/596241
    (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255) === 0
        ? 'text-white'
        : 'text-black';
};
/**
 * One line fits approximately 38 i’s or 12 w’s (determined visually). A lower
 * number is more conservative (lower chance of needlessly).
 */
var NUMBER_OF_TITLE_CHARACTERS_PER_LINE = 15;
var getTitleLineClamp = function (_a) {
    var hasSubtitle = _a.hasSubtitle, titleText = _a.titleText;
    if (titleText.length < NUMBER_OF_TITLE_CHARACTERS_PER_LINE) {
        return 'line-clamp-1';
    }
    if (titleText.length < 2 * NUMBER_OF_TITLE_CHARACTERS_PER_LINE) {
        return 'line-clamp-2';
    }
    return hasSubtitle ? 'line-clamp-3' : 'line-clamp-4';
};
var getSubtitleLineClamp = function (_a) {
    var titleText = _a.titleText;
    if (titleText.length < NUMBER_OF_TITLE_CHARACTERS_PER_LINE) {
        return 'line-clamp-3';
    }
    if (titleText.length < 2 * NUMBER_OF_TITLE_CHARACTERS_PER_LINE) {
        return 'line-clamp-2';
    }
    return 'line-clamp-1';
};
var LinkCoverContainer = function (_a) {
    var className = _a.className, path = _a.path, children = _a.children, style = _a.style;
    var _b = useFontSize(), fontSize = _b.fontSize, ref = _b.ref;
    return (_jsx(Link, __assign({ ref: ref, className: className, href: "/book/".concat(path), style: __assign(__assign({}, style), { fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : STATIC_FALLBACK_FONT_SIZE }) }, { children: children })));
};
var StaticCoverContainer = function (_a) {
    var className = _a.className, children = _a.children, style = _a.style;
    var _b = useFontSize(), fontSize = _b.fontSize, ref = _b.ref;
    return (_jsx("div", __assign({ ref: ref, className: className, style: __assign(__assign({}, style), { fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : STATIC_FALLBACK_FONT_SIZE }) }, { children: children })));
};
/** Book cover */
export var Cover = function (_a) {
    var authors = _a.authors, classNameProp = _a.className, cover = _a.cover, coverGradientFrom = _a.coverGradientFrom, coverGradientTo = _a.coverGradientTo, _b = _a.isStatic, isStatic = _b === void 0 ? false : _b, language = _a.language, path = _a.path, subtitle = _a.subtitle, title = _a.title;
    var authorNames = authors.map(function (_a) {
        var firstName = _a.firstName, middleName = _a.middleName, lastName = _a.lastName;
        return [firstName, middleName != null && "".concat(middleName.slice(0, 1), "."), lastName]
            .filter(Boolean)
            .join(' ');
    });
    var backgroundStyle = useMemo(function () {
        var _a;
        return ({
            backgroundColor: (_a = coverGradientFrom !== null && coverGradientFrom !== void 0 ? coverGradientFrom : coverGradientTo) !== null && _a !== void 0 ? _a : undefined,
            backgroundImage: coverGradientFrom != null && coverGradientTo != null
                ? "-webkit-linear-gradient(left top, ".concat(coverGradientFrom, ", ").concat(coverGradientTo, ")")
                : undefined,
        });
    }, [coverGradientFrom, coverGradientTo]);
    var titleText = deltaToString(title.delta);
    var dir = rightToLeftLanguages.includes(language) ? 'rtl' : 'ltr';
    var contents = cover.startsWith('google-earth-view-') && (_jsxs("div", __assign({ className: "absolute inset-0 box-border flex flex-col justify-between overflow-hidden px-0 rounded-sm text-neutral-750 bg-white dark:bg-neutral-150 dark:text-neutral-750" }, { children: [_jsxs("div", __assign({ className: "flex flex-col justify-between h-[37%] border-t border-x rounded-t-sm border-neutral-200 hires:border-0.5 dark:border-none" }, { children: [_jsx("div", __assign({ className: "tracking-tight pt-3 px-3 text-xl leading-[1.35rem] hyphens-manual font-medium ".concat(getTitleLineClamp({ hasSubtitle: subtitle != null, titleText: titleText })), dir: dir, lang: language, style: { fontSize: '1.85em' }, title: deltaToString(title.delta) }, { children: _jsx(Text, { delta: title.delta }) })), subtitle && (_jsx("div", __assign({ className: "overflow-hidden px-3 pb-1 text-xs font-light hyphens-none ".concat(getSubtitleLineClamp({ titleText: titleText })), dir: dir, lang: language, style: { fontSize: '1.25em' }, title: deltaToString(subtitle.delta) }, { children: _jsx(Text, { delta: subtitle.delta }) })))] })), _jsxs("div", __assign({ className: "relative flex items-end h-[63%] rounded-b dark:rounded-b-sm overflow-hidden", style: backgroundStyle }, { children: [_jsx("div", { className: "absolute inset-0 bg-cover top-2 rounded-b mix-blend-darken dark:rounded-b-sm", style: {
                            // Provide 393 x 482 (@ 2x resolution?) — cut Google Earth images in 4 x 4 = 16
                            backgroundImage: "url(/images/covers/".concat(cover, ")"),
                        } }), authorNames.length >= 1 && (_jsx("div", __assign({ className: "ml-1 mb-1 align-bottom text-base w-full font-normal z-1", style: { fontSize: '1.2em', lineHeight: '1.25em' } }, { children: _jsx("span", __assign({ className: classNames('py-px px-1 relative box-decoration-clone opacity-95 rounded-sm brightness-125 mix-blend-screen', backgroundStyle.backgroundColor != null &&
                                getContrastingColor(backgroundStyle.backgroundColor)), style: __assign(__assign({}, backgroundStyle), { backgroundSize: 256 }) }, { children: authorNames.length === 1 ? (authorNames) : (_jsxs(_Fragment, { children: [init(authorNames.map(function (authorName, authorNameIndex) { return (_jsxs(Fragment, { children: [authorName, authorNameIndex === authorNames.length - 2
                                                ? ' &'
                                                : ',', _jsx("br", {})] }, authorName)); })), last(authorNames)] })) })) })))] }))] })));
    var className = classNames(classNameProp, 
    // Enable hover behavior only in the bookshelf view (library)
    !isStatic &&
        'scale-100 auto-cols-auto pb-[141.421%] transition-transform duration-200 after:shadow-md hover:z-1 hover:scale-105 hover:after:z-1 hover:after:opacity-100 active:scale-102 active:after:opacity-50', 
    // `z-0` fixes flickering effect on hover in Chrome (macOS)
    'relative z-0 box-border block rounded border-none text-left font-sans text-neutral-750 before:absolute before:inset-0 before:rounded-b before:opacity-0 before:transition-opacity after:absolute after:inset-0 after:rounded-b after:opacity-0 after:transition-opacity dark:rounded-sm before:dark:rounded-b-sm after:dark:rounded-b-sm');
    var style = useMemo(function () { return (__assign({}, (cover.startsWith('google-earth-view-')
        ? {}
        : {
            // Provide 393 x 482 (@ 2x resolution?) — cut Google Earth images in 4 x 4 = 16
            backgroundImage: "url(".concat([
                S3_CONTENT_ENDPOINT,
                'media',
                path,
                cover,
            ].join('/'), ")"),
            backgroundSize: 'cover',
        }))); }, [cover, path]);
    if (isStatic) {
        return (_jsx(StaticCoverContainer, __assign({ className: className, style: style }, { children: contents })));
    }
    return (_jsx(LinkCoverContainer, __assign({ className: className, path: path, style: style }, { children: contents })));
};
