var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@private/fontawesome/pro-solid-svg-icons/faNote';
import gql from 'graphql-tag';
import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { selectionPopupVar } from 'src/cache/reactiveVars';
import { useOverlay } from 'src/hooks';
import { getSelectedRange, handleKeyDown } from './functions';
import { useCreateNote } from './useCreateNote';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation CreateNote($input: CreateNoteInput!) {\n\t\tcreateNote(input: $input) {\n\t\t\t... on CreateNotePayload {\n\t\t\t\tnote {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...note\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation CreateNote($input: CreateNoteInput!) {\n\t\tcreateNote(input: $input) {\n\t\t\t... on CreateNotePayload {\n\t\t\t\tnote {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...note\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var NoteButton = function () {
    var _a;
    var dimensions = useRef((_a = getSelectedRange()) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect());
    var intl = useIntl();
    var showingOverlay = useOverlay();
    var createNote = useCreateNote();
    var create = useCallback(function () {
        if (dimensions.current != null) {
            // Include the config here so that we always recalculate the rich text part
            createNote({ dimensions: dimensions.current, showingOverlay: showingOverlay });
        }
        selectionPopupVar(undefined);
    }, [createNote, showingOverlay]);
    return (_jsx("div", __assign({ className: "inline-flex h-10 w-10 cursor-pointer items-center justify-center py-1 text-center leading-10", onKeyDown: handleKeyDown(create), onMouseDown: create, role: "button", tabIndex: 0, title: intl.formatMessage({ defaultMessage: 'Add a note', id: 'dTW4m3' }) }, { children: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: faNote }) })));
};
var templateObject_1;
