var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroupPlus } from '@private/fontawesome/pro-regular-svg-icons';
import { faLayerGroupMinus } from '@private/fontawesome/pro-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { modeVar, showingCommentsVar, showingHighlightsVar, showingNotesVar, } from 'src/cache/reactiveVars';
import { useIsChatModeActive } from 'src/hooks';
import { ToolbarButton } from '../ToolbarButton';
import { ToolbarButtonSeparator } from '../ToolbarButtonSeparator';
export var Layers = function () {
    var isChatModeActive = useIsChatModeActive();
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var mode = useReactiveVar(modeVar);
    var showingHighlights = useReactiveVar(showingHighlightsVar);
    var showingNotes = useReactiveVar(showingNotesVar);
    var showingComments = useReactiveVar(showingCommentsVar);
    var handleClick = useCallback(function () {
        setIsOpen(function (prev) { return !prev; });
        if (mode != null) {
            modeVar(undefined);
        }
    }, [mode]);
    useEffect(function () {
        if (mode != null) {
            setIsOpen(false);
        }
    }, [mode]);
    return (_jsxs(_Fragment, { children: [_jsx(ToolbarButtonSeparator, {}), _jsx(ToolbarButton, { action: handleClick, active: isOpen, icon: _jsx("svg", __assign({ "aria-hidden": "true", 
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    className: "svg-inline--fa fa-layer-group fa-fw", "data-icon": "layer-group", "data-prefix": "fas", focusable: "false", role: "img", viewBox: "0 0 512 512", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "".concat(showingHighlights
                            ? 'M232.5 5.171C247.4-1.718 264.6-1.718 279.5 5.171L498.1 106.2C506.6 110.1 512 118.6 512 127.1C512 137.3 506.6 145.8 498.1 149.8L279.5 250.8C264.6 257.7 247.4 257.7 232.5 250.8L13.93 149.8C5.438 145.8 0 137.3 0 127.1C0 118.6 5.437 110.1 13.93 106.2L232.5 5.171z'
                            : 'M231.2 5.092C239 1.732 247.5 0 256 0C264.5 0 272.1 1.732 280.8 5.092L490.1 94.79C503.4 100.5 512 113.5 512 128C512 142.5 503.4 155.5 490.1 161.2L280.8 250.9C272.1 254.3 264.5 256 256 256C247.5 256 239 254.3 231.2 250.9L21.9 161.2C8.614 155.5 0 142.5 0 128C0 113.5 8.614 100.5 21.9 94.79L231.2 5.092zM256 48C253.1 48 251.1 48.41 250.1 49.21L66.26 128L250.1 206.8C251.1 207.6 253.1 208 256 208C258 208 260 207.6 261.9 206.8L445.7 128L261.9 49.21C260 48.41 258 48 256 48V48z').concat(showingNotes
                            ? 'M498.1 234.2C506.6 238.1 512 246.6 512 255.1C512 265.3 506.6 273.8 498.1 277.8L279.5 378.8C264.6 385.7 247.4 385.7 232.5 378.8L13.93 277.8C5.438 273.8 0 265.3 0 255.1C0 246.6 5.437 238.1 13.93 234.2L67.13 209.6L219.1 279.8C242.5 290.7 269.5 290.7 292.9 279.8L444.9 209.6L498.1 234.2z'
                            : 'M250.1 334.8C251.1 335.6 253.1 336 256 336C258 336 260 335.6 261.9 334.8L452 253.3C447.4 246.4 446.5 237.2 450.5 229.3C456.5 217.4 470.9 212.6 482.7 218.5L491.8 223.1C504.2 229.3 512 241.9 512 255.7C512 270.4 503.3 283.6 489.9 289.3L280.8 378.9C272.1 382.3 264.5 384 256 384C247.5 384 239 382.3 231.2 378.9L22.81 289.6C8.971 283.7 .0006 270.1 .0006 255C.0006 242.9 5.869 231.5 15.76 224.4L26.05 217C36.84 209.3 51.83 211.8 59.53 222.6C66.15 231.9 65.24 244.3 57.1 252.5L250.1 334.8z').concat(showingComments
                            ? 'M292.9 407.8L444.9 337.6L498.1 362.2C506.6 366.1 512 374.6 512 383.1C512 393.3 506.6 401.8 498.1 405.8L279.5 506.8C264.6 513.7 247.4 513.7 232.5 506.8L13.93 405.8C5.438 401.8 0 393.3 0 383.1C0 374.6 5.437 366.1 13.93 362.2L67.13 337.6L219.1 407.8C242.5 418.7 269.5 418.7 292.9 407.8V407.8z'
                            : 'M59.53 350.6C66.15 359.9 65.24 372.3 57.1 380.5L250.1 462.8C251.1 463.6 253.1 464 256 464C258 464 260 463.6 261.9 462.8L452 381.3C447.4 374.4 446.5 365.2 450.5 357.3C456.5 345.4 470.9 340.6 482.7 346.5L491.8 351.1C504.2 357.3 512 369.9 512 383.7C512 398.4 503.3 411.6 489.9 417.3L280.8 506.9C272.1 510.3 264.5 512 256 512C247.5 512 239 510.3 231.2 506.9L22.81 417.6C8.971 411.7 .001 398.1 .001 383C.001 370.9 5.87 359.5 15.76 352.4L26.05 345C36.84 337.3 51.83 339.8 59.53 350.6L59.53 350.6z'), fill: "currentColor" }) })), tooltip: isOpen ? undefined : showingHighlights &&
                    showingNotes &&
                    showingComments ? (_jsx(FormattedMessage, { defaultMessage: "Hide annotations", id: "eOnv2D" })) : !showingHighlights && !showingNotes && !showingComments ? (_jsx(FormattedMessage, { defaultMessage: "Show annotations", id: "hvzaGK" })) : (_jsx(FormattedMessage, { defaultMessage: "Show or hide annotations", id: "OWHxm6" })) }), _jsx(ToolbarButton, { action: function () { return showingHighlightsVar(!showingHighlights); }, icon: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: showingHighlights ? faLayerGroupMinus : faLayerGroupPlus }), showing: isOpen, tooltip: showingHighlights ? (_jsx(FormattedMessage, { defaultMessage: "Hide highlights", id: "wdCUOf" })) : (_jsx(FormattedMessage, { defaultMessage: "Show highlights", id: "9qZm0Q" })) }), _jsx(ToolbarButton, { action: function () { return showingNotesVar(!showingNotes); }, icon: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: showingNotes ? faLayerGroupMinus : faLayerGroupPlus }), showing: isOpen, tooltip: showingNotes ? (_jsx(FormattedMessage, { defaultMessage: "Hide notes", id: "t9Sq0d" })) : (_jsx(FormattedMessage, { defaultMessage: "Show notes", id: "q3LisJ" })) }), _jsx(ToolbarButton, { action: function () { return showingCommentsVar(!showingComments); }, disabled: isChatModeActive, icon: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: showingComments ? faLayerGroupMinus : faLayerGroupPlus }), showing: isOpen, tooltip: showingComments ? (_jsx(FormattedMessage, { defaultMessage: "Hide comments", id: "Rj1cJO" })) : (_jsx(FormattedMessage, { defaultMessage: "Show comments", id: "jKngIe" })) })] }));
};
