import { values } from 'ramda';
import { isNote, isRichText } from 'src/functions/typeGuards';
export var getNoteAtBottomOfPage = function (_a) {
    var blocks = _a.blocks;
    return blocks.reduce(function (accumulator, currentValue) {
        return accumulator !== null && accumulator !== void 0 ? accumulator : values(currentValue).reduce(function (acc, curr) {
            return acc !== null && acc !== void 0 ? acc : (isRichText(curr)
                ? curr.richTextAnnotations
                    .map(function (_a) {
                    var annotation = _a.annotation;
                    return annotation;
                })
                    .filter(isNote)
                    .reduce(function (a, c) {
                    return a !== null && a !== void 0 ? a : (c.shownAtBottomOfPage ? c : null);
                }, null)
                : null);
        }, null);
    }, null);
};
