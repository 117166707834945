var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@private/fontawesome/pro-solid-svg-icons/faUsers';
import { FormattedMessage } from 'react-intl';
export var VisibilitySelector = function (_a) {
    var className = _a.className;
    return (_jsx(FormattedMessage, __assign({ defaultMessage: "Visible to everyone in your class", id: "eJv0uv" }, { children: function (_a) {
            var _b = __read(_a, 1), msg = _b[0];
            return (_jsxs("div", __assign({ "aria-label": msg, className: "group absolute -mt-0.5 -mx-2 flex text-xs cursor-default justify-center p-1.5 ".concat(className) }, { children: [_jsx(FontAwesomeIcon, { icon: faUsers }), _jsx("div", __assign({ className: "absolute z-10 ml-2 mt-6 hidden justify-center after:absolute after:-top-2 after:-ml-2 after:h-0 after:w-0 after:border-8 after:border-t-0 after:border-transparent after:border-b-neutral-750 group-hover:flex" }, { children: _jsx("div", __assign({ className: "rounded bg-neutral-750 whitespace-nowrap px-3 py-2 text-xs font-light text-neutral-100 dark:bg-gray-810" }, { children: msg })) }))] })));
        } })));
};
