var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useApolloClient } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TitleFragmentDoc } from 'src/__generated__';
import { cache } from 'src/cache';
import { HEADER_HEIGHT } from 'src/constants';
import { isTitle } from 'src/functions/typeGuards';
import { findMatchingBreadcrumb } from '../functions';
import { useSetBreadcrumb } from './useSetBreadcrumb';
export var useHandleScroll = function (_a) {
    var blocks = _a.blocks;
    var client = useApolloClient();
    var pathname = useLocation().pathname;
    var setBreadcrumb = useSetBreadcrumb();
    var scrollTimeout = useRef(undefined);
    /**
     * Used as offset to update header labels when scrolling, initialized with
     * approximate value (once measured as 30.578125px)
     *
     * FIXME: Keep in sync with `HEADER_HEIGHT`
     */
    // /**
    //  * Prevent recalculating the breadcrumbs when the previous calculation hasn’t
    //  * been finished yet
    //  */
    // const scrollTimeout = useRef(null)
    var breadcrumbCandidateTitles = useRef((blocks !== null && blocks !== void 0 ? blocks : []).filter(isTitle));
    useEffect(function () {
        breadcrumbCandidateTitles.current = (blocks !== null && blocks !== void 0 ? blocks : []).filter(isTitle);
    }, [blocks]);
    /**
     * When the user navigates to a different page in the same book,
     * `handleScroll` isn’t fired due to no scrolling happening. The `blocks`
     * array changes and different titles get their `breadcrumb` flag set to
     * `true`.
     *
     * However, some titles from the previous page might still need to get their
     * `breadcrumb` flag set to `false`. We want to do this housekeeping everytime
     * the user navigates to a new page, so we save the `pathname` for which we’ve
     * done this cleanup in a reference.
     */
    var pathnameRef = useRef(pathname);
    if (pathnameRef.current !== pathname) {
        pathnameRef.current = pathname; // FIXME: disallowed in future React
        breadcrumbCandidateTitles.current.forEach(function (title) {
            if ('breadcrumb' in title && title.breadcrumb === true) {
                client.writeFragment({
                    data: __assign(__assign({}, title), { breadcrumb: false }),
                    fragment: TitleFragmentDoc,
                    fragmentName: 'title',
                    id: cache.identify(title),
                });
            }
        });
    }
    useEffect(function () {
        /** Put expensive calculations in a timeout */
        var handleScroll = function () {
            window.clearTimeout(scrollTimeout.current);
            scrollTimeout.current = window.setTimeout(function () {
                var y = window.pageYOffset + HEADER_HEIGHT;
                // remove breadcrumbs that should disappear
                breadcrumbCandidateTitles.current.forEach(function (title, index) {
                    if (findMatchingBreadcrumb({
                        index: index,
                        title: title,
                        titles: breadcrumbCandidateTitles.current,
                        y: y,
                    })) {
                        // prevent unnecessary updates
                        if (!('breadcrumb' in title) || title.breadcrumb !== true) {
                            setBreadcrumb({ id: title.id });
                        }
                    }
                    else {
                        /**
                         * Set the `breadcrumb` flag to `false` on the applicable `Title`
                         * object in the `blocks` array. This causes the title to be removed
                         * from the breadcrumbs in the header, and from the document
                         * `<title>`.
                         */
                        var id = cache.identify(title);
                        var cacheData = client.readFragment({
                            fragment: TitleFragmentDoc,
                            fragmentName: 'title',
                            id: id,
                        });
                        if ((cacheData === null || cacheData === void 0 ? void 0 : cacheData.breadcrumb) === true) {
                            client.writeFragment({
                                data: __assign(__assign({}, cacheData), { breadcrumb: false }),
                                fragment: TitleFragmentDoc,
                                fragmentName: 'title',
                                id: id,
                            });
                        }
                    }
                });
            }, 100);
        };
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); }; // cleanup
    }, [client, setBreadcrumb]);
};
