var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { GroupFragmentDoc, ToggleGroupSelectionDocument, } from 'src/__generated__';
import { useBookQuery } from 'src/hooks';
export var SummaryMenu = function () {
    var _a = __read(useMutation(ToggleGroupSelectionDocument), 1), toggleGroupSelection = _a[0];
    // The table of contents on the left can get displayed before the book query
    // has returned.
    var book = useBookQuery();
    var handleChange = useCallback(function (group) {
        toggleGroupSelection({
            optimisticResponse: {
                __typename: 'Mutation',
                toggleGroupSelection: {
                    __typename: 'ToggleGroupSelectionPayload',
                    selected: group.selected !== true,
                },
            },
            update: function (cache, _a) {
                var data = _a.data;
                if ((data === null || data === void 0 ? void 0 : data.toggleGroupSelection.__typename) !==
                    'ToggleGroupSelectionPayload') {
                    return;
                }
                var selected = data.toggleGroupSelection.selected;
                cache.writeFragment({
                    data: __assign(__assign({}, group), { selected: selected }),
                    fragment: GroupFragmentDoc,
                    id: cache.identify(group),
                });
                // refetch:
                if (book) {
                    cache.evict({ id: cache.identify(book) });
                }
            },
            variables: { input: { groupId: group.id } },
        });
    }, [book, toggleGroupSelection]);
    if (!(book === null || book === void 0 ? void 0 : book.groups)) {
        return null;
    }
    switch (book.groups.length) {
        case 0:
        case 1:
            return null;
        default:
            return (_jsxs("fieldset", __assign({ className: "mt-3 ml-2 rounded border border-neutral-250 pt-0 text-xs dark:border-neutral-650" }, { children: [_jsx("legend", __assign({ className: "font-semibold uppercase tracking-wide text-neutral-500 dark:text-neutral-350" }, { children: _jsx(FormattedMessage, { defaultMessage: "Show conversations from", id: "D3rh/i" }) })), _jsx("div", __assign({ className: "flex flex-col gap-1 p-2" }, { children: book.groups.map(function (group) {
                            var _a, _b;
                            return (_jsxs("label", __assign({ className: "select-none", htmlFor: group.id }, { children: [_jsx("input", { checked: (_a = group.selected) !== null && _a !== void 0 ? _a : false, className: "mr-1" // should never be `null` for teachers, but difficult to formalise, hence `|| false`
                                        , id: group.id, onChange: function () { return handleChange(group); }, type: "checkbox", value: group.id }), ' ', (_b = group.name) !== null && _b !== void 0 ? _b : (_jsx(FormattedMessage, { defaultMessage: "(no name)", id: "1xSCmt" }))] }), group.id));
                        }) }))] })));
    }
};
