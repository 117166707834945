var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { hasNavVar } from 'src/cache/reactiveVars';
import { FootnotesContext } from 'src/context';
import { classNames } from 'src/functions';
import { useIsChatModeActive } from 'src/hooks';
import { NextButton } from './NextButton';
import { PreviousButton } from './PreviousButton';
export var DEFAULT_SEPARATOR = '.';
/**
 * Renders the buttons (hyperlinks) at the bottom of a book page, leading to the
 * previous and next section or chapter, whichever is relevant and available.
 */
export var Pagination = function (_a) {
    var _b = _a.bottom, bottom = _b === void 0 ? false : _b;
    var isChatModeActive = useIsChatModeActive();
    var hasNav = useReactiveVar(hasNavVar);
    var setFootnotes = useContext(FootnotesContext).setFootnotes;
    var footnotesContext = useMemo(function () { return ({ footnotes: false, setFootnotes: setFootnotes }); }, [setFootnotes]);
    if (isChatModeActive) {
        return _jsx("div", { className: "h-16" });
    }
    return (_jsx(FootnotesContext.Provider, __assign({ value: footnotesContext }, { children: _jsxs("div", __assign({ className: classNames('flex select-none justify-between pr-7 font-sans', hasNav ? 'pl-2' : 'pl-7', bottom ? 'mt-16 self-end pb-0' : 'mb-12 mt-1'), style: __assign(__assign({}, (bottom
                ? { gridColumn: 'bottom', order: 99 }
                : { gridArea: 'top' })), { gridColumn: 'bottom' }) }, { children: [_jsx(PreviousButton, {}), _jsx(NextButton, {})] })) })));
};
