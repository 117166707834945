export var flags = {
    'ar-SA': '🇸🇦',
    'cs-CZ': '🇨🇿',
    'da-DK': '🇩🇰',
    'de-DE': '🇩🇪',
    'el-GR': '🇬🇷',
    'en-AU': '🇦🇺',
    'en-GB': '🇬🇧',
    'en-IE': '🇮🇪',
    'en-IN': '🇮🇳',
    'en-scotland': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    'en-US': '🇺🇸',
    'en-ZA': '🇿🇦',
    'es-AR': '🇦🇷',
    'es-ES': '🇪🇸',
    'es-MX': '🇲🇽',
    'fi-FI': '🇫🇮',
    'fr-CA': '🇨🇦',
    'fr-FR': '🇫🇷',
    'he-IL': '🇮🇱',
    'hi-IN': '🇮🇳',
    'hu-HU': '🇭🇺',
    'id-ID': '🇮🇩',
    'it-IT': '🇮🇹',
    'ja-JP': '🇯🇵',
    'ko-KR': '🇰🇷',
    'nb-NO': '🇳🇴',
    'nl-BE': '🇧🇪',
    'nl-NL': '🇳🇱',
    'pl-PL': '🇵🇱',
    'pt-BR': '🇧🇷',
    'pt-PT': '🇵🇹',
    'ro-RO': '🇷🇴',
    'ru-RU': '🇷🇺',
    'sk-SK': '🇸🇰',
    'sv-SE': '🇸🇪',
    'th-TH': '🇹🇭',
    'tr-TR': '🇹🇷',
    'zh-CN': '🇨🇳',
    'zh-HK': '🇭🇰',
    'zh-TW': '🇹🇼',
};
export var ie11 = Boolean([/Edge/i, /Trident.*rv[ :]*11\./i].find(function (regex) {
    return window.navigator.userAgent.match(regex);
}));
export var LINE_HEIGHT = 24; // px
export var HEADER_HEIGHT = 31; // px
export var TOOLBAR_HEIGHT = 35; // px
export var OPTIMISTIC_COMMENT_ID = '00000000-0000-0000-0000-000000000000';
export var OPTIMISTIC_DISCUSSION_ID = '00000000-0000-0000-0000-000000000001';
export var OPTIMISTIC_EMAIL_ID = '00000000-0000-0000-0000-000000000002';
export var OPTIMISTIC_HIGHLIGHT_ID = '00000000-0000-0000-0000-000000000003';
export var OPTIMISTIC_NOTE_ID = '00000000-0000-0000-0000-000000000004';
/**
 * List of ISO 639-1 codes for languages that are written from the right to the
 * left (like Arabic)
 */
export var rightToLeftLanguages = ['ar'];
/** In lieu of environment variables, which cannot be used on the front end: */
export var S3_CONTENT_ENDPOINT = 'https://nextbook-content.s3.eu-west-2.amazonaws.com';
