var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { autoScrollVar, isSpeakingVar } from 'src/cache/reactiveVars';
import { scroll } from '../functions';
export var windowWithGlobals = window;
export var useUtterance = function () {
    var BUGFIX_TIMEOUT = 100;
    var synth = window.speechSynthesis;
    var timeout = useRef(undefined);
    var utterance = useRef(undefined);
    // fix for `SpeechSynthesisUtterance.onend` behaving erratically, cf. https://stackoverflow.com/a/35935851
    useEffect(function () {
        windowWithGlobals.utterances = [];
    });
    var autoScroll = useReactiveVar(autoScrollVar);
    var isSpeaking = useReactiveVar(isSpeakingVar);
    var initializeUtterance = useCallback(function (_a) {
        var index = _a.index, language = _a.language, muted = _a.muted, play = _a.play, richTextId = _a.richTextId, sentences = _a.sentences, setCharIndex = _a.setCharIndex, speed = _a.speed, stopSpeaking = _a.stopSpeaking, text = _a.text, voice = _a.voice, volume = _a.volume;
        // Reusing the same utterance causes Firefox to remain silent after initial `play()`.
        // if (utterance.current) utterance.current.text = text
        // else {
        utterance.current = new SpeechSynthesisUtterance(text);
        // }
        if (language != null) {
            utterance.current.lang = language;
        }
        utterance.current.rate = speed;
        if (voice) {
            utterance.current.voice = voice;
        }
        utterance.current.volume = muted ? 0 : volume;
        // Make the index of the word that’s currently being spoken available
        // throughout the component. This value is used to cut off the remaining
        // part of the current sentence so that this part can be played after
        // adjusting a setting (volume, speed, dialect, etc.)
        utterance.current.onboundary = function (_a) {
            var charIndex = _a.charIndex;
            setCharIndex(charIndex);
        };
        /** Fires in Safari with `e.error === undefined` and no further information */
        // utterance.current.onerror = ({ error }) => console.log(error)
        utterance.current.onend = function () {
            if (windowWithGlobals.SKIP_UTTERANCE_ONEND === true) {
                windowWithGlobals.SKIP_UTTERANCE_ONEND = false;
            }
            else {
                if (!sentences) {
                    return;
                }
                // play next sentence
                var next = sentences[sentences.findIndex(function (sentence) {
                    return sentence.index === index && sentence.richTextId === richTextId;
                }) + 1];
                if (next == null) {
                    stopSpeaking();
                }
                else {
                    /**
                     * Due to a presumed bug in Safari, the `onend` function gets called
                     * after the time an utterance **would have** taken has passed, even
                     * if the speech has been paused in the meantime. So below we check
                     * if the voice is currently playing, which should be redundant, but
                     * isn’t.
                     *
                     * TODO: Check if still needed when we don’t pause, but cancel, as
                     * is currently the case.
                     */
                    if (isSpeaking) {
                        play(__assign(__assign({}, next), { ongoing: true, speed: speed, voice: voice, volume: volume }));
                    }
                }
            }
        };
        utterance.current.onstart = function () {
            windowWithGlobals.SKIP_UTTERANCE_ONEND = false;
        };
        // fix for `SpeechSynthesisUtterance.onend` behaving erratically
        windowWithGlobals.utterances = [utterance.current]; // cf. https://stackoverflow.com/a/35935851
        var DELAY = 50; // TODO: Explain why
        if (autoScroll) {
            window.setTimeout(scroll, DELAY);
        }
        if (timeout.current != null) {
            window.clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(function () {
            if (!utterance.current) {
                return;
            }
            synth.cancel(); // fixes Chrome bug to make first `play()` work
            synth.speak(utterance.current);
        }, BUGFIX_TIMEOUT);
    }, [autoScroll, isSpeaking, synth]);
    return [utterance, initializeUtterance];
};
