var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { Text } from 'src/content/inline/text/Text';
import { useSelectableRef } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment formula on Formula {\n\t\t# latex\n\t\tparentId\n\t\ttext {\n\t\t\t...richText\n\t\t}\n\t}\n"], ["\n\tfragment formula on Formula {\n\t\t# latex\n\t\tparentId\n\t\ttext {\n\t\t\t...richText\n\t\t}\n\t}\n"
    /**
     * Formulas can be either plain text or LaTeX-formatted mathematical equations.
     *
     * TODO: Implement LaTeX option
     */
])));
/**
 * Formulas can be either plain text or LaTeX-formatted mathematical equations.
 *
 * TODO: Implement LaTeX option
 */
export var Formula = function (_a) {
    var text = _a.text;
    var selectableRef = useSelectableRef(text.id);
    return (_jsx("div", __assign({ ref: selectableRef, className: "my-6 text-center lining-nums" }, { children: _jsx(Text, __assign({}, text)) })));
};
var templateObject_1;
