import { mergeRight } from 'ramda';
/**
 * Structures the flat titles array so that it’s easier to use for the purposes
 * of the dropdown menu.
 *
 * @returns Titles with their dropdown menu items as array in an additional
 *   `siblings` key
 */
export var groupTitlesPerDropdownMenu = function (titles) {
    if (titles === void 0) { titles = []; }
    return titles.map(function (title, index, array) {
        /** The index of an immediately preceding title of lower depth (higher level) */
        var previousParentIndex = array.findIndex(function (_a, i) {
            var depth = _a.depth;
            return depth < title.depth &&
                i < index &&
                !array.slice(i + 1, index).find(function (t) { return t.depth < title.depth; });
        });
        /**
         * The index of an immediately succeeding title of lower depth (higher
         * level)
         */
        var nextParentIndex = array.findIndex(function (_a, i) {
            var depth = _a.depth;
            return depth < title.depth &&
                i > index &&
                !array.slice(index + 1, i).find(function (t) { return t.depth < title.depth; });
        });
        return mergeRight(title, {
            siblings: array
                .slice(previousParentIndex === -1 ? 0 : previousParentIndex, nextParentIndex === -1 ? array.length : nextParentIndex)
                .filter(function (_a) {
                var depth = _a.depth;
                return depth === title.depth;
            }),
        });
    });
};
