import { useCallback, useEffect, useRef } from 'react';
import { MINIMUM_HORIZONTAL_DROPDOWN_MARGIN } from '../constants';
export var useHandleResize = function (_a) {
    var dropdownElement = _a.dropdownElement, dropdownMenu = _a.dropdownMenu, horizontalPositioningMode = _a.horizontalPositioningMode, positions = _a.positions, setHorizontalPositioningMode = _a.setHorizontalPositioningMode;
    /** Maintain the value for `dropdownMenu` */
    var dropdownMenuRef = useRef(dropdownMenu);
    if (dropdownMenu != null && dropdownMenu !== dropdownMenuRef.current) {
        dropdownMenuRef.current = dropdownMenu; // FIXME: disallowed in future React
    }
    /**
     * The window at which we switched to a full-width dropdown menu. Used to
     * check if we need to switch back to a different positioning mode.
     */
    var fullWidthDropdownWindowSize = useRef(0);
    /**
     * Prevents dropdown menu cutoff where part of the dropdown menu appears off
     * screen because the menu is positioned too far to the right, by
     * repositioning the dropdown menu relative to the right side of the window
     * instead of to the left — if necessary.
     *
     * FIXME: infinite loop on narrow screens, perhaps by saving the window width
     * at mode cutoff points in a ref
     */
    var handleResize = useCallback(function () {
        var _a;
        if (dropdownMenuRef.current != null && dropdownElement.current) {
            var innerWidth = window.innerWidth;
            var width = dropdownElement.current.getBoundingClientRect().width;
            if ((!fullWidthDropdownWindowSize.current &&
                innerWidth < width + 2 * MINIMUM_HORIZONTAL_DROPDOWN_MARGIN) ||
                innerWidth <= fullWidthDropdownWindowSize.current) {
                if (horizontalPositioningMode !== 'FULL_WIDTH') {
                    fullWidthDropdownWindowSize.current = innerWidth;
                    setHorizontalPositioningMode('FULL_WIDTH');
                }
            }
            else {
                var breadcrumbElement = typeof dropdownMenuRef.current === 'number'
                    ? (_a = positions.current[dropdownMenuRef.current]) === null || _a === void 0 ? void 0 : _a.breadcrumb.current
                    : undefined;
                if (breadcrumbElement != null) {
                    /**
                     * Correction parameter so that, when horizontally resizing the screen
                     * when the dropdown is open, it doesn’t “jump” to the right edge at
                     * the cutoff value, but rather the transition is smooth. Determined
                     * visually.
                     */
                    var RESIZE_SNAP_CORRECTION = 30;
                    if (breadcrumbElement.getBoundingClientRect().left +
                        width +
                        MINIMUM_HORIZONTAL_DROPDOWN_MARGIN -
                        RESIZE_SNAP_CORRECTION >
                        innerWidth) {
                        if (horizontalPositioningMode !== 'RIGHT') {
                            setHorizontalPositioningMode('RIGHT');
                        }
                    }
                    else if (horizontalPositioningMode !== 'BREADCRUMB') {
                        setHorizontalPositioningMode('BREADCRUMB');
                    }
                }
            }
        }
    }, [
        dropdownElement,
        horizontalPositioningMode,
        positions,
        setHorizontalPositioningMode,
    ]);
    useEffect(function () {
        handleResize();
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, [handleResize]);
    return handleResize;
};
