var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/ui/Link';
export var NotInGroupErrorMessage = function () { return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "text-center font-medium" }, { children: _jsx(FormattedMessage, { defaultMessage: "Join the Conversation", id: "IP4rzN" }) })), _jsx("div", __assign({ className: "my-6 mx-auto max-w-lg px-4" }, { children: _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: "Only students who are officially enrolled in this course can add and read comments. Confirm your enrolment status by adding your academic email address on your {_}.", id: "o8Y4Ca", values: {
                        _: (_jsx(Link, __assign({ className: "border-none text-red-accent hover:border-b hover:border-b-red-accent", href: "/profile" }, { children: _jsx(FormattedMessage, { defaultMessage: "profile page", id: "8dh+7K" }) }))),
                    } }) }) }))] })); };
