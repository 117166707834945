/**
 * Determines whether a title should be shown on the page or not. (Parent titles
 * are always included in the `blocks` array for breadcrumb purposes, but may be
 * hidden in the main content.) If `true`, the title should not be shown on the
 * page and is just included for the breadcrumbs (e.g. chapter title on second
 * and later pages of chapter).
 *
 * Used to hide the title, and to exclude the annotations from the sidebar.
 */
export var shouldSkipOnPage = function (_a) {
    var depth = _a.depth, params = _a.params;
    return Boolean(params[depth + 2]);
};
