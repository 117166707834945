import { reverse } from 'ramda';
import { useMemo } from 'react';
import { getFormattedPath } from 'src/functions';
import { useBookQuery, useIsChatModeActive, useParams } from 'src/hooks';
import { getCurrentTitle } from './getCurrentTitle';
var getPreviousTitleIndex = function (_a) {
    // We can’t just look at the first title to know on which page we are: the
    // chapter (i.e. top-level) title is included in the blocks for each page, but
    // is only shown on the first page of the chapter.
    var depthOfPageTitle = _a.depthOfPageTitle, params = _a.params, titles = _a.titles;
    var currentTitle = getCurrentTitle({ params: params, titles: titles });
    if (!currentTitle) {
        return undefined;
    }
    var currentTitleIndex = titles.findIndex(function (_a) {
        var id = _a.id;
        return id === currentTitle.id;
    });
    // We combine `reverse` with `findIndex` because Ramda’s `findLastIndex` takes
    // only one argument in callback function
    var previousTitleIndex = reverse(titles).findIndex(function (_a, index) {
        var depth = _a.depth;
        return depthOfPageTitle !== undefined &&
            depth <= depthOfPageTitle &&
            index > titles.length - 1 - currentTitleIndex;
    });
    previousTitleIndex =
        previousTitleIndex === -1
            ? previousTitleIndex
            : titles.length - 1 - previousTitleIndex;
    return previousTitleIndex;
};
export var usePreviousPage = function () {
    var _a;
    var _b = (_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}, depthOfPageTitle = _b.depthOfPageTitle, titles = _b.titles;
    var isChatModeActive = useIsChatModeActive();
    var params = useParams();
    return useMemo(function () {
        if (!titles) {
            return undefined;
        }
        var previousTitleIndex = getPreviousTitleIndex({
            depthOfPageTitle: depthOfPageTitle,
            params: params,
            titles: titles,
        });
        if (previousTitleIndex == null) {
            return undefined;
        }
        var title = titles[previousTitleIndex];
        var url = "/book/".concat(params[1]);
        if (isChatModeActive) {
            url = "/".concat(params.join('/'));
        }
        else if (title != null) {
            // always remain within the same mode & book:
            url = "/".concat(params.slice(0, 2).join('/')).concat(previousTitleIndex === 0
                ? // sanity check (first title should always have depth 1) with
                    // fallback (the first page of the current chapter):
                    "/".concat(title.depth === 1 ? title.path : params[2])
                : getFormattedPath({
                    depthOfPageTitle: depthOfPageTitle,
                    titles: titles.slice(0, previousTitleIndex + 1),
                }));
        }
        return { title: title, url: url };
    }, [depthOfPageTitle, isChatModeActive, params, titles]);
};
