import { walkTheDOM } from '@nextbook/shared';
import { useCallback, useContext } from 'react';
import { SelectableContext } from 'src/context';
import { isElement } from 'src/functions/typeGuards';
import { getSelectedRange } from './functions/getSelection';
/** @returns Selection parts */
export var useGetRichTextParts = function () {
    var selectableElements = useContext(SelectableContext).selectableElements;
    return useCallback(function () {
        var selectedRange = getSelectedRange();
        // Check if `selectedRange` exists to avoid runtime error in `react-styleguidist`
        if (selectedRange == null) {
            return [];
        }
        var selectionData = [];
        // Define, for each content item that's (at least partially) selected,
        //  * the content and/or hierarchical id's
        //  * `position`: the start position of the part of the selection that's in this content item
        //  * `length`: the length of the part of the selection that's in this content item
        var container = selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.commonAncestorContainer;
        while (
        // Make sure we're starting off with an element node (and not a e.g. a text node)
        (!isElement(container) ||
            // If necessary, level up to the deepest block element
            window.getComputedStyle(container).display !== 'block') &&
            container.parentNode != null) {
            container = container.parentNode;
        }
        walkTheDOM(container, function (node) {
            var _a, _b;
            var ids = Object.keys(selectableElements);
            var richTextId = ids.find(function (id) { return isElement(node) && selectableElements[id] === node; });
            if (richTextId != null) {
                var range = selectedRange.cloneRange();
                var temp = document.createRange();
                temp.selectNode(node);
                // Reduce the range to its intersection with the current node and
                // determine its length.
                if (range.compareBoundaryPoints(Range.START_TO_START, temp) === -1) {
                    // The start boundary point of `range` is before that of the current node
                    range.setStartBefore(node); // Cut off the part before the current node
                }
                if (range.compareBoundaryPoints(Range.END_TO_END, temp) === 1) {
                    // The end boundary point of `range` is after that of the current node
                    range.setEndAfter(node); // Cut off the part after the current node
                }
                temp.detach(); // Served its purpose -- release from use.
                var length = range.toString().length;
                // If the end of `range` coincides with that of `node`, the length
                // difference between `range` and the contents of `node` equals the
                // starting position of `range`. (So `range` is kind of repurposed here.)
                range.setEndAfter(node);
                var position = 
                // (*) TODO: use `innerText` instead of `textContent`
                // (node instanceof HTMLElement ? node.innerText.length : 0) - // not robust across multiple paragraphs
                ((_b = (_a = node.textContent) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) - range.toString().length;
                range.detach();
                if (length && richTextId != null) {
                    selectionData.push({
                        length: length,
                        position: position,
                        richTextId: richTextId,
                        // If the attribute is not set, some DOM implementations return an
                        // empty string instead of null, cf. https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttribute
                    });
                }
            }
        });
        return selectionData;
    }, [selectableElements]);
};
