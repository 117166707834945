var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@private/fontawesome/pro-light-svg-icons/faCheck';
import { faLongArrowRight } from '@private/fontawesome/pro-light-svg-icons/faLongArrowRight';
import { faTimes } from '@private/fontawesome/pro-light-svg-icons/faTimes';
import { useCallback } from 'react';
import { animateScroll } from 'react-scroll';
import { ContinueGuidedTourDocument, QuitGuidedTourDocument, } from 'src/__generated__';
export var Buttons = function (_a) {
    var action = _a.action, _b = _a.last, last = _b === void 0 ? false : _b, _c = _a.skip, skip = _c === void 0 ? false : _c;
    var _d = __read(useMutation(ContinueGuidedTourDocument), 1), continueGuidedTour = _d[0];
    var _e = __read(useMutation(QuitGuidedTourDocument), 1), quitGuidedTour = _e[0];
    var handleMouseDownNext = useCallback(function () {
        action === null || action === void 0 ? void 0 : action();
        continueGuidedTour();
        if (skip) {
            continueGuidedTour();
        }
    }, [action, continueGuidedTour, skip]);
    var handleMouseDownQuit = useCallback(function () {
        action === null || action === void 0 ? void 0 : action();
        quitGuidedTour();
    }, [action, quitGuidedTour]);
    var handleKeyDownNext = useCallback(function (event) {
        switch (event.key) {
            case ' ':
            case 'Enter':
                event.preventDefault();
                action === null || action === void 0 ? void 0 : action();
                continueGuidedTour();
                if (skip) {
                    continueGuidedTour();
                }
                break;
            default:
        }
    }, [action, continueGuidedTour, skip]);
    var handleKeyDownQuit = useCallback(function (event) {
        switch (event.key) {
            case ' ':
            case 'Enter':
                event.preventDefault();
                action === null || action === void 0 ? void 0 : action();
                quitGuidedTour();
                break;
            default:
        }
    }, [action, quitGuidedTour]);
    var scrollUp = useCallback(function () { return animateScroll.scrollTo(0, { smooth: 'easeOutQuint' }); }, []);
    return (_jsxs("div", __assign({ 
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className: '_preventHandleMouseDown' // , styles.buttons
     }, { children: [!last && (_jsxs("div", __assign({ className: 
                // ${styles.quit}
                'pointer-events-auto inline-block cursor-pointer whitespace-nowrap rounded border-2 py-0.5 px-1.5 text-red-darker outline-4 outline-neutral-70 theme-contrast:outline-white dark:py-1 dark:px-2.5 dark:text-gray-780 dark:outline-gray-780', onKeyDown: handleKeyDownQuit, onMouseDown: handleMouseDownQuit, role: "button", tabIndex: 0 }, { children: [_jsx(FontAwesomeIcon, { icon: faTimes }), " Quit Tour"] }))), _jsx("div", __assign({ className: 
                // `${styles.next}
                'pointer-events-auto inline-block cursor-pointer whitespace-nowrap rounded border-2 py-0.5 px-1.5 text-green-darker outline-4 outline-neutral-70 theme-contrast:outline-white dark:py-1 dark:px-2.5 dark:text-gray-780 dark:outline-gray-780', onKeyDown: function (event) {
                    handleKeyDownNext(event);
                    if (last) {
                        scrollUp();
                    }
                }, onMouseDown: function () {
                    handleMouseDownNext();
                    if (last) {
                        scrollUp();
                    }
                }, role: "button", tabIndex: 0 }, { children: last ? (_jsxs(_Fragment, { children: ["Finish Tour ", _jsx(FontAwesomeIcon, { icon: faCheck })] })) : (_jsxs(_Fragment, { children: ["Next ", _jsx(FontAwesomeIcon, { icon: faLongArrowRight })] })) }))] })));
};
