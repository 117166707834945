import { jsx as _jsx } from "react/jsx-runtime";
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useIsShowingNav } from 'src/hooks/useIsShowingNav';
import { ToolbarButton } from '../ToolbarButton';
export var TableOfContentsToggle = function () {
    var _a = useIsShowingNav(), isShowingNav = _a.isShowingNav, setIsShowingNav = _a.setIsShowingNav;
    return (_jsx(ToolbarButton, { action: setIsShowingNav, 
        // TODO: align with the vertical line to the right of the Nextbook icon
        className: "ml-px", icon: _jsx(FontAwesomeIcon
        // no `fixedWidth` prop in order to align with the vertical line to
        // the right of the Nextbook icon
        , { 
            // no `fixedWidth` prop in order to align with the vertical line to
            // the right of the Nextbook icon
            icon: faList }), tooltip: isShowingNav ? (_jsx(FormattedMessage, { defaultMessage: "Hide table of contents", id: "zCqOrI" })) : (_jsx(FormattedMessage, { defaultMessage: "Show table of contents", id: "AJdxwl" })) }));
};
