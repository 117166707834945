"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firstName = void 0;
const firstName = (
/** The first name, to be evaluated */
input) => {
    if (input === null || input.trim().length === 0) {
        return 'FirstNameEmpty';
    }
    return null;
};
exports.firstName = firstName;
