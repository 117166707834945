var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { CurrentUserDocument } from 'src/__generated__';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment preference on Preference {\n\t\tcolor\n\t\tfont\n\t\tguidedTourStep\n\t\tid\n\t\tlibrarySortOrder\n\t\tmailOnComment\n\t\tmailOnReply\n\t\ttoc\n\t}\n\tquery CurrentUser {\n\t\tcurrentUser {\n\t\t\t... on CurrentUserPayload {\n\t\t\t\tuser {\n\t\t\t\t\tfirstName\n\t\t\t\t\tid\n\t\t\t\t\tisAuthor\n\t\t\t\t\tlastName\n\t\t\t\t\tpreference {\n\t\t\t\t\t\t...preference\n\t\t\t\t\t}\n\t\t\t\t\trole\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tfragment preference on Preference {\n\t\tcolor\n\t\tfont\n\t\tguidedTourStep\n\t\tid\n\t\tlibrarySortOrder\n\t\tmailOnComment\n\t\tmailOnReply\n\t\ttoc\n\t}\n\tquery CurrentUser {\n\t\tcurrentUser {\n\t\t\t... on CurrentUserPayload {\n\t\t\t\tuser {\n\t\t\t\t\tfirstName\n\t\t\t\t\tid\n\t\t\t\t\tisAuthor\n\t\t\t\t\tlastName\n\t\t\t\t\tpreference {\n\t\t\t\t\t\t...preference\n\t\t\t\t\t}\n\t\t\t\t\trole\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"
    /**
     * Convenience hook to use `currentUser` from the cache when it’s already been
     * retrieved by another component.
     */
])));
/**
 * Convenience hook to use `currentUser` from the cache when it’s already been
 * retrieved by another component.
 */
export var useCurrentUserQuery = function () {
    var _a = useQuery(CurrentUserDocument), data = _a.data, error = _a.error, loading = _a.loading;
    if ((data === null || data === void 0 ? void 0 : data.currentUser.__typename) !== 'CurrentUserPayload' ||
        error ||
        loading) {
        return undefined;
    }
    return data.currentUser.user;
};
var templateObject_1;
