var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useApolloClient } from '@apollo/client';
import { TitleFragmentDoc } from 'src/__generated__';
import { cache } from 'src/cache';
import { isTitle } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
/**
 * Sets a `breadcrumb` flag to `true` on the applicable `Title` object in the
 * `blocks` array. This causes the title to be shown as a breadcrumb in the
 * header, and in the document `<title>`.
 */
export var useSetBreadcrumb = function () {
    var _a;
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    var client = useApolloClient();
    return function (_a) {
        var id = _a.id;
        /** To-be-added breadcrumb */
        var match = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (block) { return block.id === id; });
        /** Previous breadcrumb at the same position */
        var previous = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (block) {
            return isTitle(block) &&
                'breadcrumb' in block &&
                block.breadcrumb &&
                match &&
                'depth' in match &&
                block.depth === match.depth;
        });
        if ((previous === null || previous === void 0 ? void 0 : previous.id) === id) {
            // title was already shown in breadcrumb
        }
        else {
            if (previous != null) {
                client.writeFragment({
                    data: __assign(__assign({}, previous), { breadcrumb: false }),
                    fragment: TitleFragmentDoc,
                    fragmentName: 'title',
                    id: cache.identify(previous),
                });
            }
            var current = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (block) { return block.id === id; });
            if (current == null) {
                return;
            }
            client.writeFragment({
                data: __assign(__assign({}, current), { breadcrumb: true }),
                fragment: TitleFragmentDoc,
                fragmentName: 'title',
                id: cache.identify(current),
            });
        }
    };
};
