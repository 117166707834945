var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@private/fontawesome/pro-solid-svg-icons/faPaperPlane';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// import type { Comment } from 'src/__generated__'
import { classNames } from 'src/functions';
export var SendButton = function (_a) {
    var send = _a.send;
    var _b = __read(useState(false), 2), submitMouseDown = _b[0], setSubmitMouseDown = _b[1];
    var handleKeyDown = useCallback(function (event) {
        switch (event.key) {
            case 'Enter':
                send();
                break;
            case ' ':
                send();
                event.preventDefault();
                break;
            default:
        }
    }, [send]);
    var handleClick = send;
    var handleMouseDown = useCallback(function (event) {
        setSubmitMouseDown(true);
        event.preventDefault(); // prevents blue focus outline (element is still accessible via tab)
    }, []);
    var handlePaperPlaneMouseLeave = useCallback(function () { return setSubmitMouseDown(false); }, []);
    return (_jsxs("span", __assign({ className: "group relative -my-1.5 -mr-3 ml-1.5 inline-block cursor-pointer select-none py-1.5 px-3 text-neutral-500 dark:text-neutral-300" }, { children: [_jsx(FontAwesomeIcon, { className: classNames('pointer-events-none absolute top-1.5 right-[calc(100%_-_0.6em)] -translate-x-2.5 translate-y-2.5 scale-75 opacity-0 transition-all group-hover:translate-x-0 group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100', submitMouseDown && 'animate-plane-disappear opacity-0'), icon: faPaperPlane }), _jsx("span", __assign({ className: "-my-1.5 -mx-3 py-1.5 px-3", onClick: handleClick, onKeyDown: handleKeyDown, onMouseDown: handleMouseDown, onMouseLeave: handlePaperPlaneMouseLeave, role: "button", tabIndex: 0 }, { children: _jsx("strong", { children: _jsx(FormattedMessage, { defaultMessage: "Send", id: "9WRlF4" }) }) }))] })));
};
