var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { last } from 'ramda';
import { useContext, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { hasNavVar } from 'src/cache/reactiveVars';
import { NavContext } from 'src/context';
import { classNames } from 'src/functions';
export var Label = function (_a) {
    var _b = _a.hanging, hanging = _b === void 0 ? false : _b, nonAbbreviatedLabel = _a.label, _c = _a.numbers, numbers = _c === void 0 ? [] : _c, separator = _a.separator, style = _a.style;
    var appearsWithinNav = useContext(NavContext).appearsWithinNav;
    var hasNav = useReactiveVar(hasNavVar);
    /**
     * Avoid using `id` here which would not be not unique if we use the component
     * more than once on a page
     */
    var counterId = useRef("counter-".concat(Math.random().toString().slice(2)));
    var label = typeof nonAbbreviatedLabel === 'string' ? (appearsWithinNav ? (nonAbbreviatedLabel === 'Hoofdstuk' ? (_jsx(_Fragment, { children: "H\u202F" }) // narrow non-breaking space between “H” and subsequent ordinal
    ) : nonAbbreviatedLabel === 'Afdeling' ? ('Afd. ' // incl. trailing space
    ) : nonAbbreviatedLabel.startsWith('Chap') ? ( // Chapter, Chapitre
    'Ch. ' // incl. trailing space
    ) : ("".concat(nonAbbreviatedLabel, " ") // incl. trailing space
    )) : ("".concat(nonAbbreviatedLabel, " ") // incl. trailing space
    )) : (_jsxs(_Fragment, { children: [nonAbbreviatedLabel, " "] }));
    return numbers != null && numbers.length > 0 ? (_jsxs(_Fragment, { children: [style != null && style !== 'decimal' && (_jsx(Helmet, { children: _jsx("style", __assign({ type: "text/css" }, { children: "#".concat(counterId.current, ":after {\n\t\t\t\t\t\t\t\tcontent: counter(title, ").concat(style, ") ").concat(separator == null ? '' : "\"".concat(separator, "\""), ";\n\t\t\t\t\t\t\t\tcounter-reset: title ").concat(last(numbers), ";\n\t\t\t\t\t\t\t}") })) })), _jsxs("span", __assign({ className: classNames('inline-block cursor-default select-none whitespace-nowrap', appearsWithinNav && 'mr-1.5', hanging &&
                    !appearsWithinNav &&
                    (hasNav
                        ? 'widewithtoc:absolute widewithtoc:right-full widewithtoc:mr-1'
                        : 'normalwithouttoc:absolute normalwithouttoc:right-full normalwithouttoc:mr-1')), id: counterId.current }, { children: [label, style == null || style === 'decimal' ? numbers.join('.') : ''] })), !appearsWithinNav &&
                nonAbbreviatedLabel &&
                (typeof nonAbbreviatedLabel !== 'string' ||
                    nonAbbreviatedLabel.length > 1) &&
                ':'] })) : null;
};
