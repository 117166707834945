var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// leave out the mode (e.g. "edit") and book (e.g. "arbeidsrecht")
var NUMBER_OF_PARAMS_TO_IGNORE = 2;
/** @returns The item in `titles` corresponding that matches `params`. */
export var getCurrentTitle = function (_a) {
    var params = _a.params, titles = _a.titles;
    var titleParams = params.slice(NUMBER_OF_PARAMS_TO_IGNORE);
    var title = titleParams.reduce(function (accumulator, titleParam, index) {
        if (!accumulator) {
            return undefined;
        }
        var match = accumulator === null || accumulator === void 0 ? void 0 : accumulator.find(
        // e.g. for chapter: index = 0 (in `params.slice(2)`) and depth = 1 (in `titles`)
        function (_a) {
            var depth = _a.depth, path = _a.path;
            return depth === index + 1 && path === titleParam;
        });
        if (!match) {
            return undefined;
        }
        var matchIndex = accumulator.findIndex(function (_a) {
            var id = _a.id;
            return id === match.id;
        });
        // We end the process when we used the rightmost param
        if (index === titleParams.length - 1) {
            return [match];
        }
        var endOfChildrenIndex = accumulator.findIndex(function (_a, i) {
            var depth = _a.depth;
            return i > matchIndex && depth <= match.depth;
        });
        // Retain only the child titles that succeed and occur within the matched title
        return accumulator.slice(matchIndex + 1, endOfChildrenIndex === -1 ? Infinity : endOfChildrenIndex);
    }, __spreadArray([], __read(titles), false));
    return title === null || title === void 0 ? void 0 : title[0];
};
