var result = {
    "possibleTypes": {
        "AddBookToOrganizationResult": [
            "AddBookToOrganizationPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddBookToStoreResult": [
            "AddBookToStorePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddBookToUnitResult": [
            "AddBookToUnitPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddCoordinatorToGroupResult": [
            "AddCoordinatorToGroupPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddEmailResult": [
            "AddEmailPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddEmailsToGroupResult": [
            "AddEmailsToGroupPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddGroupToOrganizationResult": [
            "AddGroupToOrganizationPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddUnitToOrganizationResult": [
            "AddUnitToOrganizationPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddUserToBookResult": [
            "AddUserToBookPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddUserToGroupResult": [
            "AddUserToGroupPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddUserToOrganizationResult": [
            "AddUserToOrganizationPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AddUserToUnitResult": [
            "AddUserToUnitPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AllBooksResult": [
            "AllBooksPayload",
            "NotAllowedError"
        ],
        "AllFeedbackResult": [
            "AllFeedbackPayload",
            "NotAllowedError"
        ],
        "AllOrganizationsResult": [
            "AllOrganizationsPayload",
            "NotAllowedError"
        ],
        "AllUnitsResult": [
            "AllUnitsPayload",
            "NotAllowedError"
        ],
        "AllUsersResult": [
            "AllUsersPayload",
            "NotAllowedError"
        ],
        "Annotation": [
            "Discussion",
            "Highlight",
            "Note"
        ],
        "AnnotationInterface": [
            "Discussion",
            "Highlight",
            "Note"
        ],
        "AnswerInputResult": [
            "AnswerInputPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "AnswerTextAreaResult": [
            "AnswerTextAreaPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "ArchiveBookResult": [
            "ArchiveBookPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "Block": [
            "Blockquote",
            "Box",
            "Codeblock",
            "File",
            "Formula",
            "Image",
            "List",
            "ListItem",
            "MultipleChoice",
            "MultipleChoiceOption",
            "Paragraph",
            "Table",
            "TableCell",
            "TextArea",
            "Title",
            "Video"
        ],
        "BlockInterface": [
            "Blockquote",
            "Box",
            "Codeblock",
            "File",
            "Formula",
            "Image",
            "List",
            "ListItem",
            "MultipleChoice",
            "MultipleChoiceOption",
            "Paragraph",
            "Table",
            "TableCell",
            "TextArea",
            "Title",
            "Video"
        ],
        "BookResult": [
            "BookPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "BooksResult": [
            "BooksPayload",
            "InvalidInputError",
            "NotAllowedError"
        ],
        "BooksWhereAuthorResult": [
            "BooksWhereAuthorPayload",
            "NotAllowedError"
        ],
        "CommentDeletedResult": [
            "CommentDeletedPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CommentLikeToggledResult": [
            "CommentLikeToggledPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "ConfirmEmailResult": [
            "ConfirmEmailPayload",
            "InvalidInputError",
            "NotAllowedError"
        ],
        "ContinueGuidedTourResult": [
            "ContinueGuidedTourPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CoverUploadStreamResult": [
            "CoverUploadStreamPayload",
            "NotAllowedError",
            "NotFoundError",
            "UnknownError"
        ],
        "CreateBlockquoteResult": [
            "CreateBlockquotePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateBookResult": [
            "CreateBookPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateBoxResult": [
            "CreateBoxPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateCodeblockResult": [
            "CreateCodeblockPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateCommentResult": [
            "CreateCommentPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateDiscussionResult": [
            "CreateDiscussionPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateFileResult": [
            "CreateFilePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateFormulaResult": [
            "CreateFormulaPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateHighlightResult": [
            "CreateHighlightPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateImageResult": [
            "CreateImagePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateListItemResult": [
            "CreateListItemPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateListResult": [
            "CreateListPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateMultipleChoiceOptionResult": [
            "CreateMultipleChoiceOptionPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateMultipleChoiceResult": [
            "CreateMultipleChoicePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateNoteResult": [
            "CreateNotePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateParagraphResult": [
            "CreateParagraphPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateTableResult": [
            "CreateTablePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateTextAreaResult": [
            "CreateTextAreaPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateTitleResult": [
            "CreateTitlePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CreateUserResult": [
            "CreateUserPayload",
            "InvalidInputError",
            "NotAllowedError"
        ],
        "CreateVideoResult": [
            "CreateVideoPayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "CurrentUserResult": [
            "CurrentUserPayload",
            "NotFoundError"
        ],
        "DeleteBookResult": [
            "DeleteBookPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteCommentResult": [
            "DeleteCommentPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteDiscussionResult": [
            "DeleteDiscussionPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteEmailResult": [
            "DeleteEmailPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteHighlightResult": [
            "DeleteHighlightPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteNoteResult": [
            "DeleteNotePayload",
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DeleteUserResult": [
            "DeleteUserPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "DiscussionCreatedResult": [
            "DiscussionCreatedPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "Error": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UnknownError"
        ],
        "ForfeitQrResult": [
            "ForfeitQrPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "GroupResult": [
            "GroupPayload",
            "NotAllowedError",
            "NotFoundError"
        ],
        "InputResponseResult": [
            "InputResponsePayload",
            "NotAllowedError"
        ],
        "ManuscriptUploadEndpointResult": [
            "InvalidInputError",
            "ManuscriptUploadEndpointPayload",
            "NotAllowedError"
        ],
        "MergeTableCellsResult": [
            "InvalidInputError",
            "MergeTableCellsPayload",
            "NotAllowedError",
            "NotFoundError",
            "UnknownError"
        ],
        "Op": [
            "Delete",
            "InputInsert",
            "Retain",
            "TextInsert"
        ],
        "OrganizationResult": [
            "NotAllowedError",
            "NotFoundError",
            "OrganizationPayload"
        ],
        "OrganizationsResult": [
            "NotAllowedError",
            "OrganizationsPayload"
        ],
        "PreferenceResult": [
            "NotAllowedError",
            "NotFoundError",
            "PreferencePayload"
        ],
        "PurchaseBookResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "PurchaseBookPayload",
            "UnknownError"
        ],
        "QuitGuidedTourResult": [
            "NotAllowedError",
            "NotFoundError",
            "QuitGuidedTourPayload"
        ],
        "RecolorHighlightResult": [
            "NotAllowedError",
            "NotFoundError",
            "RecolorHighlightPayload"
        ],
        "RedeemQrResult": [
            "NotAllowedError",
            "NotFoundError",
            "RedeemQrPayload"
        ],
        "RemoveBookFromOrganizationResult": [
            "NotAllowedError",
            "NotFoundError",
            "RemoveBookFromOrganizationPayload"
        ],
        "RemoveBookFromUnitResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveBookFromUnitPayload"
        ],
        "RemoveEmailFromBookResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveEmailFromBookPayload"
        ],
        "RemoveGroupFromOrganizationResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveGroupFromOrganizationPayload"
        ],
        "RemoveUnitFromOrganizationResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveUnitFromOrganizationPayload"
        ],
        "RemoveUserFromBookResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveUserFromBookPayload"
        ],
        "RemoveUserFromGroupResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveUserFromGroupPayload"
        ],
        "RemoveUserFromOrganizationResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveUserFromOrganizationPayload"
        ],
        "RemoveUserFromUnitResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "RemoveUserFromUnitPayload"
        ],
        "ReplyAddedResult": [
            "NotAllowedError",
            "NotFoundError",
            "ReplyAddedPayload"
        ],
        "SendResetPasswordMailResult": [
            "NotFoundError",
            "SendResetPasswordMailPayload"
        ],
        "SetLastVisitedPageResult": [
            "NotAllowedError",
            "NotFoundError",
            "SetLastVisitedPagePayload"
        ],
        "StartGuidedTourResult": [
            "NotAllowedError",
            "NotFoundError",
            "StartGuidedTourPayload"
        ],
        "SubmitCommentResult": [
            "NotAllowedError",
            "NotFoundError",
            "SubmitCommentPayload"
        ],
        "SubmitFeedbackResult": [
            "SubmitFeedbackPayload"
        ],
        "TextAreaResult": [
            "NotAllowedError",
            "NotFoundError",
            "TextAreaPayload"
        ],
        "ToggleCommentLikeResult": [
            "NotAllowedError",
            "NotFoundError",
            "ToggleCommentLikePayload"
        ],
        "ToggleGroupSelectionResult": [
            "NotAllowedError",
            "NotFoundError",
            "ToggleGroupSelectionPayload"
        ],
        "ToggleMultipleChoiceOptionResult": [
            "NotAllowedError",
            "NotFoundError",
            "ToggleMultipleChoiceOptionPayload"
        ],
        "UnarchiveBookResult": [
            "NotAllowedError",
            "NotFoundError",
            "UnarchiveBookPayload"
        ],
        "UniqueBookPathResult": [
            "NotAllowedError",
            "UniqueBookPathPayload"
        ],
        "UnitsResult": [
            "NotAllowedError",
            "UnitsPayload"
        ],
        "UpdateBookResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UpdateBookPayload"
        ],
        "UpdateCommentResult": [
            "NotAllowedError",
            "NotFoundError",
            "UpdateCommentPayload"
        ],
        "UpdateNoteResult": [
            "NotAllowedError",
            "NotFoundError",
            "UpdateNotePayload"
        ],
        "UpdateOrganizationResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UpdateOrganizationPayload"
        ],
        "UpdatePasswordResult": [
            "InvalidInputError",
            "NotAllowedError",
            "UpdatePasswordPayload"
        ],
        "UpdatePreferenceResult": [
            "NotAllowedError",
            "NotFoundError",
            "UpdatePreferencePayload"
        ],
        "UpdateRichTextResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UpdateRichTextPayload"
        ],
        "UpdateUserResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UpdateUserPayload"
        ],
        "UserResult": [
            "InvalidInputError",
            "NotAllowedError",
            "NotFoundError",
            "UserPayload"
        ],
        "UsersResult": [
            "InvalidInputError",
            "NotAllowedError",
            "UsersPayload"
        ]
    }
};
export default result;
