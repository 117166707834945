var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AnswerTextAreaDocument } from 'src/__generated__';
import { LINE_HEIGHT } from 'src/constants';
import { useBookQuery, useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
import { TextAreaAnswers } from './TextAreaAnswers';
var APPROX_CHARACTERS_PER_LINE = 100;
var DEFAULT_NUMBER_OF_LINES = 4;
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment textArea on TextArea {\n\t\tcharacterLimit\n\t\tlines\n\t\tnumberOfAnswers\n\t\tparentId\n\t\tvalue\n\t}\n\n\tmutation AnswerTextArea($input: AnswerTextAreaInput!) {\n\t\tanswerTextArea(input: $input) {\n\t\t\t... on AnswerTextAreaPayload {\n\t\t\t\ttextArea {\n\t\t\t\t\tid\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tfragment textArea on TextArea {\n\t\tcharacterLimit\n\t\tlines\n\t\tnumberOfAnswers\n\t\tparentId\n\t\tvalue\n\t}\n\n\tmutation AnswerTextArea($input: AnswerTextAreaInput!) {\n\t\tanswerTextArea(input: $input) {\n\t\t\t... on AnswerTextAreaPayload {\n\t\t\t\ttextArea {\n\t\t\t\t\tid\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"
    /**
     * @returns Input field for answering questions in the book. TODO: Add an
     *   optional field (via bridge table) to include the question text (should be
     *   an array of content)
     */
])));
/**
 * @returns Input field for answering questions in the book. TODO: Add an
 *   optional field (via bridge table) to include the question text (should be
 *   an array of content)
 */
export var TextArea = function (_a) {
    var _b, _c;
    var characterLimit = _a.characterLimit, id = _a.id, lines = _a.lines, numberOfAnswers = _a.numberOfAnswers, value = _a.value;
    var _d = __read(useState(value), 2), answer = _d[0], setAnswer = _d[1];
    var book = useBookQuery();
    var user = useCurrentUserQuery();
    var _e = __read(useState(false), 2), showingAnswers = _e[0], setShowingAnswers = _e[1];
    var _f = __read(useMutation(AnswerTextAreaDocument), 1), saveText = _f[0];
    var timeout = useRef(null);
    var DEBOUNCE = 1000; // required delay after key press before update (in milliseconds)
    var handleChange = useCallback(function (_a) {
        var target = _a.target;
        setAnswer(target.value);
        if (timeout.current != null) {
            window.clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveText({ variables: { input: { id: id, value: target.value } } })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, DEBOUNCE);
    }, [id, saveText]);
    var hasCharacterLimit = typeof characterLimit === 'number';
    /**
     * Flag used to determine whether to show a button to view the students’
     * answers.
     */
    var isCoordinator = (_b = book === null || book === void 0 ? void 0 : book.groups.some(function (group) { return group.hasCurrentUserAsCoordinator; })) !== null && _b !== void 0 ? _b : false;
    var style = {
        height: Math.round(lines !== null && lines !== void 0 ? lines : (characterLimit == null
            ? DEFAULT_NUMBER_OF_LINES
            : characterLimit / APPROX_CHARACTERS_PER_LINE)) *
            LINE_HEIGHT +
            LINE_HEIGHT, // vertical padding
    };
    if (user) {
        return (_jsxs(_Fragment, { children: [showingAnswers ? (_jsx("div", __assign({ className: "my-3 mx-0 box-border block h-40 w-auto resize-y overflow-auto rounded border border-neutral-200 bg-neutral-50 p-3 font-sans text-sm leading-6 dark:border-black/40 dark:bg-gray-745 dark:text-neutral-250", style: style }, { children: _jsx(TextAreaAnswers, { id: id }) }))) : (_jsx("textarea", { className: "my-3 mx-0 box-border block h-40 w-full resize-y rounded border border-neutral-200 bg-neutral-50 p-3 font-sans text-sm leading-6 dark:border-black/40 dark:bg-gray-745 dark:text-neutral-250", maxLength: characterLimit !== null && characterLimit !== void 0 ? characterLimit : undefined, onChange: handleChange, style: style, value: answer !== null && answer !== void 0 ? answer : '' })), 
                // `div` has negative vertical margin, so avoid rendering when empty:
                (isCoordinator || hasCharacterLimit) && (_jsxs("div", __assign({ className: "mx-0 mb-3 -mt-2 flex justify-between py-0 px-1.5 font-sans text-xs text-neutral-500 dark:text-neutral-350" }, { children: [isCoordinator ? (numberOfAnswers === 0 ? (_jsx(FormattedMessage, { defaultMessage: "None of your students have answered this question yet.", id: "v+Hl4C" })) : (_jsx("button", __assign({ className: "cursor-pointer select-none", onClick: function () { return setShowingAnswers(function (prev) { return !prev; }); }, type: "button" }, { children: showingAnswers ? (_jsxs(_Fragment, { children: ["\u27F5\u00A0", _jsx(FormattedMessage, { defaultMessage: "Hide answers", id: "kocqmC" })] })) : (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Show {_, plural, one {# answer} other {# answers} }", id: "6MOy08", values: { _: numberOfAnswers } }), "\u00A0\u27F6"] })) })))) : (
                        // Placeholder to align second element to the right
                        _jsx("div", {})), hasCharacterLimit && (_jsxs("div", __assign({ className: "text-right" }, { children: [(_c = answer === null || answer === void 0 ? void 0 : answer.length) !== null && _c !== void 0 ? _c : 0, "/", characterLimit] })))] })))] }));
    }
    return (_jsx(Link, __assign({ className: "disabled-textarea-pattern group my-3 mx-0 box-border flex h-40 w-full resize-y items-center justify-center rounded border border-neutral-300 bg-neutral-50 p-3 font-sans text-sm leading-6 dark:border-gray-780 dark:bg-gray-745", href: "/login", style: style }, { children: _jsx("span", __assign({ className: "my-1 rounded bg-neutral-50 px-2 align-middle text-neutral-600 transition-all duration-100 group-hover:rounded-lg group-hover:py-2.5 group-hover:px-5 dark:bg-gray-745 dark:text-neutral-250" }, { children: _jsx(FormattedMessage, { defaultMessage: "Log in to save your answer", id: "pMa60Q" }) })) })));
};
var templateObject_1;
