/**
 * React’s Context is used to pass data down the component tree.
 *
 * @see {@link https://reactjs.org/docs/context.html}
 * @see {@link https://reactjs.org/docs/hooks-reference.html#usecontext}
 */
var _a;
import { createContext } from 'react';
export var FootnotesContext = createContext({
    /**
     * `true` if a footnote section is present on the page, so that it makes sense
     * to render a footnote number with hyperlink to the corresponding footnote.
     */
    footnotes: false,
    setFootnotes: function (_) { },
});
/**
 * Make references to DOM elements available to the GuidedTour component, so
 * that the guided tour arrows can be positioned accordingly and only if
 * applicable.
 */
export var GuidedTourContext = createContext({
    breadcrumbs: { current: null },
    dropDownMenu: { current: null },
    languageSelector: { current: null },
    playButton: { current: null },
    profileButton: { current: null },
});
/**
 * Signals to deeper components that they’re being rendered inside of a title
 * block.
 */
export var HeadingContext = createContext({ level: undefined });
var languages = ['en', 'nl'];
/**
 * UI language, can be different from the book language. The values of this
 * object are lowercase, to match the ISO 639-1 code saved in a cookie.
 */
export var LanguageContext = createContext({
    language: (_a = languages.find(function (lang) { return window.navigator.language.startsWith(lang); })) !== null && _a !== void 0 ? _a : 'en',
    setLanguage: function (_) { },
});
/**
 * `id`/ref pairs for all elements that contains highlightable text. Not
 * implemented via a reactive variable (`makeVar`), because its API doesn’t
 * offer an update function, and simultaneous updates via `useEffect` seem to
 * overwrite each other instead of being applied successively.
 */
export var SelectableContext = createContext({
    selectableElements: {},
    setSelectableElements: function (_) { return _; },
});
/**
 * `appearsWithinNav` is used to determine whether title labels should be
 * abbreviated.
 *
 * - In the navigation menu on the left, the title labels in the table of contents
 *   should be abbreviated, due to width constraints; e.g. “H” instead of
 *   “Hoofdstuk” and “Ch.” instead of “Chapter”.
 * - In the table of contents on the cover page, title labels should not be
 *   abbreviated. This is the default.
 */
export var NavContext = createContext({ appearsWithinNav: false });
/**
 * Signals to deeper components that a block is wide, i.e. that there’s no space
 * available to the right hand side to display the annotations sidebar, even for
 * wide viewports.
 */
export var WideBlockContext = createContext({ appearsWithinWideBlock: false });
