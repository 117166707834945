var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloLink } from '@apollo/client';
/**
 * Problem: Sometimes Apollo Client includes the one fragment multiple times.
 * Workaround: Remove duplicate fragments from query definitions
 *
 * @see {@link https://github.com/dotansimha/graphql-code-generator/issues/3063#issuecomment-842536997}
 */
export var makeFragmentDedupeLink = function () {
    return new ApolloLink(function (operation, forward) {
        var previousDefinitions = new Set();
        var definitions = operation.query.definitions.filter(function (def) {
            if (def.kind !== 'FragmentDefinition') {
                return true;
            }
            var name = "".concat(def.name.value, "-").concat(def.typeCondition.name.value);
            if (previousDefinitions.has(name)) {
                return false;
            }
            previousDefinitions.add(name);
            return true;
        });
        var newQuery = __assign(__assign({}, operation.query), { definitions: definitions });
        operation.query = newQuery; // eslint-disable-line no-param-reassign
        return forward(operation);
    });
};
