var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNetworkState } from 'react-use';
import { useBreadcrumbs } from 'src/hooks';
var unknownErrorEmoji = [
    // woman facepalming
    '🤦🏻‍♀️',
    '🤦🏼‍♀️',
    '🤦🏽‍♀️',
    '🤦🏾‍♀️',
    '🤦🏿‍♀️',
    // person facepalming
    '🤦🏻',
    '🤦🏼',
    '🤦🏽',
    '🤦🏾',
    '🤦🏿',
    // man facepalming
    '🤦🏻‍♂️',
    '🤦🏼‍♂️',
    '🤦🏽‍♂️',
    '🤦🏾‍♂️',
    '🤦🏿‍♂️',
    // woman frowning
    '🙍🏻‍♀️',
    '🙍🏼‍♀️',
    '🙍🏽‍♀️',
    '🙍🏾‍♀️',
    '🙍🏿‍♀️',
    // person frowning
    '🙍🏻',
    '🙍🏼',
    '🙍🏽',
    '🙍🏾',
    '🙍🏿',
    // man frowning
    '🙍🏻‍♂️',
    '🙍🏼‍♂️',
    '🙍🏽‍♂️',
    '🙍🏾‍♂️',
    '🙍🏿‍♂️',
];
var offlineEmoji = [
    // woman technologist
    '👩🏻‍💻',
    '👩🏼‍💻',
    '👩🏽‍💻',
    '👩🏾‍💻',
    '👩🏿‍💻',
    // technologist
    '🧑🏻‍💻',
    '🧑🏼‍💻',
    '🧑🏽‍💻',
    '🧑🏾‍💻',
    '🧑🏿‍💻',
    // man technologist
    '👨🏻‍💻',
    '👨🏼‍💻',
    '👨🏽‍💻',
    '👨🏾‍💻',
    '👨🏿‍💻',
];
export var UnknownError = function () {
    useBreadcrumbs([{ href: '/', text: 'Nextbook' }]);
    var _a = useNetworkState().online, online = _a === void 0 ? false : _a;
    var _randomUnknownErrorEmojiIndex = useRef(Math.floor(Math.random() * unknownErrorEmoji.length));
    var _randomOfflineEmojiIndex = useRef(Math.floor(Math.random() * offlineEmoji.length));
    return (_jsx("main", { children: _jsxs("div", { children: [_jsx("div", __assign({ "aria-hidden": true, className: "float-left mr-5 flex h-16 w-16 items-center justify-center text-7xl" }, { children: "\uD83E\uDD16" })), online ? (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-1 text-3xl" }, { children: _jsx(FormattedMessage, { defaultMessage: "Something went wrong.", id: "iuY8kx" }) })), _jsx("p", __assign({ className: "indent-0" }, { children: _jsx(FormattedMessage, { defaultMessage: "Please reload the page to continue.", id: "iH/e8k" }) }))] })) : (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "mb-1 text-3xl" }, { children: _jsx(FormattedMessage, { defaultMessage: "You\u2019re offline", id: "9/WCbR" }) })), _jsx("p", __assign({ className: "indent-0" }, { children: _jsx(FormattedMessage, { defaultMessage: "Please connect to the internet and try again.", id: "GmyXsz" }) }))] }))] }) }));
};
