import { useIntl } from 'react-intl';
export var useWarningDictionary = function () {
    var intl = useIntl();
    /** We use the output from `zxcvbn` as keys to */
    return {
        'A word by itself is easy to guess': intl.formatMessage({
            defaultMessage: 'A word by itself is easy to guess',
            id: 'wVTc7n',
        }),
        'Add another word or two. Uncommon words are better.': intl.formatMessage({
            defaultMessage: 'Add another word or two. Uncommon words are better.',
            id: 'urKgEn',
        }),
        'All-uppercase is almost as easy to guess as all-lowercase': intl.formatMessage({
            defaultMessage: 'All-uppercase is almost as easy to guess as all-lowercase',
            id: 'MXI9Yb',
        }),
        'Avoid dates and years that are associated with you': intl.formatMessage({
            defaultMessage: 'Avoid dates and years that are associated with you',
            id: 'XOyUtt',
        }),
        'Avoid recent years': intl.formatMessage({
            defaultMessage: 'Avoid recent years',
            id: '27E0vM',
        }),
        'Avoid repeated words and characters': intl.formatMessage({
            defaultMessage: 'Avoid repeated words and characters',
            id: 'L8d5kZ',
        }),
        'Avoid sequences': intl.formatMessage({
            defaultMessage: 'Avoid sequences',
            id: '/xXe1q',
        }),
        'Avoid years that are associated with you': intl.formatMessage({
            defaultMessage: 'Avoid years that are associated with you',
            id: 'mjjNrD',
        }),
        "Capitalization doesn't help very much": intl.formatMessage({
            defaultMessage: "Capitalization doesn't help very much",
            id: 'oOmo5O',
        }),
        'Common names and surnames are easy to guess': intl.formatMessage({
            defaultMessage: 'Common names and surnames are easy to guess',
            id: 'T2A7f5',
        }),
        'Dates are often easy to guess': intl.formatMessage({
            defaultMessage: 'Dates are often easy to guess',
            id: '+SEeUC',
        }),
        'Names and surnames by themselves are easy to guess': intl.formatMessage({
            defaultMessage: 'Names and surnames by themselves are easy to guess',
            id: '6PQFQZ',
        }),
        'No need for symbols, digits, or uppercase letters': intl.formatMessage({
            defaultMessage: 'No need for symbols, digits, or uppercase letters',
            id: 'K1o6Do',
        }),
        'Predictable substitutions like ‘@’ instead of ‘a’ don’t help very much': intl.formatMessage({
            defaultMessage: "Predictable substitutions like '@' instead of 'a' don't help very much",
            id: 'uygeD8',
        }),
        'Recent years are easy to guess': intl.formatMessage({
            defaultMessage: 'Recent years are easy to guess',
            id: 'kAcA48',
        }),
        'Repeats like "aaa" are easy to guess': intl.formatMessage({
            defaultMessage: 'Repeats like "aaa" are easy to guess',
            id: 'pNFpvs',
        }),
        'Repeats like "abcabcabc" are only slightly harder to guess than "abc"': intl.formatMessage({
            defaultMessage: 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
            id: '9bE1em',
        }),
        "Reversed words aren't much harder to guess": intl.formatMessage({
            defaultMessage: "Reversed words aren't much harder to guess",
            id: 'gVYVRO',
        }),
        'Sequences like abc or 6543 are easy to guess': intl.formatMessage({
            defaultMessage: 'Sequences like abc or 6543 are easy to guess',
            id: 'qmzAIr',
        }),
        'Short keyboard patterns are easy to guess': intl.formatMessage({
            defaultMessage: 'Short keyboard patterns are easy to guess',
            id: 'cgj+YE',
        }),
        'Straight rows of keys are easy to guess': intl.formatMessage({
            defaultMessage: 'Straight rows of keys are easy to guess',
            id: 'vdx4RQ',
        }),
        'This is a top-10 common password': intl.formatMessage({
            defaultMessage: 'This is a top-10 common password',
            id: '0ojbEA',
        }),
        'This is a top-100 common password': intl.formatMessage({
            defaultMessage: 'This is a top-100 common password',
            id: 'pUvGhh',
        }),
        'This is a very common password': intl.formatMessage({
            defaultMessage: 'This is a very common password',
            id: '7cQNd/',
        }),
        'This is similar to a commonly used password': intl.formatMessage({
            defaultMessage: 'This is similar to a commonly used password',
            id: '0LhuT8',
        }),
        'Use a few words, avoid common phrases': intl.formatMessage({
            defaultMessage: 'Use a few words, avoid common phrases',
            id: 'jqS948',
        }),
        'Use a longer keyboard pattern with more turns': intl.formatMessage({
            defaultMessage: 'Use a longer keyboard pattern with more turns',
            id: 'DL7GJq',
        }),
    };
};
