var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * A text label that can contain a hyperlink, and a button that, when clicked,
 * removes the entity from a list.
 */
export var RemoveButton = function (_a) {
    var action = _a.action, children = _a.children;
    return (_jsxs("code", __assign({ className: "m-0.5 flex items-center py-0 px-1.5" }, { children: [children, _jsx("button", __assign({ className: "ml-2 flex cursor-pointer items-center justify-center rounded-full border-none p-0 text-xs text-black/50 transition-colors duration-100 hover:bg-white hover:text-red-accent", onClick: action, type: "button" }, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle }) }))] })));
};
