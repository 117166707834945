var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { email as validateEmail } from '@nextbook/shared/validators';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { ValidatedInput } from 'src/ui/form';
import { Button } from 'src/ui/form/Button';
import { useAddEmail } from './useAddEmail';
var messages = defineMessages({
    EmailAlreadyInUse: {
        defaultMessage: 'This email address is already in use.',
        id: 'fYgCZQ',
    },
    EmailEmpty: {
        defaultMessage: 'Please enter your email address.',
        id: 'XeUfIK',
    },
    EmailInvalid: { defaultMessage: 'Invalid email address', id: '5WgK3Y' },
});
export var EmailVerificationForm = function (_a) {
    var _b;
    var emails = _a.emails;
    var intl = useIntl();
    var ref = useRef(null);
    var _c = __read(useState(false), 2), validate = _c[0], setValidate = _c[1];
    var _d = __read(useState(''), 2), value = _d[0], setValue = _d[1];
    var _e = useAddEmail(), addEmail = _e.addEmail, error = _e.error;
    var handleSubmit = useCallback(function (event) {
        var _a;
        event.preventDefault(); // Prevent the form from being submitted
        setValidate(true);
        if (validateEmail(value) || emails.includes(value)) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            addEmail({ email: value });
            if (!error) {
                setValue('');
                setValidate(false);
            }
            // TODO: If `addEmail` returns without error, `validate` should be set to `false`.
        }
    }, [addEmail, emails, error, value]);
    var serverErrors = (_b = error === null || error === void 0 ? void 0 : error.graphQLErrors.map(function (_a) {
        var message = _a.message;
        return message;
    })) !== null && _b !== void 0 ? _b : [];
    return (_jsxs("form", __assign({ className: "my-6 -mx-3 flex justify-between", onSubmit: handleSubmit }, { children: [_jsx(FormattedMessage, __assign({ defaultMessage: "Email", id: "sy+pv5" }, { children: function (_a) {
                    var _b = __read(_a, 1), msg = _b[0];
                    var validation = validate ? validateEmail(value) : null;
                    return (_jsx(ValidatedInput, { ref: ref, errorMessage: validation &&
                            (serverErrors[0] in messages //  before (*)
                                ? intl.formatMessage(messages[serverErrors[0]])
                                : validation in messages // (*)
                                    ? intl.formatMessage(messages[validation])
                                    : emails.includes(value)
                                        ? intl.formatMessage(messages.EmailAlreadyInUse)
                                        : undefined), name: "email", onChange: function (_a) {
                            var target = _a.target;
                            return setValue(target.value);
                        }, placeholder: msg, type: "email", value: value }));
                } })), _jsx(Button, { children: _jsx(FormattedMessage, { defaultMessage: "Send Verification Mail", id: "VXdTAW" }) })] })));
};
