import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { BookDocument } from 'src/__generated__';
/** Preload previous or next page’s GraphQL query */
export var usePrefetch = function (pathname) {
    var client = useApolloClient();
    return useCallback(function () {
        var _a, _b;
        if (pathname != null) {
            var params = (_b = (_a = pathname.slice(1).split('#')[0]) === null || _a === void 0 ? void 0 : _a.split('/')) !== null && _b !== void 0 ? _b : [];
            /**
             * Only refetch if we navigate to a page with a book query (i.e. not for
             * the cover page)
             */
            var MINIMUM_PARAMS = 3;
            if (params.length >= MINIMUM_PARAMS) {
                void client.query({
                    query: BookDocument,
                    variables: {
                        bookParams: params.slice(0, 2),
                        pageParams: params.slice(2),
                    },
                });
            }
        }
    }, [client, pathname]);
};
