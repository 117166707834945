var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import { map } from 'ramda';
import { DeleteNoteDocument } from 'src/__generated__';
import { writeRichTextFragment } from 'src/content/inline/text/writeRichTextFragment';
import { isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
export var useDeleteNote = function (_a) {
    var _b;
    var noteId = _a.noteId, textAreaRef = _a.textAreaRef;
    var blocks = ((_b = useBookQuery()) !== null && _b !== void 0 ? _b : {}).blocks;
    var _c = __read(useMutation(DeleteNoteDocument, {
        optimisticResponse: {
            __typename: 'Mutation',
            deleteNote: { __typename: 'DeleteNotePayload', deleted: true },
        },
        update: function (cache, _a) {
            var data = _a.data;
            if ((data === null || data === void 0 ? void 0 : data.deleteNote.__typename) !== 'DeleteNotePayload') {
                return;
            }
            /**
             * `textAreaRef.current?.blur()` fixes janky scroll behaviour under the
             * following cumulative conditions (not sure why):
             *
             * - The note has been tabbed into focus and the user presses Escape
             * - The note has just appeared upon creation and the user presses Escape
             */
            if (textAreaRef.current) {
                textAreaRef.current.blur();
            }
            blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                // `map` from Ramda (below) is applied to an object:
                return map(function (value) {
                    if (isRichText(value)) {
                        var newRichText = __assign(__assign({}, value), { richTextAnnotations: value.richTextAnnotations.filter(function (_a) {
                                var annotation = _a.annotation;
                                return annotation.id !== noteId;
                            }) });
                        writeRichTextFragment({ cache: cache, data: newRichText });
                    }
                }, block);
            });
        },
        variables: { input: { id: noteId } },
    }), 1), deleteNote = _c[0];
    return deleteNote;
};
