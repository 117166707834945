var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { omit } from 'ramda';
/**
 * Adds a sidebar annotation (discussion or note) to a rich text item.
 *
 * When a new discussion or note gets created, the blocks array must be updated.
 * This is done in the `update` function of the `createDiscussion` &
 * `createNote` mutations, as well as the `updateQuery` function of the
 * `subscribeToMore` subscription from the Book query (for discussions).
 *
 * First, we write the optimistic response to the store. Then, we process the
 * server response for the `createNote` mutation (or, for discussions, depending
 * on which data arrives first, the `createDiscussion` mutation or the
 * `discussionCreated` subscription).
 *
 * @returns A rich text element, if it should be updated
 */
export var addAnnotationToRichText = function (_a) {
    var _b;
    var annotation = _a.annotation, optimisticId = _a.optimisticId, richText = _a.richText;
    /**
     * 0-based order of the richTextAnnotation within the set of rich text
     * elements that are annotated by the annotation, used to decide whether to
     * shown an annotation in the sidebar (for annotations that spans multiple
     * rich text items).
     */
    var index = (_b = annotation.richTextAnnotations) === null || _b === void 0 ? void 0 : _b.findIndex(function (rTA) { var _a; return ((_a = rTA.richText) === null || _a === void 0 ? void 0 : _a.id) === richText.id; });
    if (index === -1 || !annotation.richTextAnnotations) {
        return undefined;
    }
    var newRichTextAnnotations = annotation.richTextAnnotations.map(function (richTextAnnotation) { return (__assign(__assign({}, richTextAnnotation), { annotation: omit(['richTextAnnotations'], annotation) })); });
    var newRichTextAnnotation = newRichTextAnnotations.find(function (nRTA) { var _a; return ((_a = nRTA.richText) === null || _a === void 0 ? void 0 : _a.id) === richText.id; });
    if (richText.richTextAnnotations.find(function (_a) {
        var _b;
        var id = _a.id;
        return id === ((_b = newRichTextAnnotations === null || newRichTextAnnotations === void 0 ? void 0 : newRichTextAnnotations[0]) === null || _b === void 0 ? void 0 : _b.id);
    })) {
        return richText;
    }
    if (richText.richTextAnnotations.find(function (_a) {
        var id = _a.annotation.id;
        return id === optimisticId;
    })) {
        // Replace the optimistic response when the server response arrives,
        // maintaining the local query data (scalar properties with `@client` directive)
        return __assign(__assign({}, richText), { richTextAnnotations: richText.richTextAnnotations.map(function (richTextAnnotation) {
                return richTextAnnotation.annotation.id === optimisticId
                    ? newRichTextAnnotation
                    : richTextAnnotation;
            }) });
    }
    // Append the optimistic response on first pass through the `update` function:
    return __assign(__assign({}, richText), { richTextAnnotations: __spreadArray(__spreadArray([], __read(richText.richTextAnnotations), false), [
            newRichTextAnnotation,
        ], false) });
};
