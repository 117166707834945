var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useWindowSize } from 'react-use';
import { classNames } from 'src/functions';
var MINIMUM_HORIZONTAL_VIEWPORT_MARGIN = 6; // px
export var Tooltip = function (_a) {
    var _b, _c;
    var buttonRef = _a.buttonRef, children = _a.children, id = _a.id, showing = _a.showing;
    var ref = useRef(null);
    var tooltipWidth = (_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width) !== null && _c !== void 0 ? _c : 0;
    var viewportWidth = useWindowSize().width;
    if (buttonRef.current == null) {
        return null;
    }
    var _d = buttonRef.current.getBoundingClientRect(), buttonLeft = _d.left, buttonRight = _d.right, buttonBottom = _d.bottom, buttonWidth = _d.width;
    var extendsBeyondLeftMargin = buttonLeft + buttonWidth / 2 - tooltipWidth / 2 <
        MINIMUM_HORIZONTAL_VIEWPORT_MARGIN;
    var extendsBeyondRightMargin = buttonLeft + buttonWidth / 2 + tooltipWidth / 2 >
        viewportWidth - MINIMUM_HORIZONTAL_VIEWPORT_MARGIN;
    var left = extendsBeyondLeftMargin
        ? MINIMUM_HORIZONTAL_VIEWPORT_MARGIN + tooltipWidth / 2
        : extendsBeyondRightMargin
            ? undefined
            : buttonLeft + buttonWidth / 2;
    var right = extendsBeyondRightMargin
        ? MINIMUM_HORIZONTAL_VIEWPORT_MARGIN
        : undefined;
    var marginPercentage = 50 -
        (extendsBeyondLeftMargin
            ? (MINIMUM_HORIZONTAL_VIEWPORT_MARGIN -
                (buttonLeft + buttonWidth / 2 - tooltipWidth / 2)) /
                tooltipWidth
            : extendsBeyondRightMargin
                ? 50
                : 0);
    var triangleLeft = extendsBeyondLeftMargin
        ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            -tooltipWidth / 2 + buttonLeft + buttonWidth / 2 - 12 // determined visually
        : extendsBeyondRightMargin
            ? undefined
            : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                -8;
    var triangleRight = extendsBeyondRightMargin
        ? viewportWidth -
            buttonRight +
            buttonWidth / 2 -
            MINIMUM_HORIZONTAL_VIEWPORT_MARGIN -
            4 // determined visually
        : undefined;
    return (_jsxs("div", __assign({ ref: ref, className: classNames('pointer-events-none fixed -top-2 z-10 mt-4', !showing && 'invisible'), id: id, style: { left: left, right: right, top: buttonBottom } }, { children: [_jsx("div", { className: "absolute bottom-full h-0 w-0 border-8 border-t-0 border-transparent border-b-neutral-750 dark:border-b-neutral-900", style: { left: triangleLeft, right: triangleRight } }), _jsx("div", __assign({ className: "whitespace-nowrap rounded bg-neutral-750 py-2 px-3 text-xs font-light text-neutral-100 shadow dark:bg-neutral-900", style: { margin: "0 ".concat(marginPercentage, "% 0 ").concat(-marginPercentage, "%") } }, { children: children }))] })));
};
