var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import { TextAreaAnswersDocument } from 'src/__generated__';
import { Spinner } from 'src/ui/Spinner';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery TextAreaAnswers($id: UUID!) {\n\t\ttextArea(id: $id) {\n\t\t\t... on TextAreaPayload {\n\t\t\t\ttextArea {\n\t\t\t\t\tanswers {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tlastModified\n\t\t\t\t\t\t# score\n\t\t\t\t\t\tuser {\n\t\t\t\t\t\t\tfirstName\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tlastName\n\t\t\t\t\t\t}\n\t\t\t\t\t\tvalue\n\t\t\t\t\t}\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery TextAreaAnswers($id: UUID!) {\n\t\ttextArea(id: $id) {\n\t\t\t... on TextAreaPayload {\n\t\t\t\ttextArea {\n\t\t\t\t\tanswers {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tlastModified\n\t\t\t\t\t\t# score\n\t\t\t\t\t\tuser {\n\t\t\t\t\t\t\tfirstName\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tlastName\n\t\t\t\t\t\t}\n\t\t\t\t\t\tvalue\n\t\t\t\t\t}\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var TextAreaAnswers = function (_a) {
    var _b;
    var id = _a.id;
    var _c = useQuery(TextAreaAnswersDocument, {
        variables: { id: id },
    }), data = _c.data, loading = _c.loading;
    if (loading) {
        return _jsx(Spinner, {});
    }
    var now = new Date();
    var millisecondsPassedToday = now.valueOf() -
        new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
    var MILLISECONDS_PER_SECOND = 1000;
    var ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * MILLISECONDS_PER_SECOND;
    if ((data === null || data === void 0 ? void 0 : data.textArea.__typename) !== 'TextAreaPayload') {
        return null;
    }
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment -- help TypeScript: see https://stackoverflow.com/a/53689186 and perhaps awaiting fix: https://github.com/microsoft/TypeScript/issues/21699
    _jsx(_Fragment, { children: (_b = data.textArea.textArea.answers) === null || _b === void 0 ? void 0 : _b.map(function (_a, index, _b) {
            var key = _a.id, lastModified = _a.lastModified, user = _a.user, value = _a.value;
            var length = _b.length;
            var numericDate = new Date(lastModified).valueOf();
            return (_jsxs(Fragment, { children: [_jsx("div", __assign({ className: "w-full whitespace-pre-wrap hyphens-none", style: { overflowWrap: 'anywhere' } }, { children: value })), _jsxs("div", __assign({ className: "mt-2.5 flex justify-between text-xs text-neutral-500 dark:text-neutral-350 hires:border-t-0.5" }, { children: [_jsxs("div", { children: [user.firstName, " ", user.lastName] }), _jsx("div", { children: 
                                // Show a relative date if last updated yesterday at the latest
                                now.valueOf() - numericDate >
                                    millisecondsPassedToday + ONE_DAY_IN_MILLISECONDS ? (_jsx(FormattedDate, { day: "numeric", month: "long", value: lastModified, year: "numeric" })) : (_jsx(FormattedRelativeTime, { updateIntervalInSeconds: 1, value: (numericDate - now.valueOf()) /
                                        MILLISECONDS_PER_SECOND })) })] })), index < length - 1 && (_jsx("hr", { className: "-mx-3 mt-4 mb-3 border-neutral-200 dark:border-black/40" }))] }, key));
        }) }));
};
var templateObject_1;
