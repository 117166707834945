var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { animated, useSpring } from '@react-spring/web';
import gql from 'graphql-tag';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMeasure } from 'react-use';
import { S3_CONTENT_ENDPOINT } from 'src/constants';
import { Text } from 'src/content/inline/text/Text';
import { classNames, deltaToString } from 'src/functions';
import { isElement } from 'src/functions/typeGuards';
import { useSelectableRef } from 'src/hooks';
import { useMaxDimensions } from './useMaxDimensions';
var CONTENT_MAX_WIDTH = 680;
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment image on Image {\n\t\talt\n\t\tcaption {\n\t\t\t...richText\n\t\t}\n\t\theight\n\t\tparentId\n\t\tscale\n\t\turl\n\t\twide\n\t\twidth\n\t}\n"], ["\n\tfragment image on Image {\n\t\talt\n\t\tcaption {\n\t\t\t...richText\n\t\t}\n\t\theight\n\t\tparentId\n\t\tscale\n\t\turl\n\t\twide\n\t\twidth\n\t}\n"
    /**
     * The size of images should depend on the resolution of the file that’s
     * provided, with the possibility to override this size.
     *
     * - **High-resolution images** should span the entire width of the screen.
     *
     *   - Some images may be too large to fit the window width without requiring the
     *       user to scroll. For these images, it might be preferable to show the
     *       entire height (letterboxed). Should probably be a setting for the
     *       author.
     *   - A special case can be made for images that are suitable to fill the entire
     *       browser screen by clipping the sides. For these images, it might be
     *       useful to let the author define a single point on the image which will
     *       remain centered for as long as possible, depending on the dimensions of
     *       the viewport.
     * - **Medium-resolution images** should span the paragraph width,
     * - **Low-resolution images** should be centered relative to the horizontal
     *   paragraph position. Perhaps include setting to toggle anti-aliasing & a
     *   setting to enable or disable scaling to paragraph width.
     *
     * The background color is `#F9F9F9` which is slightly problematic, because many
     * images will have a white background. If easily doable, images with a white
     * background should be transformed so that this background becomes
     * transparent.
     *
     * The caption should be positioned below the image because the image attracts
     * the attention of the reader first, anyway (cf. either _The Visual Display of
     * Quantitative Information_ or _The Elements of Typographic Style_).
     */
])));
/**
 * The size of images should depend on the resolution of the file that’s
 * provided, with the possibility to override this size.
 *
 * - **High-resolution images** should span the entire width of the screen.
 *
 *   - Some images may be too large to fit the window width without requiring the
 *       user to scroll. For these images, it might be preferable to show the
 *       entire height (letterboxed). Should probably be a setting for the
 *       author.
 *   - A special case can be made for images that are suitable to fill the entire
 *       browser screen by clipping the sides. For these images, it might be
 *       useful to let the author define a single point on the image which will
 *       remain centered for as long as possible, depending on the dimensions of
 *       the viewport.
 * - **Medium-resolution images** should span the paragraph width,
 * - **Low-resolution images** should be centered relative to the horizontal
 *   paragraph position. Perhaps include setting to toggle anti-aliasing & a
 *   setting to enable or disable scaling to paragraph width.
 *
 * The background color is `#F9F9F9` which is slightly problematic, because many
 * images will have a white background. If easily doable, images with a white
 * background should be transformed so that this background becomes
 * transparent.
 *
 * The caption should be positioned below the image because the image attracts
 * the attention of the reader first, anyway (cf. either _The Visual Display of
 * Quantitative Information_ or _The Elements of Typographic Style_).
 */
export var Image = function (_a) {
    var alt = _a.alt, caption = _a.caption, height = _a.height, 
    // scale,
    // showingMetadataForContentId,
    // TODO: Leave out default value and make prop mandatory
    _b = _a.url, 
    // scale,
    // showingMetadataForContentId,
    // TODO: Leave out default value and make prop mandatory
    url = _b === void 0 ? '' : _b, wide = _a.wide, width = _a.width;
    var figure = useRef(null);
    var expandable = width > CONTENT_MAX_WIDTH && !wide;
    var _c = __read(useState(false), 2), expanded = _c[0], setExpanded = _c[1];
    var _d = __read(useMeasure(), 2), div = _d[0], containerDimensions = _d[1];
    var _e = useMaxDimensions(), maxHeight = _e.maxHeight, maxWidth = _e.maxWidth;
    useEffect(function () {
        var handleMouseDown = function (_a) {
            var _b;
            var target = _a.target;
            if ((!isElement(target) || ((_b = figure.current) === null || _b === void 0 ? void 0 : _b.contains(target)) !== true) &&
                expanded) {
                setExpanded(false);
            }
        };
        var handleKeyDown = function (_a) {
            var key = _a.key;
            if (key === 'Escape' && expanded) {
                setExpanded(false);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleMouseDown);
        return function () {
            window.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [expanded]);
    var wideWidth = Math.min((maxHeight * width) / height, width, maxWidth);
    var style = useSpring(__assign(__assign(__assign(__assign({ config: { friction: 30, tension: 180 } }, (expanded || containerDimensions.height > 0
        ? {
            height: expanded
                ? Math.min(height, maxHeight, (maxWidth * height) / width)
                : containerDimensions.height,
        }
        : {})), { 
        // Move the image to the right so that the full-size image is centered on
        // the page, but don’t move the image more than half its width increase.
        marginLeft: Math.min(expanded
            ? maxWidth / 2 -
                (containerDimensions.left + containerDimensions.width / 2)
            : 0, (wideWidth - containerDimensions.width) / 2) }), (expanded || containerDimensions.width > 0
        ? { width: expanded ? wideWidth : containerDimensions.width }
        : {})), { zIndex: expanded ? 1 : 0 }));
    var handleClick = useCallback(function () {
        setExpanded(function (prevExpanded) { return !prevExpanded; });
    }, []);
    /** Round off to two decimal places. (10^2 = 100)^2 = 10_000 */
    var scaledHeight = Math.round((height / width) * 10000) / 100;
    var selectableRef = useSelectableRef(caption === null || caption === void 0 ? void 0 : caption.id);
    return (_jsxs("figure", __assign({ ref: figure, className: classNames('relative', expanded ? 'z-3' : 'z-2', wide
            ? 'col-start-centeredContainer col-end-last mx-2.5 my-0'
            : 'mx-0 my-6') }, { children: [_jsx("div", __assign({ className: "mx-auto my-0 flex w-full items-center justify-center", style: { maxWidth: width } }, { children: _jsx("div", __assign({ ref: div, className: "pointer-events-none relative flex h-0 w-full items-center justify-center", style: {
                        marginBottom: "-".concat(scaledHeight / 2, "%"),
                        marginTop: "".concat(scaledHeight / 2, "%"),
                        /**
                         * The height is set as a percentage via `padding-bottom` to set the
                         * correct aspect ratio before the image has loaded.
                         *
                         * @see {@link https://www.andyshora.com/css-image-container-padding-hack.html}
                         */
                        paddingBottom: "".concat(scaledHeight, "%"),
                    } }, { children: _jsx(animated.div
                    // setting background color lets Safari apply mix-blend-mode on image
                    , __assign({ 
                        // setting background color lets Safari apply mix-blend-mode on image
                        className: "pointer-events-auto absolute h-full w-full rounded bg-neutral-70 before:absolute before:h-full before:w-full before:rounded before:opacity-0 before:shadow-xl before:transition-opacity before:duration-200 focus:border-4 focus:border-transparent focus:shadow-md focus:outline-none theme-contrast:bg-white dark:bg-gray-780 ".concat(expanded ? 'before:opacity-100' : ''), style: style }, { children: _jsx("img", __assign({ alt: alt !== null && alt !== void 0 ? alt : undefined, 
                            // eslint-disable-next-line tailwindcss/no-custom-classname
                            className: classNames('_preventHandleMouseDown', (url === null || url === void 0 ? void 0 : url.endsWith('svg')) === true && 'dark:invert', 'h-full w-full rounded mix-blend-darken dark:mix-blend-lighten', expandable && (expanded ? 'cursor-zoom-out' : 'cursor-zoom-in')), 
                            // `height` and `width` included to set correct aspect ratio before loading
                            height: height }, (expandable
                            ? {
                                onClick: handleClick,
                                role: 'button',
                                tabIndex: 0,
                            }
                            : {}), { src: url == null
                                ? undefined
                                : "".concat(S3_CONTENT_ENDPOINT).concat(url.startsWith('/') ? url : "/".concat(url)), 
                            // style={{ marginTop: `${scaledHeight / 2}%` }}
                            width: width })) })) })) })), caption && (
            /**
             * Image captions are positioned below the image.
             *
             * @see {@link https://tex.stackexchange.com/a/3253}
             */
            _jsxs("figcaption", __assign({ ref: selectableRef, className: "mt-6 text-center" }, { children: [![
                        'Afbeelding',
                        'Afb.',
                        'Diagram',
                        'Figuur',
                        'Figure',
                        'Fig.',
                        'Grafiek',
                        'Illustratie',
                        'Image',
                        'Kaart',
                        'Kader',
                    ].some(function (_) { return deltaToString(caption.delta).startsWith(_); }) && (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Image:", id: "lr4lXN" }), ' '] })), _jsx(Text, __assign({}, caption))] })))] })));
};
var templateObject_1;
