import { RichTextFragmentDoc } from 'src/__generated__';
import { cache } from 'src/cache';
export var writeRichTextFragment = function (_a) {
    var store = _a.cache, data = _a.data;
    store.writeFragment({
        data: data,
        fragment: RichTextFragmentDoc,
        fragmentName: 'richText',
        id: cache.identify(data),
    });
};
