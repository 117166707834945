var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { animated, config, useTransition } from '@react-spring/web';
import { Note } from 'src/content/annotation/note/Note';
import { useBookQuery } from 'src/hooks';
import { getNoteAtBottomOfPage } from './getNoteAtBottomOfPage';
/**
 * Area at the lower half of the window where overlaying content can be shown.
 * Currently used for notes when the page is too narrow to show the sidebar.
 */
export var Overlay = function () {
    var _a;
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    var note = getNoteAtBottomOfPage({ blocks: blocks !== null && blocks !== void 0 ? blocks : [] });
    // FIXME: multiple copies are shown at the same time when toggling quickly
    var transition = useTransition(note, {
        config: __assign(__assign({}, config.stiff), { clamp: true }),
        enter: { height: 256 },
        from: { height: 0 },
        keys: function (n) { var _a; return (_a = n === null || n === void 0 ? void 0 : n.id) !== null && _a !== void 0 ? _a : 'fallback key'; },
        leave: { height: 5 },
    });
    return (_jsx("div", __assign({ className: "overlay-container pointer-events-none fixed bottom-0 z-3 grid" }, { children: _jsx("div", __assign({ className: "flex justify-center", style: { gridColumn: 'content' } }, { children: transition(function (style, item) {
                return item && (_jsx(animated.div, __assign({ className: "pointer-events-auto relative h-24 max-h-96 w-full max-w-md rounded-t print:hidden", style: style }, { children: _jsx(Note, __assign({}, item, { text: item.text })) })));
            }) })) })));
};
