"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeTableCells = void 0;
/**
 * Returns an array containing `tableCells`, with `null` placeholders following
 * and/or (diagonally) below cells with `colSpan` > 1 and/or `rowSpan` > 1.
 * These placeholders are positioned such that they fill in for the missing
 * table cells that have been merged into the column- and/or row-spanning cell,
 * as applicable.
 *
 * The resulting array has a total number of elements equal to the number of
 * ‘equivalent’ cells if none of the table cells would have been merged.
 */
const normalizeTableCells = ({ numberOfColumns, tableCells, }) => {
    var _a, _b, _c;
    const numberOfNormalizedCells = tableCells.reduce((sum, { colSpan, rowSpan }) => sum + (colSpan !== null && colSpan !== void 0 ? colSpan : 1) * (rowSpan !== null && rowSpan !== void 0 ? rowSpan : 1), 0);
    const numberOfRows = numberOfNormalizedCells / numberOfColumns;
    /**
     * `undefined` if not yet considered in the `for`-loop below, `null` if
     * placeholder cell
     */
    const normalizedTable = [
        ...Array(numberOfRows),
    ].map((_) => [...Array(numberOfColumns)]);
    for (let tableCellIndex = 0; tableCellIndex < tableCells.length; tableCellIndex += 1) {
        const rowIndex = normalizedTable.findIndex((row) => row.includes(undefined));
        const columnIndex = (_a = normalizedTable[rowIndex]) === null || _a === void 0 ? void 0 : _a.findIndex((cell) => cell === undefined);
        const tableCell = tableCells[tableCellIndex];
        // @ts-expect-error -- ok because of `findIndex` above
        normalizedTable[rowIndex][columnIndex] = tableCell;
        const colSpan = (_b = tableCell.colSpan) !== null && _b !== void 0 ? _b : 1;
        const rowSpan = (_c = tableCell.rowSpan) !== null && _c !== void 0 ? _c : 1;
        for (let i = 0; i < rowSpan; i += 1) {
            for (let j = 0; j < colSpan; j += 1) {
                if (i > 0 || j > 0) {
                    // @ts-expect-error -- ok here
                    normalizedTable[rowIndex + i][columnIndex + j] = null;
                }
            }
        }
    }
    return normalizedTable.flat();
};
exports.normalizeTableCells = normalizeTableCells;
