export var getRichTexts = function (block) {
    if (!block) {
        return [];
    }
    return Object.values(block).filter(function (value) {
        return value != null &&
            typeof value === 'object' &&
            '__typename' in value &&
            (value === null || value === void 0 ? void 0 : value.__typename) === 'RichText';
    });
};
