var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@private/fontawesome/pro-regular-svg-icons/faQrcode';
/**
 * QR icon: a visual element to be displayed in the signup and login form when
 * non-logged-in users visit a link to redeem a QR code
 */
export var QrInput = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: "-mx-3 mt-6 mb-0 flex items-center justify-start rounded-md border border-neutral-200 bg-neutral-50 py-3 px-4 font-sans text-base font-light text-neutral-750 dark:border-neutral-600 dark:bg-neutral-800 dark:text-white" }, { children: [_jsx(FontAwesomeIcon, { className: "mr-1 self-start text-5xl", icon: faQrcode }), _jsx("div", { children: children })] })));
};
