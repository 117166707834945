var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@private/fontawesome/pro-light-svg-icons/faArrowCircleDown';
import { useCallback } from 'react';
import { animateScroll } from 'react-scroll';
// TODO: detect `prefers-reduced-motion` user preference
var immediately = false;
export var Arrow = function (_a) {
    var y = _a.y;
    var handleMouseDown = useCallback(function () {
        var _a;
        var position = window.pageYOffset + ((_a = y()) !== null && _a !== void 0 ? _a : 0);
        if (immediately) {
            window.scrollTo(0, position);
        }
        else {
            animateScroll.scrollTo(position, { smooth: 'easeOutQuint' });
        }
    }, [y]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-24 overflow-hidden" }), _jsx("div", __assign({ className: "absolute bottom-0 left-0 w-full select-none text-center" }, { children: _jsx("svg", __assign({ "aria-hidden": "true", className: "h-8 w-8 cursor-pointer px-12 pt-12 pb-3 opacity-60 transition-opacity duration-200 hover:opacity-90", onMouseDown: handleMouseDown, role: "img", viewBox: "0 0 512 512", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx(FontAwesomeIcon, { icon: faArrowCircleDown }) })) }))] }));
};
