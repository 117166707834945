var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import { mergeArrayByIdField } from '../fieldPolicies';
import { sidebarAnnotationFields } from './Note';
export var DEFAULT_DISCUSSION_HEIGHT = 73; // Approximation (measured)
export var discussionTypeDef = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\textend type Discussion {\n\t\theight: Int\n\t\tleft: Int\n\t\tmaxBottom: Int\n\t\tminTop: Int\n\t\ttop: Int\n\t}\n"], ["\n\textend type Discussion {\n\t\theight: Int\n\t\tleft: Int\n\t\tmaxBottom: Int\n\t\tminTop: Int\n\t\ttop: Int\n\t}\n"])));
export var Discussion = {
    fields: __assign(__assign({}, sidebarAnnotationFields), { comments: mergeArrayByIdField, height: function (_) { return _ !== null && _ !== void 0 ? _ : DEFAULT_DISCUSSION_HEIGHT; } }),
};
var templateObject_1;
