var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBooks } from '@private/fontawesome/pro-solid-svg-icons/faBooks';
import { sortBy } from 'ramda';
import { useCallback, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GuidedTourContext, LanguageContext } from 'src/context';
import { useCookie, useLanguageList } from 'src/hooks';
import { Link } from 'src/ui';
/** Persistent footer on each page */
export var Footer = function () {
    var ref = useRef(null);
    var refs = useContext(GuidedTourContext);
    var _a = __read(useCookie('locale'), 2), _ = _a[0], setCookie = _a[1];
    var _b = useContext(LanguageContext), language = _b.language, setLanguage = _b.setLanguage;
    var languages = useLanguageList();
    /**
     * Hide the footer on the dedicated chat page, because this page should remain
     * scrolled to the bottom.
     */
    var pathname = useLocation().pathname;
    var handleChange = useCallback(function (_a) {
        var value = _a.target.value;
        if (language !== value) {
            var newLanguage = value;
            // TODO: Provide an “auto” value that removes the cookie and takes the book language or "en" elsewhere
            setCookie(newLanguage);
            setLanguage(newLanguage);
            var html = document === null || document === void 0 ? void 0 : document.getElementsByTagName('html')[0];
            if (html != null) {
                html.lang = language;
            }
        }
    }, [language, setCookie, setLanguage]);
    /**
     * When tabbing through the interface, the links at the bottom of the page
     * don’t automatically reveal themselves on focus (at least not in Safari on
     * macOS).
     */
    var handleFocus = useCallback(function () {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    }, []);
    var _c = __read(useSearchParams(), 1), searchParams = _c[0];
    var discussionId = searchParams.get('conversation');
    if (
    // TODO: Make more robust if we later start using /boek/, /livre/ etc.
    pathname.startsWith('/book/') &&
        discussionId != null) {
        return null;
    }
    return (_jsxs("footer", __assign({ ref: ref, className: "m-0 flex select-none items-center justify-between bg-neutral-820 text-sm text-neutral-450 dark:bg-gray-870 dark:text-neutral-420" }, { children: [_jsxs("div", __assign({ className: "flex justify-start" }, { children: [_jsx(Link, __assign({ className: "mr-4 w-4 overflow-hidden py-5 pl-7 transition-width hover:text-white dark:hover:text-neutral-310 contactpagebgcutoff:mr-2 contactpagebgcutoff:pl-5 coverpage:w-32", href: "/", onFocus: handleFocus }, { children: _jsx("img", { alt: "Nextbook", 
                            // width: auto (126px ← Safari bug: svg becomes smaller after navigating if width is explicitly set to 126px)
                            className: "h-[22px]", src: "/images/footer/126x22.svg" }) })), _jsx("div", __assign({ className: "flex items-stretch" }, { children: _jsxs(Link, __assign({ className: "relative inline-flex items-center whitespace-nowrap py-5 px-3 hover:text-white focus:text-neutral-150 dark:hover:text-neutral-310", href: "/library", onFocus: handleFocus }, { children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: faBooks }), _jsx("span", __assign({ className: "relative inline-block w-0 overflow-hidden transition-width contactpagebgcutoff:w-auto" }, { children: _jsx(FormattedMessage, { defaultMessage: "Library", id: "StcK67" }) }))] })) }))] })), _jsx("div", __assign({ className: "mr-6 flex items-stretch justify-end" }, { children: _jsxs("select", __assign({ ref: refs.languageSelector, className: "relative appearance-none rounded-md border-neutral-820 bg-neutral-820 px-3 py-5 text-xs text-neutral-70 dark:border-gray-870 dark:bg-gray-870 hires:border-0.5", defaultValue: language, onChange: handleChange, onFocus: handleFocus }, { children: [_jsx(FormattedMessage, __assign({ defaultMessage: "Select Your Language:", id: "HhVFUh" }, { children: 
                            // Don’t use `optgroup`: its background-color cannot be set in Firefox
                            function (msg) { return _jsx("option", __assign({ disabled: true }, { children: msg[0] })); } })), sortBy(function (item) { return item.name.native; })(languages).map(function (_a) {
                            var emoji = _a.emoji, iso = _a.iso, _b = _a.name, native = _b.native, translated = _b.translated;
                            return (_jsxs("option", __assign({ className: "bg-neutral-820 font-sans text-neutral-70 dark:bg-gray-870", value: iso }, { children: [emoji, " ", native, language !== iso && " / ".concat(translated)] }), iso));
                        })] })) }))] })));
};
