import { useQuery } from '@apollo/client';
import { BookDocument } from 'src/__generated__';
import { useParams } from './useParams';
/**
 * Convenience hook to use `book` from the cache when it’s already been
 * retrieved by another component.
 */
export var useBookQuery = function (_a) {
    var _b = _a === void 0 ? { skip: false } : _a, _c = _b.skip, skip = _c === void 0 ? false : _c;
    var params = useParams();
    var _d = useQuery(BookDocument, {
        skip: skip,
        variables: {
            bookParams: params.slice(0, 2),
            pageParams: params.slice(2),
        },
    }), data = _d.data, error = _d.error, loading = _d.loading;
    if ((data === null || data === void 0 ? void 0 : data.book.__typename) !== 'BookPayload' || error || loading) {
        return undefined;
    }
    return data.book.book;
};
