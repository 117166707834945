var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { useBreadcrumbs } from 'src/hooks';
import { Color } from './Color';
import { Font } from './Font';
// import Language from './Language'
export default function DisplayPreferences() {
    var intl = useIntl();
    useBreadcrumbs([
        {
            href: '/profile',
            text: intl.formatMessage({ defaultMessage: 'Profile', id: 'itPgxd' }),
        },
        {
            href: '/profile/preferences',
            text: intl.formatMessage({
                defaultMessage: 'Display Preferences',
                id: '1eslim',
            }),
        },
    ]);
    /*
     * Additional options:
     * - turn animations on/off
     * - take user preference for contrast into account
     * - user preference for reduced transparency
     */
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "text-3xl mb-2" }, { children: _jsx(FormattedMessage, { defaultMessage: "Display Preferences", id: "1eslim" }) })), _jsxs("form", __assign({ className: "mx-0 mt-0 mb-6" }, { children: [_jsx(Color, {}), _jsx(Font, {})] }))] }));
}
