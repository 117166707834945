var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useApolloClient, useMutation } from '@apollo/client';
import { faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@private/fontawesome/pro-light-svg-icons/faPlusSquare';
import { faArrowLeft } from '@private/fontawesome/pro-regular-svg-icons';
import gql from 'graphql-tag';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DeleteUserDocument, ToggleIsAuthorDocument } from 'src/__generated__';
import { classNames } from 'src/functions';
import { useBreadcrumbs, useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
import { Button } from 'src/ui/form/Button';
import { Spinner } from 'src/ui/Spinner';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation ToggleIsAuthor($input: UpdateUserInput!) {\n\t\tupdateUser(input: $input) {\n\t\t\t... on UpdateUserPayload {\n\t\t\t\tuser {\n\t\t\t\t\tid\n\t\t\t\t\tisAuthor\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation ToggleIsAuthor($input: UpdateUserInput!) {\n\t\tupdateUser(input: $input) {\n\t\t\t... on UpdateUserPayload {\n\t\t\t\tuser {\n\t\t\t\t\tid\n\t\t\t\t\tisAuthor\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export default function ProfileSettings() {
    var intl = useIntl();
    useBreadcrumbs([
        {
            href: '/profile',
            text: intl.formatMessage({ defaultMessage: 'Profile', id: 'itPgxd' }),
        },
        {
            href: '/profile/settings',
            text: intl.formatMessage({
                defaultMessage: 'Account Settings',
                id: 'NQgbYA',
            }),
        },
    ]);
    var navigate = useNavigate();
    var _a = __read(useState([false, false]), 2), values = _a[0], setValues = _a[1];
    var _b = __read(useState(false), 2), sent = _b[0], setSent = _b[1];
    var client = useApolloClient();
    var _c = __read(useMutation(DeleteUserDocument), 1), deleteUser = _c[0];
    var _d = __read(useMutation(ToggleIsAuthorDocument), 1), updateUser = _d[0];
    var currentUser = useCurrentUserQuery();
    var handleChange = useCallback(function (which) { return function (event) {
        setValues(values.map(function (value, index) {
            return which === index ? event.target.checked : value;
        }));
    }; }, [values]);
    var handleSubmit = useCallback(function (event) {
        if (currentUser == null) {
            return;
        }
        event.preventDefault();
        setSent(true);
        if (values.reduce(function (acc, curr) { return acc && curr; })) {
            // all boxes are checked
            deleteUser({
                update: function () {
                    client.resetStore();
                    navigate('/');
                },
                variables: { input: { id: currentUser.id } },
            });
        }
    }, [client, deleteUser, currentUser, navigate, values]);
    var toggleAuthorStatus = useCallback(function () {
        if (!currentUser) {
            return;
        }
        updateUser({
            optimisticResponse: {
                __typename: 'Mutation',
                updateUser: {
                    __typename: 'UpdateUserPayload',
                    user: {
                        __typename: 'User',
                        id: currentUser.id,
                        isAuthor: !currentUser.isAuthor,
                    },
                },
            },
            variables: {
                input: { id: currentUser.id, isAuthor: !currentUser.isAuthor },
            },
        });
    }, [currentUser, updateUser]);
    if (!currentUser) {
        return (_jsx("main", { children: _jsx(Spinner, {}) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "text-3xl mb-2" }, { children: _jsx(FormattedMessage, { defaultMessage: "Account Settings", id: "NQgbYA" }) })), _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: "Please {_} if you want to update your account data. We generally handle requests within one business day.", id: "U1HlVL", values: {
                        _: (_jsx(Link, __assign({ href: "mailto:support@nextbook.io" }, { children: _jsx(FormattedMessage, { defaultMessage: "contact the helpdesk", id: "CFcWNN" }) }))),
                    } }) }), _jsx("h2", __assign({ className: "mt-6 text-2xl" }, { children: _jsx(FormattedMessage, { defaultMessage: "Publish on Nextbook", id: "+eRKUD" }) })), _jsxs("fieldset", { children: [_jsxs("label", __assign({ className: "flex select-none", htmlFor: "isAuthor" }, { children: [_jsx("input", { checked: currentUser.isAuthor, className: "relative top-1 mr-2 h-4 w-4 rounded border-gray-300 text-blue-checked focus:ring-blue-checked/80", id: "isAuthor", name: "isAuthor", onChange: toggleAuthorStatus, type: "checkbox" }), _jsx(FormattedMessage, { defaultMessage: "I\u2019m an author and would like to publish my work on Nextbook.", id: "83FGlm" })] })), _jsx("p", __assign({ className: "mt-3 ml-7", style: currentUser.isAuthor ? undefined : { visibility: 'hidden' } }, { children: _jsx(FormattedMessage, { defaultMessage: "You can {_} by clicking {__} on the {___}.", id: "5bI1np", values: {
                                _: (_jsx(Link, __assign({ href: "/publish" }, { children: _jsx(FormattedMessage, { defaultMessage: "publish your books", id: "RVxERx" }) }))),
                                __: _jsx(FontAwesomeIcon, { icon: faPlusSquare }),
                                ___: (_jsx(Link, __assign({ href: "/" }, { children: _jsx(FormattedMessage, { defaultMessage: "home page", id: "nizegm" }) }))),
                            } }) }))] }), _jsx("h2", __assign({ className: "mt-6 text-2xl" }, { children: _jsx(FormattedMessage, { defaultMessage: "Delete Your Account", id: "ja8jXT" }) })), _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: "If you want to delete your account, you can do so by clicking the button below. Please be aware that any information related to your account (highlights, notes, comments, library) will be deleted as well. This action cannot be undone.", id: "r9jlOD" }) }), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [[
                        _jsx(FormattedMessage, { defaultMessage: "I am sure that I want to delete my account.", id: "MS/jwZ" }, "MS/jwZ"),
                        _jsx(FormattedMessage, { defaultMessage: "I understand that my study progress will be lost.", id: "VQlotl" }, "VQlotl"),
                    ].map(function (message, index) { return (_jsxs("label", __assign({ className: "my-1 flex select-none", htmlFor: "confirm-".concat(index) }, { children: [_jsx("input", { checked: values[index], className: "relative top-1 mr-2 h-4 w-4 rounded border-gray-300 text-blue-checked focus:ring-blue-checked/80", id: "confirm-".concat(index), onChange: handleChange(index), type: "checkbox" }), _jsxs("div", __assign({ className: classNames(sent &&
                                    values[index] !== true &&
                                    'text-red-accent dark:text-red-strong', 'w-full') }, { children: [message, sent && values[index] !== true && ' ☜'] }))] }), message.key)); }), _jsxs("div", __assign({ className: "mt-6 flex w-full items-center justify-center gap-6" }, { children: [_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsxs(Link, __assign({ href: "/profile" }, { children: [_jsx(FontAwesomeIcon, { className: "mr-1.5", icon: faArrowLeft }), _jsx(FormattedMessage, { defaultMessage: "Go Back", id: "ekfOaV" })] })) })), _jsx(Button, __assign({ className: "border-0 border-b-2", icon: faUserXmark, type: "submit", variant: "danger" }, { children: _jsx(FormattedMessage, { defaultMessage: "Delete Account", id: "R2OqnW" }) }))] }))] }))] }));
}
var templateObject_1;
