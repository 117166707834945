import { useQuery } from '@apollo/client';
import { BookDocument } from 'src/__generated__';
import { useParams } from './useParams';
/**
 * Convenience hook to use `book` from the cache when it’s already been
 * retrieved by another component.
 */
export var useBlocks = function () {
    var params = useParams();
    var _a = useQuery(BookDocument, {
        variables: {
            bookParams: params.slice(0, 2),
            pageParams: params.slice(2),
        },
    }), data = _a.data, error = _a.error, loading = _a.loading;
    if ((data === null || data === void 0 ? void 0 : data.book.__typename) !== 'BookPayload' || error || loading) {
        return undefined;
    }
    return data.book.book.blocks;
};
