import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { highlightPopupVar, selectionPopupVar } from 'src/cache/reactiveVars';
export var useClosePopup = function () {
    var highlightPopup = useReactiveVar(highlightPopupVar);
    var selectionPopup = useReactiveVar(selectionPopupVar);
    return useCallback(function () {
        if (highlightPopup != null) {
            highlightPopupVar(undefined);
        }
        if (selectionPopup != null) {
            selectionPopupVar(undefined);
        }
    }, [highlightPopup, selectionPopup]);
};
