var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commentSanitizer } from '@nextbook/shared/sanitizers';
import { faTimes } from '@private/fontawesome/pro-regular-svg-icons/faTimes';
import { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UpdateCommentDocument } from 'src/__generated__';
import { useCurrentUserQuery, useParams } from 'src/hooks';
import { LikeButton, SendButton, VisibilitySelector } from './controls';
import { 
// useCommentLikeToggled,
useCreateComment, useDeleteComment, useSubmitComment, } from './graphql';
var MINIMUM_WIDTH = 48; // Speech bubble triangle +/- in center
var TRIANGLE_PADDING = 32; // px
export var Comment = function (_a) {
    var _b;
    var _c = _a.byCoordinator, byCoordinator = _c === void 0 ? false : _c, disabled = _a.disabled, discussionId = _a.discussionId, id = _a.id, _d = _a.liked, liked = _d === void 0 ? false : _d, handleResize = _a.onResize, _e = _a.reply, reply = _e === void 0 ? false : _e, _f = _a.sent, initialSent = _f === void 0 ? false : _f, _g = _a.text, text = _g === void 0 ? '' : _g, user = _a.user;
    var intl = useIntl();
    var params = useParams();
    var delay = useRef(null);
    var hiddenCopyRef = useRef(null);
    var placeholder = reply
        ? intl.formatMessage({ defaultMessage: 'Reply', id: '9HU8vw' })
        : intl.formatMessage({ defaultMessage: 'Add a comment', id: 'vPCMyI' });
    var placeholderTranslationRef = useRef(placeholder);
    var _h = __read(useState(), 2), placeholderWidth = _h[0], setPlaceholderWidth = _h[1];
    var _j = __read(useState(), 2), width = _j[0], setWidth = _j[1];
    useEffect(function () {
        var _a;
        var mismatch = placeholderTranslationRef.current !== placeholder;
        if ((placeholderWidth == null && hiddenCopyRef.current) || mismatch) {
            var newWidth = (_a = hiddenCopyRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width;
            if (newWidth != null || mismatch) {
                setPlaceholderWidth(newWidth);
            }
            if (width == null || mismatch) {
                setWidth(newWidth);
            }
        }
        if (mismatch) {
            placeholderTranslationRef.current = placeholder;
        }
    }, [
        placeholder,
        placeholderWidth,
        width,
    ]);
    var _k = __read(useState(), 2), height = _k[0], setHeight = _k[1];
    var _l = __read(useState(text), 2), value = _l[0], setValue = _l[1];
    var currentUser = useCurrentUserQuery();
    // Uncomment to enable subscription that shows number of likes. Use `use[...]Subscription`
    // const { subscribeToMore } = useBookQuery({ variables: { bookParams: params.slice(0, 2), pageParams: params.slice(2) } })
    // useEffect(() => {
    // 	if (id) {
    // 		const unsubscribe = subscribeToMore(
    // 			commentLikeToggledConfig({ commentId: id, discussionId })
    // 		)
    // 		return unsubscribe
    // 	}
    // })
    /** Update dimensions when `value` changes */
    useLayoutEffect(function () {
        if (hiddenCopyRef.current && placeholderWidth != null) {
            var rect = hiddenCopyRef.current.getBoundingClientRect();
            if (height !== rect.height) {
                setHeight(rect.height);
            }
            if (width !== rect.width) {
                setWidth(Math.max(rect.width, placeholderWidth));
            }
        }
    }, [height, placeholderWidth, value, width]); // include `value`
    /**
     * After the height was updated in `useLayoutEffect`, we update the sidebar
     * positions
     */
    useEffect(function () {
        if (height != null && handleResize) {
            handleResize();
        } // update sidebar positions
    }, [height, handleResize]);
    var createComment = useCreateComment();
    var deleteComment = useDeleteComment();
    var _m = useSubmitComment(), submitComment = _m.submitComment, submitted = _m.submitted;
    var _o = __read(useMutation(UpdateCommentDocument), 1), updateComment = _o[0];
    var sent = initialSent || submitted;
    var send = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var sanitizedValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sanitizedValue = commentSanitizer(value);
                    if (!sanitizedValue.length) return [3 /*break*/, 4];
                    if (!(id != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, submitComment({
                            commentId: id,
                            currentUser: currentUser,
                            text: sanitizedValue,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(discussionId != null)) return [3 /*break*/, 4];
                    return [4 /*yield*/, createComment({
                            currentUser: currentUser,
                            discussionId: discussionId,
                            value: sanitizedValue,
                        })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [createComment, currentUser, discussionId, id, submitComment, value]);
    var handleChange = useCallback(function (_a) {
        var target = _a.target;
        setValue(target.value);
        /*
         * The mutation gets delayed by 3 seconds, and gets reset if there’s a new
         * mutation within 3 seconds. This makes the textarea more responsive,
         * also, it only serves as a backup while typing anyway (the comment
         * doesn’t get displayed to others before submission).
         */
        if (id != null) {
            var DELAY = 2000;
            if (delay.current != null) {
                window.clearTimeout(delay.current);
            }
            delay.current = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!submitted) return [3 /*break*/, 2];
                            return [4 /*yield*/, updateComment({
                                    optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateComment: {
                                            __typename: 'UpdateCommentPayload',
                                            comment: { __typename: 'Comment', id: id, text: value },
                                        },
                                    },
                                    variables: { input: { id: id, text: value } },
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, DELAY);
        }
    }, [id, submitted, updateComment, value]);
    var handleKeyDown = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = event.key;
                    switch (_a) {
                        case 'Escape': return [3 /*break*/, 1];
                        case 'Enter': return [3 /*break*/, 4];
                    }
                    return [3 /*break*/, 7];
                case 1:
                    if (!(value === '')) return [3 /*break*/, 3];
                    if (delay.current != null) {
                        window.clearTimeout(delay.current);
                    }
                    if (!(id != null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, deleteComment({
                            commentId: id,
                            params: params,
                            reply: reply,
                        })];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3: return [3 /*break*/, 7];
                case 4:
                    if (!(!event.shiftKey && !event.ctrlKey && !event.metaKey)) return [3 /*break*/, 6];
                    event.preventDefault();
                    return [4 /*yield*/, send()];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6: return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [deleteComment, id, params, reply, send, value]);
    var minWidth = Math.max(MINIMUM_WIDTH, sent ? 0 : placeholderWidth !== null && placeholderWidth !== void 0 ? placeholderWidth : 0);
    var style = { height: height, minWidth: minWidth, width: width };
    var colorClassName = "block shadow-sm dark:shadow-none rounded-lg text-left outline-none -mt-2.5 p-2.5 pb-2 border-b dark:border-b-0 ".concat(reply
        ? 'text-black dark:text-neutral-70 border-neutral-250 dark:border-transparent bg-gradient-to-br from-neutral-150 to-neutral-170 dark:bg-none bg-neutral-170 dark:bg-gray-850'
        : 'bg-gradient-to-br from-[#c4e5fa] to-[#a0d5f8] dark:bg-none border-0 border-blue-mid bg-blue-subtle text-black dark:bg-blue-dark/40 dark:text-blue-strong');
    var dimensionClassName = "box-border resize-none overflow-hidden font-sans text-sm leading-6 border-b ".concat(reply ? '' : 'border-0');
    return (_jsxs("div", __assign({ className: "relative mb-3 font-normal ".concat(reply ? 'text-right' : 'text-left') }, { children: [_jsxs("div", __assign({ className: "before:absolute before:top-full before:block before:h-0 before:w-0 before:border-8 after:absolute after:top-[calc(100%_-_1px)] after:block after:h-0 after:w-0 after:border-8 ".concat(reply
                    ? 'before:right-4 before:border-transparent before:border-r-neutral-305 before:border-t-neutral-305 after:right-4 after:border-transparent after:border-r-neutral-170 after:border-t-neutral-170 dark:before:border-transparent dark:after:border-t-gray-850 dark:after:border-r-gray-850'
                    : 'before:left-4 before:border-transparent before:border-l-blue-mid before:border-t-blue-mid after:left-4 after:border-transparent after:border-l-blue-subtle after:border-t-blue-subtle dark:before:border-transparent dark:after:border-t-blue-dark/40 dark:after:border-l-blue-dark/40', " group relative inline-block h-full"), style: { minWidth: minWidth, width: width } }, { children: [user && user.id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) && (
                    // || groups.find(({ users }) => users.find(({ id }) => id === userId))
                    _jsx(FontAwesomeIcon, { className: "".concat(reply ? 'dark:text-neutral-250' : 'dark:text-blue-strong', " absolute -right-1 -top-4 cursor-pointer rounded-tr-sm rounded-bl-sm py-2 px-2.5 text-sm text-neutral-750 opacity-0 transition-opacity group-hover:opacity-50 group-hover:hover:opacity-100 theme-contrast:text-black"), icon: faTimes, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(id != null)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, deleteComment(
                                            // Only execute when needed:
                                            {
                                                commentId: id,
                                                params: params,
                                                reply: reply,
                                            })];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); } })), _jsx("textarea", { className: "".concat(dimensionClassName, " ").concat(colorClassName, " ").concat(reply
                            ? 'focus:border-neutral-350'
                            : 'opacity-100 focus:border-blue-mid dark:placeholder:text-[#2679a8]', " border-b focus:ring-0"), id: id == null ? undefined : "comment-".concat(id), onChange: handleChange, onKeyDown: handleKeyDown, placeholder: placeholder, readOnly: sent, style: style, value: value }), !sent && (_jsx(VisibilitySelector, { className: reply ? 'right-0' : 'left-0' })), disabled !== true && sent && id && (_jsx(LikeButton, { className: reply ? 'right-0' : 'left-0', id: id, liked: liked, reply: reply }))] })), (disabled === true || !submitted || user) && (_jsx("div", __assign({ className: "mt-0.5 w-full text-ellipsis overflow-hidden whitespace-nowrap text-xs text-neutral-400 dark:text-neutral-470 ".concat(submitted && !reply ? 'text-left' : 'text-right'), style: reply
                    ? { paddingRight: TRIANGLE_PADDING }
                    : { paddingLeft: TRIANGLE_PADDING } }, { children: sent ? ((_b = (user &&
                    (user.id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) ? (_jsx(FormattedMessage, { defaultMessage: "You", id: "kJ5W29" })) : (_jsxs(_Fragment, { children: [byCoordinator && (_jsx(FontAwesomeIcon, { className: "mr-1", icon: faGraduationCap })), user.firstName, " ", user.lastName] }))))) !== null && _b !== void 0 ? _b : _jsx(_Fragment, { children: "\u00A0" })) : (_jsx(SendButton, { send: send })) }))), _jsx("div", __assign({ 
                // Hidden element with same design as textarea. The dimensions of this element
                // are measured and set on the textarea so that it can increase in size when
                // typing. Also used to find the dimensions of the placeholder text.
                ref: hiddenCopyRef, "aria-hidden": true, className: "".concat(dimensionClassName, " pointer-events-none invisible absolute left-0 top-0 inline-block max-w-[calc(100%_-_22px)] whitespace-pre-wrap px-3 pt-2.5 pb-2"), style: { overflowWrap: 'anywhere' } }, { children: 
                // Make sure the last line gets shown, if empty
                value ? (value.endsWith('\n') ? "".concat(value, " ") : value) : placeholder }))] })));
};
