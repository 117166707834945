var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Based on example in Apollo docs
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
 */
export var mergeArrayByIdField = {
    // eslint-disable-next-line @typescript-eslint/default-param-last -- Apollo API
    merge: function (existing, incoming, _a) {
        if (existing === void 0) { existing = []; }
        if (incoming === void 0) { incoming = []; }
        var readField = _a.readField, mergeObjects = _a.mergeObjects;
        var merged = __spreadArray([], __read(incoming), false);
        var existingIds = existing.map(function (item) { return readField('id', item); });
        merged.forEach(function (item, index) {
            var itemId = readField('id', item);
            var existingIndex = existingIds.findIndex(function (id) { return id === itemId; });
            if (existingIndex !== -1) {
                var existingItem = existing[existingIndex];
                var mergedItem = merged[index];
                if (existingItem != null && mergedItem != null) {
                    merged[index] = mergeObjects(existingItem, mergedItem);
                }
            }
        });
        return merged;
    },
};
