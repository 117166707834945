"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookieDomain = void 0;
const POTENTIAL_HOSTS = ['localhost', 'lvh.me', 'nextbook'];
/** Take `hostname` as an argument so that it can be supplied during unit testing */
const getCookieDomain = (hostname = window.location.hostname) => {
    if (hostname.split(':')[0] === 'localhost') {
        return 'localhost';
    }
    // no subdomains possible on localhost
    const host = POTENTIAL_HOSTS.find((item) => hostname.includes(item));
    if (host != null) {
        const index = hostname.lastIndexOf(host);
        if (index === 0) {
            return `.${hostname.split(':')[0]}`;
        }
        if (index > 0) {
            return `.${hostname.slice(index).split(':')[0]}`;
        }
    }
    return undefined;
};
exports.getCookieDomain = getCookieDomain;
