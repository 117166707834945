import { findLastIndex, last } from 'ramda';
/**
 * Function that calls itself recursively, each time with a longer `path` and a
 * shorter `titles` array. The `titles` array is shortened from right to left,
 * with each recursion leaving out the title that was added to `path` at a given
 * depth, and all succeeding titles, if any. The root (chapter) path is added to
 * `path` last.
 *
 * The last element in the `titles` array (at the beginning of the recursion) is
 * the title that we want to get the formatted path for.
 *
 * @returns The formatted path for the last title from `titles`. The parts that
 *   correspond to `mode` and `book` are ignored, and can be easily retrieved
 *   from the url itself.
 */
export var getFormattedPath = function (_a) {
    var depthOfPageTitle = _a.depthOfPageTitle, fragment = _a.fragment, path = _a.path, titles = _a.titles;
    var title = last(titles);
    /**
     * Avoid runtime error when navigating away from a book (e.g. clicking on the
     * user name) when a dropdown menu is open
     */
    if (depthOfPageTitle == null || depthOfPageTitle === 0 || !title) {
        return undefined;
    }
    if (title.depth === 1) {
        // top-level title
        return "/".concat(title.path).concat(path == null ? '' : "/".concat(path)).concat(fragment == null ? '' : "#".concat(fragment));
    }
    // because we’ve proceeded past the case where `title.depth === 1`, we can
    // assume that a parent title exists
    var parentIndex = findLastIndex(function (t) { return t.depth < title.depth; }, titles);
    // ignore all that precedes the parent title because they cannot be siblings
    var allTitlesWithinParent = titles.slice(parentIndex + 1); // OK if for whatever reason `parentIndex` wasn’t found (-1 + 1 = 0)
    /**
     * If the title is the first within its parent, it doesn’t have a dedicated
     * page, and we refer to it via a fragment (the url part after #) on a parent
     * page.
     *
     * If `path` has a value, this implies that at a deeper level, we have already
     * processed a title that was not the first within its parent, and in this
     * case we don’t want to move up either.
     */
    var TITLE_IS_FIRST_AMONG_ITS_SIBLINGS = path == null &&
        allTitlesWithinParent.findIndex(function (_a) {
            var depth = _a.depth;
            return depth === title.depth;
        }) ===
            allTitlesWithinParent.length - 1;
    if (TITLE_IS_FIRST_AMONG_ITS_SIBLINGS || title.depth > depthOfPageTitle) {
        // in both cases, the page doesn’t have a dedicated page and we move one level up
        return getFormattedPath({
            depthOfPageTitle: depthOfPageTitle,
            fragment: fragment !== null && fragment !== void 0 ? fragment : (path == null ? title.path : undefined),
            path: path,
            titles: titles.slice(0, parentIndex + 1), // next iteration, the parent title is the last element in `titles`
        });
    }
    var parentTitle = titles[parentIndex];
    var TITLE_HAS_SKIPPED_A_LEVEL = parentTitle != null && title.depth - parentTitle.depth !== 1;
    return getFormattedPath({
        depthOfPageTitle: depthOfPageTitle,
        fragment: TITLE_HAS_SKIPPED_A_LEVEL && path == null
            ? fragment !== null && fragment !== void 0 ? fragment : title.path
            : fragment,
        path: TITLE_HAS_SKIPPED_A_LEVEL
            ? path
            : "".concat(title.path).concat(path == null ? '' : "/".concat(path)),
        titles: titles.slice(0, parentIndex + 1),
    });
};
