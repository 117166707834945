var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsAlt } from '@private/fontawesome/pro-solid-svg-icons/faCommentsAlt';
import gql from 'graphql-tag';
import { useIntl } from 'react-intl';
import { selectionPopupVar } from 'src/cache/reactiveVars';
import { handleKeyDown } from './functions';
import { useCreateDiscussion } from './useCreateDiscussion';
import { useGetRichTextParts } from './useGetRichTextParts';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation CreateDiscussion($input: CreateDiscussionInput!) {\n\t\tcreateDiscussion(input: $input) {\n\t\t\t... on CreateDiscussionPayload {\n\t\t\t\tdiscussion {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...discussion\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation CreateDiscussion($input: CreateDiscussionInput!) {\n\t\tcreateDiscussion(input: $input) {\n\t\t\t... on CreateDiscussionPayload {\n\t\t\t\tdiscussion {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...discussion\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var DiscussionButton = function () {
    var getRichTextParts = useGetRichTextParts();
    var intl = useIntl();
    var createDiscussion = useCreateDiscussion();
    return (_jsx("div", __assign({ className: "inline-flex h-10 w-11 cursor-pointer items-center justify-center py-1 pr-1 text-center leading-10", onKeyDown: handleKeyDown(function () {
            createDiscussion({
                variables: {
                    input: { richTextParts: getRichTextParts(), text: '' },
                },
            });
            selectionPopupVar(undefined);
        }), onMouseDown: function () {
            createDiscussion({
                variables: {
                    input: { richTextParts: getRichTextParts(), text: '' },
                },
            });
            selectionPopupVar(undefined);
        }, role: "button", tabIndex: 0, title: intl.formatMessage({
            defaultMessage: 'Post a comment',
            id: 'YjX2c6',
        }) }, { children: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: faCommentsAlt }) })));
};
var templateObject_1;
