var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GuidedTourDropdownMenu } from './GuidedTourDropdownMenu';
export var DropdownMenu = function (_a) {
    var children = _a.children, minWidth = _a.minWidth, style = _a.style;
    return (_jsxs(_Fragment, { children: [_jsx(GuidedTourDropdownMenu, {}), _jsx("div", __assign({ 
                // eslint-disable-next-line tailwindcss/no-custom-classname -- TODO: use ref and `Node.contains()` instead
                className: "_preventHandleMouseDown _preventToggleDropdownMenu relative max-h-[calc(100vh_-_83px)] cursor-default overflow-y-auto rounded border border-neutral-250 bg-white py-3 font-sans font-normal text-neutral-650 shadow backdrop-blur supports-blur:bg-white/80 dark:border-transparent dark:bg-gray-850 dark:text-neutral-70 hires:border-0.5", role: "menu", style: __assign(__assign({}, style), { counterReset: 'menu', minWidth: minWidth }) }, { children: children }))] }));
};
