import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@private/fontawesome/pro-regular-svg-icons/faEye';
import { faCog } from '@private/fontawesome/pro-solid-svg-icons/faCog';
import { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbsDocument } from 'src/__generated__';
import { useCurrentUserQuery, useParams } from 'src/hooks';
import { Button } from './Button';
/** Button that toggles the editor view */
export var SettingsButton = forwardRef(function (_a, ref) {
    var onKeyDown = _a.onKeyDown;
    var params = useParams();
    var currentUser = useCurrentUserQuery();
    var bookParams = params.slice(0, 2);
    var mode = params[0];
    var data = useQuery(BreadcrumbsDocument, {
        skip: mode == null ||
            !['book', 'edit'].includes(mode) ||
            bookParams.length !== 2,
        variables: { bookParams: bookParams },
    }).data;
    var book = (data === null || data === void 0 ? void 0 : data.book.__typename) === 'BookPayload' ? data.book.book : undefined;
    var _b = book !== null && book !== void 0 ? book : {}, _c = _b.authors, authors = _c === void 0 ? [] : _c, _d = _b.groups, groups = _d === void 0 ? [] : _d;
    var isAdmin = useMemo(function () { return Boolean((currentUser === null || currentUser === void 0 ? void 0 : currentUser.role) === 'Administrator'); }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.role]);
    var isAuthor = useMemo(function () { return Boolean(authors.find(function (_a) {
        var id = _a.id;
        return id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    })); }, [authors, currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    // TODO: move logic to backend?
    var isCoordinator = useMemo(function () { return Boolean(groups.find(function (group) { return group.hasCurrentUserAsCoordinator; })); }, [groups]);
    if (mode == null ||
        !['book', 'edit', 'settings', 'summary'].includes(mode) ||
        !(isAdmin || isAuthor || isCoordinator)) {
        return null;
    }
    var isShowingView = mode === 'settings';
    return (_jsx(Routes, { children: _jsx(Route, { element: _jsx(Button, { ref: ref, className: "group", href: "/".concat(isShowingView ? 'book' : 'settings', "/").concat(params[1]), icon: _jsx(FontAwesomeIcon, { className: isShowingView
                        ? undefined
                        : 'animate-[spin_2s_linear_infinite] paused group-hover:running', icon: isShowingView ? faEye : faCog }), label: isShowingView ? (_jsx(FormattedMessage, { defaultMessage: "View", id: "FgydNe" })) : (_jsx(FormattedMessage, { defaultMessage: "Settings", id: "D3idYv" })), onKeyDown: onKeyDown }), path: "/:mode/:book/*" }) }));
});
SettingsButton.displayName = 'SettingsButton';
