// Other type guards
export var isBlockquote = function (block) { return block.__typename === 'Blockquote'; };
export var isDelete = function (op) {
    return (op === null || op === void 0 ? void 0 : op.__typename) === 'Delete';
};
export var isDiscussion = function (annotation) { return annotation.__typename === 'Discussion'; };
export var isElement = function (eventTarget) { return eventTarget instanceof Element; };
export var isError = function (result) {
    return Boolean(result === null || result === void 0 ? void 0 : result.__typename.endsWith('Error'));
};
// TODO: remove
var allErrorCodes = [
    'BookAlreadyInStore',
    'ConfirmPasswordDoesntMatch',
    'ConfirmPasswordEmpty',
    'ConfirmPasswordInsufficientLength',
    'Denied',
    'EmailAlreadyInUse',
    'EmailAlreadyVerified',
    'EmailEmpty',
    'EmailInvalid',
    'EmailNotPrimary',
    'EmailUnknown',
    'EmailVerificationTokenInvalid',
    'FirstNameEmpty',
    'IdAlreadyInUse',
    'Impossible',
    'InvalidColSpanOrRowSpan',
    'InvalidToken',
    'LastNameEmpty',
    'LinkExpired',
    'NotFound',
    'NotLoggedIn',
    'PasswordEmpty',
    'PasswordInsufficientLength',
    'QrAlreadyRedeemed',
    'QrInvalid',
    'TermsNotAccepted',
    'UploadFailed',
];
export var isErrorCode = function (errorCode) {
    // @ts-expect-error -- to be expected
    return allErrorCodes.includes(errorCode);
};
export var isHighlight = function (annotation) { return annotation.__typename === 'Highlight'; };
export var isImage = function (block) {
    return block.__typename === 'Image';
};
export var isInputInsert = function (op) {
    return (op === null || op === void 0 ? void 0 : op.__typename) === 'InputInsert';
};
export var isList = function (block) {
    return block.__typename === 'List';
};
export var isListItem = function (block) { return block.__typename === 'ListItem'; };
export var isMultipleChoice = function (block) {
    return block.__typename === 'MultipleChoice';
};
export var isMultipleChoiceOption = function (block) {
    return block.__typename === 'MultipleChoiceOption';
};
export var isNote = function (annotation) { return annotation.__typename === 'Note'; };
export var isParagraph = function (block) { return block.__typename === 'Paragraph'; };
export var isRetain = function (op) {
    return (op === null || op === void 0 ? void 0 : op.__typename) === 'Retain';
};
export var isRichText = function (value) {
    return value != null &&
        typeof value === 'object' &&
        '__typename' in value &&
        value.__typename === 'RichText';
};
var SIDEBAR_ANNOTATIONS = ['Discussion', 'Note'];
export var isSidebarAnnotation = function (annotation) {
    return SIDEBAR_ANNOTATIONS.includes(annotation.__typename);
};
export var isTableCell = function (block) { return (block === null || block === void 0 ? void 0 : block.__typename) === 'TableCell'; };
export var isTextInsert = function (op) { return (op === null || op === void 0 ? void 0 : op.__typename) === 'TextInsert'; };
export var isTitle = function (block) { return (block === null || block === void 0 ? void 0 : block.__typename) === 'Title'; };
