var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { init } from 'ramda';
import { deltaToString } from 'src/functions';
import { isTextInsert } from './typeGuards';
/** Regular expression used to separate sentences at `. `, `? `, `! ` and `… ` */
var regex = /(\.\)*\s|\?\)*\s|!\)*\s|…\)*\s)/g;
/**
 * Turns a Delta into an array of sentence strings. Sentences end with `.`, `?`,
 * or `!`, followed by whitespace.
 *
 * ⚠️ The combined string length of the elements in the output array should be
 * exactly equal to the length of `deltaToString(delta)`, because this function
 * is used in `addDataToDelta`, where positions and indexes are important.
 */
export var deltaToSentences = function (delta) {
    return deltaToString(__assign(__assign({}, delta), { ops: 
        /**
         * The extra space serves to maintain the correct length so that the
         * underlined text matches the voice. Apparently this also works for
         * footnote numbers < 10.
         */
        delta.ops.filter(isTextInsert).map(function (op, index, array) {
            var _a, _b, _c;
            return ((_a = op.attributes) === null || _a === void 0 ? void 0 : _a.footnote) == null
                ? op
                : {
                    __typename: 'TextInsert',
                    insert: ((_c = (_b = array[index - 1]) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.footnote) == null ? ' ' : '',
                };
        }) }))
        .split(regex)
        .reduce(function (accumulator, currentValue) {
        if (currentValue.match(regex) && accumulator.length) {
            return __spreadArray(__spreadArray([], __read(init(accumulator)), false), [
                "".concat(accumulator[accumulator.length - 1]).concat(currentValue),
            ], false);
        }
        return __spreadArray(__spreadArray([], __read(accumulator), false), [currentValue], false);
    }, []);
};
