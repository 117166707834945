var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { reconciliation } from '@nextbook/shared';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { activeHighlightColorVar, selectionPopupVar, } from 'src/cache/reactiveVars';
import { classNames, extractHighlights } from 'src/functions';
import { useBookQuery } from 'src/hooks';
import { handleKeyDown } from './functions';
import { useCreateHighlight } from './useCreateHighlight';
import { useGetRichTextParts } from './useGetRichTextParts';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation CreateHighlight($input: CreateHighlightInput!) {\n\t\tcreateHighlight(input: $input) {\n\t\t\t... on CreateHighlightPayload {\n\t\t\t\thighlight {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...highlight\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation CreateHighlight($input: CreateHighlightInput!) {\n\t\tcreateHighlight(input: $input) {\n\t\t\t... on CreateHighlightPayload {\n\t\t\t\thighlight {\n\t\t\t\t\t...annotation\n\t\t\t\t\t...highlight\n\t\t\t\t}\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
var getFillClassNames = function (color) {
    switch (color) {
        case 'Blue':
            return classNames('fill-blue-subtle dark:fill-blue-strong');
        case 'Green':
            return classNames('fill-green-subtle dark:fill-green-strong');
        case 'Orange':
            return classNames('fill-orange-subtle dark:fill-orange-strong');
        case 'Red':
            return classNames('fill-red-subtle dark:fill-red-strong');
        case 'Yellow':
            return classNames('fill-yellow-subtle dark:fill-yellow-strong');
        default:
            return undefined;
    }
};
export var HighlightButton = function () {
    var _a;
    var activeHighlightColor = useReactiveVar(activeHighlightColorVar);
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    var getRichTextParts = useGetRichTextParts();
    var intl = useIntl();
    // useMemo(() => , [blocks])
    var mergedColor = reconciliation({
        existingHighlightedRichTextParts: extractHighlights({ blocks: blocks }),
        newRichTextParts: getRichTextParts(),
    }).mergedColor;
    var createHighlight = useCreateHighlight();
    /**
     * `getRichTextParts` is in the function body, so we get the rich text parts
     * (used in the optimistic response) when needed, and can avoid calculating
     * them on every render.
     */
    var create = useCallback(function () {
        var _a;
        createHighlight(__assign(__assign({}, reconciliation({
            existingHighlightedRichTextParts: extractHighlights({ blocks: blocks }),
            newRichTextParts: getRichTextParts(),
        })), { activeHighlightColor: activeHighlightColor }));
        selectionPopupVar(undefined);
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
    }, [activeHighlightColor, blocks, createHighlight, getRichTextParts]);
    var handleMouseDown = useCallback(function (event) {
        switch (event.button) {
            case 0: // left click
                create();
                break;
            case 2: // right click
                event.preventDefault();
                break;
            default:
        }
    }, [create]);
    return (_jsx("div", __assign({ className: "inline-flex h-10 w-11 cursor-pointer items-center justify-center py-1 pl-1 text-center leading-10", onKeyDown: handleKeyDown(create), onMouseDown: handleMouseDown, role: "button", tabIndex: 0, title: intl.formatMessage({ defaultMessage: 'Highlight', id: '5UoW6K' }) }, { children: _jsxs("svg", __assign({ "aria-hidden": "true", 
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className: "svg-inline--fa fa-highlighter fa-w-17 fa-fw", "data-icon": "highlighter", "data-prefix": "fas", focusable: "false", role: "img", viewBox: "0 0 544 512", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M0 479.98m124.61-240.01a36.592 36.592 0 0 0-10.79 38.1l13.05 42.83-50.93 50.94 96.23 96.23 50.86-50.86 42.74 13.08c13.73 4.2 28.65-.01 38.15-10.78l35.55-41.64-173.34-173.34-41.52 35.44zm403.31-160.7l-63.2-63.2c-20.49-20.49-53.38-21.52-75.12-2.35L190.55 183.68l169.77 169.78L530.27 154.4c19.18-21.74 18.15-54.63-2.35-75.13z", fill: "currentColor" }), _jsx("path", { className: getFillClassNames(mergedColor !== null && mergedColor !== void 0 ? mergedColor : activeHighlightColor), d: "M0 479.98L99.92 512l35.45-35.45-67.04-67.04L0 479.98z" })] })) })));
};
var templateObject_1;
