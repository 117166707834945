var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@private/fontawesome/pro-regular-svg-icons/faSignIn';
import { FormattedMessage } from 'react-intl';
import { useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
/** The icon in the upper left corner on every page */
export var UserIcon = function (_a) {
    var color = _a.color;
    var currentUser = useCurrentUserQuery();
    return (_jsx(Link, __assign({ className: "group relative right-0 z-50 my-3 mx-1.5 inline-block overflow-hidden whitespace-nowrap rounded bg-red-accent/80 py-3 px-4 align-top text-sm font-light text-white backdrop-blur transition-all", href: currentUser ? '/profile' : '/login' }, { children: currentUser ? (_jsxs(_Fragment, { children: [currentUser.firstName, ' ', _jsx(FontAwesomeIcon, { className: "relative -bottom-0.5 -mb-px ml-1.5 align-baseline opacity-100 transition-opacity", icon: faUserCircle, style: { color: color } })] })) : (_jsxs("span", __assign({ className: "transition-margin group-hover:ml-3 group-hover:-mr-3" }, { children: [_jsx(FormattedMessage, { defaultMessage: "Sign In", id: "Ub+AGc" }), ' ', _jsx(FontAwesomeIcon, { className: "relative -bottom-0.5 -mb-px ml-1.5 align-baseline opacity-100 transition-opacity group-hover:opacity-0", icon: faSignIn })] }))) })));
};
