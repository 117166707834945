"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.terms = void 0;
const terms = (
/** The Terms & Conditions checkbox status */
input) => {
    if (!input) {
        return 'TermsNotAccepted';
    }
    return null;
};
exports.terms = terms;
