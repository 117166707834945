var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { map, omit } from 'ramda';
import { useCallback } from 'react';
import { CreateHighlightDocument, } from 'src/__generated__';
import { lightboxVar } from 'src/cache/reactiveVars';
import { OPTIMISTIC_HIGHLIGHT_ID } from 'src/constants';
import { writeRichTextFragment } from 'src/content/inline/text/writeRichTextFragment';
import { isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
import { NotLoggedIn } from 'src/ui/lightbox/NotLoggedIn';
import { useGetRichTextParts } from 'src/ui/popup/selectionButtons/useGetRichTextParts';
export var useCreateHighlight = function () {
    var _a;
    var blocks = ((_a = useBookQuery()) !== null && _a !== void 0 ? _a : {}).blocks;
    var _b = __read(useMutation(CreateHighlightDocument), 1), createHighlight = _b[0];
    var getRichTextParts = useGetRichTextParts();
    var createHighlightCb = useCallback(function (_a) {
        var activeHighlightColor = _a.activeHighlightColor, deleteIds = _a.deleteIds, mergedColor = _a.mergedColor, mergedParts = _a.mergedParts;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, createHighlight({
                        optimisticResponse: {
                            __typename: 'Mutation',
                            createHighlight: {
                                __typename: 'CreateHighlightPayload',
                                highlight: {
                                    __typename: 'Highlight',
                                    color: mergedColor !== null && mergedColor !== void 0 ? mergedColor : activeHighlightColor,
                                    id: OPTIMISTIC_HIGHLIGHT_ID,
                                    richTextAnnotations: mergedParts.map(function (part) { return (__assign(__assign({}, omit(['richTextId'], part)), { __typename: 'RichTextAnnotation', id: Math.random().toString(), richText: { __typename: 'RichText', id: part.richTextId } })); }),
                                },
                            },
                        },
                        update: function (cache, _a) {
                            var data = _a.data;
                            if (data == null) {
                                return;
                            }
                            switch (data.createHighlight.__typename) {
                                case 'InvalidInputError':
                                case 'NotFoundError':
                                    return;
                                case 'NotAllowedError':
                                    lightboxVar(_jsx(NotLoggedIn, {}));
                                    return;
                                case 'CreateHighlightPayload': {
                                    var highlight_1 = data.createHighlight.highlight;
                                    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                                        // `map` from Ramda (below) is applied to an object here:
                                        return map(function (value) {
                                            if (!isRichText(value) || !(highlight_1 === null || highlight_1 === void 0 ? void 0 : highlight_1.richTextAnnotations)) {
                                                return;
                                            }
                                            /**
                                             * Rich text `index` used to find the values for `length` and
                                             * `position` that apply to this rich text item.
                                             */
                                            var index = highlight_1.richTextAnnotations.findIndex(function (_a) {
                                                var richText = _a.richText;
                                                return richText && richText.id === (value === null || value === void 0 ? void 0 : value.id);
                                            });
                                            /**
                                             * We don’t use `addAnnotationToRichText from
                                             * `Content/annotation/functions` because this function
                                             * applies to sidebar annotations, which can overlap with one
                                             * another. Highlights cannot overlap with one another, and so
                                             * when a new highlight gets added, a previous one might have
                                             * to be deleted.
                                             */
                                            if (index === -1) {
                                                return;
                                            }
                                            var richTextAnnotation = highlight_1.richTextAnnotations[index];
                                            var newRichText = __assign(__assign({}, value), { richTextAnnotations: __spreadArray(__spreadArray([], __read(value.richTextAnnotations.filter(function (_a) {
                                                    var id = _a.annotation.id;
                                                    return !deleteIds.includes(id);
                                                })), false), __read((richTextAnnotation == null
                                                    ? []
                                                    : [
                                                        __assign(__assign({}, omit(['richText'], richTextAnnotation)), { annotation: omit(['richTextAnnotations'], highlight_1) }),
                                                    ])), false) });
                                            writeRichTextFragment({ cache: cache, data: newRichText });
                                        }, block);
                                    });
                                }
                            }
                        },
                        variables: {
                            input: {
                                color: activeHighlightColor,
                                richTextParts: getRichTextParts(),
                            },
                        },
                    })];
            });
        });
    }, [blocks, createHighlight, getRichTextParts]);
    return createHighlightCb;
};
