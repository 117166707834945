var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { Block } from 'src/content/block/Block';
import { Text } from 'src/content/inline/text/Text';
import { useBlocks, useSelectableRef } from 'src/hooks';
import { getChildren } from '../functions';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment blockquote on Blockquote {\n\t\tfrom {\n\t\t\t...richText\n\t\t}\n\t\tparentId\n\t}\n"], ["\n\tfragment blockquote on Blockquote {\n\t\tfrom {\n\t\t\t...richText\n\t\t}\n\t\tparentId\n\t}\n"
    /**
     * Blockquote component, used to quote paragraphs and other content. Can contain
     * rich text.
     */
])));
/**
 * Blockquote component, used to quote paragraphs and other content. Can contain
 * rich text.
 */
export var Blockquote = function (_a) {
    var from = _a.from, id = _a.id;
    var selectableRef = useSelectableRef(from === null || from === void 0 ? void 0 : from.id);
    var blocks = useBlocks();
    if (!blocks) {
        return null;
    }
    var quotedBlocks = getChildren({ blocks: blocks, parentId: id });
    return (_jsxs(_Fragment, { children: [_jsx("blockquote", { children: quotedBlocks.map(function (block) { return (_jsx(Block, { block: block }, block.id)); }) }), from && (_jsx("div", __assign({ ref: selectableRef, className: "mb-6 text-right before:content-['\u2014_']" }, { children: _jsx(Text, __assign({}, from)) })))] }));
};
var templateObject_1;
