import { LINE_HEIGHT } from 'src/constants';
var POPUP_MIN_TOP_MARGIN = 100;
export var position = function (boundingClientRect, clientRects) {
    var arrowPosition = boundingClientRect.width / 2 + boundingClientRect.left;
    var yCorrection = clientRects.length > 1 && // highlight or selection spans multiple lines
        clientRects[0] != null &&
        arrowPosition < clientRects[0].left && // first line starts east of the popup arrow
        clientRects[1] != null &&
        clientRects[1].left < arrowPosition && // second line starts west of the popup arrow
        clientRects[1].right > arrowPosition // second line ends to the right of the popup arrow
        ? LINE_HEIGHT
        : 0;
    var pageXOffset = window.pageXOffset, pageYOffset = window.pageYOffset;
    return {
        // x: event.pageX, event.pageY - 10 // mouse position -- uglier, more convenient?
        x: boundingClientRect.width / 2 + boundingClientRect.left + pageXOffset,
        y: Math.max(boundingClientRect.top + pageYOffset + yCorrection, // Default
        pageYOffset + POPUP_MIN_TOP_MARGIN // When top of range is scrolled outside view (i.e. too high)
        // ... (hide/reverse popup arrow in this case?)
        ),
    };
};
