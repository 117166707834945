var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-default-export, react/function-component-definition */
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBreadcrumbs, useCurrentUserQuery } from 'src/hooks';
import { Link } from 'src/ui';
var emojis = [
    '🤖',
    // woman shrugging
    '🤷🏻‍♀️',
    '🤷🏼‍♀️',
    '🤷🏽‍♀️',
    '🤷🏾‍♀️',
    '🤷🏿‍♀️',
    // person shrugging
    '🤷🏻',
    '🤷🏼',
    '🤷🏽',
    '🤷🏾',
    '🤷🏿',
    // man shrugging
    '🤷🏻‍♂️',
    '🤷🏼‍♂️',
    '🤷🏽‍♂️',
    '🤷🏾‍♂️',
    '🤷🏿‍♂️',
    // woman detective
    '🕵🏻‍♀️',
    '🕵🏼‍♀️',
    '🕵🏽‍♀️',
    '🕵🏾‍♀️',
    '🕵🏿‍♀️',
    // detective
    '🕵🏻',
    '🕵🏼',
    '🕵🏽',
    '🕵🏾',
    '🕵🏿',
    // man detective
    '🕵🏻‍♂️',
    '🕵🏼‍♂️',
    '🕵🏽‍♂️',
    '🕵🏾‍♂️',
    '🕵🏿‍♂️',
];
/**
 * Not found page, serving as 404 error catch-all
 *
 * TODO: Accompany with HTTP status code 404
 */
export default function NotFound() {
    var intl = useIntl();
    var random = useRef(Math.round(Math.random() * (emojis.length - 1)));
    var emoji = emojis[random.current];
    useBreadcrumbs([
        {
            href: '/',
            text: intl.formatMessage({
                defaultMessage: 'Page Not Found',
                id: 'Mz/gsa',
            }),
        },
        emoji === '🤖' && {
            href: '/',
            text: intl.formatMessage({
                defaultMessage: 'Bleep bloop',
                id: 'WENIln',
            }),
        },
    ].filter(Boolean));
    var user = useCurrentUserQuery();
    return (_jsx("main", { children: _jsxs("div", { children: [_jsx("div", __assign({ "aria-hidden": true, className: "float-left mr-5 flex h-16 w-16 items-center justify-center text-7xl" }, { children: emoji })), _jsx("h1", __assign({ className: "mb-1 text-3xl" }, { children: _jsx(FormattedMessage, { defaultMessage: "Page Not Found", id: "Mz/gsa" }) })), _jsx("p", __assign({ className: "indent-0" }, { children: _jsx(FormattedMessage, { defaultMessage: "The page you\u2019re trying to access was not found.", id: "a6Joxj" }) })), _jsx("p", __assign({ className: "indent-0" }, { children: user ? (_jsx(FormattedMessage, { defaultMessage: "You can {_} instead.", id: "zGHcmO", values: {
                            _: (_jsx(Link, __assign({ href: "/library" }, { children: _jsx(FormattedMessage, { defaultMessage: "go to your library", id: "jHE6Lc" }) }))),
                        } })) : (_jsx(FormattedMessage, { defaultMessage: "You can {_} instead.", id: "zGHcmO", values: {
                            _: (_jsx(Link, __assign({ href: "/library" }, { children: _jsx(FormattedMessage, { defaultMessage: "go to the library", id: "CKiTEp" }) }))),
                        } })) }))] }) }));
}
