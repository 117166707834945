import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useNextPage } from 'src/content/layout/pagination/useNextPage';
import { useIsChatModeActive, usePrefetch } from 'src/hooks';
import { ToolbarButton } from '../ToolbarButton';
import { ToolbarButtonSeparator } from '../ToolbarButtonSeparator';
export var NextPage = function () {
    var _a;
    var isChatModeActive = useIsChatModeActive();
    var url = ((_a = useNextPage()) !== null && _a !== void 0 ? _a : {}).url;
    var prefetch = usePrefetch(url);
    if (url == null || isChatModeActive) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ToolbarButtonSeparator, {}), _jsx(ToolbarButton, { href: url, icon: _jsx(FontAwesomeIcon, { fixedWidth: true, icon: faAngleRight }), onFocus: prefetch, onMouseOver: prefetch, tooltip: _jsx(FormattedMessage, { defaultMessage: "Next page", id: "rBj9Ib" }) })] }));
};
