var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { normalizeTableCells } from '@nextbook/shared';
import gql from 'graphql-tag';
import { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'src/content/inline/text/Text';
import { WideBlockContext } from 'src/context';
import { classNames, deltaToString } from 'src/functions';
import { isTableCell } from 'src/functions/typeGuards';
import { useBlocks, useSelectableRef } from 'src/hooks';
import { TableRow } from './TableRow';
var WIDE_BLOCK_THRESHOLD_WIDTH = 680;
var DEFAULT_COLUMN_WIDTH = 120;
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment table on Table {\n\t\tcaption {\n\t\t\t...richText\n\t\t}\n\t\tcolumnWidths\n\t\tfooterRows\n\t\theaderRows\n\t\tparentId\n\t\twide @client\n\t}\n\n\tfragment tableCell on TableCell {\n\t\tcolSpan\n\t\tparentId\n\t\trowSpan\n\t}\n"], ["\n\tfragment table on Table {\n\t\tcaption {\n\t\t\t...richText\n\t\t}\n\t\tcolumnWidths\n\t\tfooterRows\n\t\theaderRows\n\t\tparentId\n\t\twide @client\n\t}\n\n\tfragment tableCell on TableCell {\n\t\tcolSpan\n\t\tparentId\n\t\trowSpan\n\t}\n"])));
var isNotNullish = function (argument) {
    return argument != null;
};
/**
 * Table captions are positioned above the table.
 *
 * TODO: In summaries, the entire table is shown.
 */
export var Table = function (_a) {
    var caption = _a.caption, columnWidths = _a.columnWidths, id = _a.id, footerRows = _a.footerRows, headerRows = _a.headerRows;
    var blocks = useBlocks();
    var ref = useRef(null);
    var selectableRef = useSelectableRef(caption === null || caption === void 0 ? void 0 : caption.id);
    var TABLE_WIDTH = columnWidths
        .map(function (columnWidth) {
        return columnWidth === 0 ? DEFAULT_COLUMN_WIDTH : columnWidth;
    })
        .reduce(function (a, b) { return a + b; });
    var isWide = TABLE_WIDTH > WIDE_BLOCK_THRESHOLD_WIDTH;
    var wideBlockContext = useMemo(function () { return ({ appearsWithinWideBlock: isWide }); }, [isWide]);
    if (!blocks) {
        return null;
    }
    var tableCells = blocks
        .filter(isTableCell)
        .filter(function (block) { return block.parentId === id; });
    var NUMBER_OF_COLUMNS = columnWidths.length;
    /**
     * Array containing `tableCells`, with `null` placeholders following and/or
     * (diagonally) below cells with `colSpan` > 1 and/or `rowSpan` > 1. These
     * placeholders are positioned such that they fill in for the missing table
     * cells that have been merged into the column- and/or row-spanning cell, as
     * applicable.
     *
     * Array length is equal to the number of ‘equivalent’ cells if none of the
     * table cells would have been merged.
     */
    var normalizedTableCells = normalizeTableCells({
        numberOfColumns: NUMBER_OF_COLUMNS,
        tableCells: tableCells,
    });
    var NUMBER_OF_ROWS = normalizedTableCells.length / NUMBER_OF_COLUMNS;
    /**
     * Number of rows not in table header or footer `Math.round` should not be
     * necessary but will prevent a runtime error in case the database contains
     * incomplete/incorrect data
     */
    var bodyRows = Math.round(NUMBER_OF_ROWS - headerRows - footerRows);
    return (_jsx(WideBlockContext.Provider, __assign({ value: wideBlockContext }, { children: _jsx("div", __assign({ className: classNames('mx-auto my-3 rounded border border-neutral-250 p-1 text-left dark:border-gray-745', isWide && 'overflow-scroll'), style: isWide ? { gridArea: 'centeredContainer' } : undefined }, { children: _jsxs("table", __assign({ ref: ref, className: "border-collapse text-left", style: { emptyCells: 'show' } }, { children: [caption && (_jsxs("caption", __assign({ ref: selectableRef, className: "mb-6" }, { children: [tableCells.length > 1 &&
                                !['Kader', 'Tabel', 'Table', 'Tableau'].some(function (_) {
                                    return deltaToString(caption.delta).startsWith(_);
                                }) && (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { defaultMessage: "Table:", id: "9ZvIEP" }), ' '] })), _jsx(Text, __assign({}, caption))] }))), _jsx("colgroup", { children: columnWidths.map(function (width, index) { return (
                        // eslint-disable-next-line react/no-array-index-key
                        _jsx("col", { style: width === 0 ? undefined : { width: width } }, index)); }) }), headerRows > 0 && (_jsx("thead", __assign({ className: "rounded-sm bg-black/10 dark:bg-white/10" }, { children: __spreadArray([], __read(Array(headerRows)), false).map(function (_, index) { return (_jsx(TableRow, { parent: "thead", tableCells: normalizedTableCells
                                .slice(index * NUMBER_OF_COLUMNS, (index + 1) * NUMBER_OF_COLUMNS)
                                .filter(isNotNullish) // ignore empty placeholders due to cells with `colSpan` > 1 and/or `rowSpan` > 1
                         }, index)); }) }))), bodyRows > 0 && (_jsx("tbody", { children: __spreadArray([], __read(Array(bodyRows)), false).map(function (_, index, array) { return (_jsx(TableRow, { isLast: index === array.length - 1, parent: "tbody", tableCells: normalizedTableCells
                                .slice((headerRows + index) * NUMBER_OF_COLUMNS, (headerRows + index + 1) * NUMBER_OF_COLUMNS)
                                .filter(isNotNullish) // ignore empty placeholders due to cells with `colSpan` > 1 and/or `rowSpan` > 1
                         }, index)); }) })), footerRows > 0 && (_jsx("tfoot", { children: __spreadArray([], __read(Array(footerRows)), false).map(function (_, index) { return (_jsx(TableRow, { parent: "tfoot", tableCells: normalizedTableCells
                                .slice((headerRows + bodyRows + index) * NUMBER_OF_COLUMNS, (headerRows + bodyRows + index + 1) * NUMBER_OF_COLUMNS)
                                .filter(isNotNullish) // ignore empty placeholders due to cells with `colSpan` > 1 and/or `rowSpan` > 1
                         }, index)); }) }))] })) })) })));
};
var templateObject_1;
