var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var richTextTypeDef = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\textend type RichText {\n\t\t\"\"\"\n\t\t0-based sentence index, or null when the block is not being read out loud.\n\t\tSentences are derived from Delta, by splitting up the string by `. `, `? `\n\t\tand `! `.\n\t\t\"\"\"\n\t\tsentenceBeingSpoken: Int\n\t}\n"], ["\n\textend type RichText {\n\t\t\"\"\"\n\t\t0-based sentence index, or null when the block is not being read out loud.\n\t\tSentences are derived from Delta, by splitting up the string by \\`. \\`, \\`? \\`\n\t\tand \\`! \\`.\n\t\t\"\"\"\n\t\tsentenceBeingSpoken: Int\n\t}\n"])));
export var RichText = {
    fields: {
        richTextAnnotations: {
            /**
             * Custom merge function to prevent warning when e.g. deleting a Note
             *
             * TODO: Consider whether it’s a better practice to include merge logic
             * here and call e.g. `writeFragment` with an incoming annotations array.
             */
            merge: function (_existing, incoming) { return incoming; },
        },
        // Avoid replacing `0` (= falsy) with `null`
        sentenceBeingSpoken: function (_) { return (_ == null ? null : _); },
    },
    /**
     * If we can identify rich texts by their UUID only (instead of both their
     * `id` and `__typename`), it’s easier to update them via `writeFragment`. If
     * changes, keep in sync with all occurrences of `readFragment` and
     * `writeFragment`.
     */
    keyFields: ['id'],
};
var templateObject_1;
