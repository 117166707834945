var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isTitle } from 'src/functions/typeGuards';
/**
 * Nests blocks (titles as well as content items) inside their parent title
 * blocks. The `depth` field on title blocks gets replaced by an `offset` field,
 * which is equal to `depth` minus the nesting depth and minus the sum of the
 * `offset` value of all parent elements.
 *
 * This function can be called with an array of blocks, or an array of just
 * titles.
 *
 * @returns Titles blocks with recursively nested content and title blocks.
 */
export var nestContentInsideSections = function (
/** Titles and content blocks */
blocks) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix type
    return blocks
        .reduceRight(function (accumulator, currentValue) {
        if (isTitle(currentValue)) {
            var nextParentOrSiblingTitleIndex = accumulator.findIndex(function (block) { return isTitle(block) && block.offset <= currentValue.depth; });
            var children = nextParentOrSiblingTitleIndex === -1
                ? accumulator
                : accumulator.slice(0, nextParentOrSiblingTitleIndex);
            return __spreadArray([
                __assign(__assign({}, currentValue), { children: children.map(function (child) {
                        return isTitle(child)
                            ? __assign(__assign({}, child), { offset: child.offset - currentValue.depth - 1 }) : child;
                    }), offset: currentValue.depth })
            ], __read((nextParentOrSiblingTitleIndex === -1
                ? []
                : accumulator.slice(nextParentOrSiblingTitleIndex))), false);
        }
        return __spreadArray([currentValue], __read(accumulator), false);
    }, [])
        .map(function (block) {
        /**
         * Correct the `offset` value for chapters. Chapters have a depth of 1,
         * but shouldn’t have an offset, because they’re the root-level hierarchy
         * element in the blocks array. Returns block with revised offset, if
         * chapter.
         */
        return isTitle(block) ? __assign(__assign({}, block), { offset: block.offset - 1 }) : block;
    });
};
