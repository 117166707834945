"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commentSanitizer = void 0;
/**
 * Sanitizes comment text:
 *
 * - Removes surrounding whitespace
 * - Replaces successive line breaks with a single newline
 *
 * This file is shared between server and client.
 *
 * @returns Comment text, as saved
 */
const commentSanitizer = (
/** Comment text, as will be sent */
input) => input.trim().replace(/\n+/g, '\n');
exports.commentSanitizer = commentSanitizer;
