import { useCallback } from 'react';
import { isSpeakingVar } from 'src/cache/reactiveVars';
// TODO: Reset `sentenceBeingSpoken`
export var useStopSpeaking = function () {
    return useCallback(function () {
        isSpeakingVar(false);
        if (window.speechSynthesis != null) {
            window.speechSynthesis.cancel();
        }
    }, []);
};
