var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import gql from 'graphql-tag';
import { isTextInsert } from 'src/functions/typeGuards';
import { useSelectableRef } from 'src/hooks';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment codeblock on Codeblock {\n\t\tcode {\n\t\t\t...richText\n\t\t}\n\t\tlanguage\n\t\tparentId\n\t}\n"], ["\n\tfragment codeblock on Codeblock {\n\t\tcode {\n\t\t\t...richText\n\t\t}\n\t\tlanguage\n\t\tparentId\n\t}\n"
    /**
     * Syntax-highlighted code.
     *
     * TODO: Implement syntax highlighting
     */
])));
/**
 * Syntax-highlighted code.
 *
 * TODO: Implement syntax highlighting
 */
export var Codeblock = function (_a) {
    var code = _a.code;
    var selectableRef = useSelectableRef(code.id);
    return (_jsx("pre", __assign({ className: "my-3 -mx-3 p-3 text-sm" }, { children: _jsx("code", __assign({ ref: selectableRef }, { children: code.delta.ops
                .filter(isTextInsert)
                .map(function (op) { return op.insert; })
                .join('') })) })));
};
var templateObject_1;
