var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getRichTextAnnotations = function (
/** Discussions, highlights or notes */
richTextAnnotations, 
/** Cutoff points for inserts */
breakpoint, index, 
/** Breakpoints */
array) {
    return richTextAnnotations
        .map(function (richTextAnnotation) {
        var _a;
        return (__assign(__assign({}, richTextAnnotation), { length: richTextAnnotation.length -
                Math.max(0, breakpoint - richTextAnnotation.position) -
                Math.max(0, richTextAnnotation.length +
                    richTextAnnotation.position -
                    ((_a = array[index + 1]) !== null && _a !== void 0 ? _a : 0)), position: Math.max(0, richTextAnnotation.position - breakpoint) }));
    })
        .filter(function (_a) {
        var length = _a.length;
        return length > 0;
    });
};
