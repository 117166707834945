var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { CommentFragmentDoc, ToggleCommentLikeDocument, } from 'src/__generated__';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation ToggleCommentLike($input: ToggleCommentLikeInput!) {\n\t\ttoggleCommentLike(input: $input) {\n\t\t\t... on ToggleCommentLikePayload {\n\t\t\t\tlike\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation ToggleCommentLike($input: ToggleCommentLikeInput!) {\n\t\ttoggleCommentLike(input: $input) {\n\t\t\t... on ToggleCommentLikePayload {\n\t\t\t\tlike\n\t\t\t}\n\t\t\t... on Error {\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"])));
export var useToggleCommentLike = function (_a) {
    var id = _a.id, liked = _a.liked;
    var _b = __read(useMutation(ToggleCommentLikeDocument, {
        optimisticResponse: {
            __typename: 'Mutation',
            toggleCommentLike: {
                __typename: 'ToggleCommentLikePayload',
                like: !liked,
            },
        },
        /**
         * The update function is run twice: directly after the optimistic response,
         * and a second time after receiving fresh data from the server.
         *
         * @see {@link https://www.apollographql.com/docs/react/essentials/mutations.html}
         */
        update: function (
        /** The Apollo GraphQL store */
        cache, 
        /** New data from server */
        _a) {
            var data = _a.data;
            if ((data === null || data === void 0 ? void 0 : data.toggleCommentLike.__typename) !== 'ToggleCommentLikePayload') {
                return;
            }
            var oldComment = cache.readFragment({
                fragment: CommentFragmentDoc,
                id: "Comment:".concat(id), // TODO: Use `cache.identify`
            });
            if (!oldComment) {
                return;
            }
            if (oldComment.liked !== data.toggleCommentLike.like) {
                cache.writeFragment({
                    data: __assign(__assign({}, oldComment), { liked: data.toggleCommentLike.like }),
                    fragment: CommentFragmentDoc,
                    id: "Comment:".concat(id),
                });
            }
        },
        variables: { input: { id: id } },
    }), 1), toggleCommentLike = _b[0];
    return { toggleCommentLike: toggleCommentLike };
};
var templateObject_1;
