var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { deltaToString } from 'src/functions';
import { useBreadcrumbs, useParams } from 'src/hooks';
import { Link } from 'src/ui';
/** Fallback page when the user doesn’t have the required permissions */
export var PermissionDenied = function (_a) {
    var delta = _a.bookTitle.delta;
    var intl = useIntl();
    var _b = __read(useParams(), 2), mode = _b[0], book = _b[1];
    useBreadcrumbs([
        { href: "/".concat(mode, "/").concat(book), text: delta },
        {
            href: "/".concat(mode, "/").concat(book),
            text: intl.formatMessage({
                defaultMessage: 'Authentication Required',
                id: 'F5xt/F',
            }),
        },
    ]);
    return (_jsxs("main", { children: [_jsx("h1", { children: _jsx(FormattedMessage, { defaultMessage: "Authentication Required", id: "F5xt/F" }) }), _jsx("p", __assign({ className: "indent-0" }, { children: book != null && ['erfrecht'].includes(book) ? (_jsx(FormattedMessage, { defaultMessage: "Unfortunately, {book} is not available anymore.", id: "aYK+oT", values: {
                        book: _jsx("em", { children: deltaToString(delta) }),
                    } })) : (_jsx(FormattedMessage, { defaultMessage: "Send an email to {email} to get access to {book}, and we\u2019ll gladly help you further!", id: "S/Q+ik", values: {
                        book: _jsx("em", { children: deltaToString(delta) }),
                        email: (_jsx(Link, __assign({ href: "mailto:support@nextbook.io" }, { children: "support@nextbook.io" }))),
                    } })) }))] }));
};
