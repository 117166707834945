"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reconciliation = void 0;
const overlapping_1 = require("./overlapping");
/**
 * # Highlight reconciliation
 *
 * When a new highlight overlaps with an existing one, both highlights need to
 * be merged to meet user expectation. Merged highlights are saved as one and
 * act as one (e.g. you can only delete them as a whole).
 *
 * Highlights can span multiple pieces of rich text. Highlights that span an
 * entire list, for example, must remain easily deletable so we don’t want to
 * split them up per list item.
 *
 * The default highlight color for new visits to Nextbook is yellow. If the
 * color of a highlight gets changed, this new color becomes the default for new
 * high- lights. (Maybe we should save this as a user setting...) If the new
 * highlight overlaps, however, with an existing one, then the highlight color
 * is determined by the color(s) of the existing highlight(s) for visual
 * harmony.
 *
 * Overlapping highlights either (a) overlap with the starting point of an
 * existing highlight
 *
 *      e.g.:       ┣━━ existing ━┫
 *            ┣━━━━ new ━━━┫
 *
 *      or      ┣━━ existing ━┫
 *            ┣━━━━ new ━━━━━━━━━┫
 *
 * (b) overlap with the ending point of an existing highlight
 *
 *      e.g.: ┣━━━ existing ━━┫
 *                     ┣━━━ new ━━━━┫
 *
 *      or      ┣━━ existing ━┫
 *            ┣━━━━ new ━━━━━━━━━┫
 *
 * (c) occur completely within an existing highlight
 *
 *      e.g. ┣━━━━ existing ━━━━┫
 *       ┣━━ new ━┫
 *
 * Scenarios (a) and (b) are not mutually exclusive.
 *
 * @returns An object with three fields:
 *
 *   - `mergedParts`
 */
const reconciliation = ({ existingHighlightedRichTextParts = [], newRichTextParts, }) => {
    let mergedColor = null;
    let mergedParts = [];
    /**
     * Each highlight that (partially) overlaps with the new highlight must be
     * deleted (merge = extend new & delete old). Note that `deleteIds` contains
     * the **annotation** id’s, not the highlight id’s.
     */
    let deleteIds = []; // Id's of (e.g. overlapping) highlights that are to be deleted
    /**
     * Each `newRichTextPart` is the intersection of the selected text and a
     * content item.
     */
    newRichTextParts.forEach((newRichTextPart) => {
        var _a, _b, _c;
        /**
         * For each content item that is at least partially selected,
         * `additionalMergedParts` are the additional sections that overlap with,
         * and thus will belong to the new highlight. Note that some of these parts
         * can belong to content items that are not selected, and hence are not
         * included in `newRichTextParts`.
         */
        let additionalMergedParts = [];
        let additionalDeleteIds = [];
        const overlapped = (0, overlapping_1.overlapping)(existingHighlightedRichTextParts, newRichTextPart);
        if (overlapped.length) {
            const leftBound = Math.min(
            // Either leftmost bound of all overlapped highlights
            Math.min(...overlapped.map((part) => part.position)), 
            // Or left bound of selection
            newRichTextPart.position);
            const rightBound = Math.max(
            // Either rightmost end point of all overlapped highlights
            Math.max(...overlapped.map((part) => part.position + part.length)), 
            // Or, if entirely overlapping, right bound of selection
            newRichTextPart.position + newRichTextPart.length);
            mergedColor = (_c = (_b = (_a = overlapped[0]) === null || _a === void 0 ? void 0 : _a.annotation) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : null; // Adapt color from leftmost overlapped highlight
            additionalMergedParts.push(Object.assign(Object.assign({}, newRichTextPart), { length: rightBound - leftBound, position: leftBound }));
            /*
             * Overlapping highlight parts can belong to a highlight that spans
             * multiple content items. These adjacent highlight parts need to be
             * joined as well.
             */
            const collateral = existingHighlightedRichTextParts.filter((p) => 
            /*
             * For each highlight part that's already being displayed, we check if
             * we can find an overlapped highlight part (i.e. a highlight part that's
             * at least partially selected) with the same annotation id.
             */
            overlapped.find((o) => { var _a; return p.annotation != null && p.annotation.id === ((_a = o.annotation) === null || _a === void 0 ? void 0 : _a.id); }) !== undefined);
            additionalMergedParts = additionalMergedParts.concat(collateral.filter((c) => 
            /*
             * Only include adjacent highlight parts that are not also added as a
             * newRichTextPart ...
             */
            (0, overlapping_1.overlapping)(newRichTextParts, c).length === 0 &&
                // ... or were not already added in a previous iteration
                (0, overlapping_1.overlapping)(mergedParts, c).length === 0));
            additionalDeleteIds = [
                ...overlapped.map((_) => { var _a; return (_a = _.annotation) === null || _a === void 0 ? void 0 : _a.id; }),
                ...collateral.map((_) => { var _a; return (_a = _.annotation) === null || _a === void 0 ? void 0 : _a.id; }),
            ].filter((_) => typeof _ === 'string');
        }
        if (additionalMergedParts.length === 0) {
            mergedParts.push(newRichTextPart);
        }
        else {
            mergedParts = mergedParts.concat(additionalMergedParts);
        }
        deleteIds = deleteIds.concat(additionalDeleteIds);
    });
    // consider using { uniq } from 'ramda'
    deleteIds = [...new Set(deleteIds)]; // unique values
    return {
        deleteIds,
        mergedColor,
        mergedParts,
    };
};
exports.reconciliation = reconciliation;
