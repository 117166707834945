var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@private/fontawesome/pro-solid-svg-icons/faHeart';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { classNames } from 'src/functions';
import { useToggleCommentLike } from './useToggleCommentLike';
export var LikeButton = function (_a) {
    var id = _a.id, liked = _a.liked, reply = _a.reply, className = _a.className;
    var intl = useIntl();
    var preventAnimationTimeout = useRef();
    // const mount = useRef(true)
    /**
     * When unliking a comment, the heart icon should become grey immediately,
     * i.e. before mouseout. For this we use the `forceUnlikeColor` variable,
     * which is set to `true` on unlike and `false` on mouseout.
     */
    var _b = __read(useState(false), 2), forceUnlikeColor = _b[0], setForceUnlikeColor = _b[1];
    /**
     * We don’t use state for `preventLikeAnimation` because we don’t want the
     * component to rerender when we change the value
     */
    var preventLikeAnimation = useRef(true);
    /**
     * When the user clicks the heart icon, the “liked” status of the comment gets
     * toggled.
     *
     * - If the comment wasn’t liked before, the heart icon becomes red on
     *   mouseover. When clicked, an animation plays and the heart icon is colored
     *   red. Because React components get rerendered on state change, we have to
     *   ensure that this doesn’t interrupt the animation.
     * - If the comment was liked before, the heart icon turns gray. This must
     *   override the red color on mouseover to show the status change when the
     *   user has unliked a comment but the mouse is still hovering the icon.
     */
    var toggleCommentLike = useToggleCommentLike({ id: id, liked: liked }).toggleCommentLike;
    var handleMouseDown = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var ANIMATION_DURATION;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.clearTimeout(preventAnimationTimeout.current);
                    preventLikeAnimation.current = false;
                    setForceUnlikeColor(liked);
                    ANIMATION_DURATION = 1000;
                    preventAnimationTimeout.current = window.setTimeout(function () {
                        preventLikeAnimation.current = true;
                    }, ANIMATION_DURATION);
                    return [4 /*yield*/, toggleCommentLike()];
                case 1:
                    _a.sent();
                    if (event) {
                        event.preventDefault();
                    } // prevents blue focus outline (element is still accessible via tab)
                    return [2 /*return*/];
            }
        });
    }); }, [liked, toggleCommentLike]);
    var handleMouseLeave = useCallback(function () {
        preventLikeAnimation.current = true;
        /*
         * Don’t check `forceUnlikeColor` via `prevState` as this will cause the
         * heart animation to play onMouseLeave.
         */
        if (forceUnlikeColor) {
            setForceUnlikeColor(false);
        }
    }, [forceUnlikeColor]);
    var handleKeyDown = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = event.key;
                    switch (_a) {
                        case 'Enter': return [3 /*break*/, 1];
                        case ' ': return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, handleMouseDown()];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, handleMouseDown()];
                case 4:
                    _b.sent();
                    event.preventDefault();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [handleMouseDown]);
    var LikesCount = useCallback(function () {
        return null;
    }, []);
    var random = Math.random();
    return (_jsx("div", __assign({ className: classNames("absolute -mx-2 -mt-1 box-content h-4 w-4 cursor-pointer select-none rounded-full p-1.5 text-center text-xs transition-all hover:scale-110 ".concat(forceUnlikeColor
            ? 'hover:scale-100 hover:text-neutral-350 dark:hover:text-white/30'
            : liked
                ? 'scale-110 text-red-heart hover:text-red-heart active:text-red-heart'
                : 'text-neutral-350 hover:text-red-heart dark:text-white/30 dark:hover:text-neutral-220'), liked &&
            !preventLikeAnimation.current &&
            (random < 1 / 3
                ? 'animate-heart-spin-reverse'
                : random < 2 / 3
                    ? 'animate-heart-spin'
                    : 'animate-heart-pulse'), className), onKeyDown: handleKeyDown, onMouseDown: handleMouseDown, onMouseLeave: handleMouseLeave, role: "button", tabIndex: 0, title: intl.formatMessage({ defaultMessage: 'Like', id: 'qtWLmt' }) }, { children: reply ? (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: faHeart }), _jsx(LikesCount, {})] })) : (_jsxs(_Fragment, { children: [_jsx(LikesCount, {}), _jsx(FontAwesomeIcon, { icon: faHeart })] })) })));
};
