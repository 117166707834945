var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import { map } from 'ramda';
import { DeleteHighlightDocument } from 'src/__generated__';
import { writeRichTextFragment } from 'src/content/inline/text/writeRichTextFragment';
import { isRichText } from 'src/functions/typeGuards';
import { useBookQuery } from 'src/hooks';
export var useDeleteHighlight = function (_a) {
    var _b;
    var highlightId = _a.highlightId;
    var blocks = ((_b = useBookQuery()) !== null && _b !== void 0 ? _b : {}).blocks;
    var _c = __read(useMutation(DeleteHighlightDocument, {
        optimisticResponse: {
            __typename: 'Mutation',
            deleteHighlight: {
                __typename: 'DeleteHighlightPayload',
                deleted: true,
            },
        },
        update: function (cache, _a) {
            var data = _a.data;
            if ((data === null || data === void 0 ? void 0 : data.deleteHighlight.__typename) !== 'DeleteHighlightPayload') {
                return;
            }
            blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                // `map` from Ramda (below) is applied to an object:
                map(function (value) {
                    if (isRichText(value)) {
                        if (value.richTextAnnotations.find(function (_a) {
                            var id = _a.annotation.id;
                            return id === highlightId;
                        })) {
                            writeRichTextFragment({
                                cache: cache,
                                data: __assign(__assign({}, value), { richTextAnnotations: value.richTextAnnotations.filter(function (_a) {
                                        var id = _a.annotation.id;
                                        return id !== highlightId;
                                    }) }),
                            });
                        }
                    }
                }, block);
            });
        },
        variables: { input: { id: highlightId } },
    }), 1), deleteHighlight = _c[0];
    return deleteHighlight;
};
