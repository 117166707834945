var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { map } from 'ramda';
import { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateDiscussionDocument } from 'src/__generated__';
import { lightboxVar } from 'src/cache/reactiveVars';
import { DEFAULT_DISCUSSION_HEIGHT } from 'src/cache/typePolicies/Discussion';
import { OPTIMISTIC_COMMENT_ID, OPTIMISTIC_DISCUSSION_ID } from 'src/constants';
import { addAnnotationToRichText } from 'src/content/inline/text/functions/addAnnotationToRichText';
import { writeRichTextFragment } from 'src/content/inline/text/writeRichTextFragment';
import { isRichText } from 'src/functions/typeGuards';
import { useBookQuery, useCurrentUserQuery, useOverlay, useParams, } from 'src/hooks';
import { NotLoggedIn } from 'src/ui/lightbox/NotLoggedIn';
import { useGetRichTextParts } from 'src/ui/popup/selectionButtons/useGetRichTextParts';
import { getSelectedRange } from './functions';
import { NotInGroupErrorMessage } from './NotInGroupErrorMessage';
export var useCreateDiscussion = function () {
    var _a, _b, _c, _d, _e, _f;
    var dimensions = useRef((_a = getSelectedRange()) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect());
    var discussionClientFields = {
        height: DEFAULT_DISCUSSION_HEIGHT,
        left: ((_c = (_b = dimensions.current) === null || _b === void 0 ? void 0 : _b.left) !== null && _c !== void 0 ? _c : 0) + window.scrollX,
        maxBottom: Infinity,
        minTop: ((_e = (_d = dimensions.current) === null || _d === void 0 ? void 0 : _d.top) !== null && _e !== void 0 ? _e : 0) + window.scrollY,
        get top() {
            return this.minTop;
        },
    };
    var blocks = ((_f = useBookQuery()) !== null && _f !== void 0 ? _f : {}).blocks;
    var currentUser = useCurrentUserQuery();
    var getRichTextParts = useGetRichTextParts();
    var navigate = useNavigate();
    var _g = __read(useSearchParams(), 1), searchParams = _g[0];
    var discussionId = searchParams.get('conversation');
    var isChatModeActive = discussionId != null;
    var params = useParams();
    var shouldRedirect = useOverlay();
    var richTextAnnotations = getRichTextParts();
    var _h = __read(useMutation(CreateDiscussionDocument, {
        optimisticResponse: {
            __typename: 'Mutation',
            createDiscussion: {
                __typename: 'CreateDiscussionPayload',
                discussion: __assign(__assign({}, discussionClientFields), { __typename: 'Discussion', comments: [
                        {
                            __typename: 'Comment',
                            anonymous: false,
                            byCoordinator: false,
                            id: OPTIMISTIC_COMMENT_ID,
                            liked: false,
                            likesCount: 0,
                            sent: false,
                            text: '',
                            user: null, // Don’t include here (not shown for unsent comments)
                        },
                    ], id: OPTIMISTIC_DISCUSSION_ID, richTextAnnotations: richTextAnnotations.map(function (richTextAnnotation) {
                        return (__assign(__assign({}, richTextAnnotation), { __typename: 'RichTextAnnotation', id: Math.random().toString(), richText: {
                                __typename: 'RichText',
                                id: richTextAnnotation.richTextId,
                            } }));
                    }) }),
            },
        },
        update: function (cache, _a) {
            var data = _a.data;
            if (data == null) {
                return;
            }
            switch (data.createDiscussion.__typename) {
                case 'InvalidInputError':
                case 'NotFoundError':
                    return;
                case 'NotAllowedError':
                    lightboxVar(currentUser == null ? _jsx(NotLoggedIn, {}) : _jsx(NotInGroupErrorMessage, {}));
                    return;
                case 'CreateDiscussionPayload': {
                    var discussion_1 = data.createDiscussion.discussion;
                    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
                        // `map` from Ramda (below) is applied to a `block` object:
                        return map(function (value) {
                            if (!isRichText(value)) {
                                return;
                            }
                            var newRichText = addAnnotationToRichText({
                                annotation: __assign(__assign({}, discussion_1), discussionClientFields),
                                optimisticId: OPTIMISTIC_DISCUSSION_ID,
                                richText: value,
                            });
                            if (newRichText) {
                                writeRichTextFragment({ cache: cache, data: newRichText });
                            }
                        }, block);
                    });
                    var SIDEBAR_RERENDER_DELAY = 300; // milliseconds
                    if (((shouldRedirect && !isChatModeActive) ||
                        // Navigate to different chat page if discussion was created in chat mode
                        (isChatModeActive && discussionId !== discussion_1.id)) &&
                        discussion_1.id !== OPTIMISTIC_DISCUSSION_ID) {
                        // After the cache has been updated:
                        navigate("/".concat(params.join('/'), "?conversation=").concat(discussion_1.id));
                    }
                    window.setTimeout(function () {
                        var _a;
                        var textarea = document.getElementById("comment-".concat((_a = discussion_1.comments[0]) === null || _a === void 0 ? void 0 : _a.id));
                        if (textarea) {
                            textarea.focus();
                        }
                    }, SIDEBAR_RERENDER_DELAY);
                }
            }
        },
    }), 1), createDiscussion = _h[0];
    return createDiscussion;
};
