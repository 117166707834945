var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { classNames } from 'src/functions';
export var Card = function (_a) {
    var _b = _a.isDefault, isDefault = _b === void 0 ? false : _b, children = _a.children, className = _a.className, checked = _a.checked, handleChange = _a.onChange, value = _a.value, name = _a.name, style = _a.style, title = _a.title;
    var id = useId();
    return (_jsxs("label", __assign({ className: classNames('flex grow select-none', className), htmlFor: id, style: style }, { children: [_jsx("input", { checked: checked, className: "peer sr-only -ml-8 mr-4 inline-block whitespace-nowrap", id: id, name: name, onChange: handleChange, type: "radio", value: value }), _jsxs("div", __assign({ className: "relative box-border h-full w-full cursor-pointer rounded-lg border border-neutral-250 bg-neutral-70 px-5 pt-3.5 pb-6 text-sm font-normal text-neutral-750 shadow-md peer-checked:cursor-default peer-checked:border-transparent peer-checked:ring-3 peer-checked:ring-blue-checked dark:border-neutral-600 dark:bg-gray-780 dark:font-light dark:text-neutral-250" }, { children: [_jsx("strong", __assign({ className: "mb-1 inline-block align-middle font-sans text-base font-bold lowercase small-caps" }, { children: title })), isDefault && (_jsx("code", __assign({ className: "relative -top-px ml-2 inline-block rounded-md bg-black/7 px-1.5 font-sans text-xs font-light text-neutral-750 mix-blend-darken dark:bg-white/9 dark:text-neutral-250 dark:mix-blend-lighten" }, { children: _jsx(FormattedMessage, { defaultMessage: "default", id: "FiXNt1" }) }))), children] }))] })));
};
