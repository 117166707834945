"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClosestParent = void 0;
/**
 * Returns the parent element that matches a certain selector.
 *
 * @deprecated Use ref and `Node.contains()` instead
 * @returns Closest parent element, if any
 * @see {@link https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript}
 */
const getClosestParent = (
/** DOM element, e.g. `event.target` */
element, 
/** E.g. `.c` to match against a class named `c` */
selector) => {
    // Get the closest matching element
    for (let e = element; e && e instanceof Element; e = e.parentElement) {
        if (e.matches(selector)) {
            return e;
        }
    }
    return undefined;
};
exports.getClosestParent = getClosestParent;
