import { isTextInsert } from './typeGuards';
/**
 * Converts a Delta object to a string representation of the same text.
 *
 * @returns The string representation of the Delta
 */
export var deltaToString = function (input) {
    return input.ops
        .filter(isTextInsert)
        .map(function (op) { return op.insert; })
        .join('');
};
