"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lastName = void 0;
const lastName = (
/** The last name, to be evaluated */
input) => {
    if (input.trim().length > 0) {
        // Anything goes
    }
    else {
        return 'LastNameEmpty';
    }
    return null;
};
exports.lastName = lastName;
