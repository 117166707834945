var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import gql from 'graphql-tag';
import { equals } from 'ramda';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { hasNavVar, modeVar, showingCommentsVar, showingNotesVar, } from 'src/cache/reactiveVars';
import { SectionContext } from 'src/content/layout/context';
import { WideBlockContext } from 'src/context';
import { classNames } from 'src/functions';
import { isDiscussion, isHighlight, isNote, isSidebarAnnotation, } from 'src/functions/typeGuards';
import { useParams } from 'src/hooks';
import { Link } from 'src/ui';
import { Highlight } from './Highlight';
import { useSetAnnotationPosition } from './hooks/useSetAnnotationPosition';
import { useToggleAtBottomOfPage } from './hooks/useToggleAtBottomOfPage';
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment richTextAnnotation on RichTextAnnotation {\n\t\tannotation {\n\t\t\t...discussion\n\t\t\t...highlight\n\t\t\t...note\n\t\t}\n\t\tid\n\t\tlength\n\t\tposition\n\t}\n"], ["\n\tfragment richTextAnnotation on RichTextAnnotation {\n\t\tannotation {\n\t\t\t...discussion\n\t\t\t...highlight\n\t\t\t...note\n\t\t}\n\t\tid\n\t\tlength\n\t\tposition\n\t}\n"])));
/** Highlights, and underlined texts that belongs to notes and comments */
export var Annotation = function (_a) {
    var annotations = _a.annotations, children = _a.children, hasHyperlink = _a.hasHyperlink;
    /**
     * Store dimensions that have been written to the store, to prevent duplicate
     * work
     */
    var dimensions = useRef(null);
    var mode = useReactiveVar(modeVar);
    var params = useParams();
    var showingComments = useReactiveVar(showingCommentsVar);
    var showingNotes = useReactiveVar(showingNotesVar);
    var spanRef = useRef(null);
    var aRef = useRef(null);
    var setAnnotationPosition = useSetAnnotationPosition();
    var toggleAtBottomOfPage = useToggleAtBottomOfPage();
    var sectionRef = useContext(SectionContext);
    useEffect(function () {
        var _a;
        var current = (_a = aRef.current) !== null && _a !== void 0 ? _a : spanRef.current;
        if (!current || !sectionRef.current) {
            return;
        }
        // `sectionRef` is undefined for sidebar annotations on wide content
        var bottom = sectionRef.current.getBoundingClientRect().bottom;
        var _b = current.getBoundingClientRect(), left = _b.left, top = _b.top;
        annotations.filter(isSidebarAnnotation).forEach(function (annotation) {
            var pageYOffset = window.pageYOffset; // inside timeout
            var input = {
                id: annotation.id,
                left: Math.round(left),
                /**
                 * The maximum vertical position that an annotation can extend to,
                 * because there is wide content below this point.
                 */
                maxBottom: Math.round(bottom + pageYOffset),
                /**
                 * `<main />` is the first parent element with `position: relative` and
                 * hence serves as the reference point. So we need to subtract the
                 * distance between the top of the `<main />` tag and the top of the
                 * page.
                 *
                 * See src/content/layout/styles
                 */
                minTop: Math.round(top + pageYOffset),
                params: params,
            };
            if (annotation.displayInSidebar &&
                !equals(dimensions.current, input) // check object equivalency
            ) {
                dimensions.current = input;
                setAnnotationPosition(input);
            }
        });
    }, [annotations, params, sectionRef, setAnnotationPosition]);
    var discussions = annotations.filter(function (a) { return isDiscussion(a) && a.comments.length; });
    var highlights = useMemo(function () {
        return annotations.filter(isHighlight);
    }, [annotations]);
    var notes = annotations.filter(isNote);
    /**
     * Checks whether the underlined (annotated) text is clickable.
     *
     * - For wide content, the underlined text is clickable and toggles the note in
     *   and out of view at the bottom of the page — because there is no sidebar
     *   to the right.
     * - For narrow content, the underlined text is clickable at small window widths
     *   (when no sidebar is present) and vice versa
     *
     * We use a bit of a “hacky” solution (using a CSS declaration) so that we
     * don’t have to implement a logic based on multiple class name and window
     * width combinations.
     *
     * TODO: Use `{ useContext } from 'react'` instead
     */
    var targetIsActive = useCallback(function (target) {
        return window.getComputedStyle(target).cursor === 'pointer';
    }, []);
    var handleKeyDown = useCallback(function (event) {
        if (targetIsActive(event.currentTarget)) {
            var note = notes[0];
            if (note == null) {
                return;
            }
            var id = note.id;
            switch (event.key) {
                case 'Enter':
                    toggleAtBottomOfPage({ id: id });
                    break;
                case ' ':
                    toggleAtBottomOfPage({ id: id });
                    event.preventDefault();
                    break;
                default:
            }
        }
    }, [notes, targetIsActive, toggleAtBottomOfPage]);
    var handleMouseDown = useCallback(function (event) {
        if (targetIsActive(event.currentTarget)) {
            var note = notes[0];
            if (note == null) {
                return;
            }
            var id = note.id;
            toggleAtBottomOfPage({ id: id });
            event.preventDefault(); // prevent selection (on doubleclick) & focus outline
        }
    }, [notes, targetIsActive, toggleAtBottomOfPage]);
    // eslint-disable-next-line react/jsx-no-useless-fragment -- TypeScript limitation
    var output = _jsx(_Fragment, { children: children });
    var _b = __read(useSearchParams(), 1), searchParams = _b[0];
    var discussionId = searchParams.get('conversation');
    var isChatModeActive = discussionId != null;
    var appearsWithinWideBlock = useContext(WideBlockContext).appearsWithinWideBlock;
    var hasNav = useReactiveVar(hasNavVar);
    if (highlights.length) {
        var highlight = highlights[0];
        if (highlight != null) {
            var color = highlight.color, id = highlight.id;
            output = (_jsx(Highlight, __assign({ color: color, hasHyperlink: hasHyperlink, 
                // keyboardFocusTarget={keyboardFocusTarget}
                id: id }, { children: output })));
        }
    }
    if (discussions.length && showingComments) {
        var discussion = discussions[0];
        if (discussion != null) {
            // in chat mode, discussion takes precedence to determine the underline color
            if (discussion.id === discussionId) {
                output = (_jsx("span", __assign({ ref: spanRef, 
                    // only add this onClick attribute below a certain window width:
                    className: classNames(
                    // in chat mode, discussion takes precedence to determine the underline color
                    'cursor-pointer text-inherit underline decoration-[5px] underline-offset-4 dark:decoration-2', isChatModeActive &&
                        !discussions.find(function (_a) {
                            var id = _a.id;
                            return id === discussionId;
                        })
                        ? 'decoration-blue-subtle/25 dark:decoration-blue-strong/25'
                        : 'decoration-blue-subtle dark:decoration-blue-strong') }, { children: output })));
            }
            else {
                output = (_jsx(Link, __assign({ ref: aRef, className: classNames(
                    // in chat mode, discussion takes precedence to determine the underline color
                    'cursor-pointer border-none text-inherit underline decoration-[5px] underline-offset-4 dark:decoration-2', isChatModeActive &&
                        !discussions.find(function (_a) {
                            var id = _a.id;
                            return id === discussionId;
                        })
                        ? 'decoration-blue-subtle/25 dark:decoration-blue-strong/25'
                        : 'decoration-blue-subtle dark:decoration-blue-strong'), href: "?conversation=".concat(discussion.id) }, { children: output })));
            }
        }
    }
    else if (notes.length && showingNotes) {
        output = (_jsx("span", __assign({ className: classNames('cursor-pointer underline decoration-yellow-subtle decoration-[5px] underline-offset-4 dark:decoration-yellow-strong dark:decoration-2', 
            // Notes in a wide block must always be clickable, because they don’t
            // have a corresponding sidebar, and always show up on the bottom.
            !appearsWithinWideBlock &&
                (hasNav
                    ? 'normalwithtoc:cursor-auto'
                    : 'compactwithouttoc:cursor-auto')) }, (isChatModeActive || mode != null
            ? {} // disable note click action when a conversation is open
            : {
                onKeyDown: handleKeyDown,
                onMouseDown: handleMouseDown,
                role: 'button',
                tabIndex: 0,
            }), { ref: spanRef }, { children: output })));
    }
    return output;
};
var templateObject_1;
