"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirmPassword = void 0;
const confirmPassword = (
/** Password confirmation field */
input, 
/** Password field */
compare) => {
    if (input) {
        if (compare !== input) {
            return 'ConfirmPasswordDoesntMatch';
        }
    }
    else {
        return 'ConfirmPasswordEmpty';
    }
    return null;
};
exports.confirmPassword = confirmPassword;
