var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { classNames } from 'src/functions';
/**
 * @deprecated Use `{ Button } from 'src/ui/form/Button'` instead and rename
 *   that one to this one after removing all uses of this one
 */
export var Button = function (_a) {
    var children = _a.children, href = _a.href, _b = _a.red, red = _b === void 0 ? false : _b, onClick = _a.onClick;
    if (href != null) {
        return (_jsx(Link, __assign({ className: classNames(red && 'shadow dark:shadow-none', 'cursor-default select-none'), onClick: onClick, to: href }, { children: children })));
    }
    return (_jsx("button", __assign({ className: classNames(red &&
            'border-0 border-b-2 border-neutral-310 bg-red-accent/80 text-white dark:border dark:border-red-strong dark:bg-transparent dark:text-red-strong', 'select-none shadow dark:shadow-none'), onClick: onClick, type: "submit" }, { children: children })));
};
