var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@private/fontawesome/pro-regular-svg-icons/faEnvelope';
import { faExternalLink } from '@private/fontawesome/pro-regular-svg-icons/faExternalLink';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useStopSpeaking } from 'src/ui/toolbar/textToSpeech/hooks';
export var Link = forwardRef(function (_a, ref) {
    var children = _a.children, hideExternalLinkIcon = _a.hideExternalLinkIcon, href = _a.href, onClick = _a.onClick, onMouseDown = _a.onMouseDown, target = _a.target, passThroughProps = __rest(_a, ["children", "hideExternalLinkIcon", "href", "onClick", "onMouseDown", "target"]);
    var EMAIL = href.startsWith('mailto:');
    var EXTERNAL = href.startsWith('http');
    var stopSpeaking = useStopSpeaking();
    var handleClick = useCallback(function (event) {
        var newTab = event.ctrlKey || event.metaKey || event.shiftKey;
        if (onClick) {
            onClick(event);
        }
        if (!newTab) {
            if (href.startsWith('#')) {
                scroller.scrollTo(href.substring(1), { smooth: 'easeOutQuint' });
            }
            else {
                stopSpeaking();
                window.scrollTo(0, 0); // Make aware of horizontal scroll position?
            }
        }
        if (document.activeElement instanceof HTMLElement &&
            document.activeElement.blur != null // IE11 doesn’t support blur method
        ) {
            // BUG: Should not scroll to top on Ctrl + click (Windows) / Cmd + click (Mac)
            document.activeElement.blur();
            // So that tabbing doesn’t continue where it left off (this could make the page
            /// jump down unexpectedly, e.g. when the next focus is on an element in the footer)
        }
    }, [href, onClick, stopSpeaking]);
    var aProps = __assign(__assign({ href: href, ref: ref }, (EXTERNAL ? { rel: 'nofollow', target: '_blank' } : {})), { className: 'decoration-inherit' });
    var linkProps = {
        onClick: handleClick,
        onMouseDown: onMouseDown,
        ref: ref,
        target: target,
        to: href,
    };
    // TODO: consider after:content-['_↗']
    return EMAIL || EXTERNAL ? (_jsxs("a", __assign({}, passThroughProps, aProps, { children: [EMAIL && (_jsx(FormattedMessage, __assign({ defaultMessage: "Launch Email Client", id: "yq1Ozs" }, { children: function (_a) {
                    var _b = __read(_a, 1), _msg = _b[0];
                    return (_jsx(FontAwesomeIcon, { className: "mr-1 text-sm", icon: faEnvelope }));
                } }))), children, EXTERNAL && hideExternalLinkIcon !== true && (_jsx(FormattedMessage, __assign({ defaultMessage: "External Link", id: "/dzcE1" }, { children: function (_a) {
                    var _b = __read(_a, 1), _msg = _b[0];
                    return (_jsx(FontAwesomeIcon, { className: "ml-1 pb-0.5 text-sm", icon: faExternalLink }));
                } })))] }))) : (_jsx(ReactRouterDomLink, __assign({}, passThroughProps, linkProps, { children: children })));
});
Link.displayName = 'Link';
