var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@private/fontawesome/pro-solid-svg-icons/faUserShield';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { useCurrentUserQuery, useParams } from 'src/hooks';
import { Button } from './Button';
/** Link to the admin page for the current book */
export var AdminButton = forwardRef(function (_a, ref) {
    var onKeyDown = _a.onKeyDown;
    var _b = __read(useParams(), 2), mode = _b[0], book = _b[1];
    var currentUser = useCurrentUserQuery();
    if (mode != null &&
        ['book', 'edit', 'settings', 'summary'].includes(mode) &&
        (currentUser === null || currentUser === void 0 ? void 0 : currentUser.role) === 'Administrator') {
        return (_jsx(Routes, { children: _jsx(Route, { element: _jsx(Button, { ref: ref, href: "/admin/book/".concat(book), icon: _jsx(FontAwesomeIcon, { icon: faUserShield }), label: _jsx(FormattedMessage, { defaultMessage: "Admin", id: "iHN12u" }), onKeyDown: onKeyDown }), path: "/:mode/:book/*" }) }));
    }
    return null;
});
AdminButton.displayName = 'AdminButton';
