var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMedia } from 'react-use';
import { UpdatePreferenceDocument } from 'src/__generated__';
import { LanguageContext } from 'src/context';
import { useCurrentUserQuery } from 'src/hooks';
export var Color = function () {
    var _a;
    var language = useContext(LanguageContext).language;
    var _b = __read(useMutation(UpdatePreferenceDocument), 1), updateColor = _b[0];
    var preference = ((_a = useCurrentUserQuery()) !== null && _a !== void 0 ? _a : {}).preference;
    /** @returns Optimistic response */
    var settings = useCallback(function (
    /** New color setting */
    value) { return ({
        optimisticResponse: preference
            ? {
                __typename: 'Mutation',
                updatePreference: {
                    __typename: 'UpdatePreferencePayload',
                    preference: __assign(__assign({}, preference), { color: value }),
                },
            }
            : undefined,
        variables: { input: { color: value } },
    }); }, [preference]);
    var prefersDark = useMedia('(prefers-color-scheme: dark)');
    return (_jsxs("fieldset", __assign({ className: "flex flex-wrap items-stretch justify-between gap-3" }, { children: [_jsx("legend", { children: _jsx(FormattedMessage, { defaultMessage: "Color Theme", id: "dmbA6n" }) }), _jsxs("label", __assign({ className: "flex grow basis-5/12 select-none", htmlFor: "color-default" }, { children: [_jsx("input", { checked: (preference === null || preference === void 0 ? void 0 : preference.color) == null || preference.color === 'Default', className: "peer sr-only -ml-8 mr-4 inline-block whitespace-nowrap", id: "color-default", name: "color", onChange: function (_a) {
                            var value = _a.target.value;
                            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                                return [2 /*return*/, updateColor(settings(value))];
                            }); });
                        }, type: "radio", value: "Default" }), _jsxs("div", __assign({ className: "relative box-border h-full cursor-pointer rounded-lg border px-5 pt-3.5 pb-6 text-sm shadow-md peer-checked:cursor-default peer-checked:border-transparent peer-checked:ring-3 peer-checked:ring-blue-checked ".concat(prefersDark
                            ? 'border-neutral-600 bg-gray-780 font-light text-neutral-250'
                            : 'border-neutral-250 bg-neutral-70 font-normal text-neutral-750') }, { children: [_jsx("strong", __assign({ className: "mb-1 inline-block align-middle text-base font-bold lowercase small-caps" }, { children: _jsx(FormattedMessage, { defaultMessage: "Automatic", id: "2zQt+3" }) })), _jsx("code", __assign({ className: "relative -top-px ml-2 inline-block rounded-md px-1.5 font-sans text-xs font-light ".concat(prefersDark
                                    ? 'bg-white/9 text-neutral-250 mix-blend-lighten'
                                    : 'bg-black/7 text-neutral-750 mix-blend-darken dark:bg-black/7 dark:text-neutral-750 dark:mix-blend-darken') }, { children: _jsx(FormattedMessage, { defaultMessage: "default", id: "FiXNt1" }) })), _jsx("p", __assign({ className: "text-left leading-5" }, { children: _jsx(FormattedMessage, { defaultMessage: "Automatically select a color theme based on your operating system setting.", id: "eg/UZE" }) }))] }))] })), _jsxs("label", __assign({ className: "flex grow basis-5/12 select-none", htmlFor: "color-light" }, { children: [_jsx("input", { checked: (preference === null || preference === void 0 ? void 0 : preference.color) === 'Light', className: "peer sr-only -ml-8 mr-4 inline-block whitespace-nowrap", id: "color-light", name: "color", onChange: function (_a) {
                            var value = _a.target.value;
                            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                                return [2 /*return*/, updateColor(settings(value))];
                            }); });
                        }, type: "radio", value: "Light" }), _jsxs("div", __assign({ className: "relative box-border flex h-full cursor-pointer flex-col justify-between rounded-lg border border-neutral-250 bg-neutral-70 px-5 pt-3.5 pb-6 text-sm font-normal text-neutral-750 shadow-md peer-checked:cursor-default peer-checked:border-transparent peer-checked:ring-3 peer-checked:ring-blue-checked dark:border-neutral-500" }, { children: [_jsxs("div", { children: [_jsx("strong", __assign({ className: "mb-1 inline-block align-middle text-base lowercase small-caps" }, { children: _jsx(FormattedMessage, { defaultMessage: "Light", id: "3cc4Ct" }) })), _jsx("p", __assign({ className: "text-left leading-5" }, { children: _jsx(FormattedMessage, { defaultMessage: "A light and soft color theme optimised for reading comfort: {_} text on a {__} background.", id: "G1MOCe", values: {
                                                _: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-neutral-750 bg-neutral-750 align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-black/7 text-neutral-750 dark:bg-black/7 dark:text-neutral-750 dark:mix-blend-darken" }, { children: "#333" }))] }))),
                                                __: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-neutral-750 bg-neutral-70 align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-black/7 text-neutral-750 dark:bg-black/7 dark:text-neutral-750 dark:mix-blend-darken" }, { children: "#f9f9f9" }))] }))),
                                            } }) }))] }), _jsxs("div", __assign({ className: "mt-4 flex justify-center gap-2" }, { children: [_jsx("div", { className: "h-4 w-4 rounded-full bg-red-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-orange-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-yellow-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-green-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-blue-subtle" })] }))] }))] })), _jsxs("label", __assign({ className: "flex grow basis-5/12 select-none", htmlFor: "color-dark" }, { children: [_jsx("input", { checked: (preference === null || preference === void 0 ? void 0 : preference.color) === 'Dark', className: "peer sr-only -ml-8 mr-4 inline-block whitespace-nowrap", id: "color-dark", name: "color", onChange: function (_a) {
                            var value = _a.target.value;
                            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                                return [2 /*return*/, updateColor(settings(value))];
                            }); });
                        }, type: "radio", value: "Dark" }), _jsxs("div", __assign({ className: "relative box-border flex h-full cursor-pointer flex-col justify-between rounded-lg border border-neutral-250 bg-gray-780 px-5 pt-3.5 pb-6 text-sm font-light text-neutral-250 shadow-md peer-checked:cursor-default peer-checked:border-transparent peer-checked:ring-3 peer-checked:ring-blue-checked dark:border-neutral-500" }, { children: [_jsxs("div", { children: [_jsx("strong", __assign({ className: "mb-1 inline-block align-middle text-base font-bold lowercase small-caps" }, { children: _jsx(FormattedMessage, { defaultMessage: "Dark", id: "tOdNiY" }) })), _jsx("p", __assign({ className: "text-left leading-5" }, { children: _jsx(FormattedMessage, { defaultMessage: "A visual theme for night owls. Goes well with a sans-serif. {_} text on a {__} background.", id: "fq9Wt8", values: {
                                                _: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-neutral-250 bg-neutral-250 align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-white/9 text-neutral-250 mix-blend-lighten" }, { children: "#ddd" }))] }))),
                                                __: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-neutral-250 bg-gray-780 align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-white/9 text-neutral-250 mix-blend-lighten" }, { children: "#292c34" }))] }))),
                                            } }) }))] }), _jsxs("div", __assign({ className: "mt-4 flex justify-center gap-2" }, { children: [_jsx("div", { className: "h-4 w-4 rounded-full bg-red-strong" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-orange-strong" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-yellow-strong" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-green-strong" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-blue-strong" })] }))] }))] })), _jsxs("label", __assign({ className: "flex grow basis-5/12 select-none", htmlFor: "color-increased-contrast" }, { children: [_jsx("input", { checked: (preference === null || preference === void 0 ? void 0 : preference.color) === 'Contrast', className: "peer sr-only -ml-8 mr-4 inline-block whitespace-nowrap", id: "color-increased-contrast", name: "color", onChange: function (_a) {
                            var value = _a.target.value;
                            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                                return [2 /*return*/, updateColor(settings(value))];
                            }); });
                        }, type: "radio", value: "Contrast" }), _jsxs("div", __assign({ className: "relative box-border flex h-full cursor-pointer flex-col justify-between rounded-lg border border-neutral-250 bg-white px-5 pt-3.5 pb-6 text-sm font-normal text-black shadow-md peer-checked:cursor-default peer-checked:border-transparent peer-checked:ring-3 peer-checked:ring-blue-checked dark:border-neutral-500" }, { children: [_jsxs("div", { children: [_jsx("strong", __assign({ className: "mb-1 inline-block align-middle text-base lowercase small-caps" }, { children: _jsx(FormattedMessage, { defaultMessage: "Maximum Contrast", id: "XzzPGF" }) })), _jsx("p", __assign({ className: "text-left leading-5" }, { children: _jsx(FormattedMessage, { defaultMessage: "{black} text on a {white} background{translucency}.", id: "NxhaZU", values: {
                                                black: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-black bg-black align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-black/7 text-black dark:bg-black/7 dark:text-black dark:mix-blend-darken" }, { children: "black" })), !language.includes('en') && (_jsxs(_Fragment, { children: ["\u00A0", _jsx(FormattedMessage, { defaultMessage: "(black)", id: "V4e2xZ" })] }))] }))),
                                                translucency: (
                                                // Only display this message if the browser supports translucency
                                                _jsxs("span", __assign({ className: "hidden supports-blur:inline" }, { children: [' ', _jsx(FormattedMessage, { defaultMessage: "& non-translucent header", id: "uZXmBE" })] }))),
                                                white: (_jsxs("span", __assign({ style: { marginRight: '2px', whiteSpace: 'nowrap' } }, { children: [_jsx("span", { className: "box-border inline-block h-3.5 w-3.5 rounded-full border border-black bg-white align-middle", style: { marginRight: '2px' } }), "\u00A0", _jsx("code", __assign({ className: "bg-black/7 text-black dark:bg-black/7 dark:text-black dark:mix-blend-darken" }, { children: "white" })), !language.includes('en') && (_jsxs(_Fragment, { children: ["\u00A0", _jsx(FormattedMessage, { defaultMessage: "(white)", id: "WnZnFq" })] }))] }))),
                                            } }) }))] }), _jsxs("div", __assign({ className: "mt-4 flex justify-center gap-2" }, { children: [_jsx("div", { className: "h-4 w-4 rounded-full bg-red-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-orange-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-yellow-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-green-subtle" }), _jsx("div", { className: "h-4 w-4 rounded-full bg-blue-subtle" })] }))] }))] }))] })));
};
